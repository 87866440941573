import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Card } from 'react-bootstrap';
import Accordion from '../../components/Accordion';
import SimpleButton from '../../components/SimpleButton/index';
import SimpleTable from '../../components/SimpleTable';
import SimpleModal from '../../components/SimpleModal/index';
import Textarea from '../../components/Textarea';
import PageTitle from '../../components/PageTitle/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  listarEstoques,
  listarMovimentacoes,
  cancelarMovimentacao,
  buscarMotivoCancelamentoMovimentacao,
  receberMovimentacao,
  filtrarUnidade,
  limparLista,
} from '../../actions';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';

import styles from './GerenciarMovimentacoes.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import BackButton from '../../components/BackButton';

class GerenciarMovimentacoesPage extends Component {
  state = {
    isPesquisa: false,
    showModalCancelamento: false,
    showModalMotivo: false,
    estoqueUnidade: -1,
    idMovimentacao: -1,
    motivo: '',
    pesquisaTipo: '',
    // observacao: '',
  }

  componentDidMount() {
    this.props.listarEstoques(this.props.token)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.closeModalNumbers < nextProps.closeModalNumbers) this.handleCloseModalCancelar();
  }

  handlePesquisarEstoque = () => {
    // e.preventDefault();
    this.props.limparLista();
    this.props.listarMovimentacoes(this.state.estoqueUnidade, this.props.token)
  }

  mostrarPaginaRelatorio = idMovimentacao => {
    this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_MOVIMENTACAO_PRODUTOS}/${[this.state.estoqueUnidade, idMovimentacao]}`);
  }

  handleCloseModalCancelar = () => {
    this.setState({ ...this.state, motivo: '', idMovimentacao: -1, showModalCancelamento: false })
  }

  handleShowModalCancelar = idMovimentacao => {
    this.setState({ ...this.state, idMovimentacao, showModalCancelamento: true })
  }

  handleCloseModalMotivo = () => {
    this.setState({ ...this.state, showModalMotivo: false })
  }

  handleShowModalMotivo = idMovimentacao => {
    this.setState({ ...this.state, showModalMotivo: true })
    this.props.buscarMotivoCancelamentoMovimentacao(this.state.estoqueUnidade, idMovimentacao, this.props.token)
  }

  render() {
    //console.log(this.props.estoques);
    //console.log(this.props.list);

    return (
      <>
        <BackButton />
        <Row>
          <PageTitle name='Gerenciar Movimentações de Estoque' hasDivider={true} />
        </Row>

        {/* <Form>
          <Form.Group>
            <Form.Label>Selecione a Unidade Estoque:</Form.Label>
            <Row>
              <Col lg={12}>
                <Form.Control as='select' value={this.state.estoqueUnidade} onChange={e => this.setState({ estoqueUnidade: parseInt(e.target.value) }, () => this.handlePesquisarEstoque())}>
                  <option></option>
                  {this.props.estoquesUsuario.map(estoque => (
                    <option key={estoque.id} value={estoque.id}>{estoque.unidadeEducacional.nome}</option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Form> */}

        <Form>
          <Form.Group>
            <Form.Label>Pesquiar</Form.Label>
            <Row>
              <Col lg={6}>
                <Form.Control as='select' value={this.state.estoqueUnidade} onChange={e => this.setState({ estoqueUnidade: parseInt(e.target.value), pesquisaTipo: '' }, () => this.handlePesquisarEstoque())}>
                  <option></option>
                  {this.props.estoquesUsuario.map(estoque => (
                    <option on key={estoque.id} value={estoque.id}>{estoque.unidadeEducacional.nome}</option>
                  ))}
                </Form.Control>
              </Col>
              <Col lg={6}>
                <Form.Check type='radio' id='origem' inline custom name='tipo' checked={this.state.pesquisaTipo === 'origem'}
                  value={'origem'}
                  label={'ORIGEM'}
                  onChange={e => this.setState({ ...this.state, pesquisaTipo: e.target.value, isPesquisa: true },
                    () => this.props.filtrarUnidade(this.state.estoqueUnidade, this.state.pesquisaTipo, this.props.list))}
                />
                <Form.Check type='radio' id='destino' inline custom name='tipo' checked={this.state.pesquisaTipo === 'destino'}
                  value={'destino'}
                  label={'DESTINO'}
                  onChange={e => this.setState({ ...this.state, pesquisaTipo: e.target.value, isPesquisa: true },
                    () => this.props.filtrarUnidade(this.state.estoqueUnidade, this.state.pesquisaTipo, this.props.list))}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>

        <Accordion>
          {this.props.filtrados && this.props.filtrados.map((movimentacao, index) => (
            <Card className={styles.card} key={movimentacao.id}>
              <Card.Header className={`${styles.cardHeader} ${movimentacao.status === 'CANCELADA' ? styles.cancel : ''} ${movimentacao.status === 'RECEBIDA' ? styles.receive : ''}`} >

                    <Accordion.DataToggle target={movimentacao.id}>

                      <div style={{ display: 'flex', gap: '27px',justifyContent:'space-between' }}>
                        <div>
                          <h6>Origem: {this.props.estoques.map(estoque => (estoque.id === movimentacao.origem ? estoque.unidadeEducacional.nome : ''))}</h6>
                          <h6>Destino: {this.props.estoques.map(estoque => (estoque.id === movimentacao.destino ? estoque.unidadeEducacional.nome : ''))}</h6>
                          <h6>
                            Status: {movimentacao.status}
                            {
                              movimentacao.status === 'CANCELADA'
                              &&
                              <>
                                <div
                                  className={styles.motivo}
                                  onClick={e => this.handleShowModalMotivo(movimentacao.id)}>
                                  <span>?</span>
                                </div>
                              </>
                            }
                          </h6>
                        </div>
                        <div>
                          <h6>Inicio: {movimentacao.dataInicioMovimentacao}</h6>
                          <h6>Fim: {movimentacao.dataFimMovimentacao === null ? '---' : movimentacao.dataFimMovimentacao}</h6>
                        </div>
                        <div style={{ width: '194px'}}>
                          <SimpleButton
                            style={{ marginBottom: '10px' }}
                            type='button'
                            color='green'
                            name='Receber'
                            icon='fas fa-check'
                            onClick={() => this.props.receberMovimentacao(this.state.estoqueUnidade, movimentacao, this.props.token)}
                            disabled={movimentacao.status === 'CANCELADA' || movimentacao.status === 'RECEBIDA' || movimentacao.destino !== this.state.estoqueUnidade}
                          />
                          <SimpleButton
                            style={{ marginBottom: '10px' }}
                            type='button'
                            color='red'
                            name='Cancelar'
                            icon='fas fa-times'
                            onClick={() => this.handleShowModalCancelar(movimentacao.id)}
                            disabled={movimentacao.status === 'CANCELADA' || movimentacao.status === 'RECEBIDA'}
                          />
                          <SimpleButton
                            type='button'
                            color='blue'
                            name='Relatório'
                            icon='fas fa-print'
                            onClick={() => this.mostrarPaginaRelatorio(movimentacao.id)}
                          />
                        </div>
                      </div>
                      <span>Selecionar tipo de observação:</span>
                    <select style={{ width: '30%'}} value={movimentacao.tipoObservacao} onChange={e => movimentacao.tipoObservacao = e.target.value} disabled={movimentacao.status === 'RECEBIDA'}>
                      <option></option>
                      <option value="EXTRAVIO">EXTRAVIO</option>
                      <option value="VENCIDO">VENCIDO</option>
                      <option value="DANIFICADO">DANIFICADO</option>
                      <option value="ESTRAGADO">ESTRAGADO</option>
                    </select>
                    </Accordion.DataToggle>
                    <br/>
                        <Textarea style={{ width: '100%' }}
                          label='* Observação:'
                          value={movimentacao.observacao}
                          onChange={(value, upper) => movimentacao.observacao = value}
                          rows='3'
                          disabled={movimentacao.status === 'RECEBIDA'}
                        />
                      

                    {/* <ActionButton
                      title='Imprimir Relatório de Aquisição'
                      onClick={() => this.mostrarPaginaRelatorio(aquisicao.id)}
                      icon='fas fa-print'
                    /> */}
                  
              </Card.Header>

              <Accordion.DataTarget receiver={movimentacao.id}>
                <Card.Body>
                  <SimpleTable>
                    <thead>
                      <tr>
                        <th>Lote</th>
                        <th>Produto</th>
                        <th>Data Fabricação</th>
                        <th>Data Validade</th>
                        <th>Quantidade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {movimentacao.produtos.map(produto => (
                        <tr key={produto.id}>
                          <td>{produto.produtoEstoque.produtoLote.numero}</td>
                          <td>{produto.produtoEstoque.produtoLote.produto.nome}</td>
                          <td>{produto.produtoEstoque.produtoLote.dataFabricacao}</td>
                          <td>{produto.produtoEstoque.produtoLote.dataValidade}</td>
                          <td>{produto.quantidade}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </SimpleTable>
                </Card.Body>
              </Accordion.DataTarget>
            </Card>
          ))}
        </Accordion>

        <SimpleModal
          show={this.state.showModalCancelamento}
          onHide={this.handleCloseModalCancelar}
          title='Cancelar Licitação'
          icon='fas fa-ban'>

          <Row>
            <Col>
              <Textarea
                label='* Motivo:'
                rows='3'
                value={this.state.motivo}
                onChange={(value, upper) => this.setState({ ...this.state, motivo: value }, () => upper())}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: '16px' }}>
            <Col align='left'>
              <SimpleButton
                type='button'
                color='red'
                name='Fechar'
                icon='fas fa-times'
                onClick={this.handleCloseModalCancelar}
              />
            </Col>
            <Col align='right'>
              <SimpleButton
                type='button'
                color='green'
                name='Cancelar'
                icon='fas fa-ban'
                onClick={e => this.props.cancelarMovimentacao(this.state.estoqueUnidade, this.state.idMovimentacao, this.state.motivo, this.props.token)}
              />
            </Col>
          </Row>
        </SimpleModal>

        <SimpleModal
          show={this.state.showModalMotivo}
          onHide={this.handleCloseModalMotivo}
          title='Motivo'
          icon='fas fa-comment'>

          <Row>
            <Col align='center'>
              <p>{this.props.motivoCancelamento.motivo}</p>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  closeModalNumbers: state.movimentacao.closeModalNumbers,
  estoquesUsuario: state.auth.user.estoques,
  list: state.movimentacao.list,
  motivoCancelamento: state.movimentacao.motivoCancelamento,
  estoques: state.estoque.list,
  filtrados: state.movimentacao.filtrados
})

const mapDispatchToProps = dispatch => bindActionCreators({
  listarEstoques,
  listarMovimentacoes,
  cancelarMovimentacao,
  buscarMotivoCancelamentoMovimentacao,
  receberMovimentacao,
  filtrarUnidade,
  limparLista,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarMovimentacoesPage)), 'ESTOQUES');