import React, { Component } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
//import { Accordion as AccordionPrime, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Accordion from '../../components/Accordion';
import Select from 'react-select';
import moment from 'moment';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal/index';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input/index';
import SearchSelect from '../../components/SearchSelect/index';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarProdutos,
    listarPesquisaEstoques,
    listarItens,
    adicionarProdutoEstoque,
    filtrarValidadeEstoque,
    filtrarLoteEstoque,
    filtrarItemEstoque,
    listarEstoqueItens,
    excluirProdutoEstoque,
    realizarSaidaProdutoEstoque,
    realizarBalancoProdutoEstoque,
    listarEstoquesUsuario,
    filtrarProdutosEstoque
} from '../../actions';

import styles from './Estoque.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import SimpleModalSmall from '../../components/SimpleModalSmall/index';
//import Textarea from '../../components/Textarea/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import Textarea from '../../components/Textarea';
import BackButton from '../../components/BackButton';
//import { reduce } from 'highcharts';

class Estoque extends Component {    
    state = {
        showModalAdd: false,
        showModalSaida: false,
        showModalDeletar:false,
        showModalBalanco: false,

        editingIdEstoque: -1,
        editingIdProduto: -1,
        excluirIdEstoque: -1,
        produtoEstoqueDeletar: '',
        produtoDel: '',
        observacao: '',
        tipoSaidaDireta: '',
        quantidadeSaida: 0,
        quantidadeProdutoEstoque: 0,

        quantidadeBalanco: 0,

        estoquePesquisa: -1,

        pesquisaLote: '',
        pesquisaProduto: '',
        pesquisaItem: '',
        pesquisaValidade: '',

        estoques: [],

        idEstoque: {},
        numero: '',
        produtoSelecionado: {},
        produto: -1,
        dataFabricacao: '',
        dataValidade: '',
        quantidade: 0,
        unidadeProduto: '',

        showEstoques: false,
        showProdutos: false,
    }

    componentDidMount() {
        this.props.listarProdutos(this.props.token)
        //this.props.listarItens(this.props.token)
        //this.props.listarEstoquesUsuario(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.filtrados !== this.props.filtrados) {
            let estoques = JSON.parse(JSON.stringify(nextProps.filtrados))
            let estoquesMap = estoques.map(estoque => ({ ...estoque, formPesquisa: { lote: '', produto: '', item: '', validade: '' } }))
            this.setState({ ...this.state, estoques: estoquesMap })
        }

        if (this.props.closeModalNumbers < nextProps.closeModalNumbers) {
            this.setState({ showModalDeletar:false, showModalAdd: false, showModalSaida: false, showModalBalanco: false })

        }

    }

    handleShowModalAdd = idEstoque => {
        this.setState({ ...this.state, showModalAdd: true, idEstoque })
    }

    handleCloseModalAdd = () => {
        this.setState({ ...this.state, showModalAdd: false })
    }
   
    handleShowModalSaida = (idEstoque, produtoEstoque, quantidade) => {
        this.setState({ ...this.state, showModalSaida: true, editingIdEstoque: idEstoque, editingIdProduto: produtoEstoque, quantidadeProdutoEstoque: quantidade })
    }
    
    handleCloseModalSaida = () => {
        this.setState({ ...this.state, showModalSaida: false, editingIdEstoque: -1, editingIdProduto: -1, tipoSaidaDireta: '', quantidadeSaida: 0, quantidadeProdutoEstoque: 0, observacao: '' })
    }

    handleShowModalDeletar = (idEstoque, produtoEstoque, produto) => {
        this.setState({ ...this.state, showModalDeletar: true, produtoEstoqueDeletar: produtoEstoque, produtoDel: produto, excluirIdEstoque:idEstoque })
    }

    handleCloseModalDeletar = () => {
        this.setState({ ...this.state, showModalDeletar: false })
    }

    handleShowModalBalanco = (idEstoque, produtoEstoque, quantidade) => {
        this.setState({ ...this.state, showModalBalanco: true, editingIdEstoque: idEstoque, editingIdProduto: produtoEstoque, quantidadeProdutoEstoque: quantidade, quantidadeBalanco: quantidade })
    }

    handleCloseModalBalanco = () => {
        this.setState({ ...this.state, showModalBalanco: false, editingIdEstoque: -1, editingIdProduto: -1, quantidadeProdutoEstoque: 0, quantidadeBalanco: 0 })
    }

    handleChangePesquisa = (value, index, property) => {
        let formsPesquisa = this.state.formsPesquisa;
        formsPesquisa[index][property] = value;

        this.setState({ ...this.state, formsPesquisa })
    }

    handleSubmit = e => {
        e.preventDefault();

        let { numero, produto, dataFabricacao, dataValidade, quantidade } = this.state;

        let produtoEstoque = {
            numero, produto, dataFabricacao, dataValidade, quantidade
        }

        // console.log(new Date dataFabricacao)
        this.props.adicionarProdutoEstoque(this.state.estoquePesquisa, produtoEstoque, this.props.token)
    }

    handleDeleteSubmit = e => {
        e.preventDefault();
         this.props.excluirProdutoEstoque(this.state.excluirIdEstoque, this.state.produtoEstoqueDeletar, this.props.token)
    }

    handleSaidaSubmit = e => {
        e.preventDefault();

        const {
              observacao,
              tipoSaidaDireta,
               quantidadeSaida } = this.state;
        
        const saida = {
            observacao,
            tipoSaidaDireta,
            quantidade: quantidadeSaida
        }

        this.props.realizarSaidaProdutoEstoque(this.state.editingIdEstoque, this.state.editingIdProduto, saida, this.props.token)
    }

    handleBalancoSubmit = e => {
        e.preventDefault();

        const balanco = {
            quantidadeAnterior: this.state.quantidadeProdutoEstoque,
            novaQuantidade: this.state.quantidadeBalanco
        }
        console.log(this.state.editingIdEstoque, this.state.editingIdProduto, balanco, this.props.token);
        this.props.realizarBalancoProdutoEstoque(this.state.editingIdEstoque, this.state.editingIdProduto, balanco, this.props.token)
    }

    handleSearchSubmit = () => {
        // e.preventDefault()

        // if(this.state.estoquePesquisa === -1) this.props.listarEstoquesUsuario(this.props.token)
        // else
        //this.props.listarPesquisaEstoques(this.state.estoquePesquisa, this.props.token)
        this.props.listarEstoqueItens(this.state.estoquePesquisa, this.props.token)
        this.setState({ ...this.state, showEstoques: true })
    }

    showProduto() {
        this.setState({ ...this.state, showProdutos: true })
    }

    mostrarPaginaRelatorio() {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_ESTOQUE}/${[this.state.estoquePesquisa]}`);
    }


    // saidaBodyTemplate(rowData) {
    //     return  <SimpleButton
    //     style={{  fontSize: '12px', padding: '1px 1px' }}
    //     type='button'
    //     color='red'
    //     name='Saída Produto'
    //     icon='fas fa-minus'
    //     onClick={() => this.handleShowModalSaida()}
    //       // () => this.handleShowModalSaida(rowData.produtoLote.id, rowData.produtoLote.produto.id, rowData.quantidade)}
        
    // />;
    // }

    render( ) {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Estoque' hasDivider={true} />
                </Row>
                
                <Form>
                    <Form.Group>
                        <Form.Label>Selecione a Unidade:</Form.Label>
                        <Row>
                            <Col lg={12}>
                                <Form.Control as='select' value={this.state.estoquePesquisa} onChange={e => this.setState({ ...this.state, estoquePesquisa: parseInt(e.target.value) }, () => this.handleSearchSubmit())} required>
                                    <option value={-1}></option>
                                    {this.props.estoquesUsuario.map(estoque => (
                                        <option key={estoque.id} value={estoque.id}>{estoque.unidadeEducacional.nome}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            {/* <Col lg={1}>
                                <Button type='submit'>
                                    <i className='fa fa-search'></i>
                                </Button>
                            </Col> */}
                        </Row>
                    </Form.Group>
                </Form>

                <div hidden={!this.state.showEstoques}>
                    <Accordion>
                        <Card key={this.props.filtrado.id}>
                            <Card.Header className={styles.cardHeader}>
                                <Row>
                                    <Col>
                                        <SimpleButton
                                            style={{ marginLeft: '5%' }}
                                            type='button'
                                            color='blue'
                                            name='Relatório'
                                            icon='fas fa-print'
                                            onClick={() => this.mostrarPaginaRelatorio()}
                                        />
                                        {/* <SimpleButton
                                            style={{ marginLeft: '5%' }}
                                            type='submit'
                                            color='green'
                                            name='Adicionar Produto'
                                            icon='fas fa-plus'
                                            onClick={
                                                e => this.handleShowModalAdd(this.props.filtrado.id)
                                            
                                            }
                                        /> */}
                                    </Col>
                                </Row>

                            </Card.Header>

                            <Accordion.DataTarget receiver={this.props.filtrado.id}>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Pesquisar por Lote: </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    value={this.state.pesquisaLote}
                                                    onChange={e => this.setState({ ...this.state, pesquisaLote: e.target.value },
                                                        () => this.props.filtrarLoteEstoque(this.state.pesquisaLote))} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Pesquisar por Produto: </Form.Label>
                                                <Select
                                                    required
                                                    placeholder=''
                                                    isClearable
                                                    value={this.state.pesquisaProduto}
                                                    onChange={selectedOption => this.setState({ ...this.state, pesquisaProduto: selectedOption === null ? {} : selectedOption },
                                                        () => this.props.filtrarProdutosEstoque(this.state.pesquisaProduto.label))}

                                                    options={this.props.produtos.map(produto => ({
                                                        value: produto.id,
                                                        label: produto.nome
                                                    }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Pesquisar por Item: </Form.Label>
                                                <Select
                                                    required
                                                    placeholder=''
                                                    isClearable
                                                    value={this.state.pesquisaItem}
                                                    onChange={selectedOption => this.setState({ ...this.state, pesquisaItem: selectedOption === null ? {} : selectedOption },
                                                        () => this.props.filtrarItemEstoque(this.state.pesquisaItem.label))}
                                                    options={this.props.itens.map((item, index) => ({
                                                        value: index,
                                                        label: item.nome
                                                    }))}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Pesquisar por Validade: </Form.Label>
                                                <Form.Control
                                                    type='date'
                                                    value={this.state.pesquisaValidade}
                                                    onChange={e => this.setState({ ...this.state, pesquisaValidade: e.target.value },
                                                        () => this.props.filtrarValidadeEstoque(moment(this.state.pesquisaValidade, 'YYYY-MM-DD').format('DD/MM/YYYY')))} />
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                    <div className="content-section implementation">

                                    <DataTable style={{ textAlign: 'center' }} value={this.props.filtrado.produtos}>
                                         <Column field="produtoLote.numero" header="Lote"></Column>
                                         <Column field="produtoLote.produto.nome" header="Produto"></Column>
                                         <Column field="produtoLote.produto.item" header="Item"></Column>
                                         <Column field="quantidade" header="Quantidade"></Column>
                                         <Column field="produtoLote.dataFabricacao" header="Fabricação"></Column>
                                         <Column field="produtoLote.dataValidade" header="Validade"></Column>
                                         <Column header="Ações" body={(rowData) => { return(
                                             <div>
                                                <ActionButton
                                                    title='Saída Produto'
                                                    icon='fas fa-minus'
                                                    onClick={
                                                        () => this.handleShowModalSaida( rowData.estoque, rowData.id, rowData.quantidade) 
                                                      }
                                                />
                                                 <ActionButton
                                                    title='Excluir Produto'
                                                    icon='fas fa-trash-alt'
                                                    onClick={               
                                                        () => {this.handleShowModalDeletar(rowData.estoque, rowData.id, rowData.produtoLote.produto)}   
                                                    // () => this.handleShowModalSaida(rowData.id, rowData.produtoLote.produto.id, rowData.quantidade) 
                                                    }
                                                />
                                                  <ActionButton
                                                    title='Realizar Balanço'
                                                    icon='fas fa-balance-scale'
                                                    onClick={
                                                        () => this.handleShowModalBalanco(rowData.estoque,  rowData.produtoLote.id,  rowData.quantidade)
                                                     }
                                                />
                                             </div>
                                             
                                         )}}></Column>
                                     </DataTable>
                                    


                                        {/* <AccordionPrime >
                                            {this.props.filtrado.produtos.map((item, index) => (
                                                <AccordionTab header={'aa'} key={index}>
                                                    <p><b>Quantidade:</b> {item.quantidade}</p>
                                                    <AccordionPrime>
                                                         {produto.produtos.map((produtoX, index) => (
                                                            <AccordionTab key={index} header={produtoX.nome}>
                                                                <p><b>Quantidade:</b> {produtoX.quantidade}</p>
                                                                <AccordionPrime>
                                                                    {produtoX.produtosLote.map((produtosLote, index) => (
                                                                        <AccordionTab key={index} header={`LOTE: ${produtosLote.numero}`}>
                                                                            <Row>
                                                                                <Col>
                                                                                    <p><b>Quantidade:</b> {produtosLote.quantidade}</p>
                                                                                </Col>
                                                                                <Col align='right'>
                                                                                    <SimpleButton
                                                                                        type='button'
                                                                                        color='blue'
                                                                                        name='Realizar Balanço'
                                                                                        icon='fas fa-balance-scale'
                                                                                        onClick={() => this.handleShowModalBalanco(this.state.estoquePesquisa, produtosLote.produtoEstoque, produtosLote.quantidade)}
                                                                                    />

                                                                                </Col>
                                                                            </Row>

                                                                            <Row>
                                                                                <Col>
                                                                                    <p><b>Data de Fabricação:</b> {produtosLote.dataDeFabricacao}</p>
                                                                                </Col>
                                                                                <Col align='right'>
                                                                                    <SimpleButton
                                                                                        type='button'
                                                                                        color='red'
                                                                                        name='Saída Produto'
                                                                                        icon='fas fa-minus'
                                                                                        onClick={() => this.handleShowModalSaida(this.state.estoquePesquisa, produtosLote.produtoEstoque, produtosLote.quantidade)}
                                                                                    />
                                                                                </Col>
                                                                            </Row>

                                                                            <p><b>Data de Validade:</b> {produtosLote.dataDeValidade}</p>
                                                                        </AccordionTab>
                                                                    ))}
                                                                </AccordionPrime>
                                                            </AccordionTab>
                                                        ))} 
                                                    </AccordionPrime>
                                                </AccordionTab>
                                            ))}
                                        </AccordionPrime> */}
                                    </div>
                                </Card.Body>
                            </Accordion.DataTarget>
                        </Card>
                    </Accordion>
                </div>

                <SimpleModal
                    show={this.state.showModalAdd}
                    onHide={this.handleCloseModalAdd}
                    title='Adicionar Produto'
                    icon='fas fa-plus'>

                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do produto' align='left'>
                                    <Row>
                                        <Col md={5}>
                                            <Input
                                                label='* Lote:'
                                                type='text'
                                                value={this.state.numero}
                                                onChange={value => this.setState({ numero: value })}
                                                required
                                            />
                                        </Col>
                                        <Col>
                                            <SearchSelect
                                                label='* Produto:'
                                                value={this.state.produtoSelecionado}
                                                required
                                                onChange={selectedOption =>
                                                    this.setState({
                                                        ...this.state,
                                                        produtoSelecionado: selectedOption,
                                                        produto: selectedOption.value,
                                                        unidadeProduto: this.props.produtos.find(produto => produto.id === selectedOption.value).unidadeProduto
                                                    })}

                                                options={this.props.produtos.map(produto => ({
                                                    value: produto.id,
                                                    label: `${produto.nome} - ${produto.item.nome}`
                                                }))}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='* Data de Fabricação:'
                                                type='date'
                                                value={this.state.dataFabricacao}
                                                onChange={value => this.setState({ dataFabricacao: value })}
                                                required
                                            />
                                        </Col>

                                        <Col md={6}>
                                            <Input
                                                label='* Data de Validade:'
                                                type='date'
                                                value={this.state.dataValidade}
                                                onChange={value => this.setState({ dataValidade: value })}
                                                min= {new Date().toISOString().split("T")[0]}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label={`* Quantidade: (${this.state.unidadeProduto})`}
                                                type='number'
                                                min='0'
                                                value={this.state.quantidade}
                                                onChange={value => this.setState({ quantidade: value })}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModalAdd}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Adicionar'
                                    icon='fas fa-plus'
                                />
                            </Col>
                        </Row>
                    </Form>

                </SimpleModal>

                <SimpleModal 
                    show={this.state.showModalSaida}
                    onHide={this.handleCloseModalSaida}
                    title='Realizar Saída de Produto'
                    icon='fas fa-edit'>


                    <Form onSubmit={this.handleSaidaSubmit}>
                        <Row>
                            <Col>
                                <Textarea
                                    label='* Observação:'
                                    rows={3}
                                    value={this.state.observacao}
                                    onChange={(value, upper) => this.setState({ observacao: value }, () => upper())}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SimpleSelect
                                    label='* Tipo de Saida:'
                                    value={this.state.tipoSaidaDireta}
                                    onChange={e => this.setState({ ...this.state, tipoSaidaDireta: e.target.value })}
                                    required
                                >
                                    
                                    <option></option>
                                    <option value='ESTRAVIO'>ESTRAVIO</option>
                                    <option value='PREVISAO_LEGAL'>PREVISÃO LEGAL</option>
                                </SimpleSelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input
                                    label='* Quantidade:'
                                    type='number'
                                    min='1'
                                    max={this.state.quantidadeProdutoEstoque}
                                    value={this.state.quantidadeSaida}
                                    onChange={value => this.setState({ quantidadeSaida: value })}
                                    aside={this.state.quantidadeProdutoEstoque}
                                    required
                                />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModalSaida}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Realizar Saída'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>

                </SimpleModal>

                <SimpleModal 
                    show={this.state.showModalDeletar}
                    onHide={this.handleCloseModalDeletar}
                    title={`Deletar o produto ${this.state.produtoDel.nome} do estoque?`}
                    icon='fas fa-exclamation'
                    style={{width: '800px', marginLeft: '25%'}}
                    >
                        <Row style={{ marginTop: '16px' }}>
                            <Col align='center'>

                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModalDeletar}
                                />
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Confirmar'
                                    icon='fas fa-check'
                                    onClick={this.handleDeleteSubmit}
                                />
                            </Col>
                        </Row>

                </SimpleModal>

                <SimpleModalSmall
                    show={this.state.showModalBalanco}
                    onHide={this.handleCloseModalBalanco}
                    title='Balanço do Produto'
                    icon='fas fa-balance-scale'>

                    <Form onSubmit={this.handleBalancoSubmit}>
                        <Row>
                            <Col>
                                <Input
                                    label='* Quantidade:'
                                    type='number'
                                    min='0'
                                    value={this.state.quantidadeBalanco}
                                    onChange={value => this.setState({ quantidadeBalanco: value })}
                                    required
                                />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModalBalanco}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Realizar Balanço'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModalSmall>

            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalNumbers: state.estoque.closeModalNumbers,
    estoquesUsuario: state.auth.user.estoques,
    filtrado: state.estoque.filtrado,
    produtos: state.produto.list,
    itens: state.estoque.itens,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarProdutos,
    adicionarProdutoEstoque,
    excluirProdutoEstoque,
    realizarSaidaProdutoEstoque,
    realizarBalancoProdutoEstoque,
    listarEstoquesUsuario,
    listarPesquisaEstoques,
    filtrarProdutosEstoque,
    listarItens,
    listarEstoqueItens,
    filtrarItemEstoque,
    filtrarLoteEstoque,
    filtrarValidadeEstoque
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(Estoque)), 'ESTOQUES');