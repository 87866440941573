import React, { Component } from 'react'
import { Form, Row, Col, Modal } from 'react-bootstrap';
// import Accordion from '../../components/Accordion/index';
import moment from 'moment';
import PageTitle from '../../components/PageTitle/index';
import Calendar from '../../components/Calendar/index';
import SimpleModal from '../../components/SimpleModal/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleTable from '../../components/SimpleTable/index';
import SimpleButton from '../../components/SimpleButton/index';
import BackButton from '../../components/BackButton';
import Fieldset from '../../components/Fieldset';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';

import {
    listarCardapios,
    listarCardapiosSemanais,
    vincularCardapioCalendario,
    editarCardapioCalendario,
    desvincularCardapioCalendario,
    listarCardapiosCalendarioPorUnidade,
    listarCardapiosCalendarioPorMesEUnidade,
} from '../../actions'

import withAuthorization from '../../components/HighOrder/withAuthorization';

import styles from './CardapioCalendario.module.css';
import Input from '../../components/Input';
import sessionStorage from 'redux-persist/es/storage/session';

class CardapioCalendarioPage extends Component {
    state = {
        showModalVincular: false,
        showModalGerenciar: false,

        idGerenciar: -1,
        idUnidadePesquisa: -1,

        cardapios: [],
        tipoCardapio: '',
        selecionarTodas: false,

        cardapio: -1,
        data: '',
        unidadesEducacionais: [],

        showModalTeste: false,

        dataInicial: '',
        dataFinal: ''
    }

    componentDidMount() {

        this.props.listarCardapios(this.props.token);
        this.props.listarCardapiosSemanais(this.props.token);
        let unidadesUsuario = JSON.parse(JSON.stringify(this.props.unidadesUsuario)).map(unidade => ({ ...unidade.unidadeEducacional, checked: false }));

        this.setState({ ...this.state, unidadesEducacionais: unidadesUsuario })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalCount < nextProps.closeModalCount) this.setState({ showModalVincular: false, showModalGerenciar: false })
    }

    handleChangePesquisaUnidade = () => {
        // e.preventDefault();

        this.props.listarCardapiosCalendarioPorUnidade(this.state.idUnidadePesquisa, this.props.token);
    }

    handleChangeCardapio = value => {
        this.setState({ tipoCardapio: value }, () => {
            switch (value) {
                case 'DIARIO':
                    this.setState({ cardapios: JSON.parse(JSON.stringify(this.props.cardapios.filter(cardapio => cardapio.cardapioTipo === 'REGULAR'))) })
                    break;

                case 'SEMANAL':
                    this.setState({ cardapios: JSON.parse(JSON.stringify(this.props.cardapiosSemanais)) })
                    break;

                default:
                    break;
            }
        });
    }

    handleChangeQuantAlunosCardapio = (value, index) => {
        let cardapioExecutar = JSON.parse(JSON.stringify(this.state.cardapioExecutar))

        cardapioExecutar.pratos[index].quantAlunos = value;

        this.setState({ ...this.state, cardapioExecutar })
    }

    selecionarTodas = () => {
        let unidades = this.state.unidadesEducacionais;
        let modificandoUnidades = [];
        if (this.state.selecionarTodas) {
            modificandoUnidades = unidades.map(unidade => {
                unidade.checked = false;
                return unidade;
            })
        } else {
            modificandoUnidades = unidades.map(unidade => {
                unidade.checked = true;
                return unidade;
            })
        }

        this.setState({ ...this.state, selecionarTodas: !this.state.selecionarTodas, unidadesEducacionais: modificandoUnidades })
    }

    selecionarUnidade = index => {
        let unidades = this.state.unidadesEducacionais;
        unidades[index].checked = !unidades[index].checked;

        this.setState({ ...this.state, unidadesEducacionais: unidades })
    }

    handleSubmitVincular = e => {
        e.preventDefault();

        let { tipoCardapio, cardapio, data, unidadesEducacionais } = this.state;

        let newUnidadesEducacionais = unidadesEducacionais
            .filter(unidade => unidade.checked)
            .map(unidade => ({ unidade: unidade.id }))

        let vinculacao = {
            tipo: tipoCardapio,
            cardapio,
            data: moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            unidadesEducacionais: newUnidadesEducacionais
        }

        // console.log(vinculacao)

        this.props.vincularCardapioCalendario(vinculacao, this.state.idUnidadePesquisa, this.props.token);

        this.resetState(unidadesEducacionais);
    }

    handleEditar = e => {
        e.preventDefault();

        let { cardapio, unidadesEducacionais } = this.state;
        let vinculacao = {
            id: this.state.idGerenciar,
            cardapio
        }

        // console.log(vinculacao);

        this.props.editarCardapioCalendario(vinculacao, this.state.idUnidadePesquisa, this.props.token);

        this.resetState(unidadesEducacionais);
    }

    handleDesvincular = e => {
        e.preventDefault();

        this.props.desvincularCardapioCalendario(this.state.idGerenciar, this.state.idUnidadePesquisa, this.props.token)

        let { unidadesEducacionais } = this.state;
        this.resetState(unidadesEducacionais);
    }

    resetState = unidadesEducacionais => {
        this.setState({
            tipoCardario: '',
            // cardapio: -1,
            // data: '',
            unidadesEducacionais: unidadesEducacionais.map(unidade => ({ ...unidade, checked: false })),
            selecionarTodas: false,
            // idGerenciar: -1,
        })
    }

    mostrarPaginaRelatorio() {
        const datas = {
            dataInicial: moment(this.state.dataInicial, 'YYYY-MM-DD').format('DDMMYYYY'),
            dataFinal: moment(this.state.dataFinal, 'YYYY-MM-DD').format('DDMMYYYY')
        }
        sessionStorage.setItem('datas', JSON.stringify(datas));
        //
        //this.props.listarCardapiosCalendarioPorMesEUnidade(datas.dataInicial, datas.dataFinal, this.state.idUnidadePesquisa, this.props.token)
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_CARDAPIOS}/${this.state.idUnidadePesquisa}`);
    }

    formatDate() {

    }

    // eslint-disable-next-line no-undef
    handleDateClick = (e) => { 
        // eslint-disable-next-line no-undef
        // const options = {
        //     locale: 'pt-br',
        //     buttonText: {
        //         today: 'Hoje',
        //     },

           // dateClick: e => {
               this.setState({ showModalVincular: true, data: e.dateStr, cardapio: -1 })
           // },

            // eslint-disable-next-line no-unused-expressions
            info => {
               let cardapio = this.props.cardapiosCalendario.find(cardapio => cardapio.id === parseInt(info.event.id))
                this.handleChangeCardapio('DIARIO');
               this.setState({ showModalGerenciar: true, idGerenciar: e.event.id, cardapio: cardapio.cardapio.id })
            }
       // };
        }
        
    render() {

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Calendário de Cardápios' hasDivider={true} />
                </Row>

                <Form>
                    <Form.Group>
                        <Form.Label>Selecione a Unidade Educacional:</Form.Label>
                        <Row>
                            <Col lg={12}>
                                <Form.Control as='select' value={this.state.idUnidadePesquisa} onChange={e => this.setState({ idUnidadePesquisa: e.target.value }, () => this.handleChangePesquisaUnidade())} required>
                                    <option value=''></option>
                                    {this.props.unidadesUsuario.map((unidade, index) => (
                                        <option key={index} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            {/* <Col lg={1}>
                                <Button type='submit'>
                                    <i className='fa fa-search'></i>
                                </Button>
                            </Col> */}
                        </Row>
                    </Form.Group>
                </Form>

                <Row className={styles.legendas}>
                    <b>Legenda: </b>
                    <div className={`${styles.legenda} ${styles.legendaDiario}`}></div>
                    <div className={styles.tituloLegenda}>Diário</div>
                    <div className={`${styles.legenda} ${styles.legendaSemanal}`}></div>
                    <div className={styles.tituloLegenda}>Semanal</div>
                </Row>
                <Row>
                    <Col align='right'>
                        <SimpleButton
                            type='button'
                            color='blue'
                            title='Antes, selecione uma Unidade Educacional'
                            name='Relatório'
                            icon='fas fa-print'
                            disabled={this.state.idUnidadePesquisa === -1}
                            onClick={() => this.setState({ showModalTeste: true })}
                        />
                    </Col>
                </Row>
                <br />

                <Calendar events={this.props.cardapiosCalendarioEventos} dateClick={this.handleDateClick} />

                <SimpleModal
                    show={this.state.showModalVincular}
                    onHide={() => this.setState({ ...this.state, showModalVincular: false })}
                    title='Vincular Cardápio'
                    icon='fas fa-link'>

                    <Form onSubmit={this.handleSubmitVincular}>
                        <Form.Group>
                            <SimpleSelect
                                label='* Tipo:'
                                value={this.state.tipoCardapio}
                                onChange={e => this.handleChangeCardapio(e.target.value)}
                                required
                            >
                                <option></option>
                                <option value='DIARIO'>Diário</option>
                                <option value='SEMANAL'>Semanal</option>
                            </SimpleSelect>
                        </Form.Group>

                        <Form.Group>
                            <SimpleSelect
                                label='* Cardápio:'
                                value={this.state.cardapio}
                                onChange={e => this.setState({ cardapio: e.target.value })}
                                required
                            >

                                <option></option>
                                {
                                    this.state.cardapios
                                        .map(cardapio => (
                                            <option key={cardapio.id} value={cardapio.id}>{cardapio.nome}</option>
                                        ))
                                }
                            </SimpleSelect>
                        </Form.Group>

                        {/* <Accordion>
                            <Card>
                                <Card.Header className={styles.accordionHeader}>
                                    <Accordion.DataToggle target='unidades'>
                                        Selecione as Unidades
                                    </Accordion.DataToggle>
                                </Card.Header>

                                <Accordion.DataTarget receiver='unidades'>
                                    <Card.Body>
                                    </Card.Body>
                                </Accordion.DataTarget>
                            </Card>
                        </Accordion> */}

                        <Row>
                            <Col>
                                <Fieldset legend='Unidades educacionais' align='left'>
                                    <SimpleTable>
                                        <thead>
                                            <tr>
                                                <th><Form.Check type='checkbox' checked={this.state.selecionarTodas} onChange={this.selecionarTodas} /></th>
                                                <th>Unidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.unidadesEducacionais.map((unidade, index) => (
                                                <tr key={index}>
                                                    <td><Form.Check type='checkbox' checked={unidade.checked} onChange={() => this.selecionarUnidade(index)} /></td>
                                                    <td>{unidade.nome}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </SimpleTable>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ showModalVincular: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Vincular'
                                    icon='fas fa-link'
                                />
                            </Col>
                        </Row>
                        {/* <Button type='submit' className={styles.actionModalButton} block variant='success'>Vincular</Button> */}
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalGerenciar}
                    onHide={() => this.setState({ ...this.state, showModalGerenciar: false })}
                    title='Gerenciar Cardápio'
                    icon='fas fa-edit'>

                    <Form.Group>
                        <SimpleSelect
                            label='Cardápio:'
                            value={this.state.cardapio}
                            onChange={e => this.setState({ cardapio: e.target.value })}
                            required>

                            <option></option>
                            {this.state.cardapios.map((cardapio, index) => (
                                <option key={index} value={cardapio.id}>{cardapio.nome}</option>
                            ))}
                        </SimpleSelect>
                    </Form.Group>

                    <Row style={{ marginTop: '16px' }}>
                        <Col alig='left'>
                            <SimpleButton
                                type='button'
                                color='red'
                                name='Desvincular'
                                icon='fas fa-unlink'
                                onClick={this.handleDesvincular}
                            />
                        </Col>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Atualizar'
                                icon='fas fa-edit'
                                onClick={this.handleEditar}
                            />
                        </Col>
                    </Row>


                </SimpleModal>

                <Modal
                    show={this.state.showModalTeste}
                    onHide={() => this.setState({ ...this.state, showModalTeste: false })}
                    size='lg'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title>Relatório - Escolha de Datas</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <Input
                                    label='Data Inicial:'
                                    type='date'
                                    value={this.state.dataInicial}
                                    onChange={value => this.setState({ ...this.state, dataInicial: value })}
                                />
                            </Col>
                            <Col>
                                <Input
                                    label='Data Final:'
                                    type='date'
                                    value={this.state.dataFinal}
                                    onChange={value => this.setState({ ...this.state, dataFinal: value })}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col align="right">
                                <SimpleButton
                                    name="Ver Relatório"
                                    icon='fas fa-check'
                                    color='green' 
                                    onClick={() => this.mostrarPaginaRelatorio()}
                                />
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalCount: state.cardapioCalendario.closeModalCount,
    unidadesUsuario: state.auth.user.estoques,
    cardapiosCalendario: state.cardapioCalendario.list,
    cardapiosCalendarioEventos: state.cardapioCalendario.eventos,
    cardapios: state.cardapio.list,
    cardapiosSemanais: state.cardapio.semanais,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarCardapios,
    listarCardapiosSemanais,
    vincularCardapioCalendario,
    editarCardapioCalendario,
    desvincularCardapioCalendario,
    listarCardapiosCalendarioPorUnidade,
    listarCardapiosCalendarioPorMesEUnidade,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(CardapioCalendarioPage)), 'CARDAPIOS')