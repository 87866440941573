import React, { Component } from 'react';
import { Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import Textarea from '../../components/Textarea';
import SimpleButton from '../../components/SimpleButton';
import TinyAction from '../../components/TinyAction';
import SearchSelect from '../../components/SearchSelect';
import Input from '../../components/Input/index';
import PageTitle from '../../components/PageTitle/index';
import Fieldset from '../../components/Fieldset';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarPraparo, listarAlimentos, cadastrarAlimento, listarItens, listarFormasAlimentos, listarComponentesNutricionais, listarCategorias, listarGruposAlimentares } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import { roundToTwo } from '../../utils/mathCalc';

import styles from './CadastrarPreparo.module.css';

const INITIAL_FORM = {
    showModalComponenteIngrediente: false,

    modoPreparo: '',
    porcoes: 1,
    nomePreparo: '',
    ingredientes: [
        { alimento: -1, alimentoSelecionado: {}, unidadeMedida: '', quantidade: 0, substitutos: [] }
    ],

    nome: '',
    grupoAlimentar: '',
    item: -1,
    quantidadeBase: 0,
    forma: '',
    componentes: [],

    fichaTecnica: [
        { nome: '', unidadeMedida: '', valor: '' }
    ],
}

const INITIAL_INGREDIENTES = [
    { alimento: -1, alimentoSelecionado: {}, unidadeMedida: '', quantidade: 0, substitutos: [] }
]

const INITIAL_FICHA = [
    { nome: '', unidadeMedida: '', valor: '' }
]

class CadastrarPreparoPage extends Component {
    state = {
        showModalComponenteIngrediente: false,

        modoPreparo: '',
        porcoes: 1,
        nomePreparo: '',
        ingredientes: [
            { alimento: -1, alimentoSelecionado: {}, unidadeMedida: '', quantidade: 0, substitutos: [] }
        ],

        nome: '',
        grupoAlimentar: '',
        item: -1,
        quantidadeBase: 0,
        forma: '',
        componentes: [],

        fichaTecnica: [
            { nome: '', unidadeMedida: '', valor: '' }
        ],
    }

    componentDidMount() {
        this.props.listarAlimentos(this.props.token)
        // this.props.listarComponentesNutricionais(this.props.token)
        // this.props.listarCategorias(this.props.token)
        // this.props.listarGruposAlimentares(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.resetCount < nextProps.resetCount) this.setState({ ...INITIAL_FORM, ingredientes: JSON.parse(JSON.stringify(INITIAL_INGREDIENTES)), fichaTecnica: JSON.parse(JSON.stringify(INITIAL_FICHA)) })
    }

    calcularFichaTecnica = () => {
        let ingredientes = JSON.parse(JSON.stringify(this.state.ingredientes));
        let componenentesFichaTecnica = [];

        let alimentosFetch = this.props.alimentos;
        if (alimentosFetch.length > 0) {
            let componentesPadroes = alimentosFetch[0].componentes.map(componente => ({ tipo: componente.componente.tipo, unidadeMedida: componente.componente.unidadeMedida }));

            for (let i = 0; i < ingredientes.length; i++) {
                let ingrediente = ingredientes[i];

                let alimento = this.props.alimentos.find(a => a.id === ingrediente.alimento)
                if (alimento !== undefined) {
                    let componentes = alimento.componentes.map(componente => ({ tipo: componente.componente.tipo, valor: ((componente.valor * ingrediente.quantidade) / alimento.quantidadeBase) / this.state.porcoes }));
                    componenentesFichaTecnica.push(...componentes);
                }
            }

            let componentesFinais = [];
            for (let k = 0; k < componentesPadroes.length; k++) {
                let componentePadrao = componentesPadroes[k];

                if (componenentesFichaTecnica.length > 0) {
                    let valorSoma = componenentesFichaTecnica
                        .filter(componente => componente.tipo === componentePadrao.tipo)
                        .map(componente => componente.valor)
                        .reduce((accumulator, currentValue) => accumulator + currentValue)

                    let obj = { nome: componentePadrao.tipo, unidadeMedida: componentePadrao.unidadeMedida, valor: roundToTwo(valorSoma) }
                    componentesFinais.push(obj)
                }
            }

            // console.log(componentesFinais);
            this.setState({ ...this.state, fichaTecnica: componentesFinais });
        }
    }

    // INGREDIENTES

    handleIngredientesInput = (value, index, property) => {
        let ingredientes = this.state.ingredientes;

        if (property === 'alimento') {
            ingredientes[index].alimentoSelecionado = value;
            ingredientes[index][property] = value.value;
            ingredientes[index].unidadeMedida = this.props.alimentos.find(alimento => alimento.id === value.value).unidadeMedida;
        } else {
            ingredientes[index][property] = value;
        }

        this.setState({ ...this.state, ingredientes });
    }

    handleAddIngrediente = index => {
        let ingrediente = { alimento: -1, alimentoSelecionado: {}, quantidade: 0, substitutos: [] }

        let ingredientes = this.state.ingredientes;
        ingredientes.splice(index + 1, 0, ingrediente);

        this.setState({ ...this.state, ingredientes });
    }

    handleRemoveIngrediente = index => {
        let ingredientes = this.state.ingredientes;
        if (ingredientes.length > 1) {
            ingredientes.splice(index, 1);

            this.setState({ ...this.state, ingredientes });
        }
    }

    // SUBSTITUTOS

    handleSubstitutosInput = (value, ingredienteIndex, subsIndex, property) => {
        let ingredientes = JSON.parse(JSON.stringify(this.state.ingredientes));

        if (property === 'alimento') {
            ingredientes[ingredienteIndex].substitutos[subsIndex].alimentoSelecionado = value;
            ingredientes[ingredienteIndex].substitutos[subsIndex][property] = value.value;
            ingredientes[ingredienteIndex].substitutos[subsIndex].unidadeMedida = this.props.alimentos.find(alimento => alimento.id === value.value).unidadeMedida;
        } else {
            ingredientes[ingredienteIndex].substitutos[subsIndex][property] = value;
        }

        this.setState({ ...this.state, ingredientes })
    }

    handleIniciarSbustitutos = ingredienteIndex => {
        let ingredientes = JSON.parse(JSON.stringify(this.state.ingredientes));

        if (ingredientes[ingredienteIndex].substitutos.length === 0) {
            let substituto = { alimento: -1, alimentoSelecionado: {}, unidadeMedida: '', quantidade: 0, patologia: '' }
            ingredientes[ingredienteIndex].substitutos.push(substituto);
        } else {
            ingredientes[ingredienteIndex].substitutos = [];
        }

        this.setState({ ...this.state, ingredientes })
    }

    handleAddSubstituto = (ingredienteIndex, subsIndex) => {
        let substituto = { alimento: -1, alimentoSelecionado: {}, unidadeMedida: '', quantidade: 0, patologia: '' }

        let ingredientes = JSON.parse(JSON.stringify(this.state.ingredientes));
        ingredientes[ingredienteIndex].substitutos.splice(subsIndex + 1, 0, substituto);

        this.setState({ ...this.state, ingredientes })
    }

    handleRemoveSubstituto = (ingredienteIndex, subsIndex) => {
        let ingredientes = JSON.parse(JSON.stringify(this.state.ingredientes));
        ingredientes[ingredienteIndex].substitutos.splice(subsIndex, 1);

        this.setState({ ...this.state, ingredientes })
    }

    // COMPONENTES 

    handleShowComponenteModal = () => {
        this.props.listarItens(this.props.token);
        this.props.listarFormasAlimentos(this.props.token);
        this.setState({
            ...this.state,
            showModalComponenteIngrediente: true,
            componentes: JSON.parse(JSON.stringify(this.props.componentesNutricionais))
        });
    }

    handleComponentesInput = (value, index, property) => {
        let componentes = this.state.componentes;
        componentes[index][property] = value;

        this.setState({ ...this.state, componentes });
    }

    handleSubmit = e => {
        e.preventDefault();

        let { porcoes, nomePreparo, modoPreparo, ingredientes } = this.state;

        let preparo = {
            nome: nomePreparo,
            porcoes,
            modoPreparo,
            ingredientes
        }

        // console.log(preparo)

        this.props.cadastrarPraparo(preparo, this.props.token);
    }

    handleSubmitAlimento = e => {
        e.preventDefault();

        let { nome, grupoAlimentar, item, quantidadeBase, forma, componentes } = this.state;

        let { id, itemProdutoTipo } = this.props.itens.find(i => i.id === item)

        let itemAlimento = {
            id, itemProdutoTipo
        }

        let newComponentesFormat = componentes.map(comp => ({ componente: comp.id, valor: comp.valor }))

        let alimento = {
            nome,
            grupoAlimentar,
            itemAlimento,
            quantidadeBase,
            forma,
            componentes: newComponentesFormat
        }

        // console.log(alimento)

        this.props.cadastrarAlimento(alimento, this.props.token);
    }

    render() {
        const popover = (
            <Popover id='fichaTecnicaPopover' title='Ficha Técnica do Preparo'>
                {
                    this.state.porcoes !== 0
                    &&
                    this.state.fichaTecnica.map((componente, index) => (
                        <p key={index}>
                            <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeMedida})
                    </p>
                    ))
                }
            </Popover>
        )

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Preparo' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Row>
                    <OverlayTrigger trigger='click' placement='right' overlay={popover}>
                        <SimpleButton
                            type='button'
                            name='Ver ficha técnica atual'
                            color='blue'
                            icon='fas fa-clipboard'
                            onClick={this.calcularFichaTecnica}
                        />
                    </OverlayTrigger>
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Dados do preparo' align='left'>
                                <Row>
                                    <Col md={8}>
                                        <Input
                                            label='* Nome:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nomePreparo}
                                            onChange={(value, upper) => this.setState({ ...this.state, nomePreparo: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            label='* Porções'
                                            type='number'
                                            value={this.state.porcoes}
                                            onChange={value => this.setState({ ...this.state, porcoes: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Textarea
                                            label='* Modo de preparo:'
                                            rows='4'
                                            placeholder='Descreva aqui o modo de preparo.'
                                            value={this.state.modoPreparo}
                                            onChange={(value, upper) => this.setState({ ...this.state, modoPreparo: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Ingredientes' align='left'>
                                {this.state.ingredientes.map((ingrediente, index) => (
                                    <div className={styles.ingredientes} key={index}>
                                        <Row>
                                            <Col md={5}>
                                                <SearchSelect
                                                    label='Ingrediente:'
                                                    value={ingrediente.alimentoSelecionado}
                                                    onChange={selectedOption => this.handleIngredientesInput(selectedOption, index, 'alimento')}

                                                    options={this.props.alimentos.map(alimento => ({
                                                        value: alimento.id,
                                                        label: alimento.nome
                                                    }))}
                                                    required
                                                />
                                            </Col>
                                            <Col md={5}>
                                                <Input
                                                    label={`Quantidade: (${!ingrediente.unidadeMedida ? '' : ingrediente.unidadeMedida})`}
                                                    type='number'
                                                    min='0'
                                                    value={ingrediente.quantidade}
                                                    onChange={value => this.handleIngredientesInput(parseInt(value), index, 'quantidade')}
                                                    required
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Adicionar novo ingrediente'
                                                            onClick={() => this.handleAddIngrediente(index)}
                                                            color='green'
                                                            icon='fas fa-plus'
                                                        />
                                                    </Col>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Remover ingrediente'
                                                            onClick={() => this.handleRemoveIngrediente(index)}
                                                            color='red'
                                                            icon='fas fa-minus'
                                                        />
                                                    </Col>
                                                    {/* <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Adicionar substitutos'
                                                            onClick={() => this.handleIniciarSbustitutos(index)}
                                                            color='blue'
                                                            icon='fas fa-exchange-alt'
                                                        />
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                        </Row>

                                        {/* {ingrediente.substitutos.map((subs, subsIndex) => (
                                            <div key={subsIndex}>
                                                <Row>
                                                    <Col md={10}>
                                                        <SearchSelect
                                                            label='Ingrediente:'
                                                            value={subs.alimentoSelecionado}
                                                            onChange={selectedOption => this.handleSubstitutosInput(selectedOption, index, subsIndex, 'alimento')}

                                                            options={this.props.alimentos.map(alimento => ({
                                                                value: alimento.id,
                                                                label: alimento.nome
                                                            }))}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Row>
                                                            <Col align='center'>
                                                                <TinyAction
                                                                    type='button'
                                                                    title='Adicionar novo substituto'
                                                                    onClick={() => this.handleAddSubstituto(index, subsIndex)}
                                                                    color='green'
                                                                    icon='fas fa-plus'
                                                                />
                                                            </Col>
                                                            <Col align='center'>
                                                                <TinyAction
                                                                    type='button'
                                                                    title='Remover Substituto'
                                                                    onClick={() => this.handleRemoveSubstituto(index, subsIndex)}
                                                                    color='red'
                                                                    icon='fas fa-minus'
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Input
                                                            label={`Quantidade: (${!subs.unidadeMedida ? '' : subs.unidadeMedida})`}
                                                            type='number'
                                                            value={subs.quantidade}
                                                            onChange={e => this.handleSubstitutosInput(parseInt(e.target.value), index, subsIndex, 'quantidade')}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Input
                                                            label='* Patologia:'
                                                            type='text'
                                                            value={subs.patologia}
                                                            onChange={e => this.handleSubstitutosInput(e.target.value, index, subsIndex, 'patologia')}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))} */}
                                    </div>
                                ))}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetCount: state.preparo.resetCount,
    alimentos: state.alimento.list,
    componentesNutricionais: state.alimento.componentesNutricionais,
    itens: state.item.list,
    formasAlimentos: state.convencao.formasAlimentos,
    categorias: state.convencao.categorias,
    gruposAlimentares: state.convencao.gruposAlimentares,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarPraparo,
    listarAlimentos,
    cadastrarAlimento,
    listarItens,
    listarFormasAlimentos,
    listarComponentesNutricionais,
    listarCategorias,
    listarGruposAlimentares
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarPreparoPage), 'PREPAROS');