import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listarBalancos, buscarEnderecoUnidade } from '../../actions';

import styles from './styles.module.css';

class Balancos extends Component {
    state = {
        unidadeEducacional: ''
    }

    componentWillMount() {
        this.props.buscarEnderecoUnidade(this.props.data, this.props.token);
    }

    componentDidMount() {
        const idEstoque = this.props.data;
        this.props.listarBalancos(idEstoque, this.props.token);

        const estoque = this.props.estoquesUsuario.filter(estoque => estoque.id === parseInt(idEstoque));
        this.setState({ unidadeEducacional: estoque[0].unidadeEducacional.nome });
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header  escola={this.props.enderecoUnidade}/>

                    <Title name='Relatório de Balanços de Estoque' />

                    <Detail>
                        <div className='row'>
                            <div className='col'>
                                <b>Unidade educacional:</b> {this.state.unidadeEducacional}
                            </div>
                        </div>

                        <br />

                        <div className='row'>
                            <div className='col'>
                                <table border='1' className={styles.tableBalanco}>
                                    <thead>
                                        <tr>
                                            <th>Produto</th>
                                            <th>Quant. Anterior</th>
                                            <th>Nova Quant.</th>
                                            <th>Usuário da ação</th>
                                            <th>Data e Hora</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.balancos.map(balanco => (
                                            <tr key={balanco.id}>
                                                <td>{balanco.produtoLote.produto.item} - {balanco.produtoLote.produto.nome}</td>
                                                <td>{balanco.quantidadeAnterior}</td>
                                                <td>{balanco.novaQuantidade}</td>
                                                <td>{balanco.usuario.pessoa.nome}</td>
                                                <td>{balanco.dataHora}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </Detail>
                </Printable>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    balancos: state.estoque.balancos,
    estoquesUsuario: state.auth.user.estoques,
    enderecoUnidade: state.unidadeEducacional.enderecoUnidade
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarBalancos,
    buscarEnderecoUnidade
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Balancos);