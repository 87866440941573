import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarItem, listarCategoriasAlimentos, listarTiposComponentesNutricionais, listarUnidadesMedidaNutricionais } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

const INITIAL_FORM = {
    nome: '',
    itemProdutoTipo: '',
    categoria: '',
}

class CadastrarItemPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarCategoriasAlimentos(this.props.token)
        this.props.listarTiposComponentesNutricionais(this.props.token)
        this.props.listarUnidadesMedidaNutricionais(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.resetNumbers) this.setState({ ...INITIAL_FORM })
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.cadastrarItem(this.state, this.props.token);
    }

    render() {
        let componentesForm =
            (
                <Row>
                    <Col>
                        <SimpleSelect
                            label='* Categoria:'
                            value={this.state.categoria}
                            onChange={e => this.setState({ ...this.state, categoria: e.target.value })}
                            required
                        >

                            <option value=''></option>
                            {this.props.categoriasAlimentos.map((categoria, index) =>
                                <option key={index} value={categoria.nome}>{categoria.descricao}</option>
                            )}
                        </SimpleSelect>
                    </Col>
                </Row>
            );

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Item' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados do item' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            type='text'
                                            label='* Nome:'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ ...this.state, nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Tipo:'
                                            value={this.state.itemProdutoTipo}
                                            onChange={e => this.setState({ ...this.state, itemProdutoTipo: e.target.value })}
                                            required
                                        >
                                            <option value=''></option>
                                            <option value='ALIMENTO'>ALIMENTO</option>
                                            <option value='LIMPEZA'>LIMPEZA</option>
                                            <option value='ESCRITORIO'>ESCRITÓRIO</option>
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                {this.state.itemProdutoTipo === 'ALIMENTO' && componentesForm}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetNumbers: state.item.resetNumbers,
    categoriasAlimentos: state.convencao.categoriasAlimentos,
    tiposComponentesNutricionais: state.convencao.tiposComponentesNutricionais,
    unidadesMedidaNutricionais: state.convencao.unidadesMedidaNutricionais,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarItem,
    listarCategoriasAlimentos,
    listarTiposComponentesNutricionais,
    listarUnidadesMedidaNutricionais,
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarItemPage), 'ITENS');