import React from 'react';
import LogoutLink from '../../LogoutLink/index';
import { Row, Col, Popover, Overlay, Badge } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import { buscarFotoUsuario } from '../../../actions/usuario.aciton';

import styles from './SubheaderUser.module.css';
import Notifications from '../Notifications';

const userPopover = (usuario, foto) => (
    <Popover id='userPopover' bsPrefix={styles.userPopover}  >
        {/* <Row>
            <Col>
                <div>
                    <img src={foto} alt='' style={{ width: '80px', height: '80px', borderRadius: '50%', marginLeft: 20, marginRight: 20 }} />
                </div>
            </Col>
        </Row> */}
        <Row >

            <Col style={{ paddingRight: 0 }}>
                <Notifications />
            </Col>
            <Col style={{ textAlign: 'right', marginBottom: '20px', fontWeight: 'bold', marginTop: '10px' }}>
                {usuario.usuario} <br />
                {usuario.pessoa.pessoaTipo} <br /> <br />
                <LogoutLink />
            </Col>
            <p onclick={e => this.setState({ show: false })} className={styles.closeButton}>X</p>

        </Row>
    </Popover>
)

class SubheaderUser extends React.Component {
    state = {
        show: false
    }


    // componentDidMount() {
    //     this.props.buscarFotoUsuario(this.props.usuario.id, this.props.token);
    // }

    render() {
        return (
            <div className={styles.flexContainer}>
                <div className={styles.item}>-</div>
                <span>
                    <Row>
                        <Col className={styles.flexContainerUserdatas}>
                            <span className={styles.welcomeMessage}>
                                Seja bem-vindo(a):
                            </span>
                            <span className={styles.username}>
                                {this.props.usuario.pessoa.nome}
                            </span>
                        </Col>
                        <Col>
                            <div className={styles.userImageContainer}>
                                {
                                    this.props.notificacoes.length > 0
                                    &&
                                    <Badge className={styles.badge} variant='danger'>{this.props.notificacoes.length}</Badge>
                                }
                                <div
                                    className={styles.userImageClick}
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => this.setState({ show: !this.state.show, target: e.target })}
                                >


                                    <img className={styles.userimage} src={this.props.photo} alt='' style={{ width: '60px', height: '60px', borderRadius: '100%' }} />
                                    <Overlay target={this.state.target} data-toggle="popover" show={this.state.show} placement='bottom' >
                                        {userPopover(this.props.usuario, this.props.photo)}
                                    </Overlay>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </span>
                <div className={styles.item}>-</div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    usuario: state.auth.user,
    photo: state.auth.photo,
    token: state.auth.token,
    notificacoes: state.auth.notificacoes,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    // buscarFotoUsuario,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubheaderUser);