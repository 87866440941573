import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import auth from './auth.reducer';
import item from './item.reducer';
import produto from './produto.reducer';
import usuario from './usuario.reducer';
import escola from './escola.reducer';
import fornecedor from './fornecedor.reducer';
import aquisicao from './aquisicao.reducer';
import aditivo from './aditivo.reducer';
import solicitacao from './solicitacao.reducer';
import estoque from './estoque.reducer';
import movimentacao from './movimentacao.reducer';
import convencao from './convencao.reducer';
import unidadeEducacional from './unidadeEducacional.reducer';
import alimento from './alimento.reducer';
import preparo from './preparo.reducer';
import prato from './prato.reducer';
import cardapio from './cardapio.reducer';
import cardapioCalendario from './cardapioCalendario.reducer';
import execucao from './execucao.reducer';

export default combineReducers({
    auth,
    item,
    produto,
    usuario,
    escola,
    fornecedor,
    aquisicao,
    aditivo,
    solicitacao,
    estoque,
    movimentacao,
    convencao,
    unidadeEducacional,
    alimento,
    preparo,
    prato,
    cardapio,
    cardapioCalendario,
    execucao,
    toastr,
})