import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filtrarProdutos, listarProdutos } from '../../actions';

import styles from './styles.module.css';

class Produtos extends Component {

    state = {
        produtos: [],
    }

    componentWillMount() {
        const data = this.props.data.split('=');
        var pesquisaItem, pesquisaProduto = '';
        if (data[1] === 'true') {
            pesquisaItem = sessionStorage.getItem('pesquisaItem');
            pesquisaProduto = sessionStorage.getItem('pesquisaProduto');
            this.props.filtrarProdutos(pesquisaItem, pesquisaProduto);
        } else if (data[1] === 'false') {
            this.props.listarProdutos(this.props.token);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.produtos !== this.props.produtos) this.setState({ ...this.state, produtos: nextProps.produtos }, () => console.log(this.state));
    }

    componentWillUnmount() {
        sessionStorage.removeItem('pesquisaItem');
        sessionStorage.removeItem('pesquisaProduto');
    }

    render() {
        return (
            <>
              <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Produtos' />

                    <Detail>
                        <table border='1' className={styles.table}>
                            <thead>
                                <tr className={styles.titulo}>
                                    <th>Item</th>
                                    <th>Nome</th>
                                    <th>Unidade</th>
                                    <th>Medida</th>
                                    <th>Perecível</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.produtos.map((produto, index) => (
                                        <tr key={index}>
                                            <th className={styles.dados}>{produto.item.nome}</th>
                                            <th className={styles.dados}>{produto.nome}</th>
                                            <th className={styles.perecivel}>{produto.unidadeProduto}</th>
                                            <th className={styles.perecivel}>{produto.medidaReal} ({produto.unidadeMedida})</th>
                                            {
                                                produto.isPerecivel === true ? (
                                                    <th className={styles.perecivel}>SIM</th>
                                                ): 
                                                (
                                                    <th className={styles.perecivel}>NÃO</th>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    produtos: state.produto.filtrados
})

const mapDispatchToProps = dispatch => bindActionCreators({
    filtrarProdutos,
    listarProdutos
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Produtos);