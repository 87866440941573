import { LOGIN, TOKEN_VALIDATED, LOGOUT, UPDATE_AUTH_USER, ATUALIZAR_FOTO_USUARIO, BUSCAR_NOTIFICACOES, BUSCAR_SECRETARIA, IS_LOADING_AUTH } from '../actions/types'

import userImgIfError from './../utils/userImgIfError';

const INITIAL_STATE = {
    // user: {},
    user: { permissoes: [] },
    photo: userImgIfError,
    token: '',
    notificacoes: [],
    secretaria: [],
    isValidToken: false,
    isloading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                isValidToken: true
            }

        case TOKEN_VALIDATED: {
            if (action.payload) return { ...state, isValidToken: true }
            else return { ...state, user: {}, isValidToken: action.payload, token: '' }
        }

        case LOGOUT:
            return { ...state, user: {}, isValidToken: action.payload, token: '', photo: '', notificacoes: [] }

        case UPDATE_AUTH_USER:
            if (state.user.id === action.payload.id) return { ...state, user: action.payload }
            else return state

        case ATUALIZAR_FOTO_USUARIO:
            return { ...state, photo: action.payload }

        case BUSCAR_NOTIFICACOES: return { ...state, notificacoes: action.payload }

        case BUSCAR_SECRETARIA: return { ...state, secretaria: action.payload }

        case IS_LOADING_AUTH: return {
            ...state, isloading: action.payload
        }

        default:
            return state
    }
}


