import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleTable from '../../components/SimpleTable';
import SimpleButton from '../../components/SimpleButton/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarBalancos } from '../../actions';

// import styles from './Balancos.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';

class BalancosPage extends Component {
    state = {
        showEstoques: false,
        estoquePesquisa: -1,
    }

    handleSearchSubmit = () => {
        this.props.listarBalancos(this.state.estoquePesquisa, this.props.token)
        this.setState({ ...this.state, showEstoques: true })
    }

    mostrarPaginaRelatorio = () => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_BALANCO_ESTOQUE}/${this.state.estoquePesquisa}`);
    }

    render() {
        return (
            <>  
                <BackButton/>
                <Row>
                    <PageTitle name='Balanços de estoques' hasDivider={true} />
                </Row>

                <div align='right'>
                    <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        disabled={!this.state.showEstoques}
                        onClick={() => this.mostrarPaginaRelatorio()}
                    />
                </div>

                <Form>
                    <Form.Group>
                        <Form.Label>Selecione a Unidade:</Form.Label>
                        <Row>
                            <Col lg={12}>
                                <Form.Control as='select' value={this.state.estoquePesquisa} onChange={e => this.setState({ ...this.state, estoquePesquisa: parseInt(e.target.value) }, () => this.handleSearchSubmit())} required>
                                    <option value={-1}></option>
                                    {this.props.estoquesUsuario.map(estoque => (
                                        <option key={estoque.id} value={estoque.id}>{estoque.unidadeEducacional.nome}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <div hidden={!this.state.showEstoques}>
                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Produto</th>
                                <th>Quant. Anterior</th>
                                <th>Nova Quant.</th>
                                <th>Usuário da ação</th>
                                <th>Data e Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.balancos.map((balanco, index) => (
                                <tr key={index}>
                                    <td>{balanco.produtoLote.produto.item} - {balanco.produtoLote.produto.nome}</td>
                                    <td>{balanco.quantidadeAnterior}</td>
                                    <td>{balanco.novaQuantidade}</td>
                                    <td>{balanco.usuario.pessoa.nome}</td>
                                    <td>{balanco.dataHora}</td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    balancos: state.estoque.balancos,
    estoquesUsuario: state.auth.user.estoques,

})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarBalancos,
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(BalancosPage)), 'ESTOQUES');