import React from 'react';
// import { Row, Col, Popover } from 'react-bootstrap';

import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import styles from './Notifications.module.css';

const Notifications = props => (
    <div className={styles.notifications}>
        <b>Notificações</b>
        <ul>
            {props.notificacoes.length === 0 ?
                (
                    <>
                        Não há notificações
                    </>
                )
                :
                (
                    <>
                        {props.notificacoes.map((notificacao, index) => (
                            <li key={index}>
                                {notificacao.mensagem}
                            </li>
                        ))}
                    </>
                )
            }

        </ul>
    </div>
)

const mapStateToProps = state => ({
    notificacoes: state.auth.notificacoes
})

export default connect(mapStateToProps, null)(Notifications);