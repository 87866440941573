import { 
    LISTAR_MOVIMENTACOES,
    LISTAR_UMA_MOVIMENTACAO,
    MOVIMENTACAO_RELATORIO,
    BUSCAR_MOTIVO_CANCELAMENTO_MOVIMENTACAO, 
    CADASTRAR_MOVIMENTACAO,
    EDITAR_MOVIMENTACAO,
    FILTRAR_UNIDADE,
    LIMPAR_LISTA,
 } from '../actions/types';

const INITIAL_STATE = {
    list: [],
    movimentacao: {
        produtos: []
    },
    relatorio: {
        produtos: [],
        origem: {},
        destino: {},
        filtrados: []
    },
    motivoCancelamento: {},

    resetNumbers: 0,
    closeModalNumbers: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_MOVIMENTACOES:
            return { ...state, list: action.payload }

        case LISTAR_UMA_MOVIMENTACAO:
            return { ...state, movimentacao: action.payload }

        case BUSCAR_MOTIVO_CANCELAMENTO_MOVIMENTACAO:
            return { ...state, motivoCancelamento: action.payload }

        case CADASTRAR_MOVIMENTACAO:
            return { ...state, resetNumbers: ++state.resetNumbers }

        case EDITAR_MOVIMENTACAO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case MOVIMENTACAO_RELATORIO:
            return { ...state, relatorio: action.payload }

        case FILTRAR_UNIDADE:
            return { ...state, filtrados: action.payload }
        
        case LIMPAR_LISTA:
            return{ ...state, filtrados: action.payload }

        default:
            return state
    }
}