import axios from 'axios';

export async function find() {
   const removeAcento = (text) =>
    {                                                              
        text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'A');
        text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'E');
        text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'I');
        text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'O');
        text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'U');
        text = text.replace(new RegExp('[Ç]','gi'), 'C');
        text = text.replace(/_/g, ""); // remove underscore
        return text;                
    }
    const cidade= await JSON.parse(localStorage.getItem('cidade'))
    let aux = removeAcento(cidade.nome)
    aux = aux.toLowerCase()

    //temporario
    let url = `https://${aux}.iescolaronline.com.br/api/escola`;
    
    //let url = `http://localhost:8080/iescolar/api/escola`; -- PARA TESTE.

    return axios.get(url);
}