export const RELATORIO_AQUISICAO = 'aquisicao-individual';
export const RELATORIO_SOLICITACAO_COMPRA = 'solicitacao-compra';
export const RELATORIO_PRODUTOS_REFEICAO = 'produtos-refeicao';
export const RELATORIO_MOVIMENTACAO_PRODUTOS = 'movimentacao-produtos';
export const RELATORIO_ESTOQUE = 'estoque';
export const RELATORIO_CARDAPIOS = 'cardapios';
export const RELATORIO_FORNECEDORES = 'fornecedores';
export const RELATORIO_ITENS = 'itens';
export const RELATORIO_PRODUTOS = 'produtos';
export const RELATORIO_USUARIOS = 'usuarios';
export const RELATORIO_PREPAROS = 'preparos';
export const RELATORIO_ALIMENTOS = 'alimentos';
export const RELATORIO_KIT_ESCOLAR = 'kit-merenda';
export const RELATORIO_REFEICAO = 'refeicao';
export const RELATORIO_PRATOS = 'pratos';
export const RELATORIO_RECEBIMENTO_COMPRA = 'recebimento-compra';
export const RELATORIO_BALANCO_ESTOQUE = 'balanco-estoque'; 