import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';

import styles from './HomeItemModal.module.css';

class HomeItemModal extends React.Component {
    state = {
        showItemModal: false
    }

    render() {
        return (
            <div>
                <div onClick={() => this.setState({ showItemModal: true })}>
                    {React.cloneElement(React.Children.toArray(this.props.children)[0])}
                </div>

                <Modal
                    size="lg"
                    centered
                    show={this.state.showItemModal}
                    onHide={() => this.setState({ showItemModal: false })}>
                    
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.name}</Modal.Title>
                    </Modal.Header>

                    <Row className={styles.modal}>
                        {React.Children.map(this.props.children, (child, i) => {
                            if(i === 0) return;
                            return (
                                <Col className={styles.eachModalItem}>
                                    {React.cloneElement(child)}
                                </Col>
                            )
                        })}
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default HomeItemModal;