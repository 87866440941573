import React from 'react';

import styles from './styles.module.css';

const Detail = ({ children }) => (
    <div className={styles.detail}>
        {children}
    </div>
)

export default Detail;