import React, { Component } from 'react';
import { Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import SimpleTable from '../../components/SimpleTable/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal';
import SimpleButton from '../../components/SimpleButton/index';
import Input from '../../components/Input/index';
import Textarea from '../../components/Textarea/index';
import Fieldset from '../../components/Fieldset';
import SearchSelect from '../../components/SearchSelect/index';
import TinyAction from '../../components/TinyAction/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarPreparos, editarPreparo, excluirPreparo, listarAlimentos, listarCategorias, filtrarPreparos } from '../../actions';

import styles from './GerenciarPreparos.module.css';

import { roundToTwo } from '../../utils/mathCalc';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';
import BackButton from '../../components/BackButton';

class GerenciarPreparosPage extends Component {
    state = {
        showModal: false,
        showModalDetalhes: false,
        preparo: {
            ingredientes: []
        },

        pesquisaNome: '',

        fichaTecnica: [
            { nome: '', unidadeDeMedida: '', valor: '' }
        ],

        fichaTecnicaEditar: [
            { nome: '', unidadeDeMedida: '', valor: '' }
        ]
    }

    componentDidMount() {
        this.props.listarPreparos(this.props.token)
        this.props.listarAlimentos(this.props.token)
        // this.props.listarCategorias(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalCount < nextProps.closeModalCount) this.handleCloseModal();
    }

    visualizarFichaTecnica = nutriencesPreparo => {
        this.setState({ ...this.state, fichaTecnica: nutriencesPreparo })
    }

    calcularFichaTecnicaEdicao = () => {
        let preparo = JSON.parse(JSON.stringify(this.state.preparo));
        let ingredientes = preparo.ingredientes;
        let componenentesFichaTecnica = [];

        let alimentosFetch = this.props.alimentos;
        if (alimentosFetch.length > 0) {
            let componentesPadroes = alimentosFetch[0].componentes.map(componente => ({ tipo: componente.componente.tipo, unidadeMedida: componente.componente.unidadeMedida }));

            for (let i = 0; i < ingredientes.length; i++) {
                let ingrediente = ingredientes[i];

                let alimento = this.props.alimentos.find(a => a.id === ingrediente.alimento)
                if (alimento !== undefined) {
                    let componentes = alimento.componentes.map(componente => ({ tipo: componente.componente.tipo, valor: ((componente.valor * ingrediente.quantidade) / alimento.quantidadeBase) / this.state.preparo.porcoes }));
                    componenentesFichaTecnica.push(...componentes);
                }
            }

            let componentesFinais = [];
            for (let k = 0; k < componentesPadroes.length; k++) {
                let componentePadrao = componentesPadroes[k];

                if (componenentesFichaTecnica.length > 0) {
                    let valorSoma = componenentesFichaTecnica
                        .filter(componente => componente.tipo === componentePadrao.tipo)
                        .map(componente => componente.valor)
                        .reduce((accumulator, currentValue) => accumulator + currentValue)

                    let obj = { nome: componentePadrao.tipo, unidadeDeMedida: componentePadrao.unidadeMedida, valor: roundToTwo(valorSoma) }
                    componentesFinais.push(obj)
                }
            }

            // console.log(componentesFinais);
            this.setState({ ...this.state, fichaTecnicaEditar: componentesFinais });
        }
    }

    handleShowModal = preparo => {
        let copyPreparo = JSON.parse(JSON.stringify(preparo));
        copyPreparo.ingredientes = copyPreparo.ingredientes
            .map(ingrediente => ({
                ...ingrediente,
                alimentoSelecionado: {
                    value: ingrediente.alimento,
                    label: this.props.alimentos.find(alimento => alimento.id === ingrediente.alimento).nome
                }
            }))
        this.setState({ showModal: true, preparo: copyPreparo })
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleShowModalDetalhes = preparo => {
        let copyPreparo = JSON.parse(JSON.stringify(preparo));
        copyPreparo.ingredientes = copyPreparo.ingredientes
            .map(ingrediente => ({
                ...ingrediente,
                alimentoSelecionado: {
                    value: ingrediente.alimento,
                    label: this.props.alimentos.find(alimento => alimento.id === ingrediente.alimento).nome
                }
            }))
        this.setState({ showModalDetalhes: true, preparo: copyPreparo })
    }

    handleCloseModalDetalhes = () => {
        this.setState({ showModalDetalhes: false })
    }

    handleChange = (value, property, upper = () => { }) => {
        let preparo = this.state.preparo;
        preparo[property] = value;

        this.setState({ ...this.state, preparo }, () => upper())
    }

    // INGREDIENTES

    handleIngredientesInput = (value, index, property) => {
        let preparo = this.state.preparo;

        if (property === 'alimento') {
            preparo.ingredientes[index].alimentoSelecionado = value;
            preparo.ingredientes[index][property] = value.value;
            preparo.ingredientes[index].unidadeMedida = this.props.alimentos.find(alimento => alimento.id === value.value).unidadeMedida;
        } else {
            preparo.ingredientes[index][property] = value;
        }

        this.setState({ ...this.state, preparo })
    }

    handleAddIngrediente = index => {
        let ingrediente = { alimento: -1, alimentoSelecionado: {}, quantidade: 0 }

        let preparo = this.state.preparo;
        preparo.ingredientes.splice(index + 1, 0, ingrediente);

        this.setState({ ...this.state, preparo })
    }

    handleRemoveIngrediente = index => {
        let preparo = this.state.preparo;
        if (preparo.ingredientes.length !== 1) {
            preparo.ingredientes.splice(index, 1);

            this.setState({ ...this.state, preparo })
        }
    }

    handleEditSubmit = e => {
        e.preventDefault();
        // console.log(this.state.preparo)
        this.props.editarPreparo(this.state.preparo, this.props.token)
    }

    mostrarPaginaRelatorio = idPreparo => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_PREPAROS}/${idPreparo}`);
    }

    render() {
        const popover = (
            <Popover id='fichaTecnicaPopover' title='Ficha Técnica do Preparo'>
                {this.state.fichaTecnica.map((componente, index) => (
                    <p key={index}>
                        <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeDeMedida})
                    </p>
                ))}
            </Popover>
        )

        const popoverEdicao = (
            <Popover id='fichaTecnicaPopoverEdicao' title='Ficha Técnica do Preparo'>
                {
                    this.state.preparo.porcoes !== 0
                    &&
                    this.state.fichaTecnicaEditar.map((componente, index) => (
                        <p key={index}>
                            <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeDeMedida})
                    </p>
                    ))
                }
            </Popover>
        )

        return (
            <>  
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Preparos' hasDivider={true} />
                </Row>

                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Nome: </Form.Label>
                            <Form.Control
                                type='text'
                                value={this.state.pesquisaNome}
                                onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value },
                                    () => this.props.filtrarPreparos(this.state.pesquisaNome))}
                            />
                        </Form.Group>
                    </Row>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            {/* <th>Número</th> */}
                            <th>Nome</th>
                            <th>Porções</th>
                            <th>Ficha Técnica</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map((preparo, index) => (
                            <tr key={preparo.id}>
                                {/* <td>{(index + 1)}</td> */}
                                <td>{preparo.nome}</td>
                                {/* <td>
                                    {
                                        this.props.categorias.map(cat => cat.id === preparo.categoria ? `${cat.modalidade} / ${cat.faixaEtaria}` : '')
                                    }
                                </td> */}
                                <td>{preparo.porcoes}</td>
                                <td>
                                    <OverlayTrigger trigger='click' placement='left' overlay={popover}>
                                        <ActionButton
                                            title='Visualizar ficha técnica'
                                            onClick={() => this.visualizarFichaTecnica(preparo.nutrientes)}
                                            icon='fas fa-clipboard-list'
                                        />
                                    </OverlayTrigger>
                                </td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Preparo'
                                        onClick={() => this.handleShowModalDetalhes(preparo)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Editar Preparo'
                                        onClick={() => this.handleShowModal(preparo)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Preparo'
                                        onClick={() => this.props.excluirPreparo(preparo.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                    <ActionButton
                                        title='Imprimir Relatório de Preparo'
                                        onClick={() => this.mostrarPaginaRelatorio(preparo.id)}
                                        icon='fas fa-print'
                                    />

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Preparo'
                    icon='fas fa-edit'
                >

                    <p>
                        <OverlayTrigger trigger='click' placement='right' overlay={popoverEdicao}>
                            <SimpleButton
                                type='button'
                                name='Ver ficha técnica atual'
                                color='blue'
                                icon='fas fa-clipboard'
                                onClick={this.calcularFichaTecnicaEdicao}
                            />
                        </OverlayTrigger>
                    </p>

                    <Form onSubmit={this.handleEditSubmit}>
                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Dados do preparo' align='left'>
                                    <Row>
                                        <Col md={8}>
                                            <Input
                                                label='Nome:'
                                                type='text'
                                                case='upper'
                                                value={this.state.preparo.nome}
                                                onChange={(value, upper) => this.handleChange(value, 'nome', upper)}
                                                required
                                            />
                                        </Col>

                                        <Col md={4}>
                                            <Input
                                                label='Porções:'
                                                type='number'
                                                value={this.state.preparo.porcoes}
                                                onChange={value => this.handleChange(value, 'porcoes')}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Textarea
                                                label='Modo de preparo:'
                                                rows='3'
                                                value={this.state.preparo.modoPreparo}
                                                onChange={(value, upper) => this.handleChange(value, 'modoPreparo', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Ingredientes' align='left'>
                                    {this.state.preparo.ingredientes.map((ingrediente, index) => (
                                        <Row key={index}>
                                            <Col md={5}>
                                                <SearchSelect
                                                    label='Ingrediente:'
                                                    value={ingrediente.alimentoSelecionado}
                                                    onChange={selectedOption => this.handleIngredientesInput(selectedOption, index, 'alimento')}
                                                    options={this.props.alimentos.map(alimento => ({
                                                        value: alimento.id,
                                                        label: alimento.nome
                                                    }))}
                                                    required
                                                />
                                            </Col>
                                            <Col md={5}>
                                                <Input
                                                    label={`Quantidade: (${!ingrediente.unidadeMedida ? '' : ingrediente.unidadeMedida})`}
                                                    type='number'
                                                    min='0'
                                                    value={ingrediente.quantidade}
                                                    onChange={value => this.handleIngredientesInput(value, index, 'quantidade')}
                                                    required
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Adicionar novo ingrediente'
                                                            onClick={() => this.handleAddIngrediente(index)}
                                                            color='green'
                                                            icon='fas fa-plus'
                                                        />
                                                    </Col>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Remover ingrediente'
                                                            onClick={() => this.handleRemoveIngrediente(index)}
                                                            color='red'
                                                            icon='fas fa-minus'
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalDetalhes}
                    onHide={this.handleCloseModalDetalhes}
                    title='Detalhes'
                    icon='fas fa-eye'
                >

                    {/* <p>
                        <OverlayTrigger trigger='click' placement='right' overlay={popoverEdicao}>
                            <SimpleButton
                                type='button'
                                name='Ver ficha técnica atual'
                                color='blue'
                                icon='fas fa-clipboard'
                                onClick={this.calcularFichaTecnicaEdicao}
                            />
                        </OverlayTrigger>
                    </p> */}

                    <Form>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do preparo' align='left'>
                                    <Row>
                                        <Col md={8}>
                                            <Input
                                                label='Nome:'
                                                value={this.state.preparo.nome}
                                                disabled
                                            />
                                        </Col>

                                        <Col md={4}>
                                            <Input
                                                label='Porções:'
                                                value={this.state.preparo.porcoes}
                                                disabled
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Textarea
                                                label='Modo de preparo:'
                                                rows='3'
                                                value={this.state.preparo.modoPreparo}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Ingredientes' align='left'>
                                    {this.state.preparo.ingredientes.map((ingrediente, index) => (
                                        <Row key={index}>
                                            <Col md={6}>
                                                <SearchSelect
                                                    label='Ingrediente:'
                                                    value={ingrediente.alimentoSelecionado}
                                                    isDisabled
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Input
                                                    label={`Quantidade: (${!ingrediente.unidadeMedida ? '' : ingrediente.unidadeMedida})`}
                                                    value={ingrediente.quantidade}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalCount: state.preparo.closeModalCount,
    preparos: state.preparo.list,
    filtrados: state.preparo.filtrados,
    alimentos: state.alimento.list,
    categorias: state.convencao.categorias
});

const mapDispatchToProps = dispatch => bindActionCreators({
    listarPreparos,
    listarAlimentos,
    listarCategorias,
    editarPreparo,
    excluirPreparo,
    filtrarPreparos
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarPreparosPage)), 'PREPAROS');