import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import multi from 'redux-multi';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt';
import reducers from '../reducers';
import dotenv from 'dotenv';

dotenv.config();

const encryptor = createEncryptor({
    secretKey: process.env.REACT_APP_PERSIST_ENCRYPT_KEY,
    onError: error => { console.log(error) }
})

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    transforms: [encryptor],
    whitelist: ['auth'],
}

const persistedReducers = persistReducer(persistConfig, reducers)

const store = createStore(
    persistedReducers,
    composeWithDevTools(
        applyMiddleware(multi, thunk, promise)
    )
)

const persistor = persistStore(store)

export { 
    store, persistor 
};