import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Fieldset from '../../components/Fieldset';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton';
import Input from '../../components/Input';
import TextInputMask from '../../components/TextInputMask/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarUnidadeEducacional, listarEstoques, listarTiposPermissoes, listarTiposPessoa, listarCargos } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

class CadastrarUnidadePage extends Component {
    state = {
        usuario: '',
        selecionarTodosEstoques: false,
        selecionarTodasPermissoes: false,

        unidadeEstoque: -1,
        permissao: '',

        nome: '',
        codigoInep: '',
        cep: '',
        cidade: '',
        logradouro: '',
        bairro: '',
        numero: '',
        telefone: '',
        tipounidade: '',
        latitude: '',
        longitude: '',

    }

    componentDidMount() {
        this.props.listarEstoques(this.props.token)
        this.props.listarTiposPermissoes(this.props.token)
        this.props.listarTiposPessoa(this.props.token)
        this.props.listarCargos(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tiposPermissoes !== this.props.tiposPermissoes) {
            this.setState({ ...this.state, permissoes: nextProps.tiposPermissoes.map(permissao => ({ nome: permissao, checked: false })) })
        }

        // if (nextProps.estoques !== this.props.estoques) {
        //     this.setState({ ...this.state, estoques: nextProps.estoques.map(estoque => ({ id: estoque.id, nome: estoque.unidadeEducacional.nome, checked: false })) })
        // }
    }

    selecionarTodosEstoques = () => {
        let estoques = this.state.estoques;
        let modificandoEstoques = [];
        if (this.state.selecionarTodosEstoques) {
            modificandoEstoques = estoques.map(e => {
                e.checked = false;
                return e;
            })
        } else {
            modificandoEstoques = estoques.map(e => {
                e.checked = true;
                return e;
            })
        }

        this.setState({ ...this.state, selecionarTodosEstoques: !this.state.selecionarTodosEstoques, estoques: modificandoEstoques })
    }

    selecionarTodasPermissoes = () => {
        let permissoes = this.state.permissoes;
        let modificandoPermissoes = [];
        if (this.state.selecionarTodasPermissoes) {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = false;
                return p;
            })
        } else {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = true;
                return p;
            })
        }

        this.setState({ ...this.state, selecionarTodasPermissoes: !this.state.selecionarTodasPermissoes, permissoes: modificandoPermissoes })
    }

    selecionarEstoque = index => {
        let estoques = this.state.estoques;
        estoques[index].checked = !estoques[index].checked;

        this.setState({ ...this.state, estoques })
    }

    selecionarPermissao = index => {
        let permissoes = this.state.permissoes;
        permissoes[index].checked = !permissoes[index].checked;

        this.setState({ ...this.state, permissoes })
    }

    handleAddEstoque = e => {
        e.preventDefault();

        let estoques = this.state.estoques;
        let { id, unidadeEducacional } = this.props.estoques.find(e => e.id === this.state.unidadeEstoque)

        let estoque = { id, unidadeEducacional }

        estoques.push(estoque)

        this.setState({ ...this.state, estoques, showModalEstoques: false, unidadeEstoque: -1 })
    }

    handleRemoveEstoque = estoque => {
        let estoques = this.state.estoques;

        let newEstoques = estoques.filter(e => e.id !== estoque.id)

        this.setState({ ...this.state, estoques: newEstoques })
    }

    handleAddPermissao = e => {
        e.preventDefault();

        let permissoes = this.state.permissoes;

        permissoes.push(this.state.permissao)

        this.setState({ ...this.state, permissoes, showModalPermissoes: false, permissao: '' })
    }

    handleRemovePermissao = permissao => {
        let permissoes = this.state.permissoes;

        let newPermissoes = permissoes.filter(p => p !== permissao)

        this.setState({ ...this.state, permissoes: newPermissoes, permissao: '' })
    }

    handleSubmit = e => {

        e.preventDefault();
        let { usuario, nome, codigoInep, cep, cidade, logradouro, bairro, numero, telefone, tipounidade,
            latitude, longitude, estoques, permissoes } = JSON.parse(JSON.stringify(this.state));

        let u = {
            nome,
            codigoInep,
            cidade,
            logradouro,
            bairro,
            numero,
            cep: cep.replace(new RegExp(/\D/g), ''),
            telefone: telefone.replace(new RegExp(/\D/g), ''),
            tipoUnidade: tipounidade,
            nomeDoResponsavel: 'SISTEMA'
        }
        // console.log(u)

        this.props.cadastrarUnidadeEducacional(u, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton />
                <Row>
                    <PageTitle name='Cadastro de Unidade' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados' align='left'>
                                <Row>
                                    <Col md={12}>
                                        <Input 
                                            label='* Nome:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ ...this.state, nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='* Cep:'
                                            type='text'
                                            mask='99999-999'
                                            value={this.state.cep}
                                            onChange={e => this.setState({ ...this.state, cep: e.target.value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='* Cidade:'
                                            type='text'
                                            case='upper'
                                            value={this.state.cidade}
                                            onChange={(value, upper) => this.setState({ ...this.state, cidade: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Logradouro:'
                                            type='text'
                                            value={this.state.logradouro}
                                            onChange={value => this.setState({ ...this.state, logradouro: value })}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Número'
                                            type='number'
                                            value={this.state.numero}
                                            onChange={value => this.setState({ ...this.state, numero: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md={6}>
                                        <Input
                                            label='Bairro'
                                            type='text'
                                            value={this.state.bairro}
                                            onChange={value => this.setState({ ...this.state, bairro: value })}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='* Telefone:'
                                            type='text'
                                            mask='(99) 99999-9999'
                                            value={this.state.telefone}
                                            onChange={e => this.setState({ ...this.state, telefone: e.target.value })}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Tipo de Unidade:'
                                            value={this.state.tipounidade}
                                            onChange={e => this.setState({ ...this.state, tipounidade: e.target.value })}
                                            required
                                        >
                                            <option hidden></option>
                                            <option key={1} value={'ESCOLA'}>Escola</option>
                                            <option key={2} value={'INSTITUICAO'}>Instituição</option>
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {this.state.tipounidade === 'ESCOLA' &&
                                            <Input
                                                label='* Código Inep'
                                                type='number'
                                                value={this.state.codigoInep}
                                                onChange={value => this.setState({ ...this.state, codigoInep: value })}
                                                required
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>



                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'

                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    estoques: state.estoque.list,
    tiposPermissoes: state.convencao.tiposPermissoes,
    tiposPessoa: state.convencao.tiposPessoa,
    cargos: state.convencao.cargos
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarUnidadeEducacional,
    listarEstoques,
    listarTiposPermissoes,
    listarTiposPessoa,
    listarCargos
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarUnidadePage), 'UNIDADES');