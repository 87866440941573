import React, { Component  } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from '../../components/LoginForm';
import LoginInputForm from '../../components/LoginForm/LoginInputForm/index';
import SimpleButton from '../../components/SimpleButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../../actions/index'

import styles from './Login.module.css';

import * as routes from '../../Routes/constants';
import LinkButton from '../../components/LinkButton';
import SimpleModal from '../../components/SimpleModal';
import { Row, Col, Form } from 'react-bootstrap';
import Input from '../../components/Input';

import { recuperarSenha } from '../../actions';
import Axios from 'axios';
import SimpleButtonLogin from '../../components/SimpleButtonLogin';
// import Form from 'react-bootstrap/FormGroup';


class Login extends Component {
    state = {
        username: '',
        password: '',
        email: '',
        showModalSenha: false,
        cidade: '',
    }


    handleSubmit = e => {
        e.preventDefault();

        this.props.login(this.state);
    }

    handleShowModalSenha = () => {
        this.setState({ ...this.state, showModalSenha: true })
    }

    handleCloseModalSenha = () => {
        this.setState({ ...this.state, showModalSenha: false })
    }

    handleSubmitSenha = e => {
        e.preventDefault();
        const email = {
            email: this.state.email
        }
        // console.log(email)
        //
        this.props.recuperarSenha(email, this.props.token);
        this.setState({ email: '' });
    }

    removeAcento = (text) =>
{                                                              
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'A');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'E');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'I');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'O');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'U');
    text = text.replace(new RegExp('[Ç]','gi'), 'C');
    return text;                 
}

removeUnderscore = (text) => {  
    text = text.replace(/_/g, " ");
    return text;                                                            
}

   cidades = async () => {  
        let cidade = await Axios.get(process.env.REACT_APP_HOST+'/cidades').then((res) => {
            console.log(res.data)
            return res.data
            
        })
     
        this.setState({...this.state , cidade:this.removeAcento(cidade[0].nome) , cidadeCompleto:this.removeUnderscore(cidade[0].nome)+'/'+cidade[0].uf})
        localStorage.setItem('cidade', JSON.stringify(cidade[0]))
      
    }
    

    componentWillMount(){
     this.cidades();

 }
   

    render() {

        return this.props.auth.isValidToken ?
            (
                <Redirect to={routes.HOME} />
            ) : (
                <>
                    <div className={styles.loginPage}>
                        <LoginForm handleSubmit={this.handleSubmit} municipio={this.state}>
                            <label className={styles.label}>Usuário</label>
                            <LoginInputForm
                                type='text'
                                placeholder='Usuário'
                                value={this.state.username}
                                handleChange={e => this.setState({ username: e.target.value })} />
                             <label className={styles.label}>Senha</label>
                            <LoginInputForm
                                type='password'
                                placeholder='Senha'
                                value={this.state.password}
                                handleChange={e => this.setState({ password: e.target.value })} />

                            <SimpleButtonLogin className="buttonLogin" type='submit' color='blue' name='Entrar' isloading={this.props.isloading} />
                            <br />
                            <LinkButton title="Esqueceu a senha?" onClick={() => this.handleShowModalSenha()} />
                        </LoginForm>
                    </div>

                    <SimpleModal
                        show={this.state.showModalSenha}
                        onHide={this.handleCloseModalSenha}
                        title='Recuperar Senha'
                        icon='fas fa-envelope'>

                        <Form onSubmit={this.handleSubmitSenha}>
                            <Row>
                                <Col md={12}>
                                    <Input
                                        label='* E-mail:'
                                        type='email'
                                        value={this.state.email}
                                        onChange={value => this.setState({ ...this.state, email: value })}
                                        required
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
                                <Col align='center'>
                                    <SimpleButton
                                        type='submit'
                                        color='green'
                                        name='Enviar para o meu E-mail'
                                        icon='fas fa-check'
                                        disabled={!this.state.email}
                                    />
                                </Col>
                            </Row>
                        </Form>

                    </SimpleModal>
                </>
            )
    }
}



const mapStateToProps = state => ({ 
    auth: state.auth,
    isloading: state.auth.isloading 
})

const mapDispacthToProps = dispatch => bindActionCreators({
    login,
    recuperarSenha
}, dispatch)

export default connect(mapStateToProps, mapDispacthToProps)(Login);