import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { CADASTRAR_AQUISICAO, EDITAR_AQUISICAO, LISTAR_AQUISICOES, LISTAR_UMA_AQUISICAO, BUSCAR_MOTIVO_CANCELAMENTO_AQUISICAO, FILTRAR_AQUISICOES, LISTAR_ITENS_AQUISICAO } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function cadastrarAquisicao(aquisicao, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/aquisicoes`, aquisicao, configAuthorizationToken(token))
                    .then(res => {
                        dispatch({ type: CADASTRAR_AQUISICAO, payload: {} })
                        toastr.success('Sucesso', 'Aquisição cadastrada com sucesso!')
                    })
                    .catch(err => toastr.error('Erro', extractErrorMessage(err)));
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar a Aquisição?' />
            )
        })
    }
}

export function listarAquisicoes(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/aquisicoes`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: LISTAR_AQUISICOES, payload: res.data },
                    { type: FILTRAR_AQUISICOES, payload: res.data }
                ])
            })
            .catch(err => console.log(err))
    }
}

export function listarUmaAquisicao(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/aquisicoes/${id}`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: LISTAR_UMA_AQUISICAO, payload: res.data })
            })
            .catch(err => console.log(err))
    }
}

export function filtrarAquisicoes(numero) {
    return (dispatch, getState) => {
        let aquisicoes = getState().aquisicao.list
        let aquisicoesFiltradas = aquisicoes.filter(aquisicao => aquisicao.numero.includes(numero))
        dispatch({ type: FILTRAR_AQUISICOES, payload: aquisicoesFiltradas })
    }
}

export function cancelarAquisicao(id, motivo, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/aquisicoes/${id}/cancelamento`, { motivo }, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([ 
                            { type: EDITAR_AQUISICAO, payload: {} },
                            listarAquisicoes(token)
                        ])
                        toastr.success('Sucesso', 'Aquisição cancelada com sucesso!')
                    })
                    .catch(err => dispatch(listarAquisicoes(token)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cancelar a Aquisição?' />
            )
        })
    }
}

export function buscarMotivoCancelamentoAquisicao(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/aquisicoes/${id}/cancelamento`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: BUSCAR_MOTIVO_CANCELAMENTO_AQUISICAO, payload: res.data })
            })
    }
}

export function editarAquisicao(aquisicao, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/aquisicoes/${aquisicao.id}`, aquisicao, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([ 
                            { type: EDITAR_AQUISICAO, payload: {} },
                            listarAquisicoes(token)
                        ])
                        toastr.success('Sucesso', 'Aquisição atualizada com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarAquisicoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar a Aquisições?' />
            )
        })
    }
}

export function atualizarNotificarAquisicao(aquisicao, valor, token){
    return dispatch => {
        console.log(aquisicao, valor)
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/aquisicoes/notificacao/${aquisicao.id}`, {valor}, configAuthorizationToken(token))
                .then(res => {
                    dispatch([ 
                        { type: EDITAR_AQUISICAO, payload: {} },
                        listarAquisicoes(token)
                    ])
                    toastr.success('Sucesso', 'Aquisição atualizada com sucesso!')
                })
                .catch(err => {
                    toastr.error('Erro', extractErrorMessage(err))
                    dispatch(listarAquisicoes(token))
                })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar a Aquisições?' />
            )
        })
    }
}

export function excluirAquisicao(id, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/aquisicoes/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatch(listarAquisicoes(token))
                        toastr.success('Sucesso', 'Aquisição excluída com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarAquisicoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir a Aquisição?' />
            )
        })
    }
}

export function listarItensAquisicao(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/aquisicoes/${id}/itens`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: LISTAR_ITENS_AQUISICAO, payload: res.data })
            })
    }
}