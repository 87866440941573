import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Fieldset from '../../components/Fieldset/index';
import Input from '../../components/Input';
import SearchSelect from '../../components/SearchSelect/index';
import SimpleButton from '../../components/SimpleButton/index';
import SimpleTable from '../../components/SimpleTable/index';
import SimpleInput from '../../components/SimpleInput';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarAlimento, listarItens, listarFormasAlimentos, listarComponentesNutricionais, listarGruposAlimentares, listarUnidadesMedida } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

// import styles from './CadastrarAlimento.module.css';

const INITIAL_FORM = {
    nome: '',
    item: -1,
    unidadeMedida: '',
    quantidadeBase: 0,
    forma: '',
    componentes: [],

    itemSelecionado: {},
    unidadeSelecionado: {},
    formaSelecionado: {},

    isComponentes: false,
}

class CadastrarAlimentoPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarComponentesNutricionais(this.props.token)
        this.props.listarItens(this.props.token);
        this.props.listarFormasAlimentos(this.props.token);
        this.props.listarUnidadesMedida(this.props.token);
        // this.props.listarGruposAlimentares(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.resetNumbers < nextProps.resetNumbers) this.setState({ ...INITIAL_FORM })
    }

    // COMPONENTES 

    habilitarComponentes = () => {
        let componentes = JSON.parse(JSON.stringify(this.props.componentesNutricionais));
        componentes = componentes.map(comp => ({ ...comp, valor: '' }))
        this.setState({
            ...this.state,
            componentes,
            isComponentes: true
        });
    }

    handleComponentesInput = (value, index, property) => {
        let componentes = this.state.componentes;
        componentes[index][property] = value;

        this.setState({ ...this.state, componentes });
    }

    handleSubmitAlimento = e => {
        e.preventDefault();

        let { nome, item, unidadeMedida, quantidadeBase, forma, componentes } = this.state;

        let { id, itemProdutoTipo } = this.props.itens.find(i => i.id === item)

        let itemAlimento = {
            id, itemProdutoTipo
        }

        let newComponentesFormat = componentes.map(comp => ({ componente: comp.id, valor: comp.valor }))

        let alimento = {
            nome,
            itemAlimento,
            unidadeMedida,
            quantidadeBase,
            forma,
            componentes: newComponentesFormat
        }

        // console.log(alimento)

        this.props.cadastrarAlimento(alimento, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Alimento' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>
                <Form onSubmit={this.handleSubmitAlimento}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados do alimento' align='left'>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Input
                                            label='* Nome do alimento:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ ...this.state, nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Input
                                            label='* Quant. base para cálculo:'
                                            type='number'
                                            value={this.state.quantidadeBase}
                                            onChange={value => this.setState({ ...this.state, quantidadeBase: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <SearchSelect
                                            label='* Item:'
                                            value={this.state.itemSelecionado}
                                            onChange={selectedOption => this.setState({
                                                ...this.state,
                                                itemSelecionado: selectedOption === null ? {} : selectedOption,
                                                item: selectedOption.value
                                            })}
                                            options={this.props.itens.map(item => (
                                                { value: item.id, label: item.nome }
                                            ))}
                                            required
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <SearchSelect
                                            label='* Unidade medida:'
                                            value={this.state.unidadeSelecionado}
                                            onChange={selectedOption => this.setState({
                                                ...this.state,
                                                unidadeSelecionado: selectedOption,
                                                unidadeMedida: selectedOption.value
                                            })}
                                            options={this.props.unidadesMedida.map(unidade => (
                                                { value: unidade.nome, label: unidade.descricao }
                                            ))}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <SearchSelect
                                            label='* Forma do alimento:'
                                            value={this.state.formaSelecionado}
                                            onChange={selectedOption => this.setState({
                                                ...this.state,
                                                formaSelecionado: selectedOption,
                                                forma: selectedOption.value
                                            })}
                                            options={this.props.formasAlimentos.map(forma => (
                                                { value: forma.nome, label: forma.descricao }
                                            ))}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                color='blue'
                                type='button'
                                icon='fas fa-table'
                                name='Habilitar Componentes'
                                disabled={this.state.isComponentes}
                                onClick={this.habilitarComponentes} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Fieldset hidden={!this.state.isComponentes} legend='Selecione o componente' align='left'>
                                <SimpleTable>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Grupo nutricional</th>
                                            <th>Unidade de medida</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.componentes.map((componente, index) => (
                                            <tr key={index}>
                                                <td>{componente.tipo}</td>
                                                <td>{componente.grupo}</td>
                                                <td>{componente.unidadeMedida}</td>
                                                <td>
                                                    <SimpleInput type='number' step='.01' value={componente.valor} onChange={value => this.handleComponentesInput(value, index, 'valor')} required />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </SimpleTable>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                                disabled={!this.state.isComponentes}
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetNumbers: state.alimento.resetNumbers,
    componentesNutricionais: state.alimento.componentesNutricionais,
    itens: state.item.list,
    formasAlimentos: state.convencao.formasAlimentos,
    gruposAlimentares: state.convencao.gruposAlimentares,
    unidadesMedida: state.convencao.unidadesMedida,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarAlimento,
    listarItens,
    listarFormasAlimentos,
    listarComponentesNutricionais,
    listarGruposAlimentares,
    listarUnidadesMedida
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarAlimentoPage), 'PREPAROS');