import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listarRefeicao } from '../../actions';
import { Row, Col } from 'react-bootstrap';

import styles from './styles.module.css';

class Refeicao extends Component {

    state = {
        refeicao: {
            tipo: {},
            prato: {
                categoria: {},
                modalidade: {},
                criador: {},
                preparos: []
            }
        },
    }

    componentWillMount() {
        this.props.listarRefeicao(this.props.data, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.refeicao !== this.props.refeicao) this.setState({ ...this.state, refeicao: nextProps.refeicao }, () => console.log(this.state));
    }

    render() {
        return (
            <>
                <div className={styles.topbuttons}>
                    <BackButton />
                    <PrintButton />
                </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name={`Relatório de Refeição - ${this.state.refeicao.prato.nome}`} />

                    <Detail>
                        <Row>
                            <Col>
                                <b>Tipo: </b>  {this.state.refeicao.tipo.descricao} - {this.state.refeicao.hora}
                            </Col>
                            <Col>
                                <b>Categoria: </b>  {this.state.refeicao.prato.categoria.modalidade}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>Modalidade: </b>  {this.state.refeicao.prato.modalidade.descricao} - {this.state.refeicao.prato.modalidade.nome}
                            </Col>
                            <Col>
                                <b>Faixa Etária: </b>  {this.state.refeicao.prato.categoria.faixaEtaria}
                            </Col>
                        </Row>
                        <br />

                        {
                            this.state.refeicao.prato.preparos.map((preparo, index) => (
                                <div key={index}>
                                    <Title name={`Nome: ${preparo.nome} - Porções: ${preparo.porcoes}`} />

                                    <table border='1' className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th className={styles.titulo}>Ingrediente</th>
                                                <th className={styles.titulo}>Quantidade</th>
                                                <th className={styles.titulo}>Unidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                preparo.ingredientes.map((item, index) => (
                                                    <tr key={index} >
                                                        <td className={styles.tipo}>{item.alimento.nome}</td>
                                                        <td className={styles.titulo}>{item.quantidade}</td>
                                                        <td className={styles.titulo}>{item.alimento.unidadeMedida}</td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                    <Row className={styles.preparo}>
                                        <Col >
                                            <b>Modo de Preparo: </b>{preparo.modoPreparo}
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }

                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    refeicao: state.execucao.relatorio
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarRefeicao
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Refeicao);