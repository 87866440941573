import React, { Component } from 'react';
import { Form, Row, Card, Col } from 'react-bootstrap';
import Accordion from '../../components/Accordion';
import PageTitle from '../../components/PageTitle/index';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styles from './Notificacao.module.css';
import BackButton from '../../components/BackButton';

import { buscarNotificacoes, filtrarNotificacao } from '../../actions';

class Notificacao extends Component {
    state = {
        pesquisa: '',
        notificacoesFiltradas: [],
    }

    componentDidMount() {
        this.props.buscarNotificacoes(this.props.token)
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Notificações' hasDivider={true} />
                </Row>


                <Form>
                    <Form.Group>
                        <Form.Label>Pesquisar:</Form.Label>
                        <Row>
                            <Col lg={6}>
                                <Form.Control  type='text'
                                    value={this.state.pesquisa}
                                    onChange={e => this.setState({ ...this.state, pesquisa: e.target.value, isPesquisa: true })}
                                    onKeyUp={e => this.props.filtrarNotificacao(this.state.pesquisa, this.props.notificacoes)} />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <div hidden={!true}>
                    <Accordion>
                        <Card key={'a'}>
                            <div className={styles.notifications}>
                                <ul>
                                    {this.props.notificacoes.length === 0 ?
                                        (
                                            <>
                                                Não há notificações
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {this.props.filtrados.map((notificacao, index) => (
                                                    <li key={index}>
                                                        {notificacao.mensagem}
                                                    </li>
                                                ))}
                                            </>
                                        )
                                    }

                                </ul>
                            </div>

                        </Card>
                    </Accordion>
                </div>

            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    notificacoes: state.auth.notificacoes,
    filtrados: state.unidadeEducacional.filtrados
})

const mapDispatchToProps = dispatch => bindActionCreators({
    buscarNotificacoes,
    filtrarNotificacao,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notificacao));