import React, { Component } from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import Fieldset from '../../components/Fieldset';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton';
import Input from '../../components/Input';
import SimpleSelect from '../../components/SimpleSelect/index';
import TinyAction from '../../components/TinyAction/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarCardapioSemanal, listarCardapios, listarCardapiosPorCategoria, listarCardapiosPorCategoriaEModalidade, listarCategorias, listarModalidades } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import styles from './CadastrarCardapio.module.css';

const INITIAL_FORM = {
    nome: '',
    categoria: -1,
    modalidade: '',
    cardapios: [{ diaSemana: '', cardapio: -1 }]
}

const INITIAL_CARDAPIO = { diaSemana: '', cardapio: -1 };

class CardapioSemanalPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        // this.props.listarCardapios(this.props.token)
        this.props.listarCategorias(this.props.token)
        this.props.listarModalidades(this.props.token)
        // this.props.listarTiposRefeicao(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.resetCount < nextProps.resetCount) {
            this.setState({
                ...INITIAL_FORM,
                cardapios: [JSON.parse(JSON.stringify(INITIAL_CARDAPIO))]
            })
        }
    }

    handleChange = (value, property) => {
        this.setState({ ...this.state, [property]: value }, () => {
            this.props.listarCardapiosPorCategoriaEModalidade(this.state.categoria, this.state.modalidade, this.props.token)
        })
    }

    handleCardapioInput = (value, index, property) => {
        let cardapios = this.state.cardapios;
        cardapios[index][property] = value;

        this.setState({ ...this.state, cardapios });
    }

    handleAddCardapio = index => {
        let cardapio = { diaSemana: '', cardapio: -1 }

        let cardapios = this.state.cardapios;
        cardapios.splice(index + 1, 0, cardapio);

        this.setState({ ...this.state, cardapios });
    }

    handleRemoveCardapio = index => {
        let cardapios = this.state.cardapios;
        if (cardapios.length > 1) {
            cardapios.splice(index, 1);

            this.setState({ ...this.state, cardapios });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        // console.log(this.state)
        this.props.cadastrarCardapioSemanal(this.state, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Cardápio Semanal' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados do cardápio semanal' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            label='* Nome:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Categoria:'
                                            value={this.state.categoria}
                                            onChange={e => this.handleChange(parseInt(e.target.value), 'categoria')}
                                            required
                                        >

                                            <option></option>
                                            {this.props.categorias.map(categoria => (
                                                <option key={categoria.id} value={categoria.id}>{categoria.modalidade} / {categoria['faixaEtaria']['nome']}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Modalidade:'
                                            value={this.state.modalidade}
                                            onChange={e => this.handleChange(e.target.value, 'modalidade')}
                                            required
                                        >

                                            <option></option>
                                            {this.props.modalidades.map((modalidade, index) => (
                                                <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>￼
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Cardápios' align='left'>
                                {this.state.cardapios.map((cardapio, index) => (
                                    <Row key={index}>
                                        <Col md={5}>
                                            <SimpleSelect
                                                label='* Dia da Semana:'
                                                value={cardapio.diaSemana}
                                                onChange={e => this.handleCardapioInput(e.target.value, index, 'diaSemana')}
                                                required
                                            >

                                                <option></option>
                                                <option value='SEGUNDA'>Segunda-Feira</option>
                                                <option value='TERCA'>Terça-Feira</option>
                                                <option value='QUARTA'>Quarta-Feira</option>
                                                <option value='QUINTA'>Quinta-Feira</option>
                                                <option value='SEXTA'>Sexta-Feira</option>
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={5}>
                                            <SimpleSelect
                                                label='* Cardápio:'
                                                value={cardapio.cardapio}
                                                onChange={e => this.handleCardapioInput(parseInt(e.target.value), index, 'cardapio')}
                                                required
                                            >

                                                <option></option>
                                                {this.props.cardapios
                                                 //   .filter(c => c.cardapioTipo === 'REGULAR')
                                                    .map(c => (
                                                        <option key={c.id} value={c.id}>{c.nome}</option>
                                                    ))}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={2} className={styles.tinyActions}>
                                            <Row>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        icon='fas fa-plus'
                                                        onClick={() => this.handleAddCardapio(index)}
                                                        title='Adicionar novo cardápio'
                                                        color='green'
                                                    />
                                                </Col>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        icon='fas fa-minus'
                                                        onClick={() => this.handleRemoveCardapio(index)}
                                                        title='Remover cardápio'
                                                        color='red'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetCount: state.cardapio.resetCount,
    categorias: state.convencao.categorias,
    // tiposRefeicao: state.convencao.tiposRefeicao,
    cardapios: state.cardapio.list,
    modalidades: state.convencao.modalidades
})

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarCardapioSemanal,
    listarCardapios,
    listarCardapiosPorCategoria,
    listarCardapiosPorCategoriaEModalidade,
    listarCategorias,
    listarModalidades,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CardapioSemanalPage), 'CARDAPIOS');