import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import {
    LISTAR_CARDAPIOS_LANCAMENTOS_DIA,
    SUBSTITUIR_PRATO_CARDAPIO_LANCAMENTO,
    BUSCAR_PRODUTOS_ESTOQUE_REFEICAO,
    LISTAR_EXECUCOES_CARDAPIOS,
    FILTRAR_EXECUCOES_CARDAPIOS,
    LISTAR_UMA_EXECUCAO_CARDAPIOS,
    LISTAR_ALIMENTOS_REGISTRO,
    LISTAR_REFEICAO,
    LISTAR_DESPERDICIOS,
    BUSCAR_QUANTIDADE_ALUNOS_ALIMENTACAO
} from './types'

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';

export function listarCardapiosLancamentosDia(data, idUnidade, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios-calendario/data=${data};unidade=${idUnidade}/cardapios`, configAuthorizationToken(token))
            .then(res => dispatchEvent({ type: LISTAR_CARDAPIOS_LANCAMENTOS_DIA, payload: res.data }))
    }
}

export function substituirPratoCardapioLancamento(substituicao, data, idUnidade, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/cardapios-calendario/${substituicao.lancamentoCardapio}/substituicoes`, substituicao, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Prato substituido com sucesso!')
                        dispatchEvent([
                            { type: SUBSTITUIR_PRATO_CARDAPIO_LANCAMENTO, payload: {} },
                            listarCardapiosLancamentosDia(data, idUnidade, token)
                        ])
                    })
                    .catch(err => {
                        toastr.error('Aviso', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente substituir o Prato?' />
            )
        })
    }
}

export function buscarQuantidadeDeAlunosComAlimentacao(data, faixaEtaria, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/refeicoes/quantidade-alunos-com-alimentacao?data=${data}&faixaEtaria=${faixaEtaria}`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent({ type: BUSCAR_QUANTIDADE_ALUNOS_ALIMENTACAO, payload: res.data.quantidadeDeAlunos })
            })
    }
}

export function registrarRefeicao(registro, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/refeicoes`, registro.refeicao, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent(registrarAlimentosRefeicao(registro.refeicao.unidadeEducacional, res.data.id, registro.alimentos, token));
                    })
                    .catch(err => {
                        toastr.error('Aviso', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja registrar estes alimentos para refeição?' />
            )
        })
    }
}

/**
 * @description Ação para mandar os alimentos de uma refeição. Essa ação é sempre chamada
 * após a ação de `registrarRefeicao` ser despachada.  
 * @param alimentos = ` [{ id, valor, unidadeDeMedida }] ` 
 */
function registrarAlimentosRefeicao(idUnidade, idRefeicao, alimentos, token) {
    return dispatchEvent => {
        axios.post(`${BASE_URL}/refeicoes/${idRefeicao}/registros?unidade=${idUnidade}`, alimentos, configAuthorizationToken(token))
            .then(res => {
                toastr.success('Sucesso', 'Refeição registrada com sucesso!')
                // console.log(res.data)
            })
            .catch(err => {
                toastr.error('Aviso', extractErrorMessage(err))
            })
    }
}

/**
 * @description Ação para buscar os produtos disponíveis no estoque para uma refeição/execução.
 */
export function buscarProdutosEstoqueRefeicao(idRefeicao, token) {
    return dispatchEvent => {
        // const alimentosComRefeicao = alimentos.map(alimento => ({ ...alimento, refeicao: idRefeicao }));

        axios.get(`${BASE_URL}/refeicoes/${idRefeicao}/produtos`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent({ type: BUSCAR_PRODUTOS_ESTOQUE_REFEICAO, payload: res.data })
                // console.log(res.data)
            })
            .catch(err => {
                toastr.error('Aviso', extractErrorMessage(err))
            })
    }
}

export function listarExecucoesCardapios(idUnidade, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/refeicoes`, configAuthorizationToken(token))
            .then(res => {
                let execucoesPorUnidade = res.data.filter(execucao => execucao.unidadeEducacional.id === idUnidade);

                dispatchEvent([
                    { type: LISTAR_EXECUCOES_CARDAPIOS, payload: execucoesPorUnidade },
                    { type: FILTRAR_EXECUCOES_CARDAPIOS, payload: execucoesPorUnidade }
                ])
            })
    }
}

export function filtrarExecucoesCardapios(data) {
    return (dispatch, getState) => {
        let execucoes = getState().execucao.historicoExecucoes;
        let execucoesFiltradas = execucoes
            .filter(e =>
                e.dia.includes(data === 'Invalid date' ? '' : data)
            )

        dispatch({ type: FILTRAR_EXECUCOES_CARDAPIOS, payload: execucoesFiltradas })
    }
}

export function listarUmaExecucaoCardapios(idRefeicao, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/refeicoes/${idRefeicao}`, configAuthorizationToken(token))
            .then(res => dispatchEvent({ type: LISTAR_UMA_EXECUCAO_CARDAPIOS, payload: res.data }))
    }
}

export function listarAlimentosRegistro(idRefeicao, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/refeicoes/${idRefeicao}/registros-alimentos`, configAuthorizationToken(token))
            .then(res => {
                // console.log(res.data)
                dispatchEvent({ type: LISTAR_ALIMENTOS_REGISTRO, payload: res.data })
            })
    }
}

export function excluirRefeicao(idRefeicao, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/refeicoes/${idRefeicao}`, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Refeição excluída com sucesso!')
                        dispatchEvent(listarExecucoesCardapios(token))
                    })
                    .catch(err => toastr.error('Aviso', extractErrorMessage(err)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja excluir essa Refeição?' />
            )
        })
    }
}

export function executarRefeicao(idRefeicao, idUnidade, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/refeicoes/${idRefeicao}/abatimento`, {}, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Refeição executada com sucesso!')
                        dispatchEvent(listarExecucoesCardapios(idUnidade, token))
                    })
                    .catch(err => toastr.error('Aviso', extractErrorMessage(err)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja executar essa Refeição?' />
            )
        })
    }
}

export function registrarSobra(idRefeicao, sobra, idUnidade, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.patch(`${BASE_URL}/refeicoes/${idRefeicao}?sobras=${sobra}`, {}, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Sobra da Refeição cadastrada com sucesso!')
                        dispatchEvent(listarExecucoesCardapios(idUnidade, token))
                    })
                    .catch(err => toastr.error('Aviso', extractErrorMessage(err)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar a Sobra para essa Refeição?' />
            )
        })
    }
}

export function listarDesperdicios(idRefeicao, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/refeicoes/${idRefeicao}/desperdicios`, configAuthorizationToken(token))
            .then(res => dispatchEvent({ type: LISTAR_DESPERDICIOS, payload: res.data }))
    }
}

export function listarRefeicao(idRefeicao, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/relatorios/refeicoes/${idRefeicao}`, configAuthorizationToken(token))
            .then(res => dispatchEvent({ type: LISTAR_REFEICAO, payload: res.data }))
    }
}
