import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleTable from '../../components/SimpleTable/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal';
import SimpleModalSmall from '../../components/SimpleModalSmall/index';
import Input from '../../components/Input';
import SimpleButton from '../../components/SimpleButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarExecucoesCardapios,
    filtrarExecucoesCardapios,
    listarAlimentosRegistro,
    excluirRefeicao,
    executarRefeicao,
    registrarSobra,
    listarProdutos,
} from './../../actions'

import withAuthorization from '../../components/HighOrder/withAuthorization';

import styles from './HistoricoExecucaoCardapios.module.css';

import * as reports from '../../relatorios/reportTypes';
import * as routes from '../../Routes/constants';

import moment from 'moment';
import BackButton from '../../components/BackButton';

class HistoricoExecucaoCardapiosPage extends React.Component {
    state = {
        showModalVisualizarAlimentos: false,
        showModalRegistrarSobra: false,

        pesquisaUnidade: -1,
        pesquisaData: '',

        showHistoricos: false,

        idRefeicao: -1,
        sobra: 0,
    }
    teste = {
        
    }

    componentDidMount() {
        // this.props.listarExecucoesCardapios(this.props.token)
        this.props.listarProdutos(this.props.token)
    }

    handleSearchSubmit = () => {
        // e.preventDefault()
        this.props.listarExecucoesCardapios(this.state.pesquisaUnidade, this.props.token)
        this.setState({ ...this.state, showHistoricos: true })
    }

    handleShowAlimentos = idRefeicao => {
        this.setState({ showModalVisualizarAlimentos: true }, () => this.props.listarAlimentosRegistro(idRefeicao, this.props.token));
    }

    handleShowRegistrarSobra = (idRefeicao, sobra) => {
        this.setState({
            showModalRegistrarSobra: true,
            idRefeicao,
            sobra: sobra || 0
        })
    }

    handleCloseRegistrarSobra = () => {
        this.setState({ showModalRegistrarSobra: false, idRefeicao: -1, sobra: 0 })
    }

    handleRegistrarSobraSubmit = e => {
        e.preventDefault();

        this.props.registrarSobra(this.state.idRefeicao, this.state.sobra, this.state.pesquisaUnidade, this.props.token);
    }

    mostrarPaginaDesperdicios = idRefeicao => {
        this.props.history.push(`${routes.HISTORICO_EXECUCAO_CARDAPIOS}/${idRefeicao}${routes.DESPERDICIO}`);
    }

    mostrarPaginaRelatorio = idRefeicao => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_PRODUTOS_REFEICAO}/${idRefeicao}`);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Histórico de Execução de Cardápios' hasDivider={true} />
                </Row>

                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Selecione a Unidade:</Form.Label>
                            <Form.Control as='select' value={this.state.pesquisaUnidade} onChange={e => this.setState({ ...this.state, pesquisaUnidade: parseInt(e.target.value) }, () => this.handleSearchSubmit())} required>
                                <option value={-1}></option>
                                {this.props.estoquesUsuario.map(estoque => (
                                    <option key={estoque.unidadeEducacional.id} value={estoque.unidadeEducacional.id}>{estoque.unidadeEducacional.nome}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                </Form>

                <div hidden={!this.state.showHistoricos}>
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Pesquisar por Data: </Form.Label>
                                <Form.Control
                                    type='date'
                                    value={this.state.pesquisaData}
                                    onChange={e => this.setState({ ...this.state, pesquisaData: e.target.value },
                                        () => this.props.filtrarExecucoesCardapios(moment(this.state.pesquisaData, 'YYYY-MM-DD').format('DD/MM/YYYY')))}
                                />
                            </Form.Group>
                        </Row>
                    </Form>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Hora</th>
                                <th>Tipo</th>
                                <th>Prato</th>
                                <th>Quant. Alunos</th>
                                <th>Acrescimo</th>
                                <th>Status</th>
                                <th>Sobra</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.filtrados.map((refeicao, index) => (
                                <tr key={index}>
                                    <td>{refeicao.dia}</td>
                                    <td>{refeicao.hora}</td>
                                    <td>{refeicao.tipo.descricao}</td>
                                    <td>{refeicao.prato.nome}</td>
                                    <td>{refeicao.quantidadeDePessoas}</td>
                                    <td>{refeicao.acrescimo}%</td>
                                    <td>
                                        {
                                            refeicao.executado ? 'EXECUTADO' : 'NÃO EXECUTADO'
                                        }
                                    </td>
                                    <td>
                                        {
                                            refeicao.sobraDeAlimentos ? `${refeicao.sobraDeAlimentos} Kg` : '---'
                                        }
                                    </td>
                                    <td className={styles.actions}>
                                        <ActionButton
                                            title='Visualizar Refeição'
                                            onClick={() => this.handleShowAlimentos(refeicao.id)}
                                            icon='fas fa-eye'
                                        />

                                        <ActionButton
                                            title='Imprimir Relatório de Produtos'
                                            onClick={() => this.mostrarPaginaRelatorio(refeicao.id)}
                                            icon='fas fa-print'
                                        />

                                        <ActionButton
                                            title='Executar Refeição'
                                            disabled={refeicao.executado}
                                            onClick={() => this.props.executarRefeicao(refeicao.id, this.state.pesquisaUnidade, this.props.token)}
                                            icon='fas fa-bolt'
                                        />

                                        <ActionButton
                                            title='Registrar Sobra'
                                            disabled={!refeicao.executado}
                                            onClick={() => this.handleShowRegistrarSobra(refeicao.id, refeicao.sobraDeAlimentos)}
                                            icon='fas fa-cookie-bite'
                                        />

                                        <ActionButton
                                            title='Verificar Desperdícios'
                                            disabled={!refeicao.executado}
                                            onClick={() => this.mostrarPaginaDesperdicios(refeicao.id)}
                                            icon='fas fa-recycle'
                                        />

                                        <ActionButton
                                            title='Excluir Refeição'
                                            onClick={() => this.props.excluirRefeicao(refeicao.id, this.props.token)}
                                            icon='fas fa-trash-alt'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable>
                </div>


                <SimpleModal
                    show={this.state.showModalVisualizarAlimentos}
                    onHide={() => this.setState({ showModalVisualizarAlimentos: false })}
                    title='Visualizar Alimentos'
                    icon='fas fa-eye'>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Alimento</th>
                                <th>Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.alimentosRegistro.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.alimento.nome}</td>
                                    <td>{item.valorArredondado} {item.unidadeMedida}</td>
                                </tr>
                            ))}
                        </tbody>

                    </SimpleTable>
                </SimpleModal>

                <SimpleModalSmall
                    show={this.state.showModalRegistrarSobra}
                    onHide={this.handleCloseRegistrarSobra}
                    title='Registrar Sobra'
                    icon='fas fa-cookie-bite'>

                    <Form onSubmit={this.handleRegistrarSobraSubmit}>
                        <Row>
                            <Col>
                                <Input
                                    label='* Valor da sobra (kg):'
                                    type='number'
                                    min='0'
                                    step='.01'
                                    value={this.state.sobra}
                                    onChange={value => this.setState({ sobra: value })}
                                    required
                                />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseRegistrarSobra}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Registrar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModalSmall>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    // historico: state.execucao.historicoExecucoes,
    filtrados: state.execucao.filtradosHistoricoExecucoes,
    alimentosRegistro: state.execucao.alimentosRegistro,
    produtos: state.produto.list,
    estoquesUsuario: state.auth.user.estoques,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarExecucoesCardapios,
    filtrarExecucoesCardapios,
    listarAlimentosRegistro,
    excluirRefeicao,
    executarRefeicao,
    registrarSobra,
    listarProdutos,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(HistoricoExecucaoCardapiosPage)), 'CARDAPIOS')