export const LOGIN = '/'
export const HOME = '/home';

export const DADOS_CADASTRAIS = '/dados-cadastrais';

export const CADASTRAR_ITEM = '/cadastrar-ítem';
export const GERENCIAR_ITENS = '/gerenciar-itens';

export const CADASTRAR_PRODUTO = '/cadastrar-produto';
export const GERENCIAR_PRODUTOS = '/gerenciar-produtos';

export const CADASTRAR_USUARIO = '/cadastrar-usuario';
export const GERENCIAR_USUARIOS = '/gerenciar-usuarios';

export const CADASTRAR_ESCOLA = '/cadastrar-escola';
export const GERENCIAR_ESCOLAS = '/gerenciar-escolas';

export const CADASTRAR_FORNECEDOR = '/cadastrar-fornecedor';
export const GERENCIAR_FORNECEDORES = '/gerenciar-fornecedores';

export const CADASTRAR_AQUISICAO = '/cadastrar-aquisicao';
export const GERENCIAR_AQUISICOES = '/gerenciar-aquisicoes';
export const ADITIVOS = '/aditivos';

export const CADASTRAR_SOLICITACAO = '/cadastrar-solicitacao';
export const GERENCIAR_SOLICITACOES = '/gerenciar-solicitacao';

export const ESTOQUE = '/estoque';

export const KITS = '/kit-merenda';

export const CADASTRAR_MOVIMENTACAO = '/cadastrar-movimentacao';
export const GERENCIAR_MOVIMENTACOES = '/gerenciar-movimentacoes';

export const BALANCOS = '/balancos';

// export const CADASTRAR_PROPOSTA = '/cadastrar-proposta';
// export const GERENCIAR_PROPOSTAS = '/gerenciar-propostas';

// ESTOQUE

export const CADASTRAR_ALIMENTO = '/cadastrar-alimento';
export const GERENCIAR_ALIMENTOS = '/gerenciar-alimentos';

export const CADASTRAR_PREPARO = '/cadastrar-preparo';
export const GERENCIAR_PREPAROS = '/gerenciar-preparos';

export const CADASTRAR_PRATO =  '/cadastrar-prato';
export const GERENCIAR_PRATOS = '/gerenciar-pratos';

export const CADASTRAR_CARDAPIO = '/cadastrar-cardapio';
export const GERENCIAR_CARDAPIOS = '/gerenciar-cardapios';
export const CARDAPIO_SEMANAL = '/cardapio-semanal';
export const CARDAPIO_MENSAL = '/cardapio-mensal';

export const CARDAPIO_CALENDARIO = '/cardapio-calendario';

export const EXECUCAO_CARDAPIOS = '/execucao-cardapios';
export const HISTORICO_EXECUCAO_CARDAPIOS = '/historico-execucao-cardapios';
export const DESPERDICIO = '/desperdicio';

export const ESCOLA = '/escola';

export const RELATORIO = '/relatorio';

export const NOTIFICACAO = '/notificacao';

export const CADASTRAR_UNIDADE = '/cadastrar-unidade';
export const GERENCIAR_UNIDADES = '/gerenciar-unidade';