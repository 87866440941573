import { LISTAR_UNIDADES_EDUCACIONAIS, EXCLUIR_UNIDADE_EDUCACIONAL,  ENDERECO_UNIDADE, FILTRAR_UNIDADES, EDITAR_UNIDADE_EDUCACIONAL, FILTRAR_NOTIFICACOES } from '../actions/types';

const INITIAL_STATE = {
    list: [],
    filtrados: [],
    enderecoUnidade: {},
    closeModalCount:0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_UNIDADES_EDUCACIONAIS:
            return { ...state, list: action.payload }

        case ENDERECO_UNIDADE:
            return { ...state, enderecoUnidade: action.payload }

        case FILTRAR_UNIDADES:
            return { ...state, filtrados: action.payload }

        case FILTRAR_NOTIFICACOES:
            return { ...state, filtrados: action.payload }

        case EDITAR_UNIDADE_EDUCACIONAL:
            return { ...state , closeModalCount: ++state.closeModalCount }

        case EXCLUIR_UNIDADE_EDUCACIONAL:
            return { ...state }

        default:
            return state
    }
}