import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';
import { LISTAR_UNIDADES_EDUCACIONAIS ,EXCLUIR_UNIDADE_EDUCACIONAL ,
     EDITAR_UNIDADE_EDUCACIONAL ,FILTRAR_UNIDADES, FILTRAR_NOTIFICACOES, ENDERECO_UNIDADE, CADASTRAR_UNIDADE_EDUCACIONAL } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function salvarUnidadesEducacionais(unidades, token) {
    return dispatch => {
        axios.post(`${BASE_URL}/unidades-educacionais/save`, unidades, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: LISTAR_UNIDADES_EDUCACIONAIS, payload: res.data })
                toastr.success('Sucesso', 'Unidades atualizadas com sucesso!')
                listarUnidadesEducacionais(token);
               
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
    }
}

export function cadastrarUnidadeEducacional(unidades, token) {
    return dispatch => {
        axios.post(`${BASE_URL}/unidades-educacionais/`, unidades, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: CADASTRAR_UNIDADE_EDUCACIONAL, payload: res.data })

                toastr.success('Sucesso', 'Unidade cadastrada com sucesso!')
               
                
                
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
    }
}
export function editarUnidadeEducacional(id,unidade, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
        axios.put(`${BASE_URL}/unidades-educacionais/${id}`, unidade, configAuthorizationToken(token))
            .then(res => {
                dispatch([{ type: EDITAR_UNIDADE_EDUCACIONAL, payload: res.data },
                    listarUnidadesEducacionais(token)])
                toastr.success('Sucesso', 'Unidade atualizada com sucesso!')
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
        },
        onCancel: () => { },
        component: () => (
            <ConfirmMessage message='Deseja realmente atualizar a Unidade?' />
        )
        
    })
}
}

export function excluirUnidadeEducacional(unidade, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
        axios.delete(`${BASE_URL}/unidades-educacionais/${unidade}`, configAuthorizationToken(token))
            .then(res => {
                dispatch([{ type: EXCLUIR_UNIDADE_EDUCACIONAL, payload: res.data },
                    listarUnidadesEducacionais(token)])
                toastr.success('Sucesso', 'Unidade deletada com sucesso!')
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
        },
        onCancel: () => { },
        component: () => (
            <ConfirmMessage message='Deseja realmente deletar a unidade Selecionada?' />
        )
        })
    }
}


export function listarUnidadesEducacionais(token) {

    return dispatch => {
        axios.get(`${BASE_URL}/unidades-educacionais`, configAuthorizationToken(token))
            .then(res => dispatch([
                { type: LISTAR_UNIDADES_EDUCACIONAIS, payload: res.data },
                { type: FILTRAR_UNIDADES, payload: res.data }]))
            .catch(err => console.log(err))
    }
}
export function filtrarUnidades(property, value) {
   
    return (dispatch, getState) => {
        let unidades = getState().unidadeEducacional.list
        let unidadesFiltradas = unidades.filter(unidade => unidade[property] != null && unidade[property].toLowerCase().includes(value.toLowerCase()))
        dispatch({ type: FILTRAR_UNIDADES, payload: unidadesFiltradas })
    }
}

export function filtrarNotificacao(value, notificacoes) {
    return (dispatch) => {
        let notificaaoFiltradas = notificacoes.filter(notificacao => notificacao != null && notificacao.mensagem.toLowerCase().includes(value.toLowerCase()))
        dispatch({ type: FILTRAR_NOTIFICACOES, payload: notificaaoFiltradas })
    }
}

export function buscarEnderecoUnidade(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/unidades-educacionais/${id}`, configAuthorizationToken(token))
            .then(res =>
                dispatch({ type: ENDERECO_UNIDADE, payload: res.data })
            )
            .catch(err => console.log(err))
    }


}
