import React from 'react';
import styles from './LinkButton.module.css'
const LinkButton = props => {
    return (
           <div className={styles.blocoLink}>
              <a  {...props}>{props.title}</a>
            </div>
    );
};

export default LinkButton;