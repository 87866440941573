import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import styles from './Input.module.css';

class Input extends React.Component {
    handleChange = e => {
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        let value = input.value;
        
        if(this.props.case === 'upper') value = value.toUpperCase();

        this.props.onChange(value, () => input.setSelectionRange(start, end))
    }

    render() {
        return (
            <InputGroup className='mb-3'>
                <InputGroup.Prepend>
                    <InputGroup.Text className={styles.label}>{this.props.label}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    {...this.props}
                    className={styles.input}
                    type={this.props.type}
                    value={this.props.value}
                    onChange={this.handleChange}
                    style={this.props.style}
                />
                <InputGroup.Append hidden={this.props.aside !== 0 && !this.props.aside} className={styles.aside}>
                    <InputGroup.Text title={this.props.tooltip}>
                        {this.props.aside}
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        )
    }
}

export default Input;