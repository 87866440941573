import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Fieldset from '../../components/Fieldset/index';
import Input from '../../components/Input';
import SimpleSelect from '../../components/SimpleSelect/index';
import SearchSelect from '../../components/SearchSelect/index';
import SimpleButton from '../../components/SimpleButton/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarProduto, listarItens, listarUnidadesMedida, listarUnidadesProduto } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

const INITIAL_FORM = {
    nome: '',
    perecivel: false,
    unidadeMedida: '',
    unidadeProduto: '',
    medida: 0,
    item: 0,

    itemSelecionado: {}
}

class CadastrarProdutoPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarItens(this.props.token)
        this.props.listarUnidadesMedida(this.props.token)
        this.props.listarUnidadesProduto(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.resetNumbers) this.setState({ ...INITIAL_FORM })
    }

    handleSubmit = e => {
        e.preventDefault();

        let produto = {
            nome: this.state.nome,
            isPerecivel: this.state.perecivel,
            medidaReal: this.state.medida,
            item: this.props.itens.find(item => item.id === this.state.item),
            unidadeMedida: this.state.unidadeMedida,
            unidadeProduto: this.state.unidadeProduto,
        }

        this.props.cadastrarProduto(produto, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Produto' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados do produto' align='left'>
                                <Row>
                                    <Col md={9}>
                                        <Input
                                            type='text'
                                            label='* Nome:'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ ...this.state, nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <div className='mb-3'>
                                            <Form.Check
                                                type='checkbox'
                                                id='custom-checkbox'
                                                inline
                                                checked={this.state.perecivel}
                                                onChange={() => this.setState({ perecivel: !this.state.perecivel })}
                                            />
                                            <Form.Label>* Perecível</Form.Label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Unidade do Produto:'
                                            value={this.state.unidadeProduto}
                                            onChange={e => this.setState({ ...this.state, unidadeProduto: e.target.value })}
                                            required>
                                            <option></option>
                                            {this.props.unidadesProduto.map((unidade, index) => (
                                                <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Unidade de Medida:'
                                            value={this.state.unidadeMedida}
                                            onChange={e => this.setState({ ...this.state, unidadeMedida: e.target.value })}
                                            required
                                        >
                                            <option></option>
                                            {this.props.unidadesMedida.map((unidade, index) => (
                                                <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <Input
                                            type='number'
                                            label='* Valor da Medida:'
                                            placeholder='Medida do produto'
                                            value={this.state.medida}
                                            onChange={value => this.setState({ ...this.state, medida: value })}
                                            required
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <SearchSelect
                                            label='* Item:'
                                            value={this.state.itemSelecionado}
                                            onChange={selectedOption => this.setState({ ...this.state, itemSelecionado: selectedOption, item: selectedOption.value })}
                                            options={this.props.itens.map(item => (
                                                { value: item.id, label: item.nome })
                                            )}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    itens: state.item.list,
    resetNumbers: state.produto.resetNumbers,
    unidadesMedida: state.convencao.unidadesMedida,
    unidadesProduto: state.convencao.unidadesProduto
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarProduto,
    listarItens,
    listarUnidadesMedida,
    listarUnidadesProduto
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarProdutoPage), 'PRODUTOS');