import React from 'react';

const Printable = ({ children }) => (
    <div className='printable'>
        {children}
    </div>
)

export default Printable;

