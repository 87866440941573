// Cabo
//export const BASE_URL = 'http://192.168.2.70:8585'; 

// natan
//export const BASE_URL = 'http://192.168.2.31:8585'; 

// felipe
// export const BASE_URL = 'http://192.168.2.64:8585'; 

// Pitaya ADM
// export const BASE_URL = 'http://192.168.2.30:8585'; 

// export const BASE_URL = 'http://alimentacao-teste.9q4hsbjphh.sa-east-1.elasticbeanstalk.com';

export const BASE_URL = process.env.REACT_APP_HOST

export const configFormUrlEncoded = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const configAuthorizationToken = token => ({ headers: { 'Authorization': token } });

export const configAuthorizationTokenAndContentTypeMultipart = token => (
    {
        headers: {
            'Authorization': token,
            'Content-Type': 'text/plain',
            'Accept': '*/*'
        }
    }
)

export const configAuthorizationTokenAndContentTypeJson = token => (
    {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
)

export const configAuthorizationTokenAndParams = (token, params) => (
    {
        params,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
)

export const extractErrorMessage = error => JSON.parse(JSON.stringify(error))['response'].data