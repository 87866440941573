import { REALIZAR_BALANCO_PRODUTO_ESTOQUE, ADICIONAR_PRODUTO_ESTOQUE, EXCLUIR_PRODUTO_ESTOQUE, LISTAR_ITENS_PRODUTOS, LISTAR_ESTOQUE_ITENS, REALIZAR_SAIDA_PRODUTO_ESTOQUE, LISTAR_ESTOQUES, LISTAR_PRODUTOS_ESTOQUE, FILTRAR_PRODUTOS_ESTOQUE, LISTAR_ESTOQUE, LISTAR_BALANCOS, LISTAR_ALUNOS_KITS } from '../actions/types'

const INITIAL_STATE = {
    list: [],
    onlyOne: {},
    filtrado: {
        produtos: [],
        unidadeEducacional: {}
    },
    alunosKits:[],
    produtos: [],
    itens: [],
    balancos: [{
        produtoLote: { produto: {} },
        usuario: { pessoa: {} }
    }],
    closeModalNumbers: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_ESTOQUES:
            return { ...state, list: action.payload }
        
        case LISTAR_ALUNOS_KITS:
            return { ...state, alunosKits: action.payload }

        case ADICIONAR_PRODUTO_ESTOQUE:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }
        
        case REALIZAR_SAIDA_PRODUTO_ESTOQUE:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case REALIZAR_BALANCO_PRODUTO_ESTOQUE:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case LISTAR_ESTOQUE:
            return { ...state, onlyOne: action.payload }

        case LISTAR_ESTOQUE_ITENS: {
            return { ...state, onlyOne: action.payload }
        }

        case FILTRAR_PRODUTOS_ESTOQUE: {
            let filtrado = JSON.parse(JSON.stringify(state.filtrado));
            filtrado.produtos = action.payload;
            return { ...state, filtrado }
        }

        // case LISTAR_PRODUTOS_ESTOQUE: {
        //     let filtrado = JSON.parse(JSON.stringify(state.filtrado));
        //     filtrado.produtos = action.payload;
        //     return { ...state, filtrado }
        // }

        case LISTAR_PRODUTOS_ESTOQUE: {
            return { ...state, produtos: action.payload }
        }
        
        case EXCLUIR_PRODUTO_ESTOQUE: {
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }
        }

        case LISTAR_ITENS_PRODUTOS:
            return { ...state, itens: action.payload }

        case LISTAR_BALANCOS:
            return { ...state, balancos: action.payload }

        default:
            return state
    }
} 