import React from 'react';
import PrintButton from '../components/PrintButton/index';
import BackButton from '../components/BackButton';
import Header from '../components/Header';
import Title from '../components/Title';
import Printable from '../components/Printable/index';
import Detail from '../components/Detail/index';
import Info from '../components/Info/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { buscarProdutosEstoqueRefeicao, listarUmaExecucaoCardapios } from '../../actions'

import styles from './styles.module.css';

class ProdutosRefeicao extends React.Component {
    componentWillMount() {
        // this.props.data => idRefeicao
        this.props.listarUmaExecucaoCardapios(this.props.data, this.props.token)
        this.props.buscarProdutosEstoqueRefeicao(this.props.data, this.props.token);
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />
                    <Title name='Relatório de Produtos da Refeição' />

                    <Detail>
                        <div className='row'>
                            <div className='col'>
                                <b>Data:</b> {this.props.execucao.dia}
                            </div>
                            <div className='col'>
                                <b>Hora:</b> {this.props.execucao.hora}
                            </div>
                            <div className='col'>
                                <b>Tipo:</b> {this.props.execucao.tipo.descricao}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Prato:</b> {this.props.execucao.prato.nome}
                            </div>
                            <div className='col'>
                                <b>Quant. de Alunos:</b> {this.props.execucao.quantidadeDePessoas}
                            </div>
                            <div className='col'>
                                <b>Acréscimo:</b> {this.props.execucao.acrescimo}%
                            </div>
                        </div>

                        <br />

                        <div className='row'>
                            <div className='col'>
                                <b>Produtos</b>
                                <hr />
                            </div>
                        </div>

                        <table className={styles.tableProdutosExecucao} border='1'>
                            <thead>
                                <tr>
                                    <th>Lote</th>
                                    <th>Fabricação</th>
                                    <th>Validade</th>
                                    <th>Produto</th>
                                    <th>Quant.</th>
                                    <th>Unidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.produtosEstoqueRefeicao.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.produtoLote.numero}</td>
                                        <td>{item.produtoLote.dataFabricacao}</td>
                                        <td>{item.produtoLote.dataValidade}</td>
                                        <td className={styles.produto}>{item.produtoLote.produto.item} - {item.produtoLote.produto.nome}</td>
                                        <td>{item.quantidade}</td>
                                        <td>{item.unidadeProduto}</td>                                    
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Detail>
                </Printable>
            </>
        )
    }
}


const mapStateToProps = state => ({
    token: state.auth.token,
    execucao: state.execucao.execucao,
    produtosEstoqueRefeicao: state.execucao.produtosEstoqueRefeicao,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarUmaExecucaoCardapios,
    buscarProdutosEstoqueRefeicao,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProdutosRefeicao);