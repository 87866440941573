import React from 'react';
// import Select from 'react-dropdown-select';
import Select from 'react-select';

import styles from './SearchSelect.module.css';

const customStyles = {
    control: (base, state) => ({
        ...base,
        height: '34px',
        'min-height': '34px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#495057',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#495057'
    }),
}

const SearchSelect = props => (
    <div className='mb-3'>
        <div className={styles.label}>
            <div>
                {props.label}
            </div>
        </div>
        <Select
            {...props}
            styles={customStyles}
            className={styles.select}
            placeholder=''
        />
    </div>
)

export default SearchSelect;