import { 
    CADASTRAR_USUARIO, 
    EDITAR_USUARIO, 
    LISTAR_USUARIOS, 
    FILTRAR_USUARIOS, 
    RECUPERAR_SENHA_USUARIO 
} from '../actions/types';

const INITIAL_STATE = {
    list: [],
    filtrados: [],

    closeModalCount: 0
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case CADASTRAR_USUARIO : return state;

        case EDITAR_USUARIO : 
            return { ...state, closeModalCount: ++state.closeModalCount }

        case LISTAR_USUARIOS : return { ...state, list: action.payload }
 
        case FILTRAR_USUARIOS : return { ...state, filtrados: action.payload }

        case RECUPERAR_SENHA_USUARIO : return { ...state }

        default: return state
    }
}