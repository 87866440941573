import React from 'react';
import { Redirect } from 'react-router-dom';

import * as routes from '../../Routes/constants'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { checkValidToken } from '../../actions/auth.action';

const withAuthorization = (Component, permissao) => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.props.checkValidToken(this.props.auth.token)
        }

        render() {
            return this.props.auth.isValidToken && (permissao === 'HOME' || this.props.auth.user.permissoes.includes(permissao)) ?
            // return this.props.auth.isValidToken ?
                (
                    <Component />
                )
                :
                (
                    <Redirect to={routes.LOGIN} />
                )

        }
    }

    const mapStateToProps = state => ({ auth: state.auth })

    const mapDispatchToProps = dispatch => bindActionCreators({ checkValidToken }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(WithAuthorization);
}

export default withAuthorization;