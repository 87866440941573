import React, { Component } from 'react';
import { Form, Row, Col, ListGroup, InputGroup } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleButton from '../../components/SimpleButton';
import TinyAction from '../../components/TinyAction';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarMovimentacao, listarEstoques, listarProdutosEstoque } from '../../actions'

import withAuthorization from '../../components/HighOrder/withAuthorization';

// import styles from './CadastrarMovimentacao.module.css';

const INITIAL_FORM = {
    originalSource: [],
    source: [],
    target: [],
    movingProduct: {},
    estoqueDe: -1,
    estoquePara: -1
}

class CadastrarMovimentacaoPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarEstoques(this.props.token)
    }

    handleSelectEstoqueDe = value => {
        this.setState({ ...this.state, estoqueDe: value, source: [], target: [] }, () => {
            this.props.listarProdutosEstoque(value, this.props.token)
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.produtosEstoque !== this.props.produtosEstoque) {
            this.setState({ ...this.state, source: nextProps.produtosEstoque, originalSource: nextProps.produtosEstoque })
        }

        if (this.props.resetNumbers < nextProps.resetNumbers) this.setState({ ...INITIAL_FORM })
    }

    onFocusProduto = produto => {
        let produtoCopy = JSON.parse(JSON.stringify(produto));
        produtoCopy.quantidadeDefault = produtoCopy.quantidade;
        this.setState({ ...this.state, movingProduct: produtoCopy })
    }

    onBlurProduto = () => {
        this.setState({ ...this.state, movingProduct: {} })
    }

    onMoveToTarget = () => {
        const selectedProducts = this.state.source.filter(
            (produto) => produto.isSelected
        );

        if (selectedProducts.length > 0) {
            let target = [...this.state.target];
            let source = [...this.state.source];

            for (const produto of selectedProducts) {
                const existingTargetProductIndex = target.findIndex(
                    (p) => p.id === produto.id
                );
                if (existingTargetProductIndex !== -1) {
                    // Atualiza a quantidade do produto no destino
                    target[existingTargetProductIndex].quantidade += produto.quantidade;
                } else {
                    // Adiciona o produto ao destino
                    target.push({ ...produto, isSelected: false });
                }

                // Atualiza a quantidade do produto na origem
                const sourceProductIndex = source.findIndex((p) => p.id === produto.id);
                if (sourceProductIndex !== -1) {
                    source[sourceProductIndex].quantidade -= produto.quantidade;
                    source[sourceProductIndex].isSelected = false;
                }
            }

            this.setState({ ...this.state, source, target });
        }
    }

    removeFromTarget = produto => {
        let source = [...this.state.source];
        let target = [...this.state.target];

        const sourceProductIndex = source.findIndex(p => p.id === produto.id);
        if (sourceProductIndex !== -1) {
            source[sourceProductIndex].quantidade += parseInt(produto.quantidade, 10);
            source[sourceProductIndex].isSelected = false;
        } else {
            source.push({ ...produto, isSelected: false });
        }

        const targetProductIndex = target.findIndex(p => p.id === produto.id);
        if (targetProductIndex !== -1) {
            target.splice(targetProductIndex, 1);
        }

        this.setState({ ...this.state, source, target });
    };

    handleChangeQuantidade = (value, index) => {
        let target = JSON.parse(JSON.stringify(this.state.target));

        if (value < 0 || value > target[index].quantidadeDefault) {
            return;
        }

        target[index].quantidade = value;
        let targetProduto = target[index];

        let originalSource = this.state.originalSource;

        let source = JSON.parse(JSON.stringify(this.state.source));
        for (let i = 0; i < source.length; i++) {
            if (source[i].id === targetProduto.id) source[i].quantidade = originalSource[i].quantidade - targetProduto.quantidade;
        }

        this.setState({ ...this.state, source, target })
    }

    handleSubmit = e => {
        e.preventDefault();

        let { estoqueDe, estoquePara } = this.state;

        let produtosEstoque = this.state.target.map(produto => ({ produtoEstoque: produto.id, quantidade: produto.quantidade }))

        let movimentacao = {
            estoqueDe,
            estoquePara,
            produtosEstoque
        }


        this.props.cadastrarMovimentacao(movimentacao, this.props.token)
    }

    onSelectProduto = produto => {
        let source = JSON.parse(JSON.stringify(this.state.source));
        const selected = source.find(p => p.id === produto.id);
        selected.isSelected = !selected.isSelected;
        this.setState({ ...this.state, source });
    };

    onSelectTargetProduto = produto => {
        let target = JSON.parse(JSON.stringify(this.state.target));
        const selected = target.find(p => p.id === produto.id);
        selected.isSelected = !selected.isSelected;
        this.setState({ ...this.state, target });
    };

    onMoveToSource = () => {
        const selectedProducts = this.state.target.filter(produto => produto.isSelected);

        if (selectedProducts.length > 0) {
            let source = [...this.state.source];
            let target = [...this.state.target];

            for (const produto of selectedProducts) {
                const existingSourceProductIndex = source.findIndex(p => p.id === produto.id);
                if (existingSourceProductIndex !== -1) {
                    source[existingSourceProductIndex].quantidade += produto.quantidade;
                } else {
                    source.push({ ...produto, isSelected: false });
                }

                const targetProductIndex = target.findIndex(p => p.id === produto.id);
                if (targetProductIndex !== -1) {
                    target.splice(targetProductIndex, 1);
                }
            }

            this.setState({ ...this.state, source, target });
        }
    };

    render() {
        return (
            <>
                <BackButton />
                <Row>
                    <PageTitle name='Cadastro de Movimentação de Estoque' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                {/* <Form> */}
                <Row>
                    <Col>
                        <Form.Label> </Form.Label>
                        <SimpleSelect
                            label='* Unidade que vai transferir:'
                            value={this.state.estoqueDe}
                            onChange={e => this.handleSelectEstoqueDe(e.target.value)}
                            required
                        >

                            <option></option>
                            {this.props.estoquesUsuario.map(estoque => (
                                <option key={estoque.id} value={estoque.id}>{estoque.unidadeEducacional.nome}</option>
                            ))}
                        </SimpleSelect>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <SimpleSelect
                            label='* Unidade que vai receber:'
                            value={this.state.estoquePara}
                            onChange={e => this.setState({ ...this.state, estoquePara: e.target.value })}
                            required
                        >

                            <option></option>
                            {this.props.estoques.map(estoque => (
                                <option key={estoque.id} value={estoque.id}>{estoque.unidadeEducacional.nome}</option>
                            ))}
                        </SimpleSelect>
                    </Col>
                </Row>
               <h6 style={{color:'rgba(255, 0, 0, 0.60)'}}>*Produtos em vermelho estão em deficit</h6>  
                <Row>
                    <Col md={5} style={{ overflow: 'auto', height: '600px' }}>
                        <ListGroup>
                            <ListGroup.Item variant="dark">
                                <Row>
                                    <Col md={8}>
                                        <b>Produto</b>
                                    </Col>
                                    <Col md={4}>
                                        <b>Quantidade</b>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            {this.state.source.map(produto => (
                                <ListGroup.Item
                                    action
                                    disabled={produto.quantidade <= 0 ? true :false}
                                    key={produto.id}
                                    onClick={() => this.onSelectProduto(produto)}
                                >
                                    <Row style={produto.quantidade <= 0 ? {backgroundColor:'rgba(255, 0, 0, 0.10)'}: {}}>
                                        <Col md={1}>
                                        {produto.quantidade <= 0 ? '' :
                                                <Form.Check
                                                    type="checkbox"
                                                    disabled={produto.quantidade <= 0}
                                                    checked={produto.isSelected}
                                                    onChange={() => this.onSelectProduto(produto)}
                                                />
                                            }
                                        </Col>
                                        <Col md={7}>
                                            ({produto.produtoLote.numero}) <br />{" "}
                                            <b>{produto.produtoLote.produto.nome}</b> -{" "}
                                            {produto.produtoLote.produto.item}
                                        </Col>
                                        <Col md={4}>
                                            {produto.quantidade} ({produto.produtoLote.produto.unidadeProduto})
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    <Col md={2}>
                        {/* <Button block style={{ fontSize: 18 }} onClick={this.onMoveToTarget}><b>></b></Button> */}
                        <SimpleButton
                            style={{ width: '100%' }}
                            type='button'
                            color='blue'
                            onClick={this.onMoveToTarget}
                            name='>'
                        />
                        <br/>
                        <br/>
                        <SimpleButton
                            style={{ width: '100%' }}
                            type="button"
                            color="blue"
                            onClick={this.onMoveToSource}
                            name="<"
                        />
                    </Col>
                    <Col md={5}  style={{ overflow: 'auto', height: '600px' }}>
                        <ListGroup>
                            <ListGroup.Item variant="dark">
                                <Row>
                                    <Col md={5}>
                                        <b>Produto</b>
                                    </Col>
                                    <Col md={5}>
                                        <b>Quantidade</b>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            {this.state.target.map((produto, index) => (
                                <ListGroup.Item key={produto.id}>
                                    <Row >

                                        <Col md={5}>
                                            ({produto.produtoLote.numero}) <br /> <b>{produto.produtoLote.produto.nome}</b> - {produto.produtoLote.produto.item}
                                        </Col>
                                        <Col md={5}>
                                            <InputGroup>
                                                <Form.Control type="number" min="0" value={produto.quantidade} onChange={e => this.handleChangeQuantidade(e.target.value, index)} />
                                                <InputGroup.Append>
                                                    <InputGroup.Text>{produto.quantidadeDefault}</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col md={1}>
                                            <TinyAction
                                                type="button"
                                                title="Remover ingrediente"
                                                onClick={() => this.removeFromTarget(produto)}
                                                color="red"
                                                icon="fas fa-times"
                                            />
                                        </Col>
                                        <Col md={1}>
                                            <Form.Check
                                                type="checkbox"
                                                checked={produto.isSelected}
                                                onChange={() => this.onSelectTargetProduto(produto)}
                                            />
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>

                <Row style={{ marginTop: '16px' }}>
                    <Col align='right'>
                        <SimpleButton
                            type='button'
                            color='green'
                            name='Cadastrar'
                            onClick={this.handleSubmit}
                            icon='fas fa-check'
                        />
                    </Col>
                </Row>
                {/* </Form> */}
            </>
        )
    }
}


const mapStateToProps = state => ({
    token: state.auth.token,
    resetNumbers: state.movimentacao.resetNumbers,
    estoquesUsuario: state.auth.user.estoques,
    estoques: state.estoque.list,
    produtosEstoque: state.estoque.produtos
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarMovimentacao,
    listarEstoques,
    listarProdutosEstoque
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarMovimentacaoPage), 'ESTOQUES');