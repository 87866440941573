import React from 'react';
import { InputGroup } from 'react-bootstrap';
import TInput from "react-input-time";

import styles from './TimeInput.module.css';

const TimeInput = props => (
    <InputGroup className='mb-3'>
        <InputGroup.Prepend>
            <InputGroup.Text className={styles.label}>{props.label}</InputGroup.Text>
        </InputGroup.Prepend>
        <TInput
            {...props}
            className={`form-control ${styles.input}`}
        />
    </InputGroup>
)

export default TimeInput;