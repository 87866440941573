import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleTable from '../../components/SimpleTable/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal/index';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleButton from '../../components/SimpleButton';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarItens, editarItem, filtrarItens, excluirItem, listarCategoriasAlimentos, listarTiposComponentesNutricionais, listarUnidadesMedidaNutricionais } from '../../actions';

import styles from './GerenciarItens.module.css';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';

import withAuthorization from '../../components/HighOrder/withAuthorization';

class GerenciarItensPage extends Component {
    state = {
        showModal: false,

        pesquisaNome: '',
        pesquisaTipo: '',
        isPesquisa: false,

        item: {}
    }

    componentDidMount() {
        this.props.listarCategoriasAlimentos(this.props.token)
        this.props.listarTiposComponentesNutricionais(this.props.token)
        this.props.listarUnidadesMedidaNutricionais(this.props.token)
        this.props.listarItens(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.closeModalNumbers) this.handleCloseModal();
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleShowModal = item => {
        this.setState({ item, showModal: true });
    }

    handleChange = (value, property, upper) => {
        let item = JSON.parse(JSON.stringify(this.state.item));
        item[property] = value;

        this.setState({ ...this.state, item }, () => upper())
    }

    handleEditSubmit = e => {
        e.preventDefault();

        let item = JSON.parse(JSON.stringify(this.state.item));

        this.props.editarItem(item, this.props.token);
    }


    mostrarPaginaRelatorio = () => {
        if(this.state.isPesquisa) {
            sessionStorage.setItem('pesquisaNome', this.state.pesquisaNome);
            sessionStorage.setItem('pesquisaTipo', this.state.pesquisaTipo);
        }
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_ITENS}/filter=${this.state.isPesquisa}`);
    }

    render() {
        let componentesForm =
            (
                <Row>
                    <Col>
                        <SimpleSelect 
                            label='* Categoria:'
                            value={this.state.item.categoria}
                            onChange={e => this.handleChange(e.target.value, 'categoria')} 
                            required
                        >

                            {this.props.categoriasAlimentos.map((categoria, index) =>
                                <option key={index} value={categoria.nome}>{categoria.descricao}</option>
                            )}
                        </SimpleSelect>
                    </Col>
                    {/* <Form.Label>Componentes: </Form.Label>
                    {this.state.item.hasOwnProperty('componentes') && this.state.item.componentes.map((componente, index) => (
                        <div key={index}>
                            <Form.Group as={Row}>
                                <Col md={5}>
                                    <Form.Control as='select'
                                        value={componente.tipo} 
                                        onChange={e => this.handleComponentesInput(e.target.value, index, 'tipo')} required>
                                        <option value=''></option>
                                        {this.props.tiposComponentesNutricionais.map(tipo =>
                                            <option key={hash(tipo)} value={tipo}>{tipo}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                                <Col md={3}>
                                    <Form.Control as='select' 
                                        value={componente.unidadeMedida} 
                                        onChange={e => this.handleComponentesInput(e.target.value, index, 'unidadeMedida')} required>
                                        <option value=''></option>                                        
                                        {this.props.unidadesMedidaNutricionais.map(unidadeMedida =>
                                            <option key={hash(unidadeMedida)} value={unidadeMedida}>{unidadeMedida}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                                <Col md={2}>
                                    <Form.Control type='number' value={componente.valor} onChange={e => this.handleComponentesInput(parseInt(e.target.value), index, 'valor')} placeholder='Valor' required />
                                </Col>
                                <Col md={2}>
                                    <Button onClick={() => this.handleAddComponente(index)}>+</Button>
                                    <Button onClick={() => this.handleRemoveComponente(index)}>-</Button>
                                </Col>
                            </Form.Group>
                        </div>
                    ))} */}
                </Row>
            );

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Itens' hasDivider={true} />
                </Row>
                <div align='right'>
                    <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        onClick={() => this.mostrarPaginaRelatorio()}
                    />
                </div>
                <Form>
                    <Form.Group>
                        <Form.Label>Pesquisar por nome:</Form.Label>
                        <Row>
                            <Col lg={6}>
                                <Form.Control type='text'
                                    value={this.state.pesquisaNome}
                                    onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value, isPesquisa: true })}
                                    onKeyUp={e => this.props.filtrarItens(this.state.pesquisaNome, this.state.pesquisaTipo)} />
                            </Col>
                            <Col lg={6}>
                                {[
                                    { label: 'TODOS', value: '' },
                                    { label: 'ALIMENTO', value: 'ALIMENTO' },
                                    { label: 'LIMPEZA', value: 'LIMPEZA' },
                                    { label: 'ESCRITORIO', value: 'ESCRITORIO' }
                                ].map((tipo, index) => (
                                    <Form.Check key={index} type='radio' inline custom id={`tipo${index}`} name='tipo'
                                        value={tipo.value}
                                        label={tipo.label}
                                        onChange={e => this.setState({ ...this.state, pesquisaTipo: e.target.value, isPesquisa: true }, () => this.props.filtrarItens(this.state.pesquisaNome, this.state.pesquisaTipo))}
                                    />
                                ))}
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Tipo</th>
                            <th>Categoria</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(item => (
                            <tr key={item.id}>
                                <td>{item.nome}</td>
                                <td>{item.itemProdutoTipo}</td>
                                <td>{item.itemProdutoTipo === 'ALIMENTO' ? item.categoria : '---'}</td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Editar Item'
                                        onClick={() => this.handleShowModal(item)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Item'
                                        onClick={() => this.props.excluirItem(item.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Item'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do item' align='left'>
                                    <Row>
                                        <Col>
                                            <Input 
                                                type='text'
                                                label='* Nome:'
                                                case='upper'
                                                value={this.state.item.nome}
                                                onChange={(value, upper) => this.handleChange(value, 'nome', upper)} 
                                                required
                                            />
                                        </Col>

                                    </Row>
                                    
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='Tipo:' 
                                                disabled
                                                value={this.state.item.itemProdutoTipo}
                                            >
                                                <option value=''></option>
                                                <option value='ALIMENTO'>ALIMENTO</option>
                                                <option value='LIMPEZA'>LIMPEZA</option>
                                                <option value='ESCRITORIO'>ESCRITÓRIO</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    {this.state.item.itemProdutoTipo === 'ALIMENTO' && componentesForm}                                
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    list: state.item.list,
    filtrados: state.item.filtrados,
    token: state.auth.token,
    closeModalNumbers: state.item.closeModalNumbers,
    categoriasAlimentos: state.convencao.categoriasAlimentos,
    tiposComponentesNutricionais: state.convencao.tiposComponentesNutricionais,
    unidadesMedidaNutricionais: state.convencao.unidadesMedidaNutricionais,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarItens,
    filtrarItens,
    editarItem,
    excluirItem,
    listarCategoriasAlimentos,
    listarTiposComponentesNutricionais,
    listarUnidadesMedidaNutricionais,
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarItensPage)), 'ITENS');