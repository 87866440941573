import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Input from '../../components/Input';
import TextInputMask from '../../components/TextInputMask';
import PageTitle from '../../components/PageTitle/index';
import Fieldset from '../../components/Fieldset';
import PhotoUpload from '../../components/PhotoUpload';
import { toastr } from 'react-redux-toastr';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editarDadosCadastrais, atualizarFotoUsaurio, buscarFotoUsuario } from '../../actions';

// import styles from './DadosCadastrais.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import SimpleButton from '../../components/SimpleButton';

class DadosCadastraisPage extends Component {
    state = {
        nome: '',
        email: '',
        cpf: '',
        telefone: '',
        celular: '',
        senha: '',
        confirmarSenha: ''
    }

    componentDidMount() {
        this.setState({
            nome: this.props.user.pessoa.nome,
            email: this.props.user.email,
            cpf: this.props.user.pessoa.cpf,
            telefone: this.props.user.pessoa.telefone,
            celular: this.props.user.pessoa.celular
        })
    }

    handleUpload = e => {
        e.preventDefault();

        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0])

        reader.onload = e => {
            // const data = new FormData();
            // data.append('file', e.target.result);
            // data.append('_method', 'POST');

            this.props.atualizarFotoUsaurio(this.props.user.id, e.target.result, this.props.token);
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        let {
            nome,
            email,
            cpf,
            telefone,
            celular,
            senha
        } = this.state;

        
        if(this.state.senha === this.state.confirmarSenha){
            let usuario = {
                email,
                pessoa: {
                    ...this.props.user.pessoa,
                    nome,
                    cpf: cpf.replace(new RegExp(/\D/g), ''),
                    telefone: telefone.replace(new RegExp(/\D/g), ''),
                    celular: celular.replace(new RegExp(/\D/g), ''),  
                },
                senha
            }
            //
            this.props.editarDadosCadastrais(usuario, this.props.token);
        } else {
            toastr.warning('Atenção', 'Senhas não correspondem!')
        }
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Dados cadastrais' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>
                <Row>
                    <Col sm={12} md={3}>
                        <Fieldset legend='Alterar foto do perfil' align='center'>
                            <PhotoUpload image={this.props.photo} onChange={this.handleUpload} />
                        </Fieldset>
                    </Col>
                    <Col sm={12} md={9}>
                        <Fieldset legend='Dados pessoais' align='left'>
                            <Form onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            label='* Nome:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ ...this.state, nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Input
                                            label='* E-mail:'
                                            type='text'
                                            value={this.state.email}
                                            onChange={value => this.setState({ ...this.state, email: value })}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <TextInputMask
                                            label='* CPF:'
                                            type='text'
                                            value={this.state.cpf}
                                            mask='999.999.999-99'
                                            onChange={e => this.setState({ ...this.state, cpf: e.target.value })}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Telefone fixo:'
                                            type='text'
                                            value={this.state.telefone}
                                            mask='(99) 9999-9999'
                                            onChange={e => this.setState({ ...this.state, telefone: e.target.value })}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='* Celular:'
                                            type='text'
                                            value={this.state.celular}
                                            mask='(99) 9 9999-9999'
                                            onChange={e => this.setState({ ...this.state, celular: e.target.value })}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='Senha:'
                                            type='password'
                                            value={this.state.senha}
                                            onChange={value => this.setState({ ...this.state, senha: value })}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='Confirmar Senha:'
                                            type='password'
                                            value={this.state.confirmarSenha}
                                            onChange={value => this.setState({ ...this.state, confirmarSenha: value })}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col align='right'>
                                        <SimpleButton type='submit' name='Salvar' icon='fas fa-check' color='green' />
                                    </Col>
                                </Row>
                            </Form>
                        </Fieldset>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    user: state.auth.user,
    photo: state.auth.photo,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    editarDadosCadastrais,
    atualizarFotoUsaurio,
    buscarFotoUsuario
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(DadosCadastraisPage), 'HOME');