import React from 'react';
import { Form } from 'react-bootstrap';

import styles from './LoginInputForm.module.css';

const LoginInputForm = props => (
    <Form.Group>
        <Form.Control
            className={styles.input} 
            type={props.type} 
            placeholder={props.placeholder} 
            value={props.value} 
            onChange={props.handleChange} 
        />      
    </Form.Group>
)

export default LoginInputForm;