import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filtrarUsuarios, listarUsuarios } from '../../actions';

import styles from './styles.module.css';
import formatCnpjCpf from '../../utils/formatCnpjCpf';
import formatTelefoneCelular from '../../utils/formatTelefoneCelular';

class Produtos extends Component {

    state = {
        usuarios: [],
    }

    componentWillMount() {
        const data = this.props.data.split('=');
        var pesquisaNome = '';
        if (data[1] === 'true') {
            pesquisaNome = sessionStorage.getItem('pesquisaNome');
            this.props.filtrarUsuarios("nome", pesquisaNome);
        } else if (data[1] === 'false') {
            this.props.listarUsuarios(this.props.token);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.usuarios !== this.props.usuarios) this.setState({ ...this.state, usuarios: nextProps.usuarios });
    }

    componentWillUnmount() {
        sessionStorage.removeItem('pesquisaNome');
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Usuários' />

                    <Detail>
                        <table border='1' className={styles.table}>
                            <thead>
                                <tr className={styles.titulo}>
                                    <th>Nome</th>
                                    <th>CPF</th>
                                    <th>Cargo</th>
                                    <th>Tipo</th>
                                    <th>E-mail</th>
                                    <th>Telefone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.usuarios.map((usuario, index) => (
                                        <tr key={index}>
                                            <th className={styles.dados}>{usuario.pessoa.nome}</th>
                                            <th className={styles.dados}>{formatCnpjCpf(usuario.pessoa.cpf)}</th>
                                            {
                                                usuario.pessoa.cargo ? (
                                                    <th className={styles.dados}>{usuario.pessoa.cargo}</th>

                                                ) : (
                                                        <th className={styles.vazio}>---</th>
                                                    )

                                            }
                                            <th className={styles.dados}>{usuario.pessoa.pessoaTipo}</th>
                                            <th className={styles.dados}>{usuario.email}</th>
                                            {
                                                usuario.pessoa.telefone === "" ? (
                                                    <th className={styles.vazio}>---</th>
                                                ) : (
                                                        <th className={styles.dados}>{formatTelefoneCelular(usuario.pessoa.telefone)}</th>
                                                    )

                                            }

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    usuarios: state.usuario.filtrados
})

const mapDispatchToProps = dispatch => bindActionCreators({
    filtrarUsuarios,
    listarUsuarios
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Produtos);