import React, { useEffect } from 'react';

import styles from './styles.module.css';

import { connect } from 'react-redux';

const cidade = localStorage.getItem('cidade') || ""
const removeAcento = (text) =>
{                                                              
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'A');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'E');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'I');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'O');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'U');
    text = text.replace(new RegExp('[Ç]','gi'), 'C');
    return text;                 
}
const novo = removeAcento(cidade)
const Header = props => {

    const municipio = JSON.parse(localStorage.getItem('cidade'))
    const nome = removeAcento(municipio.nome)
useEffect(() => {

})

return(
    <div className={styles.header}>
         { props.escola ?
                  <div className='row'>
                    <div className='col-md-2'>
                        <img alt='' src={`https://storage.googleapis.com/iescolar-static-01/${nome}/logo/logo.png`}  style={{width: 100, height: 100}}/>
                    </div>
                    <div className='col-md-5'>
                        <ul className={styles.escolaData}>
                            <li><b>Escola:</b> {props.escola.nome} </li>
                            {props.escola.codigoInep &&
                                <li><b>INEP:</b> {props.escola.codigoInep} </li>
                            }
                             <li><b>Cidade:</b> {props.secretaria.cidade.nome} </li>
                             <li><b>Estado:</b>  {props.secretaria.cidade.estado.nome} </li>
                        </ul>
                    </div>
                    <div className='col-md-5'>
                        <ul className={styles.escolaData}>
                            
                            <li><b>Endereço:</b> {`${props.escola.logradouro}, ${props.escola.bairro}, ${props.escola.numero}, ${props.escola.cep} `} </li>
                            <li><b>Contato:</b> {props.escola.telefone} </li>
                        </ul>
                    </div>
                </div>
        : 

                <div className='row'>
                <div className='col-md-2'>
                    <img alt='' src={`https://storage.googleapis.com/iescolar-static-01/${nome}/logo/logo.png`}    style={{width: 100, height: 100}}/>
                </div>
                <div className='col-md-5'>
                    <ul className={styles.escolaData}>
                        <li><b>Secretaria:</b> {props.secretaria.nome} </li>
                        <li><b>Cidade:</b> {props.secretaria.cidade.nome} </li>
                        <li><b>Estado:</b>  {props.secretaria.cidade.estado.nome} </li>
                    </ul>
                </div>
                <div className='col-md-5'>
                    <ul className={styles.escolaData}>
                        {props.secretaria.inep &&
                            <li><b>INEP:</b> {props.secretaria.inep} </li>
                        }
                        <li><b>Endereço:</b> {`${props.secretaria.logradouro}, ${props.secretaria.bairro}, ${props.secretaria.cep} `} </li>
                        <li><b>Contato:</b> {props.secretaria.telefone} </li>
                    </ul>
                </div>
            </div>
        
        }
        </div>       
)
    }

const mapStateToProps = state => ({
    secretaria: state.auth.secretaria
})

export default connect(mapStateToProps) (Header);