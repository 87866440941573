import React from 'react';
import PrintButton from '../components/PrintButton/index';
import BackButton from '../components/BackButton';
import Printable from '../components/Printable/index';
import Header from '../components/Header/index';
import Title from '../components/Title/index';
import Detail from '../components/Detail/index';

import { connect } from 'react-redux';
import styles from './styles.module.css';
import { bindActionCreators } from 'redux';

import { listarCardapiosCalendarioPorMesEUnidade } from '../../actions';
import { Row, Col } from 'react-bootstrap';
import Info from '../components/Info';

//const vazios
const cardapioVazio = {
    cardapio: {
        nome: 'vazio',
    }, pratos: []
}

const pratoVazio = {
    id: 0,
    prato: 0,
    cardapio: {
        cardapioTipo: '-',
        id: 0
    },
    tipo: {
        nome: '-',
        descricao: '-'
    }
}

class RelatorioCardapios extends React.Component {

    state = {
        chaves: [],
        diasSemana: [
            'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'
        ],
        dias: [],
        componentesNutricionais: {}
    }



    componentWillMount() {
        const datas = JSON.parse(sessionStorage.getItem('datas'));
        this.props.listarCardapiosCalendarioPorMesEUnidade(datas.dataInicial, datas.dataFinal, this.props.data, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        const dias = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: []
        }
        let componentesNutricionais = {}
        if (nextProps.relatorio !== this.props.relatorio) {
            this.setState({ ...this.state, relatorio: nextProps.relatorio })
            //pegando as keys
            const chaves = Object.keys(nextProps.relatorio);
            //retirando o ultimo, pq é uma informação do relatorio
            chaves.pop();
            componentesNutricionais = nextProps.relatorio.conjuntoNutricional;
            this.setState({ ...this.state, chaves: chaves, dias: dias, componentesNutricionais: componentesNutricionais }, () => this.dias(chaves, dias));
        }
    }

    componentWillUnmount() {
        sessionStorage.removeItem('datas');
    }

    dias(chaves, dias) {

        let maxCardapio = [];
        let maxPrato = [];
        //mondando arrays para cada dia com seus cardapios
        chaves.map(chave => {
            if (chave === '1') {
                this.state.relatorio[chave].map(c => {
                    dias[1].push(c);
                    return null;
                })
            } else if (chave === '2') {
                this.state.relatorio[chave].map(c => {
                    dias[2].push(c);
                    return null;
                })
            } else if (chave === '3') {
                this.state.relatorio[chave].map(c => {
                    dias[3].push(c);
                    return null;
                })
            } else if (chave === '4') {
                this.state.relatorio[chave].map(c => {
                    dias[4].push(c);
                    return null;
                })
            } else if (chave === '5') {
                this.state.relatorio[chave].map(c => {
                    dias[5].push(c);
                    return null;
                })
            } else if (chave === '6') {
                this.state.relatorio[chave].map(c => {
                    dias[6].push(c);
                    return null;
                })
            }

            dias[chave].map(d => {
                maxCardapio.push(dias[chave].length);
                return null;
            })

            dias[chave].map(d => {
                maxPrato.push(d.cardapio.pratos.length);
                return null;
            })

            return null;
        })
        //pegando o maior numero de cada 
        const maiorCardapio = maxCardapio.sort(function (a, b) { return b - a; })[0];
        const maiorPrato = maxPrato.sort(function (a, b) { return b - a; })[0];
        //chamando metodos dos vazios
        this.pushPratoCardapioVazio(maiorPrato);
        this.pushVazioCardapio(chaves, dias, maiorCardapio);
        this.pushVazioPrato(chaves, dias, maiorPrato);
        //
        this.setState({ ...this.state, dias: dias });

    }

    //inserindo um cardapio vazio para todos os cardapios ficarem com o msm tamanho
    pushVazioCardapio(chaves, dias, maiorCardapio) {
        chaves.map(chave => {
            if (dias[chave].length < maiorCardapio) {
                while (dias[chave].length !== maiorCardapio) {
                    dias[chave].push(cardapioVazio);
                }
            }
            return null;
        })
    }

    //inserindo prato vazio em um cardapio vazio
    pushPratoCardapioVazio(maiorPratos) {
        while (cardapioVazio.pratos.length !== maiorPratos) {
            cardapioVazio.pratos.push(pratoVazio);
        }
    }

    //inserindo pratos vazios para todos os cardapios ficarem com o msm tamanho
    pushVazioPrato(chaves, dias, maiorPratos) {
        chaves.map(chave => {
            dias[chave].map(dia => {
                if (dia.cardapio.pratos != null && dia.cardapio.pratos.length !== maiorPratos) {
                    while (dia.cardapio.pratos.length !== maiorPratos) {
                        dia.cardapio.pratos.push(pratoVazio)
                    }
                }
                return null;
            })
            return null;
        })
    }


    render() {
        return (
            <>
                <div className={styles.topbuttons}>
                    <BackButton />
                    <PrintButton />
                </div>

                <br />

                <Printable>
                    <Info />
                    <Header />
                    <Title name='Relatório de Calendário de Cardápios' />
                    <Detail>
                        <Row className={styles.detalhes}>
                            {
                                this.state.chaves.map(i => (
                                    <Col key={i} className={styles.col}>
                                        <table border='1' className={styles.tableCalendario}>
                                            <thead>
                                                <tr>
                                                    <th className={styles.titulo}>{this.state.diasSemana[i]}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dias[i].length > 0 && (
                                                        this.state.dias[i].map(cardapio => (
                                                            cardapio.cardapio.nome !== 'vazio' ? (
                                                                <>
                                                                    <tr>
                                                                        <td className={styles.cardapio}>{cardapio.cardapio.nome} - {cardapio.cardapio.categoria.faixaEtaria.nome} - {cardapio.cardapio.categoria.modalidade} - {cardapio.cardapio.modalidade.descricao}</td>
                                                                    </tr>
                                                                    {
                                                                        cardapio.cardapio.pratos.map((prato, indexPrato) => (

                                                                            prato.id !== 0 ? (
                                                                                <tr key={indexPrato}>
                                                                                    <td className={styles.pratos}>{prato.tipo.descricao}: {prato.nome}</td>
                                                                                </tr>
                                                                            ) : (
                                                                                    <tr>
                                                                                        <td className={styles.vazio}> --- </td>
                                                                                    </tr>
                                                                                )
                                                                        ))
                                                                    }
                                                                </>
                                                            ) : (
                                                                    <>
                                                                        <tr>
                                                                            <td className={styles.cardapio}> --- </td>
                                                                        </tr>
                                                                        {
                                                                            cardapioVazio.pratos.map((prato, indexPrato) => (
                                                                                <tr key={indexPrato}>
                                                                                    <td className={styles.vazio}> --- </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </>
                                                                )
                                                        ))
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                ))
                            }
                            <table border='1' className={styles.tableCalendario}>
                                <thead>
                                    <tr>
                                        <th colSpan="12">Componentes Nutricionais</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.componentes}>
                                        <td>Cálcio</td>
                                        <td>Caloria</td>
                                        <td>Carboidrato</td>
                                        <td>Ferro</td>
                                        <td>Fibra</td>
                                        <td>Lípidos</td>
                                        <td>Magnésio</td>
                                        <td>Proteína</td>
                                        <td>Sódio</td>
                                        <td>Vitamina A</td>
                                        <td>Vitamina C</td>
                                        <td>Zinco</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.componentesNutricionais.calcio}</td>
                                        <td>{this.state.componentesNutricionais.caloria}</td>
                                        <td>{this.state.componentesNutricionais.carboidrato}</td>
                                        <td>{this.state.componentesNutricionais.ferro}</td>
                                        <td>{this.state.componentesNutricionais.fibra}</td>
                                        <td>{this.state.componentesNutricionais.lipidio}</td>
                                        <td>{this.state.componentesNutricionais.magnesio}</td>
                                        <td>{this.state.componentesNutricionais.proteina}</td>
                                        <td>{this.state.componentesNutricionais.sodio}</td>
                                        <td>{this.state.componentesNutricionais.vitamina_a}</td>
                                        <td>{this.state.componentesNutricionais.vitamina_c}</td>
                                        <td>{this.state.componentesNutricionais.zinco}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    relatorio: state.cardapioCalendario.relatorio
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarCardapiosCalendarioPorMesEUnidade
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioCardapios);