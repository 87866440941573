import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import PageTitle from '../../components/PageTitle/index';
import Fieldset from '../../components/Fieldset';
import Textarea from '../../components/Textarea/index';
import Input from '../../components/Input';
import SimpleSelect from '../../components/SimpleSelect/index';
import TinyAction from '../../components/TinyAction';
import SimpleButton from '../../components/SimpleButton';
import MoneyInputMask from '../../components/MoneyInputMask/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    cadastrarAquisicao,
    listarFornecedores,
    listarProdutos,
    listarModalidadesLicitacao,
    listarTiposLicitacao,
    listarStatusAquisicao,
    listarUnidadesProduto
} from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import moment from 'moment';

import styles from './CadastrarAquisicao.module.css';

const INITIAL_FORM = {
    unidadeEducacional: -1,
    objeto: '',
    numero: '',
    numeroProcessoAdminis: '',
    dataContrato: '',
    vigencia: '',
    ano: '',

    aquisicaoTipo: 'LICITACAO',

    tipoDeLicitacao: '',
    modalidadeLicitacao: '',

    fonteRecurso: '',

    valor: 0,
    valorParcial: 0,
    propostasVencedoras: [
        { fornecedor: 0, numeroContrato: '', itens: [{ produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0, valorItem: 0 }] }
    ],
}

const INITIAL_PROPOSTAS = [
    { fornecedor: 0, numeroContrato: '', itens: [{ produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0, valorItem: 0 }] }
]

class CadastrarAquisicaoPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarFornecedores(this.props.token)
        // this.props.listarItens(this.props.token)
        this.props.listarProdutos(this.props.token);
        this.props.listarModalidadesLicitacao(this.props.token);
        this.props.listarTiposLicitacao(this.props.token);
        // this.props.listarStatusAquisicao(this.props.token);
        this.props.listarUnidadesProduto(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ ...INITIAL_FORM, propostasVencedoras: JSON.parse(JSON.stringify(INITIAL_PROPOSTAS)) })
    }

    handleAddProposta = index => {
        let proposta = { fornecedor: 0, itens: [{ produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0 }] }

        let propostasVencedoras = this.state.propostasVencedoras;
        propostasVencedoras.splice(index + 1, 0, proposta);

        this.setState({ ...this.state, propostasVencedoras })
    }

    handleRemoveProposta = index => {
        let propostasVencedoras = this.state.propostasVencedoras;
        if (propostasVencedoras.length > 1) {
            propostasVencedoras.splice(index, 1);

            this.setState({ ...this.state, propostasVencedoras }, () => this.calcValorParcial(propostasVencedoras))
        }
    }

    handleAddItem = (propostaIndex, itemIndex) => {
        let item = { produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0 }

        let propostasVencedoras = this.state.propostasVencedoras;
        propostasVencedoras[propostaIndex].itens.splice(itemIndex + 1, 0, item);

        this.setState({ ...this.state, propostasVencedoras })
    }

    handleRemoveItem = (propostaIndex, itemIndex) => {
        let propostasVencedoras = this.state.propostasVencedoras;
        if (propostasVencedoras[propostaIndex].itens.length > 1) {
            propostasVencedoras[propostaIndex].itens.splice(itemIndex, 1);

            this.setState({ ...this.state, propostasVencedoras }, () => this.calcValorParcial(propostasVencedoras))
        }
    }

    handlePropostasInput = (value, index, property) => {
        let propostasVencedoras = this.state.propostasVencedoras;
        propostasVencedoras[index][property] = value;

        this.setState({ ...this.state, propostasVencedoras })
    }

    handleItensInput = (value, propostaIndex, itemIndex, property, upper = () => { }) => {
        let propostasVencedoras = this.state.propostasVencedoras;
        propostasVencedoras[propostaIndex].itens[itemIndex][property] = value;

        if (property === 'quantidade' || property === 'precoUnidade') {
            let quantidade = propostasVencedoras[propostaIndex].itens[itemIndex].quantidade;
            let precoUnidade = propostasVencedoras[propostaIndex].itens[itemIndex].precoUnidade;

            propostasVencedoras[propostaIndex].itens[itemIndex]['valorItem'] = quantidade * precoUnidade;
        }

        this.setState({ ...this.state, propostasVencedoras }, () => {
            this.calcValorParcial(propostasVencedoras)
            upper();
        })
    }

    calcValorParcial = propostas => {
        let valorParcial = 0;
        for (let i = 0; i < propostas.length; i++) {
            let valorSoma = propostas[i]
                .itens.map(item => item.valorItem)
                .reduce((soma, valorAtual) => soma + valorAtual);

            valorParcial += valorSoma;
        }

        this.setState({ valorParcial })
    }

    handleSubmit = e => {
        e.preventDefault();

        // this.props.produtos.find(produto => produto.id === this.state.item)

        let aquisicao = JSON.parse(JSON.stringify(this.state));

        // Culpa de Juan 2x
        aquisicao.propostasVencedoras.forEach((proposta, index) => {
            let { id, fornecedorTipo } = this.props.fornecedores.find(fornecedor => fornecedor.id === proposta.fornecedor);
            aquisicao.propostasVencedoras[index].fornecedor = { id, fornecedorTipo }

            proposta.itens.forEach((obj, i) => {
                aquisicao.propostasVencedoras[index].itens[i].produto = this.props.produtos.find(produto => produto.id === obj.produto)
                // aquisicao.propostasVencedoras[index].itens[i].produto.item.categoria = licitacao.propostasVencedoras[index].itens[i].produto.item.categoria;
            })
        })

        aquisicao.dataContrato = moment(aquisicao.dataContrato, 'YYYY-MM-DD').format('DD/MM/YYYY')
        aquisicao.vigencia = moment(aquisicao.vigencia, 'YYYY-MM-DD').format('DD/MM/YYYY')

        aquisicao.propostasVencedoras = aquisicao.propostasVencedoras.map(proposta => {
            let valorProposta = proposta.itens
                .map(item => item.quantidade * item.precoUnidade)
                .reduce((soma, atual) => soma + atual)

            return { ...proposta, valor: valorProposta }
        })

        // console.log(aquisicao)

        this.props.cadastrarAquisicao(aquisicao, this.props.token);
    }

    render() {
        let propostasForm =
            (
                <Row style={{ marginTop: '16px' }}>
                    <Col>
                        <Fieldset legend='Propostas vencedoras' align='left'>
                            {this.state.propostasVencedoras.map((proposta, propostaIndex) => (
                                <div className={styles.propostas} key={propostaIndex}>
                                    <Row style={{ marginBottom: '16px' }}>
                                        <Col align='center'>
                                            <SimpleButton type='button' name='Fornecedor' color='blue' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={10}>
                                            <SimpleSelect
                                                label={`${propostaIndex + 1}:`}
                                                value={proposta.fornecedor}
                                                onChange={e => this.handlePropostasInput(parseInt(e.target.value), propostaIndex, 'fornecedor')}
                                                required
                                            >

                                                <option></option>
                                                {this.props.fornecedores.map(fornecedor =>
                                                    <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.nomeFantasia || fornecedor.nome}</option>
                                                )}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={2}>
                                            <Row>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        title='Adicionar novo fornecedor'
                                                        onClick={() => this.handleAddProposta(propostaIndex)}
                                                        color='green'
                                                        icon='fas fa-plus'
                                                    />
                                                </Col>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        title='Remover fornecedor'
                                                        onClick={() => this.handleRemoveProposta(propostaIndex)}
                                                        color='red'
                                                        icon='fas fa-minus'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Número Contrato:'
                                                type='text'
                                                value={proposta.numeroContrato}
                                                onChange={value => this.handlePropostasInput(value, propostaIndex, 'numeroContrato')}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <SimpleButton type='button' name='Itens' color='blue' />

                                    {proposta.itens.map((item, itemIndex) => (
                                        <div className={styles.itens} key={itemIndex}>
                                            <Row>
                                                <Col md={10}>
                                                    <SimpleSelect
                                                        label={`${itemIndex + 1}:`}
                                                        value={item.produto}
                                                        onChange={e => this.handleItensInput(parseInt(e.target.value), propostaIndex, itemIndex, 'produto')}
                                                        required
                                                    >

                                                        <option></option>
                                                        {this.props.produtos.map(produto =>
                                                            <option key={produto.id} value={produto.id}>{produto.nome}</option>
                                                        )}
                                                    </SimpleSelect>
                                                </Col>
                                                <Col md={2}>
                                                    <Row>
                                                        <Col align='center'>
                                                            <TinyAction
                                                                type='button'
                                                                title='Adicionar novo produto'
                                                                onClick={() => this.handleAddItem(propostaIndex, itemIndex)}
                                                                color='green'
                                                                icon='fas fa-plus'
                                                            />
                                                        </Col>
                                                        <Col align='center'>
                                                            <TinyAction
                                                                type='button'
                                                                title='Remover produto'
                                                                onClick={() => this.handleRemoveItem(propostaIndex, itemIndex)}
                                                                color='red'
                                                                icon='fas fa-minus'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Input
                                                        label='Descrição:'
                                                        type='text'
                                                        case='upper'
                                                        value={item.descricao}
                                                        onChange={(value, upper) => this.handleItensInput(value, propostaIndex, itemIndex, 'descricao', upper)}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Input
                                                        label='* Quantidade:'
                                                        type='number'
                                                        value={item.quantidade}
                                                        onChange={value => this.handleItensInput(value, propostaIndex, itemIndex, 'quantidade')}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <SimpleSelect
                                                        label='* Unidade de medida:'
                                                        value={item.unidadeProduto}
                                                        onChange={e => this.handleItensInput(e.target.value, propostaIndex, itemIndex, 'unidadeProduto')}
                                                        required
                                                    >

                                                        <option value=''></option>
                                                        {this.props.unidadesProduto.map((unidade, index) => (
                                                            <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                                        ))}
                                                    </SimpleSelect>
                                                </Col>
                                                <Col md={6}>
                                                    <MoneyInputMask
                                                        label='* Preço unidade:'
                                                        value={item.precoUnidade}
                                                        onChangeEvent={(e, maskedValue, floatValue) => this.handleItensInput(floatValue, propostaIndex, itemIndex, 'precoUnidade')}
                                                    />
                                                    <span className={styles.valorTotalItem}>
                                                        Valor total do item: <CurrencyFormat value={item.valorItem} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                    </span>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Fieldset>
                    </Col>
                </Row>
            );

        return (
            <>
                 <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Licitação/Contrato' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados de Licitação/Contrato' align='left'>
                                <Row>
                                    <Col md={12}>
                                        <SimpleSelect
                                            label='* Órgão Promotor:'
                                            value={this.state.unidadeEducacional}
                                            onChange={e => this.setState({ ...this.state, unidadeEducacional: e.target.value })}
                                            required>
                                            <option value=''></option>
                                            {this.props.unidadesUsuario.map((unidade, index) => (
                                                <option key={index} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Textarea
                                            label='* Objeto:'
                                            rows={3}
                                            value={this.state.objeto}
                                            onChange={(value, upper) => this.setState({ objeto: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='* Número:'
                                            type='text'
                                            case='upper'
                                            value={this.state.numero}
                                            onChange={(value, upper) => this.setState({ ...this.state, numero: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Nº Processo Administrativo:'
                                            type='text'
                                            value={this.state.numeroProcessoAdminis}
                                            onChange={value => this.setState({ ...this.state, numeroProcessoAdminis: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='* Data Contrato:'
                                            type='date'
                                            value={this.state.dataContrato}
                                            onChange={value => this.setState({ ...this.state, dataContrato: value })}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Vigência:'
                                            type='date'
                                            value={this.state.vigencia}
                                            onChange={value => this.setState({ ...this.state, vigencia: value })}
                                            required
                                            min= {new Date().toISOString().split("T")[0]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='* Ano:'
                                            type='text'
                                            value={this.state.ano}
                                            onChange={value => this.setState({ ...this.state, ano: value })}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Tipo de Aquisição:'
                                            value={this.state.aquisicaoTipo}
                                            onChange={e => this.setState({ ...this.state, aquisicaoTipo: e.target.value })}
                                            required>
                                            <option></option>
                                            <option value='LICITACAO'>LICITAÇÃO</option>
                                            <option value='CHAMADA'>CHAMADA PÚBLICA</option>
                                            <option value='DIRETA'>AQUISIÇÃO DIRETA</option>
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                {
                                    this.state.aquisicaoTipo === 'LICITACAO' ?
                                        (
                                            <>
                                                <Row>
                                                    <Col md={12}>
                                                        <SimpleSelect
                                                            label='* Tipo de Licitação:'
                                                            value={this.state.tipoDeLicitacao}
                                                            onChange={e => this.setState({ ...this.state, tipoDeLicitacao: e.target.value })}
                                                            required>
                                                            <option></option>
                                                            {this.props.tipos.map((tipo, index) => (
                                                                <option key={index} value={tipo.nome}>{tipo.descricao}</option>
                                                            ))}
                                                        </SimpleSelect>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <SimpleSelect
                                                            label='* Modalidade de Licitacao:'
                                                            value={this.state.modalidadeLicitacao}
                                                            onChange={e => this.setState({ ...this.state, modalidadeLicitacao: e.target.value })}
                                                            required
                                                        >
                                                            <option></option>
                                                            {this.props.modalidades.map((modalidade, index) => (
                                                                <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                                            ))}
                                                        </SimpleSelect>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                        :
                                        (
                                            <Row>
                                                <Col md={12}>
                                                    <Input
                                                        label='* Fonte de recurso:'
                                                        type='text'
                                                        case='upper'
                                                        value={this.state.fonteRecurso}
                                                        onChange={(value, upper) => this.setState({ ...this.state, fonteRecurso: value }, () => upper())}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                }

                                <Row>
                                    <Col md={12}>
                                        <MoneyInputMask
                                            label='* Valor total:'
                                            value={this.state.valor}
                                            onChangeEvent={(e, maskValue, floatValue) => this.setState({ ...this.state, valor: floatValue })}
                                        />
                                        <span style={{ color: this.state.valorParcial !== this.state.valor ? 'red' : 'green' }}>
                                            <b>Valor parcial:</b> <CurrencyFormat value={this.state.valorParcial} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> {this.state.valorParcial !== this.state.valor && '(O valor parcial é diferente do valor total da Aquisição)'}
                                        </span>
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    {propostasForm}

                    {/* <Button>Adicionar Fornecedor</Button>
                    <Accordion>
                        {this.state.propostasVencedoras.map((proposta, propostaIndex) => (
                            <Card key={propostaIndex}>
                                <Card.Header className={styles.cardHeader}>
                                    <Row>
                                        <Col md={8}>
                                            <Accordion.DataToggle target={propostaIndex}>
                                                <h5 className='mb-0'>
                                                    {proposta.fornecedor}
                                                </h5>
                                            </Accordion.DataToggle>
                                        </Col>
                                        <Col md={4}>
                                        </Col>
                                    </Row>
                                </Card.Header>

                                <Accordion.DataTarget receiver={propostaIndex}>
                                    <Card.Body>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Item</th>
                                                    <th>Descrição</th>
                                                    <th>Quantidade</th>
                                                    <th>Unidade Medida</th>
                                                    <th>Preço Unitário</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proposta.itens.map((item, itemIndex) => (
                                                    <tr key={itemIndex}>
                                                        <td>{itemIndex + 1}</td>
                                                        <td>{item.item}</td>
                                                        <td>
                                                            {item.descricao}
                                                        </td>
                                                        <td>{item.quantidade}</td>
                                                        <td>{item.unidade}</td>
                                                        <td>{item.precoUnidade}</td>
                                                        <td className={styles.actions}>
                                                            <Button className={styles.actionButton}
                                                                // onClick={() => this.handleShowModalEdit(estoque.id, produto)}
                                                                variant='warning'>
                                                                <i className='fas fa-edit'></i>
                                                            </Button>
                                                            <Button className={styles.actionButton}
                                                                // onClick={() => this.props.excluirProdutoEstoque(estoque.id, produto.id, this.props.token)}
                                                                variant='danger'>
                                                                <i className='fas fa-trash-alt'></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.DataTarget>
                            </Card>
                        ))}
                    </Accordion> */}

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
                {/* 
                <Modal
                    show={this.state.showModalAdd}
                    onHide={this.handleCloseModalAdd}
                    size='md'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Lote: </Form.Label>
                                <Form.Control type='text' value={this.state.numero} onChange={e => this.setState({ numero: e.target.value })} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Produto: </Form.Label>
                                <Form.Control as='select' value={this.state.produto} onChange={e => this.setState({ produto: e.target.value })} required>
                                    <option value={-1}></option>
                                    {this.props.produtos.map(produto => (
                                        <option key={produto.id} value={produto.id}>{produto.nome}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Data de Fabricação: </Form.Label>
                                <Form.Control type='date' value={this.state.dataFabricacao} onChange={e => this.setState({ dataFabricacao: e.target.value })} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Data de Validade: </Form.Label>
                                <Form.Control type='date' value={this.state.dataValidade} onChange={e => this.setState({ dataValidade: e.target.value })} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Quantidade: </Form.Label>
                                <Form.Control type='number' value={this.state.quantidade} onChange={e => this.setState({ quantidade: e.target.value })} required />
                            </Form.Group>

                            <Button type='submit' onClick={this.handleSubmit} block>Adicionar</Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showModalEdit}
                    onHide={this.handleCloseModalEdit}
                    size='md'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title>Editar Produto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Quantidade: </Form.Label>
                                <Form.Control type='number'
                                    value={this.state.newQuantidade}
                                    onChange={e => this.setState({ newQuantidade: e.target.value })} required />
                            </Form.Group>

                            <Button type='submit' onClick={this.handleEditSubmit} block>Editar</Button>
                        </Form>
                    </Modal.Body>
                </Modal> */}
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    unidadesUsuario: state.auth.user.estoques,
    resetNumbers: state.aquisicao.resetNumbers,
    fornecedores: state.fornecedor.list,
    produtos: state.produto.list,
    modalidades: state.convencao.modalidadesLicitacao,
    tipos: state.convencao.tiposLicitacao,
    status: state.convencao.statusAquisicao,
    unidadesProduto: state.convencao.unidadesProduto
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarAquisicao,
    listarFornecedores,
    listarProdutos,
    listarModalidadesLicitacao,
    listarTiposLicitacao,
    listarStatusAquisicao,
    listarUnidadesProduto
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarAquisicaoPage), 'AQUISICOES');