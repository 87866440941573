import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as routes from '../../../Routes/constants';

import styles from './SubheaderLogo.module.css';

import logo from '../../../assets/images/logo.svg';

const SubheaderLogo = props => (
    <div className={styles.logo}>
        <div className={styles.item}>-</div>
        <Link to={routes.HOME}>
            <Image src={logo} fluid />
        </Link>
        <div className={styles.item}>-</div>
    </div>

)

export default SubheaderLogo;