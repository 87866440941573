import React from 'react';

import styles from './Card.module.css';

const Card = props => (
    <div className={styles.card} {...props}>
        {props.text}
    </div>
)

export default Card;