import { CADASTRAR_AQUISICAO, EDITAR_AQUISICAO, LISTAR_AQUISICOES, LISTAR_UMA_AQUISICAO, BUSCAR_MOTIVO_CANCELAMENTO_AQUISICAO, FILTRAR_AQUISICOES, LISTAR_ITENS_AQUISICAO } from '../actions/types'

const INITIAL_STATE = {
    list: [],
    filtrados: [],
    motivoCancelamento: {},
    aquisicao: {},
    itens: [],

    resetNumbers: 0,
    closeModalNumbers: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CADASTRAR_AQUISICAO:
            return { ...state, resetNumbers: ++state.resetNumbers }

        case EDITAR_AQUISICAO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case LISTAR_AQUISICOES:
            return { ...state, list: action.payload }

        case LISTAR_UMA_AQUISICAO:
            return { ...state, aquisicao: action.payload }

        case FILTRAR_AQUISICOES:
            return { ...state, filtrados: action.payload }

        case BUSCAR_MOTIVO_CANCELAMENTO_AQUISICAO:
            return { ...state, motivoCancelamento: action.payload }

        case LISTAR_ITENS_AQUISICAO:
            return { ...state, itens: action.payload }

        default:
            return state
    }
}