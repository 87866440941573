import {
    CADASTRAR_SOLICITACAO,
    PRODUTOS_RECEBIDOS, 
    RECEBIMENTO_COMPRA, 
    EDITAR_NOTA_FISCAL, 
    IMAGEM_NOTA_FISCAL, 
    EDITAR_SOLICITACAO, 
    LISTAR_SOLICITACOES, 
    BUSCAR_MOTIVO_CANCELAMENTO_SOLICITACAO, 
    FILTRAR_SOLICITACOES, 
    LISTAR_UMA_SOLICITACAO
} from '../actions/types'

const INITIAL_STATE = {
    list: [],
    filtrados: [],
    motivoCancelamento: {},
    solicitacao: {},

    resetNumbers: 0,
    closeModalNumbers: 0,
    produto: {},
    imagem: null,
    recebimento: {}
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CADASTRAR_SOLICITACAO:
            return { ...state, resetNumbers: ++state.resetNumbers }

        case EDITAR_SOLICITACAO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case LISTAR_SOLICITACOES:
            return { ...state, list: action.payload }

        case LISTAR_UMA_SOLICITACAO:
            return { ...state, solicitacao: action.payload }

        case FILTRAR_SOLICITACOES:
            return { ...state, filtrados: action.payload }

        case BUSCAR_MOTIVO_CANCELAMENTO_SOLICITACAO:
            return { ...state, motivoCancelamento: action.payload }

        case PRODUTOS_RECEBIDOS:
            return { ...state, produto: action.payload }

        case IMAGEM_NOTA_FISCAL:
            return { ...state, imagem: action.payload }

        case EDITAR_NOTA_FISCAL:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case RECEBIMENTO_COMPRA:
            return { ...state, recebimento: action.payload }

        default:
            return state
    }
}