import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import SimpleTable from '../../components/SimpleTable/index';
import PageTitle from '../../components/PageTitle/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input';
import TextInputMask from '../../components/TextInputMask';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleButton from '../../components/SimpleButton/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarUnidadesEducacionais, filtrarUnidades, editarUnidadeEducacional, excluirUnidadeEducacional, listarEstoques, listarTiposPermissoes, cadastrarUnidadeEducacional, listarCargos, salvarUnidadesEducacionais } from '../../actions';

import styles from './GerenciarUsuarios.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import * as unidadesEdu from '../../services/unidades';

class GerenciarUnidades extends Component {
    state = {
        showModalEstoques: false,
        showModalPermissoes: false,
        showModalDetalhes: false,
        urlIescolar: '',
        pesquisaNome: '',
        pesquisaInep: '',
        pesquisaCidade: '',
        isPesquisa: false,

        showModal: false,
        unidade: {
            tipoUnidade: '',
            codigoInep: '',
            cidade: '',
            logradouro: '',
            bairro: '',
            numero: '',
            cep: '',
            telefone: '',
        },

        UnidadeDetalhes: {
            tipoUnidade: '',
            codigoInep: '',
            cidade: '',
            logradouro: '',
            bairro: '',
            numero: '',
            cep: '',
            telefone: '',
        },

        selecionarTodosEstoques: false,
        selecionarTodasPermissoes: false,

        permissoesList: [],
        estoquesList: [],

        unidadeEstoque: -1,
        permissao: ''
    }


    componentDidMount() {
     
        this.props.listarUnidadesEducacionais(this.props.token);
        this.props.listarEstoques(this.props.token);
        this.props.listarTiposPermissoes(this.props.token);
        this.props.listarCargos(this.props.token);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tiposPermissoes !== this.props.tiposPermissoes) {
            this.setState({ ...this.state, permissoesList: nextProps.tiposPermissoes.map(permissao => ({ nome: permissao, checked: false })) })
        }

        // if (nextProps.estoques !== this.props.estoques) {
        //     this.setState({ ...this.state, estoquesList: nextProps.estoques.map(estoque => ({ id: estoque.id, nome: estoque.unidadeEducacional.nome, checked: false })) })
        // }

        if (this.props.closeModalCount < nextProps.closeModalCount) this.handleCloseModal();
    }

    selecionarTodosEstoques = () => {
        let unidade = this.state.unidade;
        let estoques = unidade.estoques;
        let modificandoEstoques = [];
        if (this.state.selecionarTodosEstoques) {
            modificandoEstoques = estoques.map(e => {
                e.checked = false;
                return e;
            })
        } else {
            modificandoEstoques = estoques.map(e => {
                e.checked = true;
                return e;
            })
        }

        unidade.estoques = modificandoEstoques;

        this.setState({ ...this.state, selecionarTodosEstoques: !this.state.selecionarTodosEstoques, unidade })
    }

    selecionarTodasPermissoes = () => {
        let unidade = this.state.unidade;
        let permissoes = unidade.permissoes;
        let modificandoPermissoes = [];
        if (this.state.selecionarTodasPermissoes) {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = false;
                return p;
            })
        } else {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = true;
                return p;
            })
        }

        unidade.permissoes = modificandoPermissoes;

        this.setState({ ...this.state, selecionarTodasPermissoes: !this.state.selecionarTodasPermissoes, unidade })
    }

    selecionarEstoque = index => {
        let unidade = this.state.unidade;
        let estoques = unidade.estoques;
        estoques[index].checked = !estoques[index].checked;

        unidade.estoques = estoques;

        this.setState({ ...this.state, unidade })
    }

    selecionarPermissao = index => {
        let unidade = this.state.unidade;
        let permissoes = unidade.permissoes;
        permissoes[index].checked = !permissoes[index].checked;

        unidade.permissoes = permissoes;

        this.setState({ ...this.state, unidade })
    }

    handleCloseModal = () => {
        this.setState({ ...this.state, showModal: false })
    }

    handleShowModal = unidade => {
        let copyUnidade = JSON.parse(JSON.stringify(unidade));
        


        this.setState({ ...this.state, unidade: copyUnidade, showModal: true })
    }

    handleCloseModalDetalhes = () => {
        this.setState({ ...this.state, showModalDetalhes: false })
    }

    handleShowModalDetalhes = unidade => {
        let copyUnidade = JSON.parse(JSON.stringify(unidade));
        this.setState({ ...this.state, showModalDetalhes: true, UnidadeDetalhes: copyUnidade })
    }

    handleChange = (value, property) => {
        let unidade = this.state.unidade
        unidade[property] = value

        this.setState({ ...this.state, unidade })
    }

    handleChangeUnidade = (value, property, upper = () => { }) => {
        let unidade = this.state.unidade;
        unidade[property] = value;

        this.setState({ ...this.state, unidade }, () => {
            upper();
        })
    }

    handleEditSubmit = e => {
        e.preventDefault();

        let unidade = JSON.parse(JSON.stringify(this.state.unidade));

        // unidade.permissoes = unidade.permissoes.filter(permissao => permissao.checked).map(permissao => permissao.nome)
        // unidade.estoques = unidade.estoques.filter(estoque => estoque.checked).map(estoque => ({ id: estoque.id }))
         let auxTelefone = '';
       
         if(unidade.telefone.length > 0){
            auxTelefone = unidade.telefone.replace(new RegExp(/\D/g), '')
         }

        


        let unidadeFormatted = {
            id: unidade.id,
            nome: unidade.nome,
            cep: unidade.cep.replace(new RegExp(/\D/g), ''),
            cidade: unidade.cidade,
            logradouro: unidade.logradouro,
            bairro: unidade.bairro,
            numero: unidade.numero,
            tipoUnidade: unidade.tipoUnidade.descricao === undefined || unidade.tipoUnidade.descricao === null?unidade.tipoUnidade:unidade.tipoUnidade.descricao,
            telefone: auxTelefone,
            codigoInep: unidade.codigoInep,
            nomeDoResponsavel: "sistema",
            estoque: {
                id: unidade.estoque
            }


        }


        this.props.editarUnidadeEducacional(unidade.id, unidadeFormatted, this.props.token)

    }


    mostrarPaginaRelatorio = () => {
        if (this.state.isPesquisa) {
            sessionStorage.setItem("pesquisaNome", this.state.pesquisaNome);
        }
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_USUARIOS}/filter=${this.state.isPesquisa}`);
    }

    async handleBuscarUnidades() {
        try {
            toastr.info("Procurando por novas unidades")
            const response = await unidadesEdu.find()
            const unidades = response.data;
            if (unidades) {
                toastr.info(`${unidades.length} unidades encontradas`)
                toastr.info(`Atualizando dados`)
                //formatando dados para salvalos
                const respFormated = unidades.map(u => ({
                    id: u.id,
                    idIEscolar: u.id,
                    nome: u.nome,
                    tipoUnidade: "ESCOLA",
                    //nomeDoResponsavel: "Sistema",
                    nomeDoResponsavel: u.gestor,
                    cep: u.cep,
                    cidade: u.cidade,
                    bairro: u.bairro,
                    logradouro: u.logradouro,
                    numero: u.numero,
                    telefone: u.telefone,
                    inep: u.inep,
                }
                ))

                console.log(respFormated)
                await this.props.salvarUnidadesEducacionais(respFormated, this.props.token);
                this.props.listarUnidadesEducacionais(this.props.token);

            } else {
                toastr.info("Nenhuma unidade encontrada")
            }

        } catch (err) {
            toastr.error(`Erro inesperado:${err}`)
        }
    }


    render() {
        return (
            <>
                <BackButton />
                <Row>
                    <PageTitle name='Gerenciar Unidades' hasDivider={true} />
                </Row>
                <div align='right'>
                    {/* <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        onClick={() => this.mostrarPaginaRelatorio()}
                    /> */}

                    <SimpleButton
                        type='button'
                        color='green'
                        name='Buscar unidades'
                        icon='fas fa-arrow-alt-circle-down'
                        onClick={() => this.handleBuscarUnidades()}
                    />

                </div>

                {/* <Form onSubmit={this.handlePesquisaSubmit}> */}
                <Form.Group style={{display: 'flex',gap: '13px', justifyContent:'space-between', marginTop:45}}>
                    {/* <Form.Label>Pesquisar por Inep: </Form.Label> */}
                            <Form.Control style={{ width:150}} type='text'
                                value={this.state.pesquisaInep}
                                placeholder={"Pesquisar por Inep"}
                                onChange={e => this.setState({ ...this.state, pesquisaInep: e.target.value, isPesquisa: true })}
                                // onKeyUp={e => this.props.filtrarUnidades('codigoInep', this.state.pesquisaInep)} />
                                onKeyUp={e => this.props.filtrarUnidades('inep', this.state.pesquisaInep)} />
                    {/* <Form.Label>Pesquisar por Nome: </Form.Label> */}
                    
                            <Form.Control style={{ width:400}}type='text'
                                value={this.state.pesquisaNome}
                                placeholder={"Pesquisar por Nome"}
                                onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value, isPesquisa: true })}
                                onKeyUp={e => this.props.filtrarUnidades('nome', this.state.pesquisaNome)} />
                    {/* <Form.Label>Pesquisar por Cidade: </Form.Label> */}
                            <Form.Control style={{ width:300}} type='text'
                                value={this.state.pesquisaCidade}
                                placeholder={"Pesquisar por Cidade"}
                                onChange={e => this.setState({ ...this.state, pesquisaCidade: e.target.value, isPesquisa: true })}
                                onKeyUp={e => this.props.filtrarUnidades('cidade' , this.state.pesquisaCidade)} />
                    
                </Form.Group>

                {/* </Form> */}

                <SimpleTable>
                    <thead>
                        <tr>

                            <th>Inep</th>
                            {/* <th>Usuário</th>
                            <th>Tipo</th> */}
                            {/* <th>CPF</th> */}
                            {/* <th>Telefone</th> */}
                            {/* <th>Celular</th> */}
                            <th>Nome</th>
                            <th>Cidade</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(unidade => (
                            <tr key={unidade.codigo}>
                                {/* <td>{unidade.codigoInep}</td> */}
                                <td>{unidade.inep}</td>
                                <td>{unidade.nome}</td>
                                <td>{unidade.cidade}</td>
                                {/* <td>{unidade.email}</td>
                                <td>{unidade.unidade}</td>
                                <td>{unidade.pessoa.pessoaTipo}</td> */}
                                {/* <td>{unidade.pessoa.cpf}</td> */}
                                {/* <td>{unidade.pessoa.telefone}</td> */}
                                {/* <td>{unidade.pessoa.celular}</td> */}
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Usuário'
                                        onClick={() => this.handleShowModalDetalhes(unidade)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Editar Usuário'
                                        onClick={() => this.handleShowModal(unidade)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Usuário'
                                        onClick={() => this.props.excluirUnidadeEducacional(unidade.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Unidade'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados' align='left'>
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label='* Nome:'
                                                type='text'
                                                case='upper'
                                                value={this.state.unidade.nome}
                                                onChange={(value, upper) => this.handleChangeUnidade(value, 'nome', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='* Cep:'
                                                type='text'
                                                mask='99999-999'
                                                value={this.state.unidade.cep}
                                                onChange={e => this.handleChangeUnidade(e.target.value, 'cep')}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='* Logradouro:'
                                                type='text'
                                                case='upper'
                                                value={this.state.unidade.logradouro}
                                                onChange={(e, upper) => this.handleChangeUnidade(e, 'logradouro', upper)}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='* Cidade:'
                                                type='text'
                                                case='upper'
                                                value={this.state.unidade.cidade}
                                                onChange={(e, upper) => this.handleChangeUnidade(e, 'cidade', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='* Número:'
                                                type='text'
                                                value={this.state.unidade.numero}
                                                onChange={e => this.handleChangeUnidade(e, 'numero')}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label=' Bairro:'
                                                type='text'
                                                case='upper'
                                                value={this.state.unidade.bairro}
                                                onChange={(e, upper) => this.handleChangeUnidade(e, 'bairro', upper)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='Telefone:'
                                                type='text'
                                                mask='(99) 99999-9999'
                                                value={this.state.unidade.telefone}
                                                onChange={e => this.handleChangeUnidade(e.target.value, 'telefone')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <SimpleSelect
                                                label='* Tipo de Unidade:'
                                                defaultValue={this.state.unidade.tipoUnidade.descricao}
                                                onChange={e => this.handleChangeUnidade(e.target.value, 'tipoUnidade')}
                                                required
                                            >
                                                <option hidden>Selecione uma opção</option>
                                                <option key={1} value={'ESCOLA'}>Escola</option>
                                                <option key={2} value={'INSTITUICAO'}>Instituição</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {this.state.unidade.tipoUnidade === 'ESCOLA' &&
                                                <Input
                                                    label=' Codígo do Inep:'
                                                    type='text'
                                                    value={this.state.unidade.codigoInep}
                                                    onChange={e => this.handleChangeUnidade(e, 'codigoInep')}
                                                    
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalDetalhes}
                    onHide={this.handleCloseModalDetalhes}
                    title='Detalhes'
                    icon='fas fa-eye'>

                    <Row>
                        <Col>
                            <Fieldset legend='Dados da unidade' align='left'>
                            <Row>
                                        <Col md={12}>
                                            <Input
                                                label='* Nome :'
                                                type='text'
                                                case='upper'
                                                value={this.state.UnidadeDetalhes.nome}
                                                onChange={(value, upper) => this.handleChangeUnidade(value, 'nome', upper)}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='* Cep:'
                                                type='text'
                                                mask='99999-999'
                                                value={this.state.UnidadeDetalhes.cep}
                                                onChange={e => this.handleChangeUnidade(e.target.value, 'cep')}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='* Cidade:'
                                                type='text'
                                                value={this.state.UnidadeDetalhes.cidade}
                                                onChange={e => this.handleChangeUnidade(e, 'cidade')}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='* Logradouro:'
                                                type='text'
                                                value={this.state.UnidadeDetalhes.logradouro}
                                                onChange={e => this.handleChangeUnidade(e, 'logradouro')}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='* Número:'
                                                type='text'
                                                value={this.state.UnidadeDetalhes.numero}
                                                onChange={e => this.handleChangeUnidade(e, 'numero')}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Bairro:'
                                                type='text'
                                                mask=''
                                                value={this.state.UnidadeDetalhes.bairro}
                                                onChange={e => this.handleChangeUnidade(e, 'bairro')}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='Telefone:'
                                                type='text'
                                                mask='(99) 99999-9999'
                                                value={this.state.UnidadeDetalhes.telefone}
                                                disabled
                                                onChange={e => this.handleChangeUnidade(e.target.value, 'telefone')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <SimpleSelect
                                                label='* Tipo de Unidade:'
                                                defaultValue={this.state.UnidadeDetalhes.tipoUnidade.descricao}
                                                onChange={e => this.handleChangeUnidade(e, 'tipoUnidade')}
                                                disabled
                                            >
                                                <option hidden>Não Informado</option>
                                                <option key={1} value={'ESCOLA'}>Escola</option>
                                                <option key={2} value={'INSTITUICAO'}>Instituição</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {this.state.UnidadeDetalhes.tipoUnidade === 'ESCOLA' &&
                                                <TextInputMask
                                                    label=' Codígo do Inep:'
                                                    type='text'
                                                    mask=''
                                                    value={this.state.UnidadeDetalhes.codigoInep}
                                                    onChange={e => this.handleChangeUnidade(e, 'codigoInep')}
                                                    disabled
                                                />
                                            }
                                        </Col>
                                    </Row>
                            </Fieldset>
                        </Col>
                    </Row>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalCount: state.unidadeEducacional.closeModalCount,
    list: state.unidadeEducacional.list,
    filtrados: state.unidadeEducacional.filtrados,
    estoques: state.estoque.list,
    tiposPermissoes: state.convencao.tiposPermissoes,
    cargos: state.convencao.cargos
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarUnidadesEducacionais,
    filtrarUnidades,
    salvarUnidadesEducacionais,
    cadastrarUnidadeEducacional,
    editarUnidadeEducacional,
    excluirUnidadeEducacional,
    listarEstoques,
    listarTiposPermissoes,
    listarCargos
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarUnidades)), 'UNIDADES')