import React from 'react';

import Item from './Item';
import ItemWithSubmenu from './ItemWithSubmenu';
import ItemWithSubSubmenu from './ItemWithSubSubmenu';

import { connect } from 'react-redux';

import './Sidebar.css';

import * as routes from '../../Routes/constants';

import paginaInicialIcon from '../../assets/icons/pagina-inicial.svg';
import usuariosIcon from '../../assets/icons/usuarios.svg';
import cadastrarIcon from '../../assets/icons/cadastrar.svg';
import gerenciarIcon from '../../assets/icons/gerenciar.svg';
import dadosCadastraisIcon from '../../assets/icons/dados-cadastrais.svg';
import itensIcon from '../../assets/icons/itens.svg';
import produtosIcon from '../../assets/icons/produtos.svg';
import fornecedoresIcon from '../../assets/icons/fornecedores.svg';
import licitacoesIcon from '../../assets/icons/licitacoes.svg';
import solicitacoesCompraIcon from '../../assets/icons/solicitacoesCompra.svg';
import estoqueIcon from '../../assets/icons/estoque.svg';
import gerenciarMaiorIcon from '../../assets/icons/gerenciarMaior.svg';
import movimentacoesIcon from '../../assets/icons/movimentacoes.svg';
import alimentosIcon from '../../assets/icons/alimentos.svg';
import preparosIcon from '../../assets/icons/preparos.svg';
import pratosIcon from '../../assets/icons/pratos.svg';
import cardapioIcon from '../../assets/icons/cardapio.svg';
import escolaIcon from '../../assets/icons/escola.svg';

class Sidebar extends React.Component {
    state = {
        opened: true,
    }

    handleMouseEnter = e => {
        this.setState({ opened: true })
    }

    handleMouseLeave = e => {
        this.setState({ opened: false }, () => this.props.handleClose)
    }

    render() {
        const { props } = this;

        return (
            <div>
                <nav onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className={`main-menu noHover ${this.props.respMenuOpened ? 'isVisible' : 'isNotVisible'}`}>
                    <ul>
                        <li>
                            <Item name='Página inicial' icon={paginaInicialIcon} path={routes.HOME} />
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('USUARIOS')}>
                            <ItemWithSubmenu name='Usuários' icon={usuariosIcon} target='usuarios' opened={this.state.opened}>
                                <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_USUARIO} />
                                <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_USUARIOS} />
                            </ItemWithSubmenu>
                        </li>
                        <li hidden={!props.usuario.permissoes.includes('UNIDADES')}>
                            <ItemWithSubmenu name='Unidades Educacionais' icon={escolaIcon} target='Unidades' opened={this.state.opened}>
                                <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_UNIDADE} />
                                <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_UNIDADES} />
                            </ItemWithSubmenu>
                        </li>

                        <li>
                            <Item name='Dados cadastrais' icon={dadosCadastraisIcon} path={routes.DADOS_CADASTRAIS} />
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('ITENS') && !props.usuario.permissoes.includes('PRODUTOS') && !props.usuario.permissoes.includes('FORNECEDORES')}>
                            <ItemWithSubSubmenu name='Menu de itens' icon={itensIcon} target='menuItens' opened={this.state.opened}>
                                <div hidden={!props.usuario.permissoes.includes('ITENS')}>
                                    <ItemWithSubmenu name='Itens' icon={itensIcon} target='itens' opened={this.state.opened}>
                                        <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_ITEM} />
                                        <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_ITENS} />
                                    </ItemWithSubmenu>
                                </div>
                                <div hidden={!props.usuario.permissoes.includes('PRODUTOS')}>
                                    <ItemWithSubmenu name='Produtos' icon={produtosIcon} target='produtos' opened={this.state.opened}>
                                        <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_PRODUTO} />
                                        <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_PRODUTOS} />
                                    </ItemWithSubmenu>
                                </div>
                                <div hidden={!props.usuario.permissoes.includes('FORNECEDORES')}>
                                    <ItemWithSubmenu name='Fornecedores' icon={fornecedoresIcon} target='fornecedores' opened={this.state.opened}>
                                        <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_FORNECEDOR} />
                                        <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_FORNECEDORES} />
                                    </ItemWithSubmenu>
                                </div>
                            </ItemWithSubSubmenu>
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('AQUISICOES')}>
                            <ItemWithSubmenu name='Aquisições' icon={licitacoesIcon} target='aquisicoes' opened={this.state.opened}>
                                <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_AQUISICAO} />
                                <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_AQUISICOES} />
                            </ItemWithSubmenu>
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('SOLICITACOES')}>
                            <ItemWithSubmenu name='Solicitações de compra' icon={solicitacoesCompraIcon} target='solicitacoes' opened={this.state.opened}>
                                <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_SOLICITACAO} />
                                <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_SOLICITACOES} />
                            </ItemWithSubmenu>
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('ESTOQUES')}>
                            <ItemWithSubSubmenu name='Estoque' icon={estoqueIcon} target='estoque' opened={this.state.opened}>
                                <Item name='Gerenciar estoques' icon={gerenciarMaiorIcon} path={routes.ESTOQUE} />
                                <Item name='Balanços' icon={produtosIcon} path={routes.BALANCOS} />
                                <ItemWithSubmenu name='Movimentação' icon={movimentacoesIcon} target='movimentacao' opened={this.state.opened}>
                                    <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_MOVIMENTACAO} />
                                    <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_MOVIMENTACOES} />
                                </ItemWithSubmenu>
                            </ItemWithSubSubmenu>
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('ALIMENTOS') && !props.usuario.permissoes.includes('PREPAROS') && !props.usuario.permissoes.includes('PRATOS')}>
                            <ItemWithSubSubmenu name='Menu de alimentos' icon={alimentosIcon} target='menuAlimentos' opened={this.state.opened}>
                                <div hidden={!props.usuario.permissoes.includes('ALIMENTOS')}>
                                    <ItemWithSubmenu name='Alimentos' icon={alimentosIcon} target='alimentos' opened={this.state.opened}>
                                        <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_ALIMENTO} />
                                        <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_ALIMENTOS} />
                                    </ItemWithSubmenu>
                                </div>
                                <div hidden={!props.usuario.permissoes.includes('PREPAROS')}>
                                    <ItemWithSubmenu name='Preparos' icon={preparosIcon} target='preparos' opened={this.state.opened}>
                                        <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_PREPARO} />
                                        <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_PREPAROS} />
                                    </ItemWithSubmenu>
                                </div>
                                <div hidden={!props.usuario.permissoes.includes('PRATOS')}>
                                    <ItemWithSubmenu name='Pratos' icon={pratosIcon} target='pratos' opened={this.state.opened}>
                                        <Item name='Cadastrar' icon={cadastrarIcon} path={routes.CADASTRAR_PRATO} />
                                        <Item name='Gerenciar' icon={gerenciarIcon} path={routes.GERENCIAR_PRATOS} />
                                    </ItemWithSubmenu>
                                </div>
                            </ItemWithSubSubmenu>
                        </li>

                        <li hidden={!props.usuario.permissoes.includes('CARDAPIOS')}>
                            <ItemWithSubmenu name='Cardápios' icon={preparosIcon} target='cardapios' opened={this.state.opened}>
                                <Item name='Cadastrar cardápio' icon={cadastrarIcon} path={routes.CADASTRAR_CARDAPIO} />
                                <Item name='Cardápio semanal' icon={cardapioIcon} path={routes.CARDAPIO_SEMANAL} />
                                <Item name='Gerenciar cardápios' icon={gerenciarMaiorIcon} path={routes.GERENCIAR_CARDAPIOS} />
                            </ItemWithSubmenu>
                        </li>

                        <li>
                            <Item name='Calendário de cardápios' icon={cardapioIcon} path={routes.CARDAPIO_CALENDARIO} />
                        </li>

                        <li>
                            <ItemWithSubmenu name='Execução de Cardápios' icon={pratosIcon} target='execucao' opened={this.state.opened}>
                                <Item name='Realizar' icon={cadastrarIcon} path={routes.EXECUCAO_CARDAPIOS} />
                                <Item name='Histórico' icon={gerenciarIcon} path={routes.HISTORICO_EXECUCAO_CARDAPIOS} />
                            </ItemWithSubmenu>
                        </li>

                        {/* <li>
                            <Item name='Escolas' icon={escolaIcon} path={routes.ESCOLA} />
                        </li> */}
                    </ul>
                </nav>
                <div onClick={this.props.handleClose} className='sidebar-content'>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    usuario: state.auth.user
})

export default connect(mapStateToProps, null)(Sidebar);