import React from 'react';

import styles from './SimpleButton.module.css';

import Spinner from 'react-loader-spinner';

const chooseAClass = color => {
    switch(color) {
        case 'blue' : return styles.blueButton
        case 'red' : return styles.redButton
        case 'green' : return styles.greenButton
        case 'yellow' : return styles.yellowButton
        default : return ''
    }
}

const SimpleButton = props => (
    <button
        {...props}
       className={`${props.className} ${styles.button} ${chooseAClass(props.color)}`}
        type={props.type}
    >

        {!!props.icon ? <i className={props.icon}></i> : <></>}   
        {
            props.isloading ?
            <Spinner type='TailSpin' color='#FFFFFF' height={20} width={47} timeout={3000} />
            :
            props.name
        }
    </button>
)

export default SimpleButton;