import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listarPratoPorId, listarAlimentos } from '../../actions';
import { Row, Col } from 'react-bootstrap';

import styles from './styles.module.css';

class Pratos extends Component {

    state = {
        prato: {
            modalidade: {},
            categoria: {
                faixaEtaria:{}
            },
            preparos: []
        }
    }

    componentWillMount() {
        this.props.listarPratoPorId(this.props.data, this.props.token);
        this.props.listarAlimentos(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.prato !== this.props.prato) this.setState({ ...this.state, prato: nextProps.prato }, () => console.log(this.state.prato));
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name={`Relatório de Prato - ${this.state.prato.nome}`} />

                    <Detail>

                        <Row>
                            <Col><b>Modalidade: </b> {this.state.prato.modalidade.descricao} -  {this.state.prato.modalidade.nome}</Col>
                        </Row>
                        <Row>
                            <Col><b>Categoria: </b>  {this.state.prato.categoria.modalidade}, {this.state.prato.categoria.faixaEtaria.nome}
                             </Col>
                        </Row>
                        <br/>
                        {
                            this.state.prato.preparos.map((preparo, index) => (
                                <>

                                    <Title name={`Preparo: ${preparo.nome}`}/>
                                    <Row>
                                        <Col>
                                            <b>Criador: </b> {preparo.criador.nome}
                                        </Col>
                                        <Col>
                                            <b>Porções: </b> {preparo.porcoes}
                                        </Col>
                                    </Row>

                                    <table border='1' className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th className={styles.titulo}>Ingrediente</th>
                                                <th className={styles.titulo}>Quantidade</th>
                                                <th className={styles.titulo}>Unidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                preparo.ingredientes.map((item, indexItem) => (
                                                    <tr key={indexItem} >
                                                        <td className={styles.titulo}>{this.props.alimentos.map(alimento => (alimento.id === item.alimento ? alimento.nome : ''))}</td>
                                                        <td className={styles.titulo}>{item.quantidade}</td>
                                                        <td className={styles.titulo}>{item.unidadeMedida}</td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>

                                    <Row>
                                        <Col className={styles.preparo}>
                                            <b> Modo de Preparo: </b> {preparo.modoPreparo}
                                        </Col>
                                    </Row>
                                </>
                            ))
                        }
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    prato: state.prato.prato,
    alimentos: state.alimento.filtrados,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarPratoPorId,
    listarAlimentos
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Pratos);