import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton/index';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input/index';
import SearchSelect from '../../components/SearchSelect/index';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';
import ActionButton from '../../components/ActionButton/index';

import SimpleModal from '../../components/SimpleModal/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarPesquisaEstoques,
    listarEstoqueItens,
    listarEstoquesUsuario,
    estoqueCriarKitMerenda,
    listarAlunosHistorico
} from '../../actions';

//import styles from './KitEscolar.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import BackButton from '../../components/BackButton';

import * as unidadesEdu from '../../services/unidades';
import * as turmas from '../../services/turmas';
import * as alunos from '../../services/alunos';

class KitEscolar extends Component {    
    state = {
        unidadeId: -1,
        turmaSelect: -1,
        anoSelect: -1,

        pesquisaLote: '',

        unidadesApi:[],
        unidadesEstoques:[],
        listAlunos:[],

        produto: {},
        unidadeProduto: {
            produtoLote:{
                produto:[]
            }
        },

        quantidade: 0,

        alunoSelecionado: -1,

        turmas: [],

        kitAluno: [],

        showTurma: false,
        showAlunos: false,
        showMontarKit: false,

        showModalHistorico: false,
        alunosHistorico: []
    }

    componentDidMount() {
        unidadesEdu.find().then(res => {
            this.setState(
                {...this.state,
                 apiUnidades:res.data,
                 unidadesApi: res.data,
                 unidadesEstoques : this.props.estoquesUsuario.filter(
                    e => res.data.find( i => i.id === e.unidadeEducacional.id))
                });
          })

    }

    handleSearchSubmit = async () => {
        this.props.listarEstoqueItens(this.state.unidadeId, this.props.token);
        this.setState(
            { ...this.state,
              turmas: await (await turmas.find(this.state.unidadeId, this.state.anoSelect)).data,
              showTurma: true
            })
    }

    handleSearchAluno = async () => {
        this.setState(
            { ...this.state, 
              listAlunos: await (await alunos.find(this.state.unidadeId, this.state.turmaSelect, this.state.anoSelect)).data,
              showAlunos: true })
    }

    handleMontarKit = () => {
        //this.props.listarEstoqueItens(this.state.unidadeId, this.props.token)
        this.setState({ ...this.state, showMontarKit: true })
    }

    concluirKit = () => {
        const {
          alunoSelecionado, 
          turmaSelect,
          kitAluno,
          unidadeId
        } = this.state;

        const kit = {
            nome: alunoSelecionado.label,
            alunoId: alunoSelecionado.value,
            turmaId: turmaSelect,
            escolaId: unidadeId,
            produtosKits: kitAluno 
        }
        this.props.estoqueCriarKitMerenda(this.state.unidadeId, kit, this.props.token)
    }

    showProduto() {
        this.setState({ ...this.state, showProdutos: true })
    }

    addProduto = e => {
        e.preventDefault();
        let produtos = this.state.kitAluno;
        produtos.push({ estoque: this.state.unidadeProduto.estoque, 
            unidadeMedida: this.state.unidadeProduto.produtoLote.produto.unidadeMedida,
            nome: this.state.unidadeProduto.produtoLote.produto.nome,
            produtoLoteFk:this.state.unidadeProduto.produtoLote.id, 
            produtoLote: this.state.unidadeProduto.produtoLote.numero, 
            quantidade: this.state.quantidade});
        this.setState({...this.state, kitAluno: produtos })
    }

    handleRemoverProduto = (id) =>{
        let indx = this.state.kitAluno.findIndex(p => p.produtoLote.id === id.produtoLote.id
             && p.quantidade === id.quantidade)
        
        let newKit = this.state.kitAluno;
        newKit.splice(indx, 1)
        this.setState({...this.state, kitAluno: newKit })
    }

    mostrarPaginaRelatorio = alunoKitId => {
        sessionStorage.setItem('alunoKit', alunoKitId);
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_KIT_ESCOLAR}/${[this.state.unidadeId]}`);
    }

    handleShowModal = () => {
        this.setState({...this.state, showModalHistorico: true});
        this.props.listarAlunosHistorico(this.state.unidadeId, this.state.turmaSelect, this.props.token);
    }


    render( ) {
        return <>
            <BackButton />
            <Row>
              <PageTitle name="Kit Merenda" hasDivider={true} />
            </Row>

            <div hidden={!this.state.showAlunos}>
              <SimpleButton style={{ marginLeft: "60%" }} type="button" color="green" name="Histórico de Kits" icon="fas fa-list" onClick={() => this.handleShowModal()} />
            </div>

            <Form>
              <Form.Group>
                <Form.Label>Selecione a Unidade:</Form.Label>
                <Row>
                  <Col lg={12}>
                    <Form.Control as="select" value={this.state.unidadeId} onChange={(e) => this.setState(
                          {
                            ...this.state,
                            unidadeId: parseInt(e.target.value),
                          },
                          //() => this.handleSearchSubmit()
                        )} required>
                      <option value={-1} />
                      {this.state.unidadesEstoques.map((estoque) => (
                        <option key={estoque.id} value={estoque.id}>
                          {estoque.unidadeEducacional.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
            </Form>

            <div hidden={false}>
              <Form>
                <Form.Group>
                  <Form.Label>Selecione o ano letivo:</Form.Label>
                  <Row>
                    <Col lg={2}>
                      <Form.Control as="select" 
                      value={this.state.anoSelect} 
                      onChange={(e) => this.setState(
                            {
                              ...this.state,
                              anoSelect: parseInt(e.target.value),
                            },
                            () => this.handleSearchSubmit()
                          )} 
                          required>
                        <option value={0} />
                        <option key={new Date().getFullYear() - 1}  value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                        <option key={new Date().getFullYear()} value={new Date().getFullYear() }>{ new Date().getFullYear() }</option>
                        ))
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>

            <div hidden={!this.state.showTurma}>
              <Form>
                <Form.Group>
                  <Form.Label>Selecione a Turma:</Form.Label>
                  <Row>
                    <Col lg={12}>
                      <Form.Control as="select" value={this.state.turmaSelect} onChange={(e) => this.setState(
                            {
                              ...this.state,
                              turmaSelect: parseInt(e.target.value),
                            },
                            () => this.handleSearchAluno()
                          )} required>
                        <option value={0} />
                        {this.state.turmas.map((turma) => (
                          <option key={turma.id} value={turma.id}>
                            {turma.nomeDaTurma}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>

            <div hidden={!this.state.showAlunos}>
              <Form>
                <Form.Group>
                  <Form.Label>Selecione Aluno:</Form.Label>
                  <Row>
                    <Col lg={12}>
                      <SearchSelect value={this.state.alunoSelecionado} onChange={(selectedOption) => this.setState(
                            {
                              ...this.state,
                              alunoSelecionado: selectedOption,
                            },
                            () => this.handleMontarKit()
                          )} options={this.state.listAlunos.map(
                          (aluno) => ({
                            value: aluno.id,
                            label: aluno.nome,
                          })
                        )} required />
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>

            <div hidden={!this.state.showMontarKit}>
              <Fieldset legend="Montar Kit" align="left">
                <Form.Group>
                  <Form onSubmit={this.addProduto}>
                    <Row>
                      <Col>
                        <Row>
                          <Col md={5}>
                            <SearchSelect label="* Produto:" value={this.state.produto} required onChange={(selectedOption) => this.setState(
                                  {
                                    ...this.state,
                                    produtoSelecionado: selectedOption,
                                    produto: selectedOption,
                                    unidadeProduto: this.props.filtrado.produtos.find(
                                      (produto) =>
                                        produto.id ===
                                        selectedOption.value
                                    ),
                                  }
                                )} options={this.props.filtrado.produtos.map(
                                (produto) => ({
                                  value: produto.id,
                                  label: `${
                                    produto.produtoLote.produto.nome
                                  } - ${
                                    produto.produtoLote.produto.item
                                  }`,
                                })
                              )} />
                          </Col>
                          <Col md={4}>
                            <Input label={`* Quantidade: (${this.state.unidadeProduto.produtoLote.produto.unidadeMedida})`} type="number" min="0" max={this.state.unidadeProduto.quantidade} value={this.state.quantidade} onChange={(value) => this.setState(
                                  { quantidade: value }
                                )} required />
                          </Col>
                          <Col>
                            <SimpleButton type="submit" color="green" name="Adicionar" icon="fas fa-plus" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Form.Group>
                <DataTable style={{ textAlign: "center" }} value={this.state.kitAluno}>
                  <Column field="nome" header="Produto" />
                  <Column field="quantidade" header="Quantidade" />
                  <Column header="Ações" body={(rowData) => {
                      return <div>
                          <ActionButton title="Remover Produto" icon="fas fa-minus" onClick={() => this.handleRemoverProduto(rowData)} />
                        </div>;
                    }} />
                </DataTable>
              </Fieldset>
            </div>
            <div hidden={!(this.state.kitAluno.length > 0)}>
              <SimpleButton style={{ margin: "2%" }} type="button" color="green" name="Kit pronto" icon="fas fa-check" onClick={() => this.concluirKit()} />
            </div>


            <SimpleModal show={this.state.showModalHistorico} onHide={() => this.setState(
                  { ...this.state, showModalHistorico: false }
                )} title="Histórico de Kits" icon="fas fa-list">
              <Row>
                <Col>
                  <DataTable style={{ textAlign: "center" }} value={this.props.alunosKit}>
                    <Column field="nome" header="Aluno" />
                    <Column body={(a) => {return new Date(a.data).toLocaleDateString('pt-br')}}  header="Data" />
                    <Column header="Ações" body={(rowData) => {
                        return <div>
                            <SimpleButton
                                style={{ marginLeft: '5%' }}
                                type='button'
                                color='blue'
                                name='Recibo de entrega'
                                icon='fas fa-print'
                                onClick={() => this.mostrarPaginaRelatorio(rowData.id)}
                            />
                            
                          </div>;
                      }} />
                  </DataTable>
                </Col>
              </Row>
            </SimpleModal>      
          </>;
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalNumbers: state.estoque.closeModalNumbers,
    alunosKit: state.estoque.alunosKits,
    estoquesUsuario: state.auth.user.estoques,
    filtrado: state.estoque.filtrado,
    produtos: state.produto.list
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarEstoquesUsuario,
    listarPesquisaEstoques,
    listarEstoqueItens,
    estoqueCriarKitMerenda,
    listarAlunosHistorico
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(KitEscolar)), 'ESTOQUES');