import React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction" 
import ptBrLocale from "@fullcalendar/core/locales/pt-br";

import styles from './Calendar.module.css'

const Calendar = props => (
    <FullCalendar
        locale={ptBrLocale}
        plugins={[dayGridPlugin, interactionPlugin]}
        {...props}
        className={styles.calendar} />
)

export default Calendar;