import React from 'react';

import styles from './ConfirmMessageTitle.module.css';

const ConfirmMessageTitle = props => (
    <span
        {...props}
        className={styles.alert} 
    >

        {!!props.icon ? <i className={props.icon}></i> : <></>}   
        {props.name}
    </span>
)

export default ConfirmMessageTitle;