import { LISTAR_PREPAROS, CADASTRAR_PREPARO, EDITAR_PREPARO, FILTRAR_PREPAROS, LISTAR_PREPAROS_ID } from '../actions/types'

const INITIAL_STATE = {
    list: [],
    filtrados: [],
    preparo: {},

    resetCount: 0,
    closeModalCount: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_PREPAROS:
            return { ...state, list: action.payload }

        case CADASTRAR_PREPARO:
            return { ...state, resetCount: ++state.resetCount }

        case EDITAR_PREPARO:
            return { ...state, closeModalCount: ++state.closeModalCount }

        case FILTRAR_PREPAROS:
            return { ...state, filtrados: action.payload }

        case LISTAR_PREPAROS_ID:
            return { ...state, preparo: action.payload }

        default:
            return state
    }
}