import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { LISTAR_ALIMENTOS, LISTAR_COMPONENTES_NUTRICIONAIS, FILTRAR_ALIMENTOS, CADASTRAR_ALIMENTO, EDITAR_ALIMENTO } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function listarAlimentos(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/alimentos`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent([
                    { type: LISTAR_ALIMENTOS, payload: res.data },
                    { type: FILTRAR_ALIMENTOS, payload: res.data }
                ])
            })
    }
}

export function filtrarAlimentos(nome, item, categoriaItem) {
    return (dispatchEvent, getState) => {
        let alimentos = getState().alimento.list;
        let alimentosFiltrados = alimentos
            .filter(a =>
                a.nome.toLowerCase().includes(!!nome ? nome.toLowerCase() : '') &&
                a.itemAlimento.nome.toLowerCase().includes(!!item ? item.toLowerCase() : '') &&
                a.itemAlimento.categoria.toLowerCase().includes(!!categoriaItem ? categoriaItem.toLowerCase() : '')
            )

        dispatchEvent({ type: FILTRAR_ALIMENTOS, payload: alimentosFiltrados })
    }
}

export function cadastrarAlimento(alimento, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/alimentos`, alimento, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent({ type: CADASTRAR_ALIMENTO, payload: {} })
                        toastr.success('Sucesso', 'Preparo cadastrado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar o Alimento?' />
            )
        })
    }
}

export function listarComponentesNutricionais(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/componentes-nutricionais`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent({ type: LISTAR_COMPONENTES_NUTRICIONAIS, payload: res.data })
            })
    }
}

export function editarAlimento(alimento, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/alimentos/${alimento.id}`, alimento, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: EDITAR_ALIMENTO, payload: {} },
                            listarAlimentos(token)
                        ])
                        toastr.success('Sucesso', 'Alimento editado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarAlimentos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Alimento?' />
            )
        })
    }
}

export function excluirAlimento(id, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/alimentos/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent(listarAlimentos(token))
                        toastr.success('Sucesso', 'Alimento excluido com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarAlimentos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Alimento?' />
            )
        })
    }
}