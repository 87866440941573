import React, { Component } from 'react'
import { Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import PageTitle from '../../components/PageTitle/index';
import SimpleTable from '../../components/SimpleTable';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal/index';
import Fieldset from '../../components/Fieldset';
import SimpleButton from '../../components/SimpleButton/index';
import Input from '../../components/Input/index';
import TimeInput from '../../components/TimeInput/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SearchSelect from '../../components/SearchSelect/index';
import TinyAction from '../../components/TinyAction';
import CreatableSearchSelect from '../../components/CreatableSearchSelect/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarCardapios,
    listarCardapiosSemanais,
    editarCardapio,
    editarCardapioSemanal,
    excluirCardapio,
    excluirCardapioSemanal,
    filtrarPratosPorCategoria,
    filtrarPratosPorCategoriaEModalidade,
    listarCardapiosPorCategoria,
    listarCardapiosPorCategoriaEModalidade,
    listarTiposRefeicao,
    listarReferenciasNutricionais,
    filtrarCardapios,
    filtrarCardapiosSemanais,
    listarCategorias,
    listarModalidades,
    listarPatologias,
} from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import { roundToTwo } from '../../utils/mathCalc';

import styles from './GerenciarCardapios.module.css';
import BackButton from '../../components/BackButton';

class GerenciarCardapiosPage extends Component {
    state = {
        tipoCardapio: '',
        isCardapio: false,
        isCardapioSemanal: false,
        showModalCardapio: false,
        showModalCardapioSemanal: false,
        showModalCardapioDetalhes: false,
        showModalCardapioSemanalDetalhes: false,

        cardapio: {
            categoria: {
                faixaEtaria: {}
            },
            modalidade: {},
            cardapioTipo: 'REGULAR',

            alternativos: [{ id: null, cardapioTipo: 'ALTERNATIVO', alternativoSelecionado: {} }],
            patologias: [{ id: null, tipo: '', patologiaSelecionado: {} }],

            pratos: [{ hora: '', tipo: {}, prato: -1 }]
        },

        cardapioSemanal: {
            categoria: {
                faixaEtaria: {}
            },
            modalidade: {},
            cardapios: [{ cardapio: { id: -1 }, diaSemana: {} }]
        },

        pesquisaCategoria: {},
        pesquisaModalidade: {},
        pesquisaCategoriaSemanal: {},
        pesquisaModalidadeSemanal: {},

        fichaTecnica: [
            { nome: '', unidadeDeMedida: '', valor: '' }
        ],

        fichaTecnicaEditar: [
            { nome: '', unidadeDeMedida: '', valor: '', class: '', valorReferenciaNutricional: '' }
        ],

        nutrientesInvalidos: false,
    }

    componentDidMount() {
        this.props.listarCategorias(this.props.token)
        this.props.listarModalidades(this.props.token)
        this.props.listarPatologias(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.closeModalCount < nextProps.closeModalCount) this.setState({ showModalCardapio: false, showModalCardapioSemanal: false })
    }

    visualizarFichaTecnica = nutrientesCardapio => {
        this.setState({ ...this.state, fichaTecnica: nutrientesCardapio })
    }

    calcularFichaTecnicaEdicao = () => {
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let pratos = cardapio.pratos;
        let pratosFetch = this.props.pratos;

        let componentesFichaTecnica = [];

        if (pratosFetch.length > 0) {
            let componentesPadroes = pratosFetch[0].nutrientes.map(nutriente => nutriente.nome);

            for (let i = 0; i < pratos.length; i++) {
                let prato = pratos[i];

                let pratoCompleto = pratosFetch.find(p => p.id === prato.prato);
                if (!!pratoCompleto) {
                    componentesFichaTecnica.push(...pratoCompleto.nutrientes)
                }
            }

            let componentesFinais = [];

            for (let k = 0; k < componentesPadroes.length; k++) {
                let componentePadrao = componentesPadroes[k];
                if (componentesFichaTecnica.length > 0) {
                    let valorSoma = componentesFichaTecnica
                        .filter(componente => componente.nome === componentePadrao)
                        .map(componente => componente.valor)
                        .reduce((accumulator, currentValue) => accumulator + currentValue)

                    componentesFinais.push({ ...componentesFichaTecnica[k], valor: roundToTwo(valorSoma) })
                }
            }

            let refNutri = this.formatarReferenciasNutricionais(JSON.parse(JSON.stringify(this.props.referenciasNutricionais)));

            let componentesFinaisComReferencias = componentesFinais.map(componente => {
                if (componente.valor < refNutri[componente.nome]) {
                    return ({ ...componente, class: 'red', valorReferenciaNutricional: refNutri[componente.nome] })
                }

                return ({ ...componente, class: 'green', valorReferenciaNutricional: refNutri[componente.nome] })
            })

            this.setState({
                ...this.state,
                fichaTecnicaEditar: componentesFinaisComReferencias,
                nutrientesInvalidos: componentesFinaisComReferencias.filter(comp => comp.class === 'red').length > 0
            });
        }
    }

    formatarReferenciasNutricionais = referenciasNutricionais => {
        let referenciasObj = referenciasNutricionais[0];

        let keysFormatadas = Object.keys(referenciasObj).map(key => key.toUpperCase());
        let valuesFormataas = Object.values(referenciasObj).map(value => value);

        let newReferenciasObj = {}
        for (let i = 0; i < keysFormatadas.length; i++) {
            let key = keysFormatadas[i];

            newReferenciasObj[key] = valuesFormataas[i];
        }

        return newReferenciasObj;
    }

    handleShowModalCardapio = cardapio => {
        let cardapioCopy = JSON.parse(JSON.stringify(cardapio));

        if (cardapioCopy.cardapioTipo === 'REGULAR') {
            if (cardapioCopy.alternativos.length === 0) {
                cardapioCopy.alternativos.push({ id: null, tipo: 'ALTERNATIVO', alternativoSelecionado: {} })
            } else {
                cardapioCopy.alternativos = cardapioCopy.alternativos
                    .map(alternativo => ({
                        ...alternativo,
                        alternativoSelecionado: {
                            value: alternativo.id,
                            label: alternativo.nome
                        }
                    }))
            }
        } else {
            cardapioCopy.patologias = cardapioCopy.patologias
                .map(patologia => ({
                    ...patologia,
                    patologiaSelecionado: {
                        value: patologia.id,
                        label: patologia.tipo
                    }
                }))
        }

        this.setState({ showModalCardapio: true, cardapio: cardapioCopy }, () => {
            this.props.listarTiposRefeicao(this.props.token);
            this.props.filtrarPratosPorCategoriaEModalidade(this.state.cardapio.categoria.id, this.state.cardapio.modalidade.nome, this.props.token);
            this.props.listarReferenciasNutricionais(this.state.cardapio.categoria.id, this.state.cardapio.modalidade.nome, this.props.token);
        })
    }

    handleShowModalCardapioSemanal = cardapioSemanal => {
        // console.log(cardapioSemanal)
        this.setState({ showModalCardapioSemanal: true, cardapioSemanal }, () => {
            this.props.listarCardapiosPorCategoriaEModalidade(this.state.cardapioSemanal.categoria.id, this.state.cardapioSemanal.modalidade.nome, this.props.token);
        })
    }

    handleShowModalCardapioDetalhes = cardapio => {
        this.setState({ showModalCardapioDetalhes: true, cardapio }, () => {
            this.props.listarTiposRefeicao(this.props.token);
            this.props.filtrarPratosPorCategoriaEModalidade(this.state.cardapio.categoria.id, this.state.cardapio.modalidade.nome, this.props.token);
            this.props.listarReferenciasNutricionais(this.state.cardapio.categoria.id, this.state.cardapio.modalidade.nome, this.props.token);
        })
    }

    handleShowModalCardapioSemanalDetalhes = cardapioSemanal => {
        // console.log(cardapioSemanal)
        this.setState({ showModalCardapioSemanalDetalhes: true, cardapioSemanal }, () => {
            this.props.listarCardapiosPorCategoriaEModalidade(this.state.cardapioSemanal.categoria.id, this.state.cardapioSemanal.modalidade.nome, this.props.token);
        })
    }

    // tipo => cardapio, cardapioSemanal
    handleChange = (tipo, value, property, upper = () => {}) => {
        let generic = this.state[tipo];
        generic[property] = value;

        this.setState({ ...this.state, [tipo]: generic }, () => upper())
    }

    // PATOLOGIAS

    handleChangePatologiaInput = (value, index) => {
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let patologias = cardapio.patologias;
        patologias[index].patologiaSelecionado = value;

        // console.log(value)

        patologias[index].id = value.hasOwnProperty('__isNew__') ? null : value.value;
        patologias[index].tipo = value.label.toUpperCase();

        cardapio.patologias = patologias;

        this.setState({ ...this.state, cardapio });
    }

    handleAddPatologia = index => {
        let patologia = { id: null, tipo: '', patologiaSelecionado: {} }
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let patologias = cardapio.patologias;

        patologias.splice(index + 1, 0, patologia);

        cardapio.patologias = patologias;

        this.setState({ ...this.state, cardapio });
    }

    handleRemovePatologia = index => {
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let patologias = cardapio.patologias;
        if (patologias.length > 1) {
            patologias.splice(index, 1);

            cardapio.patologias = patologias;

            this.setState({ ...this.state, cardapio });
        }
    }

    // CARDÁPIOS ALTERNATIVOS

    handleChangeAlternativoInput = (value, index) => {
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let alternativos = cardapio.alternativos;
        alternativos[index].alternativoSelecionado = value;
        alternativos[index].id = value.value;

        cardapio.alternativos = alternativos;

        this.setState({ ...this.state, cardapio });
    }

    handleAddAlternativo = index => {
        let alternativo = { id: null, cardapioTipo: 'ALTERNATIVO', alternativoSelecionado: {} }
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let alternativos = cardapio.alternativos;

        alternativos.splice(index + 1, 0, alternativo);

        cardapio.alternativos = alternativos;

        this.setState({ ...this.state, cardapio });
    }

    handleRemoveAlternativo = index => {
        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));
        let alternativos = cardapio.alternativos;
        // if (alternativos.length > 1) {
        alternativos.splice(index, 1);

        cardapio.alternativos = alternativos;

        this.setState({ ...this.state, cardapio });
        // }
    }

    // tipoCardapio => cardapio, cardapioSemanal | tipoLista => pratos, cardapios
    handleListInput = (tipoCardapio, tipoLista, value, index, property) => {
        let generic = this.state[tipoCardapio];

        if (property === 'cardapio') generic[tipoLista][index][property].id = value;
        else if (property === 'tipo' || property === 'diaSemana') generic[tipoLista][index][property].nome = value;
        else generic[tipoLista][index][property] = value;

        this.setState({ ...this.state, [tipoCardapio]: generic });
    }

    // tipo => cardapio, cardapioSemanal
    handleAdd = (tipo, index) => {
        let prato = { tipo: {}, id: -1 }
        let cardapio = { diaSemana: {}, cardapio: { id: -1 } }

        let generic = this.state[tipo];
        switch (tipo) {
            case 'cardapio':
                generic.pratos.splice(index + 1, 0, prato);
                break;

            case 'cardapioSemanal':
                generic.cardapios.splice(index + 1, 0, cardapio);
                break;

            default: break;
        }

        this.setState({ ...this.state, [tipo]: generic });
    }

    handleRemove = (tipo, index) => {
        let generic = this.state[tipo];
        switch (tipo) {
            case 'cardapio':
                generic.pratos.splice(index, 1);
                break;

            case 'cardapioSemanal':
                generic.cardapios.splice(index, 1);
                break;

            default: break;
        }

        this.setState({ ...this.state, [tipo]: generic });
    }

    submitPesquisa = () => {
        // e.preventDefault();

        switch (this.state.tipoCardapio) {
            case 'CARDAPIOS':
                this.props.listarCardapios(this.props.token)
                this.setState({ ...this.state, isCardapio: true, isCardapioSemanal: false })
                break;

            case 'CARDAPIOS_SEMANAIS':
                this.props.listarCardapiosSemanais(this.props.token)
                this.setState({ ...this.state, isCardapio: false, isCardapioSemanal: true })
                break;

            default:
                break;
        }
    }

    handleEditCardapio = e => {
        e.preventDefault();

        let cardapio = JSON.parse(JSON.stringify(this.state.cardapio));

        cardapio.categoria = cardapio.categoria.id;
        cardapio.modalidade = cardapio.modalidade.nome;
        cardapio.pratos = cardapio.pratos.map(prato => ({ cardapio: cardapio.id, id: prato.id, prato: prato.prato, tipo: prato.tipo.nome, hora: prato.hora }));

        if (cardapio.hasOwnProperty('patologias')) cardapio.patologias = cardapio.patologias.map(patologia => ({ id: patologia.id, tipo: patologia.tipo }))
        else {
            if (cardapio.alternativos.filter(alternativo => alternativo.id === null).length > 0) {
                delete cardapio.alternativos;
            }
        }

        // console.log(cardapio);

        this.props.editarCardapio(cardapio, this.props.token);
    }

    handleEditCardapioSemanal = e => {
        e.preventDefault();

        let cardapioSemanal = JSON.parse(JSON.stringify(this.state.cardapioSemanal));

        cardapioSemanal.categoria = cardapioSemanal.categoria.id;
        cardapioSemanal.modalidade = cardapioSemanal.modalidade.nome;
        cardapioSemanal.cardapios = cardapioSemanal.cardapios.map(cardapio => ({ diaSemana: cardapio.diaSemana.nome, cardapio: cardapio.cardapio.id }));

        // console.log(cardapioSemanal);

        this.props.editarCardapioSemanal(cardapioSemanal, this.props.token);
    }

    render() {
        const popover = (
            <Popover id='fichaTecnicaPopover' title='Ficha Técnica do Cardápio'>
                {this.state.fichaTecnica.map((componente, index) => (
                    <p key={index}>
                        <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeDeMedida})
                    </p>
                ))}
            </Popover>
        )

        const popoverEdicao = (
            <Popover id='fichaTecnicaPopoverEdicao' title='Ficha Técnica do Cardápio'>
                <Row>
                    <Col>
                        <b>Cardapio Atual</b>
                    </Col>
                    <Col>
                        <b>Ref. FNDE</b>
                    </Col>
                </Row>
                {this.state.fichaTecnicaEditar.map((componente, index) => (
                    <Row key={index}>
                        <Col sm={6} style={{ color: componente.class }}>
                            <b>{componente.nome}:</b> {componente.valor}
                        </Col>
                        <Col sm={6}>
                            <b>{componente.nome}:</b> {componente.valorReferenciaNutricional} ({componente.unidadeDeMedida})
                        </Col>
                    </Row>
                ))}
            </Popover>
        )

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Cardápios' hasDivider={true} />
                </Row>

                <Form>
                    <Form.Group>
                        <Form.Label>Selecione o tipo de cardapio:</Form.Label>
                        <Row>
                            <Col lg={12}>
                                <Form.Control as='select' value={this.state.tipoCardapio} onChange={e => this.setState({ tipoCardapio: e.target.value }, () => this.submitPesquisa())} required>
                                    <option value=''></option>
                                    <option value='CARDAPIOS'>Cardápios Convencionais</option>
                                    <option value='CARDAPIOS_SEMANAIS'>Cardápios Semanais</option>
                                </Form.Control>
                            </Col>
                            {/* <Col lg={1}>
                                <Button type='submit'>
                                    <i className='fa fa-search'></i>
                                </Button>
                            </Col> */}
                        </Row>
                    </Form.Group>
                </Form>

                <div hidden={!this.state.isCardapio}>
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Pesquisar por Categoria: </Form.Label>
                                <Select
                                    required
                                    placeholder=''
                                    isClearable
                                    value={this.state.pesquisaCategoria}
                                    onChange={selectedOption => this.setState({ ...this.state, pesquisaCategoria: selectedOption === null ? {} : selectedOption },
                                        () => this.props.filtrarCardapios(this.state.pesquisaCategoria.value, this.state.pesquisaModalidade.value))}

                                    options={this.props.categorias.map(categoria => ({
                                        value: categoria.id,
                                        label: `${categoria.modalidade} / ${categoria.faixaEtaria.nome}`
                                    }))}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Pesquisar por Modalidade: </Form.Label>
                                <Select
                                    required
                                    placeholder=''
                                    isClearable
                                    value={this.state.pesquisaModalidade}
                                    onChange={selectedOption => this.setState({ ...this.state, pesquisaModalidade: selectedOption === null ? {} : selectedOption },
                                        () => this.props.filtrarCardapios(this.state.pesquisaCategoria.value, this.state.pesquisaModalidade.value))}

                                    options={this.props.modalidades.map(modalidade => ({
                                        value: modalidade.nome,
                                        label: modalidade.descricao
                                    }))}
                                />
                            </Form.Group>
                        </Row>
                    </Form>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Categoria</th>
                                <th>Modalidade</th>
                                <th>Tipo</th>
                                <th>Ficha Técnica</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.filtrados.map(cardapio => (
                                <tr key={cardapio.id}>
                                    <td>{cardapio.nome}</td>
                                    <td>{cardapio.categoria.modalidade} / {cardapio.categoria.faixaEtaria.nome}</td>
                                    <td>{cardapio.modalidade.descricao}</td>
                                    <td>{cardapio.cardapioTipo}</td>
                                    <td className={styles.actions}>
                                        <OverlayTrigger trigger='click' placement='left' overlay={popover}>
                                            <ActionButton
                                                title='Visualizar ficha técnica'
                                                onClick={() => this.visualizarFichaTecnica(cardapio.nutrientes)}
                                                icon='fas fa-clipboard-list'
                                            />
                                        </OverlayTrigger>
                                    </td>
                                    <td className={styles.actions}>
                                        <ActionButton
                                            title='Mostrar Cardápio'
                                            onClick={() => this.handleShowModalCardapioDetalhes(cardapio)}
                                            icon='fas fa-eye'
                                        />

                                        <ActionButton
                                            title='Editar Cardápio'
                                            onClick={() => this.handleShowModalCardapio(cardapio)}
                                            icon='fas fa-edit'
                                        />

                                        <ActionButton
                                            title='Excluir Cardápio'
                                            onClick={() => this.props.excluirCardapio(cardapio.id, this.props.token)}
                                            icon='fas fa-trash-alt'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable>
                </div>

                <div hidden={!this.state.isCardapioSemanal}>
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Pesquisar por Categoria: </Form.Label>
                                <Select
                                    required
                                    placeholder=''
                                    isClearable
                                    value={this.state.pesquisaCategoriaSemanal}
                                    onChange={selectedOption => this.setState({ ...this.state, pesquisaCategoriaSemanal: selectedOption === null ? {} : selectedOption },
                                        () => this.props.filtrarCardapiosSemanais(this.state.pesquisaCategoriaSemanal.value, this.state.pesquisaModalidadeSemanal.value))}

                                    options={this.props.categorias.map(categoria => ({
                                        value: categoria.id,
                                        label: `${categoria.modalidade} / ${categoria.faixaEtaria.nome}`
                                    }))}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Pesquisar por Modalidade: </Form.Label>
                                <Select
                                    required
                                    placeholder=''
                                    isClearable
                                    value={this.state.pesquisaModalidadeSemanal}
                                    onChange={selectedOption => this.setState({ ...this.state, pesquisaModalidadeSemanal: selectedOption === null ? {} : selectedOption },
                                        () => this.props.filtrarCardapiosSemanais(this.state.pesquisaCategoriaSemanal.value, this.state.pesquisaModalidadeSemanal.value))}

                                    options={this.props.modalidades.map(modalidade => ({
                                        value: modalidade.nome,
                                        label: modalidade.descricao
                                    }))}
                                />
                            </Form.Group>
                        </Row>
                    </Form>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Categoria</th>
                                <th>Modalidade</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.filtradosSemanais.map(cardapio => (
                                <tr key={cardapio.id}>
                                    <td>{cardapio.nome}</td>
                                    <td>{cardapio.categoria.modalidade} / {cardapio.categoria.faixaEtaria.nome}</td>
                                    <td>{cardapio.modalidade.descricao}</td>
                                    <td className={styles.actions}>
                                        <ActionButton
                                            title='Mostrar Cardápio Semanal'
                                            onClick={() => this.handleShowModalCardapioSemanalDetalhes(cardapio)}
                                            icon='fas fa-eye'
                                        />

                                        <ActionButton
                                            title='Editar Cardápio Semanal'
                                            onClick={() => this.handleShowModalCardapioSemanal(cardapio)}
                                            icon='fas fa-edit'
                                        />

                                        <ActionButton
                                            title='Excluir Cardápio Semanal'
                                            onClick={() => this.props.excluirCardapioSemanal(cardapio.id, this.props.token)}
                                            icon='fas fa-trash-alt'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable>
                </div>

                {/* Modal de editar dados da cardápio */}
                <SimpleModal
                    show={this.state.showModalCardapio}
                    onHide={() => this.setState({ showModalCardapio: false })}
                    title='Editar Cardápio'
                    icon='fas fa-edit'>

                    <p>
                        <OverlayTrigger trigger='click' placement='right' overlay={popoverEdicao}>
                            <SimpleButton
                                type='button'
                                name='Ver ficha técnica atual'
                                color='blue'
                                icon='fas fa-clipboard'
                                onClick={this.calcularFichaTecnicaEdicao}
                            />
                        </OverlayTrigger>
                    </p>

                    <Form onSubmit={this.handleEditCardapio}>
                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Dados do cardápio' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Nome:'
                                                type='text'
                                                case='upper'
                                                value={this.state.cardapio.nome}
                                                onChange={(value, upper) => this.handleChange('cardapio', value, 'nome', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Categoria:'
                                                disabled
                                                type='text'
                                                value={`${this.state.cardapio.categoria.modalidade} / ${this.state.cardapio.categoria.faixaEtaria.nome}`}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Modalidade:'
                                                disabled
                                                type='text'
                                                value={this.state.cardapio.modalidade.nome}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Tipo:'
                                                type='text'
                                                disabled
                                                value={this.state.cardapio.cardapioTipo}
                                            />
                                        </Col>
                                    </Row>

                                    {
                                        this.state.cardapio.cardapioTipo === 'REGULAR' ?
                                            (
                                                <>
                                                    {this.state.cardapio.alternativos.map((alternativo, index) => (
                                                        <Row key={index}>
                                                            <Col md={10}>
                                                                <SearchSelect
                                                                    label={`Cardápio alternativo ${index + 1}:`}
                                                                    value={alternativo.alternativoSelecionado}
                                                                    onChange={selectedOption => this.handleChangeAlternativoInput(selectedOption, index)}

                                                                    options={
                                                                        this.props.cardapios
                                                                            .filter(cardapio => cardapio.cardapioTipo === 'ALTERNATIVO')
                                                                            .map(cardapio => ({
                                                                                value: cardapio.id,
                                                                                label: cardapio.nome
                                                                            }))
                                                                    }
                                                                    required
                                                                />
                                                            </Col>
                                                            <Col md={2}>
                                                                <Row>
                                                                    <Col align='center'>
                                                                        <TinyAction
                                                                            type='button'
                                                                            icon='fas fa-plus'
                                                                            onClick={() => this.handleAddAlternativo(index)}
                                                                            title='Adicionar cardápio alternativo'
                                                                            color='green'
                                                                        />
                                                                    </Col>
                                                                    <Col align='center'>
                                                                        <TinyAction
                                                                            type='button'
                                                                            icon='fas fa-minus'
                                                                            onClick={() => this.handleRemoveAlternativo(index)}
                                                                            title='Remover cardápio alternativo'
                                                                            color='red'
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {this.state.cardapio.patologias.map((patologia, index) => (
                                                        <Row key={index}>
                                                            <Col md={10}>
                                                                <CreatableSearchSelect
                                                                    label={`Patologia ${index + 1}:`}
                                                                    value={patologia.patologiaSelecionado}
                                                                    onChange={(newValue, actionMeta) => this.handleChangePatologiaInput(newValue, index)}
                                                                    options={
                                                                        this.props.patologias.map(patologia => (
                                                                            { value: patologia.id, label: patologia.tipo }
                                                                        ))
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col md={2}>
                                                                <Row>
                                                                    <Col align='center'>
                                                                        <TinyAction
                                                                            type='button'
                                                                            icon='fas fa-plus'
                                                                            onClick={() => this.handleAddPatologia(index)}
                                                                            title='Adicionar patologia'
                                                                            color='green'
                                                                        />
                                                                    </Col>
                                                                    <Col align='center'>
                                                                        <TinyAction
                                                                            type='button'
                                                                            icon='fas fa-minus'
                                                                            onClick={() => this.handleRemovePatologia(index)}
                                                                            title='Remover patologia'
                                                                            color='red'
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </>
                                            )
                                    }
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Pratos' align='left'>
                                    {this.state.cardapio.pratos.map((prato, index) => (
                                        <Row key={index}>
                                            <Col md={3}>
                                                <TimeInput
                                                    label='* Hora:'
                                                    initialTime={prato.hora}
                                                    onChange={(event, value) => this.handleListInput('cardapio', 'pratos', value, index, 'hora')}
                                                    required
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <SimpleSelect
                                                    label='* Tipo:'
                                                    value={prato.tipo.nome}
                                                    onChange={e => this.handleListInput('cardapio', 'pratos', e.target.value, index, 'tipo')}
                                                    required
                                                >

                                                    <option></option>
                                                    {this.props.tiposRefeicao.map((tipo, index) => (
                                                        <option key={index} value={tipo.nome}>{tipo.descricao}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            <Col md={5}>
                                                <SimpleSelect
                                                    label='* Prato:'
                                                    value={prato.prato}
                                                    onChange={e => this.handleListInput('cardapio', 'pratos', parseInt(e.target.value), index, 'prato')}
                                                    required
                                                >

                                                    <option></option>
                                                    {this.props.pratos.map(p => (
                                                        <option key={p.id} value={p.id}>{p.nome}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            {/* <Col md={2} className={styles.tinyActions}>
                                                        <Button onClick={() => this.handleAdd('cardapio', index)} className={styles.plusButton}>
                                                            <i className='fas fa-plus'></i>
                                                        </Button>
                                                        <Button onClick={() => this.handleRemove('cardapio', index)} className={styles.minusButton}>
                                                            <i className='fas fa-minus'></i>
                                                        </Button>
                                                    </Col> */}
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ showModalCardapio: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                {/* Modal de detalhes dados da cardápio */}
                <SimpleModal
                    show={this.state.showModalCardapioDetalhes}
                    onHide={() => this.setState({ showModalCardapioDetalhes: false })}
                    title='Detalhes'
                    icon='fas fa-eye'>

                    {/* <p>
                        <OverlayTrigger trigger='click' placement='right' overlay={popoverEdicao}>
                            <SimpleButton
                                type='button'
                                name='Ver ficha técnica atual'
                                color='blue'
                                icon='fas fa-clipboard'
                                onClick={this.calcularFichaTecnicaEdicao}
                            />
                        </OverlayTrigger>
                    </p> */}

                    <Form>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do cardápio' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Nome:'
                                                type='text'
                                                value={this.state.cardapio.nome}
                                                disabled
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Categoria:'
                                                disabled
                                                type='text'
                                                value={`${this.state.cardapio.categoria.modalidade} / ${this.state.cardapio.categoria.faixaEtaria.nome}`}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Modalidade:'
                                                disabled
                                                type='text'
                                                value={this.state.cardapio.modalidade.nome}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Tipo:'
                                                type='text'
                                                disabled
                                                value={this.state.cardapio.cardapioTipo}
                                            />
                                        </Col>
                                    </Row>

                                    {
                                        this.state.cardapio.cardapioTipo === 'REGULAR' ?
                                            (
                                                <>
                                                    {this.state.cardapio.alternativos.map((alternativo, index) => (
                                                        <Row key={index}>
                                                            <Col md={12}>
                                                                <SimpleSelect
                                                                    label={`Cardápio alternativo ${index + 1}:`}
                                                                    value={alternativo.id}
                                                                    disabled
                                                                // onChange={selectedOption => this.handleChangeAlternativoInput(selectedOption, index)}

                                                                >

                                                                    {this.props.cardapios
                                                                        .filter(cardapio => cardapio.cardapioTipo === 'ALTERNATIVO')
                                                                        .map((cardapio, index) => (
                                                                            <option key={index} value={cardapio.id}>{cardapio.nome}</option>
                                                                        ))}
                                                                </SimpleSelect>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {this.state.cardapio.patologias.map((patologia, index) => (
                                                        <Row key={index}>
                                                            <Col md={12}>
                                                                <SimpleSelect
                                                                    label={`Patologia ${index + 1}:`}
                                                                    value={patologia.id}
                                                                    disabled
                                                                // onChange={(newValue, actionMeta) => this.handleChangePatologiaInput(newValue, index)}
                                                                >
                                                                    {
                                                                        this.props.patologias.map((patologia, index) => (
                                                                            <option key={index} value={patologia.id}>{patologia.tipo}</option>
                                                                        ))
                                                                    }
                                                                </SimpleSelect>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </>
                                            )
                                    }
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Pratos' align='left'>
                                    {this.state.cardapio.pratos.map((prato, index) => (
                                        <Row key={index}>
                                            <Col md={3}>
                                                <TimeInput
                                                    label='* Hora:'
                                                    initialTime={prato.hora}
                                                    disabled
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <SimpleSelect
                                                    label='* Tipo:'
                                                    value={prato.tipo.nome}
                                                    disabled
                                                >

                                                    <option></option>
                                                    {this.props.tiposRefeicao.map((tipo, index) => (
                                                        <option key={index} value={tipo.nome}>{tipo.descricao}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            <Col md={5}>
                                                <SimpleSelect
                                                    label='* Prato:'
                                                    value={prato.prato}
                                                    disabled
                                                >

                                                    <option></option>
                                                    {this.props.pratos.map(p => (
                                                        <option key={p.id} value={p.id}>{p.nome}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            {/* <Col md={2} className={styles.tinyActions}>
                                                        <Button onClick={() => this.handleAdd('cardapio', index)} className={styles.plusButton}>
                                                            <i className='fas fa-plus'></i>
                                                        </Button>
                                                        <Button onClick={() => this.handleRemove('cardapio', index)} className={styles.minusButton}>
                                                            <i className='fas fa-minus'></i>
                                                        </Button>
                                                    </Col> */}
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                {/* Modal de editar dados do cardapio semanal */}
                <SimpleModal
                    show={this.state.showModalCardapioSemanal}
                    onHide={() => this.setState({ showModalCardapioSemanal: false })}
                    title='Editar Cardápio semanal'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditCardapioSemanal}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do cardápio semanal' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Nome:'
                                                type='text'
                                                case='upper'
                                                value={this.state.cardapioSemanal.nome}
                                                onChange={(value, upper) => this.handleChange('cardapioSemanal', value, 'nome', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Categoria:'
                                                disabled
                                                type='text'
                                                value={`${this.state.cardapioSemanal.categoria.modalidade} / ${this.state.cardapioSemanal.categoria.faixaEtaria.nome}`}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Modalidade:'
                                                disabled
                                                type='text'
                                                value={this.state.cardapioSemanal.modalidade.descricao}
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Cardápios' align='left'>
                                    {this.state.cardapioSemanal.cardapios.map((cardapio, index) => (
                                        <Row key={index}>
                                            <Col md={5}>
                                                <SimpleSelect
                                                    label='* Dia da Semana:'
                                                    value={cardapio.diaSemana.nome}
                                                    onChange={e => this.handleListInput('cardapioSemanal', 'cardapios', e.target.value, index, 'diaSemana')}
                                                    required
                                                >

                                                    <option></option>
                                                    <option value='SEGUNDA'>Segunda-Feira</option>
                                                    <option value='TERCA'>Terça-Feira</option>
                                                    <option value='QUARTA'>Quarta-Feira</option>
                                                    <option value='QUINTA'>Quinta-Feira</option>
                                                    <option value='SEXTA'>Sexta-Feira</option>
                                                </SimpleSelect>
                                            </Col>
                                            <Col md={7}>
                                                <SimpleSelect
                                                    label='* Cardapio:'
                                                    value={cardapio.cardapio.id}
                                                    onChange={e => this.handleListInput('cardapioSemanal', 'cardapios', parseInt(e.target.value), index, 'cardapio')}
                                                    required
                                                >

                                                    <option></option>
                                                    {this.props.cardapios.map(c => (
                                                        <option key={c.id} value={c.id}>{c.nome}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            {/* <Col md={2}>
                                                <Row>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Adicionar novo preparo'
                                                            onClick={() => this.handleAdd('cardapioSemanal', index)}
                                                            color='green'
                                                            icon='fas fa-plus'
                                                        />
                                                    </Col>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Remover preparo'
                                                            onClick={() => this.handleRemove('cardapioSemanal', index)}
                                                            color='red'
                                                            icon='fas fa-minus'
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ showModalCardapioSemanal: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                {/* Modal de detalhes dos dados do cardapio semanal */}
                <SimpleModal
                    show={this.state.showModalCardapioSemanalDetalhes}
                    onHide={() => this.setState({ showModalCardapioSemanalDetalhes: false })}
                    title='Detalhes'
                    icon='fas fa-eye'>

                    <Form>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do cardápio semanal' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Nome:'
                                                type='text'
                                                value={this.state.cardapioSemanal.nome}
                                                disabled
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Categoria:'
                                                disabled
                                                type='text'
                                                value={`${this.state.cardapioSemanal.categoria.modalidade} / ${this.state.cardapioSemanal.categoria.faixaEtaria.nome}`}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Modalidade:'
                                                disabled
                                                type='text'
                                                value={this.state.cardapioSemanal.modalidade.descricao}
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Cardápios' align='left'>
                                    {this.state.cardapioSemanal.cardapios.map((cardapio, index) => (
                                        <Row key={index}>
                                            <Col md={5}>
                                                <SimpleSelect
                                                    label='* Dia da Semana:'
                                                    value={cardapio.diaSemana.nome}
                                                    disabled
                                                >

                                                    <option></option>
                                                    <option value='SEGUNDA'>Segunda-Feira</option>
                                                    <option value='TERCA'>Terça-Feira</option>
                                                    <option value='QUARTA'>Quarta-Feira</option>
                                                    <option value='QUINTA'>Quinta-Feira</option>
                                                    <option value='SEXTA'>Sexta-Feira</option>
                                                </SimpleSelect>
                                            </Col>
                                            <Col md={7}>
                                                <SimpleSelect
                                                    label='* Cardapio:'
                                                    value={cardapio.cardapio.id}
                                                    disabled
                                                >

                                                    <option></option>
                                                    {this.props.cardapios.map(c => (
                                                        <option key={c.id} value={c.id}>{c.nome}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            {/* <Col md={2}>
                                                <Row>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Adicionar novo preparo'
                                                            onClick={() => this.handleAdd('cardapioSemanal', index)}
                                                            color='green'
                                                            icon='fas fa-plus'
                                                        />
                                                    </Col>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Remover preparo'
                                                            onClick={() => this.handleRemove('cardapioSemanal', index)}
                                                            color='red'
                                                            icon='fas fa-minus'
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalCount: state.cardapio.closeModalCount,
    cardapios: state.cardapio.list,
    filtrados: state.cardapio.filtrados,
    cardapiosSemanais: state.cardapio.semanais,
    filtradosSemanais: state.cardapio.filtradosSemanais,
    pratos: state.prato.list,
    tiposRefeicao: state.convencao.tiposRefeicao,
    referenciasNutricionais: state.convencao.referenciasNutricionais,
    categorias: state.convencao.categorias,
    modalidades: state.convencao.modalidades,
    patologias: state.convencao.patologias,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarCardapios,
    listarCardapiosSemanais,
    editarCardapio,
    editarCardapioSemanal,
    excluirCardapio,
    excluirCardapioSemanal,
    filtrarPratosPorCategoria,
    filtrarPratosPorCategoriaEModalidade,
    listarCardapiosPorCategoria,
    listarCardapiosPorCategoriaEModalidade,
    listarTiposRefeicao,
    listarReferenciasNutricionais,
    filtrarCardapios,
    filtrarCardapiosSemanais,
    listarCategorias,
    listarModalidades,
    listarPatologias,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(GerenciarCardapiosPage), 'CARDAPIOS')