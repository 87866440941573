import React, { Component } from 'react'
import { Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import TimeInput from '../../components/TimeInput';
import SimpleButton from '../../components/SimpleButton/index';
import PageTitle from '../../components/PageTitle/index';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input';
import SimpleSelect from '../../components/SimpleSelect/index';
import TinyAction from '../../components/TinyAction/index';
import SearchSelect from '../../components/SearchSelect';
import CreatableSearchSelect from '../../components/CreatableSearchSelect/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    cadastrarCardapio,
    listarCategorias,
    listarPratos,
    filtrarPratosPorCategoria,
    filtrarPratosPorCategoriaEModalidade,
    listarCardapiosPorCategoriaEModalidade,
    listarTiposRefeicao,
    listarModalidades,
    listarReferenciasNutricionais,
    listarPatologias
} from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import { roundToTwo } from '../../utils/mathCalc';

// import styles from './CadastrarCardapio.module.css';

const INITIAL_FORM = {
    nome: '',
    categoria: -1,
    modalidade: '',
    cardapioTipo: 'REGULAR',

    alternativos: [{ id: null, tipo: 'ALTERNATIVO', alternativoSelecionado: {} }],
    patologias: [{ id: null, tipo: '', patologiaSelecionado: {} }],

    pratos: [{ hora: '', tipo: '', prato: -1 }],

    quantMinimaPratos: 0,
    quantMaximaPratos: 0,

    fichaTecnica: [
        { nome: '', unidadeDeMedida: '', valor: '', class: '', valorReferenciaNutricional: '' }
    ],
}

const INITIAL_ALTERNATIVO = { id: null, tipo: 'ALTERNATIVO', alternativoSelecionado: {} };

const INITIAL_PATOLOGIA = { id: null, tipo: '', patologiaSelecionado: {} };

class CadastrarCardapioPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarCategorias(this.props.token)
        this.props.listarTiposRefeicao(this.props.token)
        this.props.listarModalidades(this.props.modalidade)
        this.props.listarPatologias(this.props.token)
        //this.props.listarPratos(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.resetCount < nextProps.resetCount) {
            this.setState({
                ...INITIAL_FORM,
                alternativos: [JSON.parse(JSON.stringify(INITIAL_ALTERNATIVO))],
                patologias: [JSON.parse(JSON.stringify(INITIAL_PATOLOGIA))],
                pratos: JSON.parse(JSON.stringify(INITIAL_FORM.pratos)), 
                fichaTecnica: JSON.parse(JSON.stringify(INITIAL_FORM.fichaTecnica))
            }, () => console.log(this.state))
        }
    }

    calcularFichaTecnica = () => {
        let pratos = JSON.parse(JSON.stringify(this.state.pratos));
        let pratosFetch = this.props.pratos;

        let componentesFichaTecnica = [];

        if (pratosFetch.length > 0) {
            let componentesPadroes = pratosFetch[0].nutrientes.map(nutriente => nutriente.nome);

            for (let i = 0; i < pratos.length; i++) {
                let prato = pratos[i];

                let pratoCompleto = pratosFetch.find(p => p.id === prato.prato);
                if (!!pratoCompleto) {
                    componentesFichaTecnica.push(...pratoCompleto.nutrientes)
                }
            }

            let componentesFinais = [];

            for (let k = 0; k < componentesPadroes.length; k++) {
                let componentePadrao = componentesPadroes[k];
                if (componentesFichaTecnica.length > 0) {
                    let valorSoma = componentesFichaTecnica
                        .filter(componente => componente.nome === componentePadrao)
                        .map(componente => componente.valor)
                        .reduce((accumulator, currentValue) => accumulator + currentValue)

                    componentesFinais.push({ ...componentesFichaTecnica[k], valor: roundToTwo(valorSoma) })
                }
            }

            let refNutri = this.formatarReferenciasNutricionais(JSON.parse(JSON.stringify(this.props.referenciasNutricionais)));

            let componentesFinaisComReferencias = componentesFinais.map(componente => {
                if (componente.valor < refNutri[componente.nome]) {
                    return ({ ...componente, class: 'red', valorReferenciaNutricional: refNutri[componente.nome] })
                }

                return ({ ...componente, class: 'green', valorReferenciaNutricional: refNutri[componente.nome] })
            })

            this.setState({
                ...this.state,
                fichaTecnica: componentesFinaisComReferencias,
                // nutrientesInvalidos: componentesFinaisComReferencias.filter(comp => comp.class === 'red').length > 0
            });
        }
    }

    formatarReferenciasNutricionais = referenciasNutricionais => {
        let referenciasObj = referenciasNutricionais[0];

        let keysFormatadas = Object.keys(referenciasObj).map(key => key.toUpperCase());
        let valuesFormataas = Object.values(referenciasObj).map(value => value);

        let newReferenciasObj = {}
        for (let i = 0; i < keysFormatadas.length; i++) {
            let key = keysFormatadas[i];

            newReferenciasObj[key] = valuesFormataas[i];
        }

        return newReferenciasObj;
    }


    ajustarQuantidadePratosPorModalidade = tipoModalidade => {
        let pratos = [];
        switch (tipoModalidade) {
            case 'PARCIAL1':
                pratos = [
                    { hora: '', tipo: '', prato: -1 }
                ]
                this.setState({ quantMinimaPratos: 1, quantMaximaPratos: 2, pratos })
                break;

            case 'PARCIAL2':
                pratos = [
                    { hora: '', tipo: '', prato: -1 },
                    { hora: '', tipo: '', prato: -1 }
                ]
                this.setState({ quantMinimaPratos: 2, quantMaximaPratos: 10, pratos })

                break;

            case 'INTEGRAL':
                pratos = [
                    { hora: '', tipo: '', prato: -1 },
                    { hora: '', tipo: '', prato: -1 },
                    { hora: '', tipo: '', prato: -1 }
                ]
                this.setState({ quantMinimaPratos: 3, quantMaximaPratos: 10, pratos })
                break;

            default:
                break;
        }
    }

    handleChange = (value, property) => {
        this.setState({ ...this.state, [property]: value }, () => {
            this.props.filtrarPratosPorCategoriaEModalidade(this.state.categoria, this.state.modalidade, this.props.token);

            if (property === 'modalidade') this.ajustarQuantidadePratosPorModalidade(value);

            if (this.state.modalidade !== '') this.props.listarReferenciasNutricionais(this.state.categoria, this.state.modalidade, this.props.token);

            if (this.state.cardapioTipo === 'REGULAR') this.props.listarCardapiosPorCategoriaEModalidade(this.state.categoria, this.state.modalidade, this.props.token);
        })
    }

    // PATOLOGIAS

    handleChangePatologiaInput = (value, index) => {
        let patologias = this.state.patologias;
        patologias[index].patologiaSelecionado = value;

        // console.log(value)

        patologias[index].id = value.hasOwnProperty('__isNew__') ? null : value.value;
        patologias[index].tipo = value.label.toUpperCase();

        this.setState({ ...this.state, patologias });
    }

    handleAddPatologia = index => {
        let patologia = { id: null, tipo: '', patologiaSelecionado: {} }
        let patologias = this.state.patologias;

        patologias.splice(index + 1, 0, patologia);

        this.setState({ ...this.state, patologias });
    }

    handleRemovePatologia = index => {
        let patologias = this.state.patologias;
        if (patologias.length > 1) {
            patologias.splice(index, 1);

            this.setState({ ...this.state, patologias });
        }
    }

    // CARDÁPIOS ALTERNATIVOS

    handleChangeAlternativoInput = (value, index) => {
        let alternativos = this.state.alternativos;
        alternativos[index].alternativoSelecionado = value;
        alternativos[index].id = value.value;

        this.setState({ ...this.state, alternativos });
    }

    handleAddAlternativo = index => {
        let alternativo = { id: null, tipo: 'ALTERNATIVO', alternativoSelecionado: {} }
        let alternativos = this.state.alternativos;

        alternativos.splice(index + 1, 0, alternativo);

        this.setState({ ...this.state, alternativos });
    }

    handleRemoveAlternativo = index => {
        let alternativos = this.state.alternativos;
        if (alternativos.length > 1) {
            alternativos.splice(index, 1);

            this.setState({ ...this.state, alternativos });
        }
    }

    // PRATOS 

    handlePratoInput = (value, index, property) => {
        let pratos = this.state.pratos;
        pratos[index][property] = value;

        this.setState({ ...this.state, pratos }, () => {
            if (property === 'prato') this.calcularFichaTecnica();
        });
    }

    handleAddPrato = index => {
        let prato = { hora: '', tipo: '', prato: -1 }

        let pratos = this.state.pratos;

        if (pratos.length < this.state.quantMaximaPratos) {
            pratos.splice(index + 1, 0, prato);

            this.setState({ ...this.state, pratos });
        }
    }

    handleRemovePrato = index => {
        let pratos = this.state.pratos;
        if (pratos.length > this.state.quantMinimaPratos && pratos.length !== 1) {
            pratos.splice(index, 1);

            this.setState({ ...this.state, pratos });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        // console.log(this.state)

        let cardapio = JSON.parse(JSON.stringify(this.state));

        delete cardapio.fichaTecnica;
        delete cardapio.quantMaximaPratos;
        delete cardapio.quantMinimaPratos;

        cardapio.patologias = cardapio.patologias.map(patologia => ({ id: patologia.id, tipo: patologia.tipo }))

        if (cardapio.alternativos.filter(alternativo => alternativo.id === null).length > 0) {
            delete cardapio.alternativos;
        }

        this.props.cadastrarCardapio(cardapio, this.props.token);
    }

    render() {
        const popover = (
            <Popover id='fichaTecnicaPopover' title='Ficha Técnica do Cardápio'>
                <Row>
                    <Col>
                        <b>Cardapio Atual</b>
                    </Col>
                    <Col>
                        <b>Ref. FNDE</b>
                    </Col>
                </Row>
                {this.state.fichaTecnica.map((componente, index) => (
                    <Row key={index}>
                        <Col sm={6} style={{ color: componente.class }}>
                            <b>{componente.nome}:</b> {componente.valor}
                        </Col>
                        <Col sm={6}>
                            <b>{componente.nome}:</b> {componente.valorReferenciaNutricional} ({componente.unidadeDeMedida})
                        </Col>
                    </Row>
                ))}
            </Popover>
        )

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Cardápio' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Row>
                    <OverlayTrigger trigger='click' placement='right' overlay={popover}>
                        <SimpleButton
                            type='button'
                            name='Ver ficha técnica atual'
                            color='blue'
                            icon='fas fa-clipboard'
                        />
                    </OverlayTrigger>
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Dados do cardápio' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            label='* Nome:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Categoria:'
                                            value={this.state.categoria}
                                            onChange={e => this.handleChange(parseInt(e.target.value), 'categoria')}
                                            required
                                        >
                                            <option></option>
                                            {this.props.categorias.map(categoria => (
                                                <option key={categoria.id} value={categoria.id}>{categoria.modalidade} / {categoria.faixaEtaria.nome}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Modalidade:'
                                            value={this.state.modalidade}
                                            onChange={e => this.handleChange(e.target.value, 'modalidade')}
                                            required
                                        >
                                            <option></option>
                                            {this.props.modalidades.map((modalidade, index) => (
                                                <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Tipo:'
                                            value={this.state.cardapioTipo}
                                            onChange={e => this.handleChange(e.target.value, 'cardapioTipo')}
                                            required
                                        >
                                            <option></option>
                                            <option value='REGULAR'>REGULAR</option>
                                            <option value='ALTERNATIVO'>ALTERNATIVO</option>
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                {
                                    this.state.cardapioTipo === 'REGULAR' ?
                                        (
                                            <>
                                                {this.state.alternativos.map((alternativo, index) => (
                                                    <Row key={index}>
                                                        <Col md={10}>
                                                            <SearchSelect
                                                                label={`Cardápio alternativo ${index + 1}:`}
                                                                value={alternativo.alternativoSelecionado}
                                                                onChange={selectedOption => this.handleChangeAlternativoInput(selectedOption, index)}

                                                                options={
                                                                    this.props.cardapios
                                                                        .filter(cardapio => cardapio.cardapioTipo === 'ALTERNATIVO')
                                                                        .map(cardapio => ({
                                                                            value: cardapio.id,
                                                                            label: cardapio.nome
                                                                        }))
                                                                }
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Row>
                                                                <Col align='center'>
                                                                    <TinyAction
                                                                        type='button'
                                                                        icon='fas fa-plus'
                                                                        onClick={() => this.handleAddAlternativo(index)}
                                                                        title='Adicionar cardápio alternativo'
                                                                        color='green'
                                                                    />
                                                                </Col>
                                                                <Col align='center'>
                                                                    <TinyAction
                                                                        type='button'
                                                                        icon='fas fa-minus'
                                                                        onClick={() => this.handleRemoveAlternativo(index)}
                                                                        title='Remover cardápio alternativo'
                                                                        color='red'
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {this.state.patologias.map((patologia, index) => (
                                                    <Row key={index}>
                                                        <Col md={10}>
                                                            <CreatableSearchSelect
                                                                label={`Patologia ${index + 1}:`}
                                                                value={patologia.patologiaSelecionado}
                                                                onChange={(newValue, actionMeta) => this.handleChangePatologiaInput(newValue, index)}
                                                                options={
                                                                    this.props.patologias.map(patologia => (
                                                                        { value: patologia.id, label: patologia.tipo }
                                                                    ))
                                                                }
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Row>
                                                                <Col align='center'>
                                                                    <TinyAction
                                                                        type='button'
                                                                        icon='fas fa-plus'
                                                                        onClick={() => this.handleAddPatologia(index)}
                                                                        title='Adicionar patologia'
                                                                        color='green'
                                                                    />
                                                                </Col>
                                                                <Col align='center'>
                                                                    <TinyAction
                                                                        type='button'
                                                                        icon='fas fa-minus'
                                                                        onClick={() => this.handleRemovePatologia(index)}
                                                                        title='Remover patologia'
                                                                        color='red'
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </>
                                        )
                                }

                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Pratos' align='left'>
                                {this.state.pratos.map((prato, index) => (
                                    <Row key={index}>
                                        <Col md={2}>
                                            <TimeInput
                                                label='* Hora:'
                                                initialTime={prato.hora}
                                                onChange={(event, value) => this.handlePratoInput(value, index, 'hora')}
                                                required
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <SimpleSelect
                                                label='* Tipo:'
                                                value={prato.tipo}
                                                onChange={e => this.handlePratoInput(e.target.value, index, 'tipo')}
                                                required
                                            >

                                                <option></option>
                                                {this.props.tiposRefeicao.map((tipo, index) => (
                                                    <option key={index} value={tipo.nome}>{tipo.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={4}>
                                            <SimpleSelect
                                                label='* Prato:'
                                                value={prato.prato}
                                                onChange={e => this.handlePratoInput(parseInt(e.target.value), index, 'prato')}
                                                required
                                            >

                                                <option></option>
                                                {this.props.pratos.map(p => (
                                                    <option key={p.id} value={p.id}>{p.nome}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={2}>
                                            <Row>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        icon='fas fa-plus'
                                                        onClick={() => this.handleAddPrato(index)}
                                                        title='Adicionar novo prato'
                                                        color='green'
                                                    />
                                                </Col>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        icon='fas fa-minus'
                                                        onClick={() => this.handleRemovePrato(index)}
                                                        title='Remover prato'
                                                        color='red'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetCount: state.cardapio.resetCount,
    categorias: state.convencao.categorias,
    tiposRefeicao: state.convencao.tiposRefeicao,
    cardapios: state.cardapio.list,
    pratos: state.prato.list,
    modalidades: state.convencao.modalidades,
    referenciasNutricionais: state.convencao.referenciasNutricionais,
    patologias: state.convencao.patologias,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarCardapio,
    listarCategorias,
    listarPratos,
    filtrarPratosPorCategoria,
    filtrarPratosPorCategoriaEModalidade,
    listarCardapiosPorCategoriaEModalidade,
    listarTiposRefeicao,
    listarModalidades,
    listarReferenciasNutricionais,
    listarPatologias,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarCardapioPage), 'CARDAPIOS');