import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { LISTAR_CARDAPIOS_CALENDARIO, ACAO_CARDAPIO_CALENDARIO, CALENDARIO_RELATORIO } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests'
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

export function listarCardapiosCalendarioPorUnidade(idUnidade, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios-calendario`, configAuthorizationToken(token))
            .then(res => {
                let data = res.data.filter(cardapio => cardapio.unidade === parseInt(idUnidade))
                let eventos = data.map(cardapio => (
                    {
                        id: cardapio.id,
                        title: cardapio.cardapio.nome,
                        start: moment(cardapio.data, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        backgroundColor: cardapio.tipo === 'DIARIO' ? '#29abe2' : '#c14545',
                        borderColor: '#ffffff'
                    }
                ))
                let calendario = {
                    list: data,
                    eventos
                }

                dispatchEvent({ type: LISTAR_CARDAPIOS_CALENDARIO, payload: calendario })
            })
    }
}

export function listarCardapiosCalendarioPorMesEUnidade(dataDe, dataPara, idUnidade, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/relatorios/cardapios-calendario?de=${dataDe}&para=${dataPara}&unidade=${idUnidade}`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: CALENDARIO_RELATORIO, payload: res.data }
                ])
            })
            .catch(err => console.log(err))
    }
}

export function vincularCardapioCalendario(vinculacao, idUnidade, token) {
    return dispatchEvent => {
        axios.post(`${BASE_URL}/cardapios-calendario`, vinculacao, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent([
                    { type: ACAO_CARDAPIO_CALENDARIO, payload: {} },
                    listarCardapiosCalendarioPorUnidade(idUnidade, token)
                ]);
                toastr.success('Sucesso', 'Cardápio vinculado com sucesso!');
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err));
                dispatchEvent(listarCardapiosCalendarioPorUnidade(idUnidade, token));
            })
    }
}

export function editarCardapioCalendario(vinculacao, idUnidade, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/cardapios-calendario/${vinculacao.id}`, vinculacao, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: ACAO_CARDAPIO_CALENDARIO, payload: {} },
                            listarCardapiosCalendarioPorUnidade(idUnidade, token)
                        ]);
                        toastr.success('Sucesso', 'Cardápio editado com sucesso!');
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err));
                        dispatchEvent(listarCardapiosCalendarioPorUnidade(idUnidade, token));
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Cardápio no Calendário?' />
            )
        })
    }
}

export function desvincularCardapioCalendario(id, idUnidade, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/cardapios-calendario/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: ACAO_CARDAPIO_CALENDARIO, payload: {} },
                            listarCardapiosCalendarioPorUnidade(idUnidade, token)
                        ]);
                        toastr.success('Sucesso', 'Cardápio desvinculado com sucesso!');
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err));
                        dispatchEvent(listarCardapiosCalendarioPorUnidade(idUnidade, token));
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente desvincular o Cardápio do Calendário?' />
            )
        })
    }
}