import React from 'react';

import styles from './PageTitle.module.css';

const PageTitle = props => (
    <>
        <span className={styles.pageTitle}>
            {props.name}
            <div hidden={!props.hasDivider} className={styles.customLine}>
                {props.requiredMessage}
            </div>
        </span>
    </>
)

export default PageTitle;