import React, { Component } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import Accordion from '../../components/Accordion';
import SimpleTable from '../../components/SimpleTable/index';
import PageTitle from '../../components/PageTitle/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input';
import TextInputMask from '../../components/TextInputMask';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleButton from '../../components/SimpleButton/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarUsuarios, filtrarUsuarios, editarUsuario, excluirUsuario, listarEstoques, listarTiposPermissoes, listarCargos, salvarUnidadesEducacionais } from '../../actions';

import styles from './GerenciarUsuarios.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import * as unidadesEdu from '../../services/unidades';

class GerenciarUsuariosPage extends Component {
    state = {
        showModalEstoques: false,
        showModalPermissoes: false,
        showModalDetalhes: false,

        pesquisaNome: ' ',
        isPesquisa: false,

        showModal: false,
        usuario: {
            estoques: [],
            permissoes: [],
            pessoa: {}
        },

        usuarioDetalhes: {
            estoques: [],
            permissoes: [],
            pessoa: {}
        },

        selecionarTodosEstoques: false,
        selecionarTodasPermissoes: false,

        permissoesList: [],
        estoquesList: [],

        unidadeEstoque: -1,
        permissao: ''
    }

    componentDidMount() {

       
        this.props.listarUsuarios(this.props.token);
        this.props.listarEstoques(this.props.token);
        this.props.listarTiposPermissoes(this.props.token);
        this.props.listarCargos(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.tiposPermissoes !== this.props.tiposPermissoes) {
            this.setState({ ...this.state, permissoesList: nextProps.tiposPermissoes.map(permissao => ({ nome: permissao, checked: false })) })
        }

        if (nextProps.estoques !== this.props.estoques) {
            this.setState({ ...this.state, estoquesList: nextProps.estoques.map(estoque => ({ id: estoque.id, nome: estoque.unidadeEducacional.nome, checked: false })) })
        }

        if(this.props.closeModalCount < nextProps.closeModalCount) this.handleCloseModal();
    }

    selecionarTodosEstoques = () => {
     
        let usuario = this.state.usuario;
        let estoques = usuario.estoques;
        let modificandoEstoques = [];
        if (this.state.selecionarTodosEstoques) {
            modificandoEstoques = estoques.map(e => {
                e.checked = false;
                return e;
            })
        } else {
            modificandoEstoques = estoques.map(e => {
                e.checked = true;
                return e;
            })
        }

        usuario.estoques = modificandoEstoques;

        this.setState({ ...this.state, selecionarTodosEstoques: !this.state.selecionarTodosEstoques, usuario })
    }

    selecionarTodasPermissoes = () => {
       
        let usuario = this.state.usuario;
        let permissoes = usuario.permissoes;
        let modificandoPermissoes = [];
        if (this.state.selecionarTodasPermissoes) {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = false;
                return p;
            })
        } else {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = true;
                return p;
            })
        }

        usuario.permissoes = modificandoPermissoes;

        this.setState({ ...this.state, selecionarTodasPermissoes: !this.state.selecionarTodasPermissoes, usuario })
    }

    selecionarEstoque = index => {
      
        let usuario = this.state.usuario;
        let estoques = usuario.estoques;
        estoques[index].checked = !estoques[index].checked;

        usuario.estoques = estoques;

        this.setState({ ...this.state, usuario })
    }

    selecionarPermissao = index => {
      
        let usuario = this.state.usuario;
        let permissoes = usuario.permissoes;
        permissoes[index].checked = !permissoes[index].checked;

        usuario.permissoes = permissoes;

        this.setState({ ...this.state, usuario })
    }

    handleCloseModal = () => {
   
        this.setState({ ...this.state, showModal: false })
    }

    handleShowModal = usuario => {
        let copyUsuario = JSON.parse(JSON.stringify(usuario));
    
        let permissoesUsuario = copyUsuario.permissoes;
        let estoquesUsuario = copyUsuario.estoques.map(estoque => estoque.unidadeEducacional.nome);

        copyUsuario.permissoes = this.state.permissoesList
            .map(permissao => permissoesUsuario.includes(permissao.nome) ? ({ ...permissao, checked: true }) : ({ ...permissao, checked: false }))

        copyUsuario.estoques = this.state.estoquesList
            .map(estoque => estoquesUsuario.includes(estoque.nome) ? ({ ...estoque, checked: true }) : ({ ...estoque, checked: false }))

        this.setState({ ...this.state, usuario: copyUsuario, showModal: true })
    }

    handleCloseModalDetalhes = () => {
    
        this.setState({ ...this.state, showModalDetalhes: false })
    }

    handleShowModalDetalhes = usuario => {
   
        let copyUsuario = JSON.parse(JSON.stringify(usuario));
        this.setState({ ...this.state, showModalDetalhes: true, usuarioDetalhes: copyUsuario })
    }

    handleChange = (value, property) => {
        
        let usuario = this.state.usuario
        usuario[property] = value

        this.setState({ ...this.state, usuario })
    }

    handleChangePessoa = (value, property, upper = () => {}) => {
        
        let usuario = this.state.usuario;
        usuario.pessoa[property] = value;

        this.setState({ ...this.state, usuario }, () => {
            upper();
        })
    }

    handleEditSubmit = e => {
        e.preventDefault();

        let usuario = JSON.parse(JSON.stringify(this.state.usuario));

        usuario.permissoes = usuario.permissoes.filter(permissao => permissao.checked).map(permissao => permissao.nome)
        usuario.estoques = usuario.estoques.filter(estoque => estoque.checked).map(estoque => ({ id: estoque.id }))

        usuario.pessoa.celular = usuario.pessoa.celular.replace(new RegExp(/\D/g), '');
        usuario.pessoa.telefone = usuario.pessoa.telefone.replace(new RegExp(/\D/g), '');
        usuario.pessoa.cpf = usuario.pessoa.cpf.replace(new RegExp(/\D/g), '');

  

        this.props.editarUsuario(usuario, this.props.token)
    }

    
    mostrarPaginaRelatorio = () => {
        if(this.state.isPesquisa){
            sessionStorage.setItem("pesquisaNome", this.state.pesquisaNome);
        }
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_USUARIOS}/filter=${this.state.isPesquisa}`);
    }

    async handleBuscarUnidades() { 
        
        try {
            toastr.info("Procurando por novas unidades")
            const response = await unidadesEdu.find()
            const unidades = response.data;
            if(unidades){
                toastr.info(`${unidades.length} unidades encontradas`)
                toastr.info(`Atualizando dados`)
                //formatando dados para salvalos
                const respFormated = unidades.map( u => ({ 
                  id: u.id,
                  nome: u.nome,
                  tipoUnidade: "ESCOLA",
                  nomeDoResponsavel: "Sistema",
                  estoque: {
                      id: u.id
                  }
                }
                ))
                await this.props.salvarUnidadesEducacionais(respFormated, this.props.token);
                this.props.listarEstoques(this.props.token);

            }else {
                toastr.info("Nenhuma unidade encontrada")
            }

        } catch(err) {
            alert(`Erro inesperado:${err}`)
        }
    }

    render() {
        
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Usuários' hasDivider={true} />
                </Row>
                <div align='right'>
                    <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        onClick={() => this.mostrarPaginaRelatorio()}
                    />

                </div>

                {/* <Form onSubmit={this.handlePesquisaSubmit}> */}
                <Form.Group>
                    <Form.Label>Pesquisar por Nome: </Form.Label>
                    <Row>
                        <Col lg={12}>
                            <Form.Control type='text'
                                value={this.state.pesquisaNome}
                                onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value, isPesquisa: true })}
                                onKeyUp={e => this.props.filtrarUsuarios('nome', this.state.pesquisaNome)} />
                        </Col>
                    </Row>
                </Form.Group>
                {/* </Form> */}

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Nome completo</th>
                            <th>E-mail</th>
                            <th>Usuário</th>
                            <th>Tipo</th>
                            {/* <th>CPF</th> */}
                            {/* <th>Telefone</th> */}
                            {/* <th>Celular</th> */}
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(usuario => (
                            <tr key={usuario.id}>
                                <td>{usuario.pessoa.nome}</td>
                                <td>{usuario.email}</td>
                                <td>{usuario.usuario}</td>
                                <td>{usuario.pessoa.pessoaTipo}</td>
                                {/* <td>{usuario.pessoa.cpf}</td> */}
                                {/* <td>{usuario.pessoa.telefone}</td> */}
                                {/* <td>{usuario.pessoa.celular}</td> */}
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Usuário'
                                        onClick={() => this.handleShowModalDetalhes(usuario)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Editar Usuário'
                                        onClick={() => this.handleShowModal(usuario)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Usuário'
                                        onClick={() => this.props.excluirUsuario(usuario.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Preparo'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados pessoais' align='left'>
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label='* Nome completo:'
                                                type='text'
                                                case='upper'
                                                value={this.state.usuario.pessoa.nome}
                                                onChange={(value, upper) => this.handleChangePessoa(value, 'nome', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='* E-mail:'
                                                type='email'
                                                value={this.state.usuario.email}
                                                onChange={value => this.handleChange(value, 'email')}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='* Usuário:'
                                                type='text'
                                                value={this.state.usuario.usuario}
                                                onChange={e => this.handleChange(e.target.value, 'usuario')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='* CPF:'
                                                type='text'
                                                mask='999.999.999-99'
                                                value={this.state.usuario.pessoa.cpf}
                                                onChange={e => this.handleChangePessoa(e.target.value, 'cpf')}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='Telefone fixo:'
                                                type='text'
                                                mask='(99) 9999-9999'
                                                value={this.state.usuario.pessoa.telefone}
                                                onChange={e => this.handleChangePessoa(e.target.value, 'telefone')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='Celular:'
                                                type='text'
                                                mask='(99) 99999-9999'
                                                value={this.state.usuario.pessoa.celular}
                                                onChange={e => this.handleChangePessoa(e.target.value, 'celular')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='* Tipo Usuário:'
                                                type='text'
                                                disabled
                                                value={this.state.usuario.pessoa.pessoaTipo}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {this.state.usuario.pessoa.pessoaTipo === 'FUNCIONARIO' &&
                                                <SimpleSelect
                                                    label='* Cargo:'
                                                    value={this.state.usuario.pessoa.cargo}
                                                    onChange={e => this.handleChangePessoa(e.target.value, 'cargo')}
                                                    required
                                                >

                                                    <option></option>
                                                    {this.props.cargos.map((cargo, index) => (
                                                        <option key={index} value={cargo.nome}>{cargo.descricao}</option>
                                                    ))}
                                                </SimpleSelect>
                                            }


                                            {this.state.usuario.pessoa.pessoaTipo === 'MOTORISTA' &&
                                                <TextInputMask
                                                    label='* CNH:'
                                                    mask='99999999999'
                                                    value={this.state.usuario.pessoa.cnh}
                                                    onChange={e => this.handleChangePessoa(e.target.value, 'cnh')}
                                                    required
                                                />
                                            }

                                            {this.state.usuario.pessoa.pessoaTipo === 'NUTRICIONISTA' &&
                                                <TextInputMask
                                                    label='* CRN:'
                                                    mask='CRN-99/9999'
                                                    value={this.state.usuario.pessoa.crn}
                                                    onChange={e => this.handleChangePessoa(e.target.value, 'crn')}
                                                    required
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Vínculos e permissões' align='left'>
                                    <Row>
                                        <Col md={6}>
                                            <Accordion>
                                                <Card>
                                                    <Card.Header className={styles.cardHeader}>
                                                        <Accordion.DataToggle target='estoques'>
                                                            <h6 className=''>
                                                                <b>Vinculo com Unidades Educacionais/Estoques</b>
                                                            </h6>
                                                        </Accordion.DataToggle>
                                                    </Card.Header>

                                                    <Accordion.DataTarget receiver='estoques'>
                                                        <Card.Body>
                                                            <SimpleTable>
                                                                <thead>
                                                                    <tr>
                                                                        <th><Form.Check type='checkbox' checked={this.state.selecionarTodosEstoques} onChange={this.selecionarTodosEstoques} /></th>
                                                                        <th>Unidade/Estoque</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.usuario.estoques.map((estoque, index) => (
                                                                        <tr key={index}>
                                                                            <td><Form.Check type='checkbox' checked={estoque.checked} onChange={() => this.selecionarEstoque(index)} /></td>
                                                                            <td>{estoque.nome}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </SimpleTable>
                                                        </Card.Body>
                                                    </Accordion.DataTarget>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                        <Col md={6}>
                                            <Accordion>
                                                <Card>
                                                    <Card.Header className={styles.cardHeader}>
                                                        <Accordion.DataToggle target='permissoes'>
                                                            <h6 className=''>
                                                                <b>Permissões</b>
                                                            </h6>
                                                        </Accordion.DataToggle>
                                                    </Card.Header>

                                                    <Accordion.DataTarget receiver='permissoes'>
                                                        <Card.Body>
                                                            <SimpleTable>
                                                                <thead>
                                                                    <tr>
                                                                        <th><Form.Check type='checkbox' checked={this.state.selecionarTodasPermissoes} onChange={this.selecionarTodasPermissoes} /></th>
                                                                        <th>Permissão</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.usuario.permissoes.map((permissao, index) => (
                                                                        <tr key={index}>
                                                                            <td><Form.Check type='checkbox' checked={permissao.checked} onChange={() => this.selecionarPermissao(index)} /></td>
                                                                            <td>{permissao.nome}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </SimpleTable>
                                                        </Card.Body>
                                                    </Accordion.DataTarget>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalDetalhes}
                    onHide={this.handleCloseModalDetalhes}
                    title='Detalhes'
                    icon='fas fa-eye'>

                    <Row>
                        <Col>
                            <Fieldset legend='Dados pessoais' align='left'>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            label='Nome completo:'
                                            type='text'
                                            value={this.state.usuarioDetalhes.pessoa.nome}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='E-mail:'
                                            type='email'
                                            value={this.state.usuarioDetalhes.email}
                                            disabled
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='Usuário:'
                                            type='text'
                                            value={this.state.usuarioDetalhes.usuario}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='CPF:'
                                            type='text'
                                            mask='999.999.999-99'
                                            value={this.state.usuarioDetalhes.pessoa.cpf}
                                            disabled
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Telefone fixo:'
                                            type='text'
                                            mask='(99) 9999-9999'
                                            value={this.state.usuarioDetalhes.pessoa.telefone}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Celular:'
                                            type='text'
                                            mask='(99) 99999-9999'
                                            value={this.state.usuarioDetalhes.pessoa.celular}
                                            disabled
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Tipo Usuário:'
                                            type='text'
                                            value={this.state.usuarioDetalhes.pessoa.pessoaTipo}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {this.state.usuarioDetalhes.pessoa.pessoaTipo === 'FUNCIONARIO' &&
                                            <SimpleSelect
                                                label='* Cargo:'
                                                value={this.state.usuarioDetalhes.pessoa.cargo}
                                                disabled
                                            >

                                                <option></option>
                                                {this.props.cargos.map((cargo, index) => (
                                                    <option key={index} value={cargo.nome}>{cargo.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        }


                                        {this.state.usuarioDetalhes.pessoa.pessoaTipo === 'MOTORISTA' &&
                                            <TextInputMask
                                                label='* CNH:'
                                                mask='99999999999'
                                                value={this.state.usuarioDetalhes.pessoa.cnh}
                                                disabled
                                            />
                                        }

                                        {this.state.usuarioDetalhes.pessoa.pessoaTipo === 'NUTRICIONISTA' &&
                                            <TextInputMask
                                                label='* CRN:'
                                                mask='CRN-99/9999'
                                                value={this.state.usuarioDetalhes.pessoa.crn}
                                                disabled
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Vínculos e permissões' align='left'>
                                <Row>
                                    <Col md={6}>
                                        <Accordion>
                                            <Card>
                                                <Card.Header className={styles.cardHeader}>
                                                    <Accordion.DataToggle target='estoques'>
                                                        <h6 className=''>
                                                            <b>Vinculo com Unidades Educacionais/Estoques</b>
                                                        </h6>
                                                    </Accordion.DataToggle>
                                                </Card.Header>

                                                <Accordion.DataTarget receiver='estoques'>
                                                    <Card.Body>
                                                        <SimpleTable>
                                                            <tbody>
                                                                {this.state.usuarioDetalhes.estoques.map((estoque, index) => (
                                                                    <tr key={index}>
                                                                        <td>{estoque.unidadeEducacional.nome}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </SimpleTable>
                                                    </Card.Body>
                                                </Accordion.DataTarget>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                    <Col md={6}>
                                        <Accordion>
                                            <Card>
                                                <Card.Header className={styles.cardHeader}>
                                                    <Accordion.DataToggle target='permissoes'>
                                                        <h6 className=''>
                                                            <b>Permissões</b>
                                                        </h6>
                                                    </Accordion.DataToggle>
                                                </Card.Header>

                                                <Accordion.DataTarget receiver='permissoes'>
                                                    <Card.Body>
                                                        <SimpleTable>
                                                            <tbody>
                                                                {this.state.usuarioDetalhes.permissoes.map((permissao, index) => (
                                                                    <tr key={index}>
                                                                        {/* <td><Form.Check type='checkbox' checked={permissao.checked} onChange={() => this.selecionarPermissao(index)} /></td> */}
                                                                        <td>{permissao}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </SimpleTable>
                                                    </Card.Body>
                                                </Accordion.DataTarget>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalCount: state.usuario.closeModalCount,
    list: state.usuario.list,
    filtrados: state.usuario.filtrados,
    estoques: state.estoque.list,
    tiposPermissoes: state.convencao.tiposPermissoes,
    cargos: state.convencao.cargos
})

const mapDispatchToProps = dispatch => bindActionCreators({
    salvarUnidadesEducacionais,
    listarUsuarios,
    filtrarUsuarios,
    editarUsuario,
    excluirUsuario,
    listarEstoques,
    listarTiposPermissoes,
    listarCargos
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarUsuariosPage)), 'USUARIOS')