import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { CADASTRAR_PRODUTO, EDITAR_PRODUTO, LISTAR_PRODUTOS, FILTRAR_PRODUTOS } from './types';

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';

export function cadastrarProduto(produto, token) {
    return dispatch => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/produtos`, produto, configAuthorizationToken(token))
                    .then(res => {
                        dispatch({ type: CADASTRAR_PRODUTO, payload: {} });

                        toastr.success('Sucesso', 'Produto cadastrado com sucesso');
                    })
                    .catch(err => toastr.error('Erro', extractErrorMessage(err)));
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar o Produto?' />
            )
        })
        
    }
}

export function listarProdutos(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/produtos`, configAuthorizationToken(token))
            .then(res => dispatch([
                { type: LISTAR_PRODUTOS, payload: res.data },
                { type: FILTRAR_PRODUTOS, payload: res.data }
            ]))
            .catch(err => console.log(err))
    }
}

export function filtrarProdutos(item, produto) {
    return (dispatch, getState) => {
        let produtos = getState().produto.list;
        let produtosFiltrados = produtos.filter(p =>
            p.item.nome.toLowerCase().includes(item) && p.nome.toLowerCase().includes(produto))
        dispatch({ type: FILTRAR_PRODUTOS, payload: produtosFiltrados })
    }
}

export function editarProduto(produto, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/produtos/${produto.id}`, produto, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_PRODUTO, payload: {} },
                            listarProdutos(token) 
                        ]);
                        toastr.success('Sucesso', 'Produto editado com sucesso')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err));
                        dispatch(listarProdutos(token));
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Produto?' />
            )
        })
    }
}

export function excluirProduto(id, token) {
    return dispatch => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.delete(`${BASE_URL}/produtos/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatch(listarProdutos(token))
                        toastr.success('Sucesso', 'Produto excluído com sucesso')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err));
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Produto?' />
            )
        })
    }
}