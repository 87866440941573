import React from 'react';
import styles from './styles.module.css';

const Etiqueta = props => (
    <>
        <hr className={styles.corte} />

        <div className='row'>
            {
                props.produtos.map((lote, i) => (
                    <div className={`col-md-6 ${styles.borda}`} key={i}>
                        <h2 className={styles.lote}>Lote: {lote.produtoEstoque.produtoLote.numero}</h2>
                        <h6><b>Produto: </b>{lote.produtoEstoque.produtoLote.produto.nome}</h6>
                        <h6><b>Val: </b>{lote.produtoEstoque.produtoLote.dataValidade}</h6>
                    </div>
                ))
            }
        </div>
    </>
);

export default Etiqueta;