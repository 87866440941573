import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filtrarAlimentos, listarAlimentos } from '../../actions';

import styles from './styles.module.css';

class Alimentos extends Component {

    state = {
        alimentos: [],
    }

    componentWillMount() {
        const data = this.props.data.split('=');
        var pesquisaNome = '';
        var pesquisaItem, pesquisaCategoria = null;
        if (data[1] === 'true') {
            if (sessionStorage.getItem('pesquisaNome')) {
                pesquisaNome = sessionStorage.getItem('pesquisaNome');
            }
            if (sessionStorage.getItem('pesquisaItem')) {
                pesquisaItem = sessionStorage.getItem('pesquisaItem');
            }
            if (sessionStorage.getItem('pesquisaCategoria')) {
                pesquisaCategoria = sessionStorage.getItem('pesquisaCategoria');
            }
            //
            this.props.filtrarAlimentos(pesquisaNome, pesquisaItem, pesquisaCategoria);
        } else if (data[1] === 'false') {
            this.props.listarAlimentos(this.props.token);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.alimentos !== this.props.alimentos) this.setState({ ...this.state, alimentos: nextProps.alimentos }, () => console.log(this.state));
    }

    componentWillUnmount() {
        sessionStorage.removeItem('pesquisaNome');
        sessionStorage.removeItem('pesquisaItem');
        sessionStorage.removeItem('pesquisaCategoria');
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Alimentos' />

                    <Detail>
                        <table border='1' className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.titulo}>Nome</th>
                                    <th className={styles.titulo}>Forma</th>
                                    <th className={styles.titulo}>Item</th>
                                    <th className={styles.titulo}>Base Cal.</th>
                                    <th className={styles.titulo}>Uni. Medida</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.alimentos.map((alimento, index) => (
                                        <tr key={index} >
                                            <td className={styles.tipo}>{alimento.nome}</td>
                                            <td className={styles.tipo}>{alimento.forma.nome}</td>
                                            <td className={styles.tipo}>{alimento.itemAlimento.nome} - {alimento.itemAlimento.categoria}</td>
                                            <td className={styles.tipo}>{alimento.quantidadeBase}</td>
                                            <td className={styles.tipo}>{alimento.unidadeMedida}</td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                        </table>
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    alimentos: state.alimento.filtrados
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarAlimentos,
    filtrarAlimentos
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Alimentos);