import { LISTAR_CARDAPIOS_CALENDARIO, ACAO_CARDAPIO_CALENDARIO, CALENDARIO_RELATORIO } from '../actions/types';

const INITIAL_STATE = {
    list: [],
    eventos: [],

    closeModalCount: 0,
    relatorio: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_CARDAPIOS_CALENDARIO:
            return { ...state, list: action.payload.list, eventos: action.payload.eventos }

        case ACAO_CARDAPIO_CALENDARIO:
            return { ...state, closeModalCount: ++state.closeModalCount }

        case CALENDARIO_RELATORIO:
            return { ...state, relatorio: action.payload }

        default:
            return state
    }
}