import React from 'react';

import styles from './FileInput.module.css';

const FileInput = props => (
    <>
        <input type='file' id='file' accept={props.extensions} className={styles.file} {...props} />
        <label htmlFor="file">
            {props.label ? props.label : 'Enviar arquivo'}
        </label>
    </>
)

export default FileInput;