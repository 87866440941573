import React from 'react';

import styles from './MainContent.module.css';

const MainContent = props => (
    <div className={styles.mainContent}>
        {props.children}
    </div>
)

export default MainContent;