import {
    LISTAR_CARDAPIOS_LANCAMENTOS_DIA,
    SUBSTITUIR_PRATO_CARDAPIO_LANCAMENTO,
    BUSCAR_PRODUTOS_ESTOQUE_REFEICAO,
    LISTAR_EXECUCOES_CARDAPIOS,
    FILTRAR_EXECUCOES_CARDAPIOS,
    LISTAR_UMA_EXECUCAO_CARDAPIOS,
    LISTAR_ALIMENTOS_REGISTRO,
    LISTAR_REFEICAO,
    LISTAR_DESPERDICIOS,
    BUSCAR_QUANTIDADE_ALUNOS_ALIMENTACAO
} from '../actions/types';

const INITIAL_STATE = {
    // execuções / refeições
    cardapiosLancamentosDia: [],
    produtosEstoqueRefeicao: [],

    quantidadeAlunosAlimentacao: 0,
    historicoExecucoes: [{
        tipo: {},
        prato: {}
    }],
    filtradosHistoricoExecucoes: [{
        tipo: {},
        prato: {}
    }],
    execucao: {
        tipo: {},
        prato: {}
    },
    alimentosRegistro: [],

    closeModalCount: 0,
    relatorio: {},

    // desperdicio
    desperdicios: {
        alunosParaRefeicao: [],
        entradaRefeitorio: [],
        desperdicio: [],
    }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_CARDAPIOS_LANCAMENTOS_DIA:
            return { ...state, cardapiosLancamentosDia: action.payload }

        case SUBSTITUIR_PRATO_CARDAPIO_LANCAMENTO:
            return { ...state, closeModalCount: ++state.closeModalCount }

        case BUSCAR_PRODUTOS_ESTOQUE_REFEICAO:
            return { ...state, produtosEstoqueRefeicao: action.payload }

        case LISTAR_EXECUCOES_CARDAPIOS:
            return { ...state, historicoExecucoes: action.payload }

        case FILTRAR_EXECUCOES_CARDAPIOS:
            return { ...state, filtradosHistoricoExecucoes: action.payload }

        case LISTAR_UMA_EXECUCAO_CARDAPIOS:
            return { ...state, execucao: action.payload }

        case LISTAR_ALIMENTOS_REGISTRO:
            return { ...state, alimentosRegistro: action.payload }

        case LISTAR_REFEICAO:
            return { ...state, relatorio: action.payload }

        case LISTAR_DESPERDICIOS:
            return { ...state, desperdicios: action.payload }

        case BUSCAR_QUANTIDADE_ALUNOS_ALIMENTACAO:
            return { ...state, quantidadeAlunosAlimentacao: action.payload }

        default:
            return state
    }
} 