import React, { Component } from 'react';
import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listarFornecedoresPorTipo } from '../../actions';

import styles from './styles.module.css';

import formatCnpjCpf from '../../utils/formatCnpjCpf';
import formatTelefoneCelular from '../../utils/formatTelefoneCelular';
import formatInscricaoEstadual from '../../utils/formatInscricaoEstadual';
import formatCEP from '../../utils/formatCEP';

let tipo;
class Fornecedores extends Component {

    state = {
        fornecedores: []
    }

    componentWillMount() {
        tipo = sessionStorage.getItem('fornecedorTipo');
        this.props.listarFornecedoresPorTipo(tipo, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.fornecedores !== this.props.fornecedores) this.setState({ ...this.state, fornecedores: nextProps.fornecedores });
    }

    componentWillUnmount() {
        sessionStorage.removeItem('fornecedorTipo');
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    {tipo === "JURIDICO" ? (
                        <Title name='Relatório de Fornecedores Jurídicos' />

                    ) : (
                            <Title name='Relatório de Fornecedores Físicos' />
                        )}

                    <Detail>
                        {
                            this.state.fornecedores.map((fornecedor, index) => (
                                <div key={index} className={styles.fornecedor}>
                                    {
                                        fornecedor.fornecedorTipo === 'JURIDICO' ? (
                                            <>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <b>Nome Fantasia:</b> {fornecedor.nomeFantasia}
                                                    </div>
                                                    <div className='col'>
                                                        <b>CNPJ:</b> {formatCnpjCpf(fornecedor.cnpj)}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <b>Razão Social:</b> {fornecedor.razaoSocial}
                                                    </div>
                                                    <div className='col'>
                                                        <b>Inscricão Estadual:</b> {formatInscricaoEstadual(fornecedor.inscricaoEstadual)}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <b>Porte Da Empresa:</b> {fornecedor.porteDaEmpresa}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                                <>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <b>Nome:</b> {fornecedor.nome}
                                                        </div>
                                                        <div className='col'>
                                                            <b>CPF:</b> {formatCnpjCpf(fornecedor.cpf)}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                    }
                                    {
                                        <>
                                            <div className='row'>
                                                <div className='col'>
                                                    <b>E-mail:</b> {fornecedor.email}
                                                </div>
                                                <div className='col'>
                                                    {fornecedor.telefone === "" ? (
                                                        <>
                                                            <b>Telefone:</b> ---
                                                       </>
                                                    ) : (
                                                            <>
                                                                <b>Telefone:</b> {formatTelefoneCelular(fornecedor.telefone)}
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    {fornecedor.celular === "" ? (
                                                        <>
                                                            <b>Celular:</b> ---
                                                       </>
                                                    ) : (
                                                            <>
                                                                <b>Celular:</b> {formatTelefoneCelular(fornecedor.celular)}
                                                            </>
                                                        )}
                                                </div>
                                                <div className='col'>
                                                    <b>CEP:</b> {formatCEP(fornecedor.cep)}
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <b>Municipío:</b> {fornecedor.municipio} - {fornecedor.uf}
                                                </div>
                                                <div className='col'>
                                                    <b>Bairro:</b> {fornecedor.bairro_distrito}
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <b>Logradouro:</b> {fornecedor.logradouro}, {fornecedor.numero}
                                                </div>
                                                <div className='col'>
                                                    {fornecedor.complemento === "" ? (
                                                        <>
                                                            <b>Complemento:</b> ---
                                                       </>
                                                    ) : (
                                                            <>
                                                                <b>Complemento:</b> {fornecedor.complemento}
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            ))
                        }
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    fornecedores: state.fornecedor.list
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarFornecedoresPorTipo
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Fornecedores);