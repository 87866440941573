import React from 'react';

import styles from './ActionButton.module.css';

const ActionButton = props => (
    <button {...props} className={styles.actionButton} title={props.title}>
        <i className={props.icon} />
    </button>
)

export default ActionButton;