import React from 'react';
import Select from 'react-select';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './SelectMultiple.module.css';

const SelectMultiple = props => (
    <div className={styles.bloco}>  
        <InputGroup.Prepend>
            <InputGroup.Text className={styles.label}>{props.label}</InputGroup.Text>
        </InputGroup.Prepend>

    <Select 
    isMulti
    placeholder="selecione"
    isDisabled={props.isDisabled}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    options={props.options}
    name="colors"
    className={styles.select}
    classNamePrefix="select"
  />
  </div>
)

export default SelectMultiple;