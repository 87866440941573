import React from 'react';
import { InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';

import styles from './MoneyInputMask.module.css';

const MoneyInputMask = props => (
    <InputGroup className='mb-3'>
        <InputGroup.Prepend>
            <InputGroup.Text className={styles.label}>{props.label}</InputGroup.Text>
        </InputGroup.Prepend>
        <CurrencyInput
            {...props}
            thousandSeparator='.'
            decimalSeparator=','
            prefix='R$ '
            className={`form-control ${styles.input}`}
        />
    </InputGroup>
)

export default MoneyInputMask;