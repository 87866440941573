import { CADASTRAR_ALIMENTO, LISTAR_ALIMENTOS, EDITAR_ALIMENTO, LISTAR_COMPONENTES_NUTRICIONAIS, FILTRAR_ALIMENTOS } from '../actions/types'

const INITIAL_STATE = {
    list: [],
    filtrados: [],
    componentesNutricionais: [],

    resetNumbers: 0,
    closeModalNumbers: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_ALIMENTOS:
            return { ...state, list: action.payload }

        case CADASTRAR_ALIMENTO:
            return { ...state, resetNumbers: ++state.resetNumbers }

        case EDITAR_ALIMENTO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case FILTRAR_ALIMENTOS:
            return { ...state, filtrados: action.payload }

        case LISTAR_COMPONENTES_NUTRICIONAIS:
            return { ...state, componentesNutricionais: action.payload }

        default:
            return state
    }
}