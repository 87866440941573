import {
    LISTAR_CARDAPIOS,
    LISTAR_CARDAPIOS_SEMANAIS,
    LISTAR_CARDAPIOS_MENSAIS,
    FILTRAR_CARDAPIOS,
    FILTRAR_CARDAPIOS_SEMANAIS,
    CADASTRAR_CARDAPIOS,
    EDITAR_CARDAPIOS
} from '../actions/types'

const INITIAL_STATE = {
    list: [],
    filtrados: [],
    semanais: [],
    filtradosSemanais: [],
    mensais: [],

    resetCount: 0,
    closeModalCount: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_CARDAPIOS:
            return { ...state, list: action.payload }

        case FILTRAR_CARDAPIOS:
            return { ...state, filtrados: action.payload }

        case LISTAR_CARDAPIOS_SEMANAIS:
            return { ...state, semanais: action.payload }

        case FILTRAR_CARDAPIOS_SEMANAIS:
            return { ...state, filtradosSemanais: action.payload }

        case LISTAR_CARDAPIOS_MENSAIS:
            return { ...state, mensais: action.payload }

        case CADASTRAR_CARDAPIOS:
            return { ...state, resetCount: ++state.resetCount }

        case EDITAR_CARDAPIOS:
            return { ...state, closeModalCount: ++state.closeModalCount }

        default:
            return state
    }
}