import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Fieldset from '../../components/Fieldset';
import PageTitle from '../../components/PageTitle/index';
import Input from '../../components/Input/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SelectMultiple from '../../components/SelectMultiple/index';
import SimpleButton from '../../components/SimpleButton';
import TinyAction from '../../components/TinyAction';
import BackButton from '../../components/BackButton';
import { MoneyFormat } from '../../utils/moneyFormat';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    cadastrarSolicitacao,
    listarAquisicoes,
    listarFornecedores,
    listarItensFornecedor,
    listarUnidadesEducacionais,
    listarCategorias
} from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import moment from 'moment';

// import styles from './CadastrarSolicitacao.module.css';

const INITIAL_FORM = {
    dataSolicitacao: moment(new Date()).format('DD/MM/YYYY'),
    aquisicao: -1,
    fornecedor: -1,
    categorias: [
        { faixaEtaria: -1}
    ],
    modalidadesAux: [],
    propostaVencedora: -1,
    unidadeEducacional: -1,
    itens: [
        { produto: -1, quantidade: 0, unidade: '', quantidadeMaxima: 0, invalidQuant: false, precoUnidade: 0 }
    ],
    invalidForm: false,

    fornecedores: []
}

const INITIAL_ITENS = [
    { produto: -1, quantidade: 0, unidade: '', quantidadeMaxima: 0, invalidQuant: false }
]

class CadastrarSolicitacaoPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarAquisicoes(this.props.token);
        this.props.listarFornecedores(this.props.token);
        this.props.listarUnidadesEducacionais(this.props.token);
        this.props.listarCategorias(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.resetNumbers < nextProps.resetNumbers) this.setState({ ...INITIAL_FORM, itens: JSON.parse(JSON.stringify(INITIAL_ITENS)) })
    }

    handleChangeAquisicao = value => {
        let itensVazios = [{ produto: -1, quantidade: 0, unidade: '', quantidadeMaxima: 0, invalidQuant: false }];

        this.setState({ ...this.state, aquisicao: value, fornecedores: [], fornecedor: -1, itens: itensVazios }, () => {
            let aquisicoes = JSON.parse(JSON.stringify(this.props.aquisicoes));
            let aquisicao = aquisicoes.find(aquisicao => aquisicao.id === value);

            if (!!aquisicao) {
                let fornecedores = aquisicao.propostasVencedoras.map(proposta => ({ idProposta: proposta.id, ...proposta.fornecedor }));
                this.setState({ ...this.state, fornecedores })
            }
        })
    }

    handleChangeCategories = values => {
        let arrayAux = [];

        values.map(itens => {
            arrayAux.push(itens.content);
        })
        
        this.setState({ ...this.state, categorias: arrayAux})
    }

    handleChangeFornecedor = value => {
        let itensVazios = [{ produto: -1, quantidade: 0, unidade: '', quantidadeMaxima: 0, invalidQuant: false }];

        this.setState({ ...this.state, fornecedor: value, itens: itensVazios }, () => {

            let fornecedor = this.state.fornecedores.find(fornecedor => fornecedor.id === value);

            !!fornecedor ? this.setState({ propostaVencedora: fornecedor.idProposta }) : this.setState({ propostaVencedora: -1 })

            this.props.listarItensFornecedor(value, this.state.aquisicao, this.props.token);
        })
    }

    handleItemInput = (value, index, property) => {
        if (isNaN(value)) value = -1;

        let itens = this.state.itens;
        itens[index][property] = value;

        this.setState({ ...this.state, itens })

        let i;
        switch (property) {
            case 'produto':
                i = this.props.itensFornecedor.find(itemFornecedor => itemFornecedor.id === value)

                if (!!i) {
                    if (itens[index]['quantidade'] < 0 || (i && itens[index]['quantidade'] > i.quantidade)) {
                        itens[index]['invalidQuant'] = true;
                        this.setState({ ...this.state, itens })
                    } else {
                        itens[index]['invalidQuant'] = false;
                        this.setState({ ...this.state, itens })
                    }
                    itens[index]['unidade'] = i.unidade;
                    itens[index]['quantidadeMaxima'] = i.quantidade;
                    itens[index]['precoUnidade'] = i.precoUnidade;
                }

                break;

            case 'quantidade':
                i = this.props.itensFornecedor.find(itemFornecedor => itemFornecedor.id === itens[index]['produto'])

                if (!!i) {
                    if (value < 0 || (i && value > i.quantidade)) {
                        itens[index]['invalidQuant'] = true;
                        this.setState({ ...this.state, itens })
                    } else {
                        itens[index]['invalidQuant'] = false;
                        this.setState({ ...this.state, itens })
                    }
                }
                break;

            default:
                break;
        }

        if (itens.find(item => item.invalidQuant)) this.setState({ ...this.state, invalidForm: true })
        else this.setState({ ...this.state, invalidForm: false })
    }

    handleAddItem = index => {
        let item = { produto: -1, quantidade: 0, medida: '', quantidadeMaxima: 0, invalidQuant: false }

        let itens = this.state.itens;
        itens.splice(index + 1, 0, item);

        this.setState({ ...this.state, itens })
    }

    handleRemoveItem = index => {
        let itens = this.state.itens;
        itens.splice(index, 1);

        this.setState({ ...this.state, itens })
    }

    handleSubmit = e => {
        e.preventDefault();

        let solicitacao = JSON.parse(JSON.stringify(this.state));

        solicitacao.fornecedor = this.props.fornecedores.find(fornecedor => fornecedor.id === solicitacao.fornecedor);
        let { id, fornecedorTipo } = solicitacao.fornecedor;
        solicitacao.fornecedor = { id, fornecedorTipo }

        // let newItens = [];
        // this.state.itens.forEach(item => {
        //     let { itemProdutoTipo } = this.props.itensFornecedor.find(i => item.id === i.id)
        //     newItens.push({
        //         item: {
        //             id: item.id,
        //             itemProdutoTipo
        //         },
        //         quantidade: item.quantidade,
        //     })
        // })

        let newItens = solicitacao.itens.map(item => ({ produto: item.produto, quantidade: item.quantidade, unidade: item.unidade, precoUnidade: item.precoUnidade }));
        let newCategories = solicitacao.categorias.map(item => ({ id: item.id, modalidade: item.modalidade, faixaEtaria: item.faixaEtaria }));

        solicitacao.itens = newItens;
        solicitacao.categorias = newCategories;

        //console.log(solicitacao)
        this.props.cadastrarSolicitacao(solicitacao, this.props.token);
    }

    render() {
        let itensForm =
            (
                <>
                    {this.state.itens.map((item, index) => (
                        <Row key={index}>
                            <Col md={5}>
                                <SimpleSelect
                                    label='* Item:'
                                    value={item.produto}
                                    onChange={e => this.handleItemInput(parseInt(e.target.value), index, 'produto')}
                                    required
                                >

                                    <option></option>
                                    {this.props.itensFornecedor.map(i =>
                                        <option key={i.id} value={i.id}>{`${i.nome} - ${i.item}`}</option>
                                    )}
                                </SimpleSelect>
                            </Col>

                            
                            <label style={{paddingTop:4}}>Preço:{isNaN(parseFloat(item.precoUnidade)) ? "R$ 0,00" : MoneyFormat(item.precoUnidade)}</label>

                            <Col md={5}>
                                <Input
                                    label={`* Quantidade: (${item.unidade})`}
                                    type='number'
                                    isInvalid={item.invalidQuant}
                                    value={item.quantidade}
                                    onChange={value => this.handleItemInput(value, index, 'quantidade')}
                                    aside={item.quantidadeMaxima}
                                    tooltip='Quantidade disponível'
                                    required
                                />
                            </Col>
                            <div style={{display: 'flex',gap: 7,height: 34}}>
                                    <TinyAction
                                        type='button'
                                        title='Adicionar novo item'
                                        onClick={() => this.handleAddItem(index)}
                                        color='green'
                                        icon='fas fa-plus'
                                    />

                                    <TinyAction
                                        type='button'
                                        title='Remover item'
                                        
                                        onClick={() => this.handleRemoveItem(index)}
                                        color='red'
                                        icon='fas fa-minus'
                                    />
                                </div>
                        </Row>
                    ))}
                </>
            );

        return (
            <>
                <BackButton />
                <Row>
                    <PageTitle name='Cadastro de Solicitação de Compra' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados da solicitação' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            label='* Data Solicitação:'
                                            type='text'
                                            value={this.state.dataSolicitacao}
                                            disabled
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5}>
                                        <SimpleSelect
                                            label='* Aquisicão:'
                                            value={this.state.aquisicao}
                                            onChange={e => this.handleChangeAquisicao(parseInt(e.target.value))}
                                            required
                                        >

                                            <option value={-1}></option>
                                            {this.props.aquisicoes
                                                .filter(aquisicao => aquisicao.status !== 'PENDENTE')
                                                .map(aquisicao => (
                                                    <option key={aquisicao.id} value={aquisicao.id}>{aquisicao.aquisicaoTipo} - {aquisicao.numero}</option>
                                                )
                                                )}
                                        </SimpleSelect>
                                    </Col>
                                   
                                        <Col md={5}>
                                            <SelectMultiple
                                                name="colors"
                                                label='Modalidade:'
                                                value={this.state.categorias}
                                                options={this.props.modalidadesAux}
                                                onChange={e => this.handleChangeCategories(e)}>
                                            </SelectMultiple>
                                        </Col>
                                   
                                </Row>

                                <Row>
                                    
                                </Row>
                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Fornecedor:'
                                            value={this.state.fornecedor}
                                            onChange={e => this.handleChangeFornecedor(parseInt(e.target.value))}
                                            required
                                        >

                                            <option value={-1}></option>
                                            {this.state.fornecedores.map(fornecedor => (
                                                <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.hasOwnProperty('nome') ? fornecedor.nome : fornecedor.nomeFantasia}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Unidade Educacional:'
                                            value={this.state.unidadeEducacional}
                                            onChange={e => this.setState({ ...this.state, unidadeEducacional: parseInt(e.target.value) })}
                                            required
                                        >

                                            <option value={-1}></option>
                                            {this.props.unidadesUsuario.map(unidade => (
                                                <option key={unidade.unidadeEducacional.id} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                {itensForm}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                                disabled={this.state.invalidForm}
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetNumbers: state.solicitacao.resetNumbers,
    unidadesUsuario: state.auth.user.estoques,
    aquisicoes: state.aquisicao.list.filter(a => a.status === 'VIGENTE'),
    fornecedores: state.fornecedor.list,
    itensFornecedor: state.fornecedor.itens,
    unidades: state.unidadeEducacional.list,
    modalidades: state.convencao.categorias,
    modalidadesAux: formatValueMultipleSelect(state.convencao.categorias),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarSolicitacao,
    listarAquisicoes,
    listarFornecedores,
    listarItensFornecedor,
    listarUnidadesEducacionais,
    listarCategorias,
}, dispatch);

const formatValueMultipleSelect = (itens) => {
    const values = [];

    if (itens) {
        itens.map(v => {
            let value ={label : v.modalidade +'/'+ v.faixaEtaria.nome, value: v.id, content: v}
            values.push(value)
        })
    }
    
    return values;
}



export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarSolicitacaoPage), 'SOLICITACOES');