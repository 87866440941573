import React, { Component } from 'react';
import { Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleTable from '../../components/SimpleTable/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal';
import Fieldset from '../../components/Fieldset';
import Input from '../../components/Input';
import SimpleSelect from '../../components/SimpleSelect/index';
import TinyAction from '../../components/TinyAction/index';
import SimpleButton from '../../components/SimpleButton/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarPratos, editarPrato, excluirPrato, listarCategorias, listarPreparos, listarModalidades, filtrarPratos } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import BackButton from '../../components/BackButton';

import { roundToTwo } from '../../utils/mathCalc';

import styles from './GerenciarPratos.module.css';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';

class GerenciarPratosPage extends Component {
    state = {
        showModal: false,
        showModalDetalhes: false,
        prato: {
            categoria: {
                id: -1
            },
            modalidade: {},
            preparos: [{ id: -1 }]
        },

        pesquisaNome: '',

        fichaTecnica: [
            { nome: '', unidadeDeMedida: '', valor: '' }
        ],

        fichaTecnicaEditar: [
            { nome: '', unidadeDeMedida: '', valor: '' }
        ]
    }

    componentDidMount() {
        this.props.listarPratos(this.props.token)
        this.props.listarCategorias(this.props.token)
        this.props.listarPreparos(this.props.token)
        this.props.listarModalidades(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.closeModalCount < nextProps.closeModalCount) this.setState({ showModal: false })
    }

    visualizarFichaTecnica = nutriencesPrato => {
        this.setState({ ...this.state, fichaTecnica: nutriencesPrato })
    }

    mostrarPaginaRelatorio = (idPrato) => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_PRATOS}/${idPrato}`);
    }

    calcularFichaTecnicaEdicao = () => {
        let prato = JSON.parse(JSON.stringify(this.state.prato));
        let preparos = prato.preparos;
        let preparosFetch = this.props.preparosPrato;

        let componentesFichaTecnica = [];

        if (preparosFetch.length > 0) {
            let componentesPadroes = preparosFetch[0].nutrientes.map(nutriente => nutriente.nome);

            for (let i = 0; i < preparos.length; i++) {
                let preparo = preparos[i];

                let preparoCompleto = preparosFetch.find(p => p.id === preparo.id);
                if (!!preparoCompleto) {
                    componentesFichaTecnica.push(...preparoCompleto.nutrientes)
                }
            }

            let componentesFinais = [];

            for (let k = 0; k < componentesPadroes.length; k++) {
                let componentePadrao = componentesPadroes[k];
                if (componentesFichaTecnica.length > 0) {
                    let valorSoma = componentesFichaTecnica
                        .filter(componente => componente.nome === componentePadrao)
                        .map(componente => componente.valor)
                        .reduce((accumulator, currentValue) => accumulator + currentValue)

                    componentesFinais.push({ ...componentesFichaTecnica[k], valor: roundToTwo(valorSoma) })
                }
            }

            this.setState({ ...this.state, fichaTecnicaEditar: componentesFinais });
        }
    }

    handleShowModal = prato => {
        this.setState({ showModal: true, prato })
    }

    handleShowModalDetalhes = prato => {
        this.setState({ showModalDetalhes: true, prato })
    }

    handleChange = (value, property, upper = () => {}) => {
        let prato = JSON.parse(JSON.stringify(this.state.prato));
        prato[property] = value;

        this.setState({ ...this.state, prato }, () => upper())
    }

    handleCategoriaInput = value => {
        let prato = this.state.prato;
        prato.categoria.id = value;

        this.setState({ prato })
    }

    handleModalidadeInput = value => {
        let prato = this.state.prato;
        prato.modalidade.nome = value;

        this.setState({ ...this.state, prato })
    }

    handlePreparoInput = (value, index) => {
        let prato = this.state.prato;
        prato.preparos[index]['id'] = value;

        this.setState({ ...this.state, prato });
    }

    handleAddPreparo = index => {
        let preparo = { id: -1 }

        let prato = this.state.prato;
        prato.preparos.splice(index + 1, 0, preparo);

        this.setState({ ...this.state, prato });
    }

    handleRemovePreparo = index => {
        let prato = this.state.prato;
        if (prato.preparos.length !== 1) {
            prato.preparos.splice(index, 1);

            this.setState({ ...this.state, prato });
        }
    }

    handleEditSubmit = e => {
        e.preventDefault();

        let prato = JSON.parse(JSON.stringify(this.state.prato));

        prato.categoria = prato.categoria.id

        prato.modalidade = prato.modalidade.nome

        prato.preparos = prato.preparos.map(preparo => ({ preparo: preparo.id }))

        // console.log(prato)

        this.props.editarPrato(prato, this.props.token)
    }

    render() {
        const popover = (
            <Popover id='fichaTecnicaPopover' title='Ficha Técnica do Prato'>
                {this.state.fichaTecnica.map((componente, index) => (
                    <p key={index}>
                        <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeDeMedida})
                    </p>
                ))}
            </Popover>
        )

        const popoverEdicao = (
            <Popover id='fichaTecnicaPopoverEdicao' title='Ficha Técnica do Prato'>
                {this.state.fichaTecnicaEditar.map((componente, index) => (
                    <p key={index}>
                        <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeDeMedida})
                    </p>
                ))}
            </Popover>
        )

        return (
            <>
               <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Pratos' hasDivider={true} />
                </Row>


                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Nome: </Form.Label>
                            <Form.Control
                                type='text'
                                value={this.state.pesquisaNome}
                                onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value },
                                    () => this.props.filtrarPratos(this.state.pesquisaNome))}
                            />
                        </Form.Group>
                    </Row>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Modalidade</th>
                            <th>Categotia</th>
                            <th>Ficha Técnica</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(prato => (
                            <tr key={prato.id}>
                                <td>{prato.nome}</td>
                                <td>{prato.modalidade.descricao}</td>
                                <td>{prato.categoria.modalidade} / {prato.categoria.faixaEtaria.nome}</td>
                                <td className={styles.actions}>
                                    <OverlayTrigger trigger='click' placement='left' overlay={popover}>
                                        <ActionButton
                                            title='Visualizar ficha técnica'
                                            onClick={() => this.visualizarFichaTecnica(prato.nutrientes)}
                                            icon='fas fa-clipboard-list'
                                        />
                                    </OverlayTrigger>
                                </td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Prato'
                                        onClick={() => this.handleShowModalDetalhes(prato)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Editar Prato'
                                        onClick={() => this.handleShowModal(prato)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Prato'
                                        onClick={() => this.props.excluirPrato(prato.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                    <ActionButton
                                        title='Relatório de Prato'
                                        onClick={() => this.mostrarPaginaRelatorio(prato.id)}
                                        icon='fas fa-print'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false })}
                    title='Editar Prato'
                    icon='fas fa-edit'>


                    <p>
                        <OverlayTrigger trigger='click' placement='right' overlay={popoverEdicao}>
                            <SimpleButton
                                type='button'
                                name='Ver ficha técnica atual'
                                color='blue'
                                icon='fas fa-clipboard'
                                onClick={this.calcularFichaTecnicaEdicao}
                            />
                        </OverlayTrigger>
                    </p>


                    <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do prato' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='Nome:'
                                                type='text'
                                                case='upper'
                                                value={this.state.prato.nome}
                                                onChange={(value, upper) => this.handleChange(value, 'nome', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='Categoria:'
                                                value={this.state.prato.categoria.id}
                                                onChange={e => this.handleCategoriaInput(parseInt(e.target.value))}
                                                required
                                            >

                                                <option></option>
                                                {this.props.categorias.map(categoria => (
                                                    <option key={categoria.id} value={categoria.id}>{categoria.modalidade} / {categoria.faixaEtaria.nome}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='Modalidade:'
                                                value={this.state.prato.modalidade.nome}
                                                onChange={e => this.handleModalidadeInput(e.target.value)}
                                                required
                                            >

                                                <option></option>
                                                {this.props.modalidades.map((modalidade, index) => (
                                                    <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Preparos' align='left'>
                                    {this.state.prato.preparos.map((preparo, index) => (
                                        <Row key={index}>
                                            <Col md={10}>
                                                <SimpleSelect
                                                    label='Preparo:'
                                                    value={preparo.id}
                                                    onChange={e => this.handlePreparoInput(parseInt(e.target.value), index)} 
                                                    required
                                                >
                                                    <option></option>
                                                    {this.props.preparosPrato.map(p => (
                                                        <option key={p.id} value={p.id}>{p.nome}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Adicionar novo preparo'
                                                            onClick={() => this.handleAddPreparo(index)}
                                                            color='green'
                                                            icon='fas fa-plus'
                                                        />
                                                    </Col>
                                                    <Col align='center'>
                                                        <TinyAction
                                                            type='button'
                                                            title='Remover preparo'
                                                            onClick={() => this.handleRemovePreparo(index)}
                                                            color='red'
                                                            icon='fas fa-minus'
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ showModal: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalDetalhes}
                    onHide={() => this.setState({ showModalDetalhes: false })}
                    title='Detalhes'
                    icon='fas fa-eye'>


                    {/* <p>
                        <OverlayTrigger trigger='click' placement='right' overlay={popoverEdicao}>
                            <SimpleButton
                                type='button'
                                name='Ver ficha técnica atual'
                                color='blue'
                                icon='fas fa-clipboard'
                                onClick={this.calcularFichaTecnicaEdicao}
                            />
                        </OverlayTrigger>
                    </p> */}


                    <Form>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do prato' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='Nome:'
                                                value={this.state.prato.nome}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='Categoria:'
                                                value={this.state.prato.categoria.id}
                                                disabled
                                            >

                                                <option></option>
                                                {this.props.categorias.map(categoria => (
                                                    <option key={categoria.id} value={categoria.id}>{categoria.modalidade} / {categoria.faixaEtaria.nome}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='Modalidade:'
                                                value={this.state.prato.modalidade.nome}
                                                disabled
                                            >

                                                <option></option>
                                                {this.props.modalidades.map((modalidade, index) => (
                                                    <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Preparos' align='left'>
                                    {this.state.prato.preparos.map((preparo, index) => (
                                        <Row key={index}>
                                            <Col md={12}>
                                                <SimpleSelect
                                                    label='Preparo:'
                                                    value={preparo.id}
                                                    disabled
                                                >
                                                    <option></option>
                                                    {this.props.preparosPrato.map(p => (
                                                        <option key={p.id} value={p.id}>{p.nome}</option>
                                                    ))}
                                                </SimpleSelect>
                                            </Col>
                                        </Row>
                                    ))}
                                </Fieldset>
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalCount: state.prato.closeModalCount,
    pratos: state.prato.list,
    filtrados: state.prato.filtrados,
    categorias: state.convencao.categorias,
    preparosPrato: state.preparo.list,
    modalidades: state.convencao.modalidades
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarPratos,
    editarPrato,
    excluirPrato,
    listarCategorias,
    listarPreparos,
    listarModalidades,
    filtrarPratos
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarPratosPage)), 'PRATOS');