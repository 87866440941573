import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ConfirmMessageTitle from './ConfirmMessageTitle';

const ConfirmMessage = props => (
    <div>
        <Row style={{ marginTop: '16px' }}>
            <Col align='center'>
                <ConfirmMessageTitle name='Aviso' icon='fas fa-exclamation-triangle' />
            </Col>
        </Row>
        <Col style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Col align='justify'>
                {props.message}
            </Col>
        </Col>
    </div>
)

export default ConfirmMessage;