import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { LISTAR_PREPAROS, CADASTRAR_PREPARO, EDITAR_PREPARO, FILTRAR_PREPAROS, LISTAR_PREPAROS_ID } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function listarPreparos(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/preparos`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent([
                    { type: LISTAR_PREPAROS, payload: res.data },
                    { type: FILTRAR_PREPAROS, payload: res.data },
                ])
            })
    }
}

export function listarPreparosId(idPreparo, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/preparos/${idPreparo}`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent({ type: LISTAR_PREPAROS_ID, payload: res.data })
            })
    }
}

export function filtrarPreparos(nome) {
    return (dispatchEvent, getState) => {
        let preparos = getState().preparo.list;
        let preparosFiltrados = preparos.filter(preparo => preparo.nome.toLowerCase().includes(nome.toLowerCase()));

        dispatchEvent({ type: FILTRAR_PREPAROS, payload: preparosFiltrados });
    }
}

export function cadastrarPraparo(preparo, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/preparos`, preparo, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent({ type: CADASTRAR_PREPARO, payload: {} })
                        toastr.success('Sucesso', 'Preparo cadastrado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        // dispatchEvent(listarPreparos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Preparo?' />
            )
        })
    }
}

export function editarPreparo(preparo, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/preparos/${preparo.id}`, preparo, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: EDITAR_PREPARO, payload: {} },
                            listarPreparos(token)
                        ])
                        toastr.success('Sucesso', 'Preparo editado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarPreparos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Preparo?' />
            )
        })
    }
}

export function excluirPreparo(id, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/preparos/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent(listarPreparos(token))
                        toastr.success('Sucesso', 'Preparo excluído com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarPreparos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Preparo?' />
            )
        })
    }
}