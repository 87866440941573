import React, { Component } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import Accordion from '../../components/Accordion';
import Fieldset from '../../components/Fieldset';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton';
import Input from '../../components/Input';
import TextInputMask from '../../components/TextInputMask/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleTable from '../../components/SimpleTable/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarUsuario, listarEstoques, listarTiposPermissoes, listarTiposPessoa, listarCargos } from '../../actions';

import styles from './CadastrarUsuario.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';

class CadastrarUsuarioPage extends Component {
    state = {
        usuario: '',
        email: '',
        senha: '',
        estoques: [],
        permissoes: [],
        selecionarTodosEstoques: false,
        selecionarTodasPermissoes: false,

        unidadeEstoque: -1,
        permissao: '',

        nome: '',
        cpf: '',
        telefone: '',
        celular: '',
        pessoaTipo: '',
        cargo: '',
        cnh: '',
        crn: ''
    }

    componentDidMount() {
        this.props.listarEstoques(this.props.token)
        this.props.listarTiposPermissoes(this.props.token)
        this.props.listarTiposPessoa(this.props.token)
        this.props.listarCargos(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tiposPermissoes !== this.props.tiposPermissoes) {
            this.setState({ ...this.state, permissoes: nextProps.tiposPermissoes.map(permissao => ({ nome: permissao, checked: false })) })
        }

        if (nextProps.estoques !== this.props.estoques) {
            this.setState({ ...this.state, estoques: nextProps.estoques.map(estoque => ({ id: estoque.id, nome: estoque.unidadeEducacional.nome, checked: false })) })
        }
    }

    selecionarTodosEstoques = () => {
        let estoques = this.state.estoques;
        let modificandoEstoques = [];
        if (this.state.selecionarTodosEstoques) {
            modificandoEstoques = estoques.map(e => {
                e.checked = false;
                return e;
            })
        } else {
            modificandoEstoques = estoques.map(e => {
                e.checked = true;
                return e;
            })
        }

        this.setState({ ...this.state, selecionarTodosEstoques: !this.state.selecionarTodosEstoques, estoques: modificandoEstoques })
    }

    selecionarTodasPermissoes = () => {
        let permissoes = this.state.permissoes;
        let modificandoPermissoes = [];
        if (this.state.selecionarTodasPermissoes) {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = false;
                return p;
            })
        } else {
            modificandoPermissoes = permissoes.map(p => {
                p.checked = true;
                return p;
            })
        }

        this.setState({ ...this.state, selecionarTodasPermissoes: !this.state.selecionarTodasPermissoes, permissoes: modificandoPermissoes })
    }

    selecionarEstoque = index => {
        let estoques = this.state.estoques;
        estoques[index].checked = !estoques[index].checked;

        this.setState({ ...this.state, estoques })
    }

    selecionarPermissao = index => {
        let permissoes = this.state.permissoes;
        permissoes[index].checked = !permissoes[index].checked;

        this.setState({ ...this.state, permissoes })
    }

    handleAddEstoque = e => {
        e.preventDefault();

        let estoques = this.state.estoques;
        let { id, unidadeEducacional } = this.props.estoques.find(e => e.id === this.state.unidadeEstoque)

        let estoque = { id, unidadeEducacional }

        estoques.push(estoque)

        this.setState({ ...this.state, estoques, showModalEstoques: false, unidadeEstoque: -1 })
    }

    handleRemoveEstoque = estoque => {
        let estoques = this.state.estoques;

        let newEstoques = estoques.filter(e => e.id !== estoque.id)

        this.setState({ ...this.state, estoques: newEstoques })
    }

    handleAddPermissao = e => {
        e.preventDefault();

        let permissoes = this.state.permissoes;

        permissoes.push(this.state.permissao)

        this.setState({ ...this.state, permissoes, showModalPermissoes: false, permissao: '' })
    }

    handleRemovePermissao = permissao => {
        let permissoes = this.state.permissoes;

        let newPermissoes = permissoes.filter(p => p !== permissao)

        this.setState({ ...this.state, permissoes: newPermissoes, permissao: '' })
    }

    handleSubmit = e => {
        e.preventDefault();

        let { usuario, email, nome, senha, estoques, permissoes, cpf, telefone, celular, pessoaTipo } = JSON.parse(JSON.stringify(this.state));

        let u = {
            usuario, email, senha,
            estoques: estoques.filter(estoque => estoque.checked).map(estoque => ({ id: estoque.id })),
            permissoes: permissoes.filter(permissao => permissao.checked).map(permissao => permissao.nome),
            pessoa: {
                nome,
                cpf: cpf.replace(new RegExp(/\D/g), ''),
                telefone: telefone.replace(new RegExp(/\D/g), ''),
                celular: celular.replace(new RegExp(/\D/g), ''),
                pessoaTipo
            }
        }

        switch (this.state.pessoaTipo) {
            case 'FUNCIONARIO':
                u.pessoa.cargo = this.state.cargo
                break;

            case 'MOTORISTA':
                u.pessoa.cnh = this.state.cnh
                break;

            case 'NUTRICIONISTA':
                u.pessoa.crn = this.state.crn
                break;

            default:
                break;
        }

        // console.log(u)

        this.props.cadastrarUsuario(u, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Usuário' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados pessoais' align='left'>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            label='* Nome completo:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ ...this.state, nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            label='* E-mail:'
                                            type='email'
                                            value={this.state.email}
                                            onChange={value => this.setState({ ...this.state, email: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='* Usuário:'
                                            type='text'
                                            value={this.state.usuario}
                                            onChange={value => this.setState({ ...this.state, usuario: value })}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Senha:'
                                            type='password'
                                            value={this.state.senha}
                                            onChange={value => this.setState({ ...this.state, senha: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='* CPF:'
                                            type='text'
                                            mask='999.999.999-99'
                                            value={this.state.cpf}
                                            onChange={e => this.setState({ ...this.state, cpf: e.target.value })}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Telefone fixo:'
                                            type='text'
                                            mask='(99) 9999-9999'
                                            value={this.state.telefone}
                                            onChange={e => this.setState({ ...this.state, telefone: e.target.value })}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Celular:'
                                            type='text'
                                            mask='(99) 99999-9999'
                                            value={this.state.celular}
                                            onChange={e => this.setState({ ...this.state, celular: e.target.value })}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SimpleSelect
                                            label='* Tipo Usuario:'
                                            value={this.state.pessoaTipo}
                                            onChange={e => this.setState({ ...this.state, pessoaTipo: e.target.value })}
                                            required
                                        >
                                            <option></option>
                                            {this.props.tiposPessoa.map((tipo, index) => (
                                                <option key={index} value={tipo.nome}>{tipo.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {this.state.pessoaTipo === 'FUNCIONARIO' &&
                                            <SimpleSelect
                                                label='* Cargo:'
                                                value={this.state.cargo}
                                                onChange={e => this.setState({ ...this.state, cargo: e.target.value })}
                                                required
                                            >

                                                <option></option>
                                                {this.props.cargos.map((cargo, index) => (
                                                    <option key={index} value={cargo.nome}>{cargo.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        }

                                        {this.state.pessoaTipo === 'MOTORISTA' &&
                                            <TextInputMask
                                                label='* CNH:'
                                                type='text'
                                                mask='99999999999'
                                                value={this.state.cnh}
                                                onChange={e => this.setState({ ...this.state, cnh: e.target.value })}
                                                required
                                            />
                                        }

                                        {this.state.pessoaTipo === 'NUTRICIONISTA' &&
                                            <TextInputMask
                                                label='* CRN'
                                                type='text'
                                                mask='CRN-99/9999'
                                                value={this.state.crn}
                                                onChange={e => this.setState({ ...this.state, crn: e.target.value })}
                                                required
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Vínculos e permissões' align='left'>
                                <Row>
                                    <Col md={6}>
                                        <Accordion>
                                            <Card>
                                                <Card.Header className={styles.cardHeader} title='Clique para abrir as Unidades/Estoques'>
                                                    <Accordion.DataToggle target='estoques'>
                                                        <h6 className=''>
                                                            <b>Vinculo com Unidades Educacionais/Estoques</b>
                                                        </h6>
                                                    </Accordion.DataToggle>
                                                </Card.Header>

                                                <Accordion.DataTarget receiver='estoques'>
                                                    <Card.Body>
                                                        <SimpleTable>
                                                            <thead>
                                                                <tr>
                                                                    <th><Form.Check type='checkbox' checked={this.state.selecionarTodosEstoques} onChange={this.selecionarTodosEstoques} /></th>
                                                                    <th>Unidades/Estoques</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.estoques.map((estoque, index) => (
                                                                    <tr key={index}>
                                                                        <td><Form.Check type='checkbox' checked={estoque.checked} onChange={() => this.selecionarEstoque(index)} /></td>
                                                                        <td>{estoque.nome}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </SimpleTable>
                                                    </Card.Body>
                                                </Accordion.DataTarget>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                    <Col md={6}>
                                        <Accordion>
                                            <Card>
                                                <Card.Header className={styles.cardHeader} title='Clique para abrir as Permissões'>
                                                    <Accordion.DataToggle target='permissoes'>
                                                        <h6 className=''>
                                                            <b>Permissões</b>
                                                        </h6>
                                                    </Accordion.DataToggle>
                                                </Card.Header>

                                                <Accordion.DataTarget receiver='permissoes'>
                                                    <Card.Body>
                                                        <SimpleTable>
                                                            <thead>
                                                                <tr>
                                                                    <th><Form.Check type='checkbox' checked={this.state.selecionarTodasPermissoes} onChange={this.selecionarTodasPermissoes} /></th>
                                                                    <th>Permissão</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.permissoes.map((permissao, index) => (
                                                                    <tr key={index}>
                                                                        <td><Form.Check type='checkbox' checked={permissao.checked} onChange={() => this.selecionarPermissao(index)} /></td>
                                                                        <td>{permissao.nome}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </SimpleTable>
                                                    </Card.Body>
                                                </Accordion.DataTarget>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    estoques: state.estoque.list,
    tiposPermissoes: state.convencao.tiposPermissoes,
    tiposPessoa: state.convencao.tiposPessoa,
    cargos: state.convencao.cargos
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarUsuario,
    listarEstoques,
    listarTiposPermissoes,
    listarTiposPessoa,
    listarCargos
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarUsuarioPage), 'USUARIOS');