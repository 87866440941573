import React from 'react';

import styles from './SimpleInput.module.css';

class SimpleInput extends React.Component {
    handleChange = e => {
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        let value = input.value;

        if(this.props.case === 'upper') value = value.toUpperCase();

        this.props.onChange(value, () => input.setSelectionRange(start, end));
    }

    render() {
        return (
            <input
                {...this.props}
                className={styles.input}
                type={this.props.type}
                value={this.props.value}
                onChange={this.handleChange}
                style={this.props.style}
            />
        )
    }
}

export default SimpleInput;