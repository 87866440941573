import React from 'react';
import { Form } from 'react-bootstrap';

import styles from './Textarea.module.css';

class Textarea extends React.Component {
    handleChange = e => {
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        let value = input.value.toUpperCase();

        this.props.onChange(value, () => {
            input.setSelectionRange(start, end);
        })
    }

    render() {
        return (
            <div className='mb-3'>
                <div className={styles.label}>
                    <div>
                        {this.props.label}
                    </div>
                </div>
                <Form.Control
                    {...this.props}
                    className={styles.textarea}
                    as='textarea'
                    onChange={this.handleChange}
                    styles={this.props.style}
                />
            </div>
        )
    }
}

export default Textarea;