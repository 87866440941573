import React from 'react';
import SimpleButton from '../../../components/SimpleButton/index';

const BackButton = () => (
    <div className='row text-left'>
        <div className='col'>
            <SimpleButton
                onClick={() => {
                    window.history.go(-1);
                }}
                type='button'
                color='blue'
                name='Voltar'
                icon='fas fa-chevron-left'
            />
        </div>
    </div>
)

export default BackButton;