import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import styles from './SimpleSelect.module.css';

const SimpleSelect = props => (
    <InputGroup className='mb-3'>
        <InputGroup.Prepend>
            <InputGroup.Text className={styles.label}>{props.label}</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
            {...props}
            className={styles.select}
            as='select'
            value={props.value}>

            {props.children}
        </Form.Control>
    </InputGroup>
)

export default SimpleSelect;