import React from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import SubItem from './SubItem';

import styles from './HomeItemOverlay.module.css';

class HomeItemOverlay extends React.Component {
    state = {
        show: false
    }

    render() {
        return (
            <div
                onClick={e => this.setState({ show: !this.state.show, target: e.target })}
                onMouseLeave={e => this.setState({ show: false })}>

                <div>
                    {this.props.children}
                </div>

                <Overlay 
                    show={this.state.show}
                    target={this.state.target}
                    placement='bottom'>
                    <Popover 
                        bsPrefix={this.props.isInModal ? styles.homeItemOverlayPopoverOver : styles.homeItemOverlayPopover}>

                        {this.props.subItems.map((item, index) => (
                            <SubItem 
                                key={index} 
                                path={item.path} 
                                onClick={() => this.setState({ show: false })} 
                                icon={item.icon} 
                                name={item.name} />
                        ))}
                    </Popover>
                </Overlay>
            </div>
        )
    }
}

export default HomeItemOverlay;