import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { LISTAR_ADITIVOS, CADASTRAR_ADITIVO, EDITAR_ADITIVO, SET_AQUISICAO_ADITIVO } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function setAquisicaoAditivo(aquisicao) {
    return { type: SET_AQUISICAO_ADITIVO, payload: aquisicao }
}

export function cadastrarAditivo(aditivo, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/aditivos`, aditivo, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: CADASTRAR_ADITIVO, payload: {} },
                            listarAditivos(aditivo.aquisicao.id, token)
                        ])
                        toastr.success('Sucesso', 'Aditivo cadastrado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente adicionar o Aditivo nessa Aquisição?' />
            )
        })
    }
}

export function listarAditivos(idAquisicao, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/aquisicoes/${idAquisicao}/aditivos`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent({ type: LISTAR_ADITIVOS, payload: res.data })
            })
    }
}

export function editarAdivito(aditivo, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/aditivos/${aditivo.id}`, aditivo, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: EDITAR_ADITIVO, payload: {} },
                            listarAditivos(aditivo.aquisicao.id, token)
                        ])
                        toastr.success('Sucesso', 'Aditivo atualizado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar os Aditivos dessa Aquisição?' />
            )
        })
    }
}


export function excluirAditivo(idAquisicao, idAditivo, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/aditivos/${idAditivo}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatch(listarAditivos(idAquisicao, token))
                        toastr.success('Sucesso', 'Aditivo excluído com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarAditivos(idAquisicao, token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Aditivo?' />
            )
        })
    }
}

export function limparAditivos() {
    return { type: LISTAR_ADITIVOS, payload: [] }
}