import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import SimpleTable from '../../components/SimpleTable/index';
import PageTitle from '../../components/PageTitle/index';
import SimpleModal from '../../components/SimpleModal/index';
import ActionButton from '../../components/ActionButton/index';
import Fieldset from '../../components/Fieldset';
import Textarea from '../../components/Textarea/index';
import Input from '../../components/Input';
import MoneyInputMask from '../../components/MoneyInputMask/index';
import { MoneyFormat } from '../../utils/moneyFormat';
import SimpleButton from '../../components/SimpleButton/index';
import SimpleSelect from '../../components/SimpleSelect';
import TinyAction from '../../components/TinyAction';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarAquisicoes,
    editarAquisicao,
    cancelarAquisicao,
    buscarMotivoCancelamentoAquisicao,
    filtrarAquisicoes,
    excluirAquisicao,
    listarFornecedores,
    listarProdutos,
    listarModalidadesLicitacao,
    listarTiposLicitacao,
    listarStatusAquisicao,
    listarUnidadesProduto,
    setAquisicaoAditivo,
    atualizarNotificarAquisicao
} from '../../actions'

// import { gerarRelatorio } from '../../relatorios/coreGenerator.relatorio';

import styles from './GerenciarAquisicoes.module.css';

import moment from 'moment';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';

import withAuthorization from '../../components/HighOrder/withAuthorization';

class GerenciarAquisicoesPage extends Component {
    state = {
        showModal: false,
        showModalEdit: false,
        showModalNotifica: false,
        aquisicao: {
            unidadeEducacional: {},
            tipoDeLicitacao: {},
            modalidadeLicitacao: {},
            valorParcial: 0,
            propostasVencedoras: [],
            notificacao: {},
        },

        showModalCancelamento: false,
        motivo: '',
        idAquisicao: 0,
        showModalMotivo: false,

        showModalAditivos: false,

        showModalRelatorioIndividual: false,

        pesquisaNumero: '',

        valorParcialAtual: 0,

        valorAbaixo:0,
    }

    componentDidMount() {
        this.props.listarAquisicoes(this.props.token)

        this.props.listarFornecedores(this.props.token)
        // this.props.listarItens(this.props.token)
        this.props.listarProdutos(this.props.token);
        this.props.listarModalidadesLicitacao(this.props.token);
        this.props.listarTiposLicitacao(this.props.token);
        this.props.listarStatusAquisicao(this.props.token);
        this.props.listarUnidadesProduto(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalNumbers < nextProps.closeModalNumbers) this.setState({ showModalEdit: false, showModalCancelamento: false, showModalNotifica: false })
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleShowModal = aq => {
        let aquisicao = JSON.parse(JSON.stringify(aq))
        aquisicao.propostasVencedoras = this.calcValorItem(aquisicao.propostasVencedoras)

        this.setState({ aquisicao, showModal: true }, () => this.calcValorParcial(aquisicao.propostasVencedoras))
    }

    handleShowModalNotificar = aq => {
        let aquisicao = JSON.parse(JSON.stringify(aq))
        let valor= aquisicao.notificacao !== null ? aquisicao.notificacao.valorAbaixo : 0
        this.setState({ aquisicao, valorAbaixo: valor, showModalNotifica: true })
    }

    handleShowModalEdit = aq => {
        let aquisicao = JSON.parse(JSON.stringify(aq))

        aquisicao.propostasVencedoras = this.calcValorItem(aquisicao.propostasVencedoras)

        aquisicao.dataContrato = moment(aquisicao.dataContrato, 'DD/MM/YYYY').format('YYYY-MM-DD');
        aquisicao.vigencia = moment(aquisicao.vigencia, 'DD/MM/YYYY').format('YYYY-MM-DD');

        this.setState({ aquisicao, showModalEdit: true }, () => this.calcValorParcial(aquisicao.propostasVencedoras))
    }

    handleCloseModalCancelar = () => {
        this.setState({ ...this.state, motivo: '', showModalCancelamento: false })
    }

    handleShowModalCancelar = idAquisicao => {
        this.setState({ ...this.state, idAquisicao, showModalCancelamento: true })
    }

    handleCloseModalMotivo = () => {
        this.setState({ ...this.state, showModalMotivo: false })
    }

    handleShowModalMotivo = idAquisicao => {
        this.setState({ ...this.state, showModalMotivo: true })
        this.props.buscarMotivoCancelamentoAquisicao(idAquisicao, this.props.token)
    }

    handleShowModalRelatorioIndividual = aquisicao => {
        let aquisicaoCopy = JSON.parse(JSON.stringify(aquisicao))
        aquisicaoCopy.propostasVencedoras = this.calcValorItem(aquisicaoCopy.propostasVencedoras)

        this.setState({ aquisicao: aquisicaoCopy, showModalRelatorioIndividual: true }, () => this.calcValorParcial(aquisicaoCopy.propostasVencedoras))
    }

    mostrarPaginaAditivos = idAquisicao => {
        // this.props.setAquisicaoAditivo(aquisicao);
        this.props.history.push(`${routes.GERENCIAR_AQUISICOES}/${idAquisicao}${routes.ADITIVOS}`);
    }

    mostrarPaginaRelatorio = idAquisicao => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_AQUISICAO}/${idAquisicao}`);
        // window.open(`${window.location.origin}${routes.RELATORIO}/${reports.RELATORIO_AQUISICAO}/${idAquisicao}`, '_blank')
    }

    handleAddProposta = index => {
        let proposta = { fornecedor: 0, numeroContrato: '', itens: [{ produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0, valorItem: 0 }] }

        let aquisicao = this.state.aquisicao;
        let propostasVencedoras = aquisicao.propostasVencedoras;
        propostasVencedoras.splice(index + 1, 0, proposta);

        aquisicao.propostasVencedoras = propostasVencedoras;

        this.setState({ ...this.state, aquisicao })
    }

    handleRemoveProposta = index => {
        let aquisicao = this.state.aquisicao;
        let propostasVencedoras = aquisicao.propostasVencedoras;
        if (propostasVencedoras.length > 1) {
            propostasVencedoras.splice(index, 1);

            aquisicao.propostasVencedoras = propostasVencedoras;

            this.setState({ ...this.state, aquisicao }, () => this.calcValorParcial(aquisicao.propostasVencedoras))
        }
    }

    handleAddItem = (propostaIndex, itemIndex) => {
        let item = { produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0, valorItem: 0 }

        let aquisicao = this.state.aquisicao;
        let propostasVencedoras = aquisicao.propostasVencedoras;
        propostasVencedoras[propostaIndex].itens.splice(itemIndex + 1, 0, item);

        aquisicao.propostasVencedoras = propostasVencedoras;

        this.setState({ ...this.state, aquisicao })
    }

    handleRemoveItem = (propostaIndex, itemIndex) => {
        let aquisicao = this.state.aquisicao;
        let propostasVencedoras = aquisicao.propostasVencedoras;
        if (propostasVencedoras[propostaIndex].itens.length > 1) {
            propostasVencedoras[propostaIndex].itens.splice(itemIndex, 1);

            aquisicao.propostasVencedoras = propostasVencedoras;

            this.setState({ ...this.state, aquisicao }, () => this.calcValorParcial(aquisicao.propostasVencedoras))
        }
    }

    handleAquisicaoInput = (value, property, upper = () => { }) => {
        let aquisicao = JSON.parse(JSON.stringify(this.state.aquisicao));
        aquisicao[property] = value;

        this.setState({ aquisicao }, () => upper());
    }

    handlePropostasInput = (value, index, property) => {
        let aquisicao = this.state.aquisicao;
        let propostasVencedoras = aquisicao.propostasVencedoras;
        propostasVencedoras[index][property] = value;

        aquisicao.propostasVencedoras = propostasVencedoras;

        this.setState({ ...this.state, aquisicao })
    }

    handleItensInput = (value, propostaIndex, itemIndex, property, upper = () => { }) => {
        let aquisicao = this.state.aquisicao;
        let propostasVencedoras = aquisicao.propostasVencedoras;
        propostasVencedoras[propostaIndex].itens[itemIndex][property] = value;

        if (property === 'quantidade' || property === 'precoUnidade') {
            let quantidade = propostasVencedoras[propostaIndex].itens[itemIndex].quantidade;

            let precoUnidade = propostasVencedoras[propostaIndex].itens[itemIndex].precoUnidade;

            propostasVencedoras[propostaIndex].itens[itemIndex]['valorItem'] = quantidade * precoUnidade;
        }

        aquisicao.propostasVencedoras = propostasVencedoras;

        this.setState({ ...this.state, aquisicao }, () => {
            this.calcValorParcial(propostasVencedoras)
            upper();
        })
    }

    calcValorItem = propostas => {
        for (let i = 0; i < propostas.length; i++) {
            propostas[i].itens = propostas[i]
                .itens.map(item => ({ ...item, valorItem: item.quantidade * item.precoUnidade }))
        }

        return propostas;
    }

    calcValorParcial = propostas => {
        let valorParcial = 0;

        for (let i = 0; i < propostas.length; i++) {
            let valorSoma = propostas[i]
                .itens.map(item => item.valorItem)
                .reduce((soma, valorAtual) => soma + valorAtual);

            valorParcial += valorSoma;
        }

        this.setState({ ...this.state, valorParcialAtual: valorParcial })
    }

    handleValueSubmit = e => {
        e.preventDefault();
        let aquisicao = JSON.parse(JSON.stringify(this.state.aquisicao))
        this.props.atualizarNotificarAquisicao(aquisicao,  this.state.valorAbaixo, this.props.token);
    }


    handleEditSubmit = e => {
        e.preventDefault();

        let aquisicao = JSON.parse(JSON.stringify(this.state.aquisicao))

        aquisicao.unidadeEducacional = aquisicao.unidadeEducacional.id || aquisicao.unidadeEducacional;

        // Culpa de Juan 2x
        aquisicao.propostasVencedoras.forEach((proposta, index) => {
            let { id, fornecedorTipo } = this.props.fornecedores.find(fornecedor => fornecedor.id === (proposta.fornecedor.id || proposta.fornecedor));
            aquisicao.propostasVencedoras[index].fornecedor = { id, fornecedorTipo }

            proposta.itens.forEach((obj, i) => {
                aquisicao.propostasVencedoras[index].itens[i].produto = this.props.produtos.find(produto => produto.id === (obj.produto.id || obj.produto))
                // aquisicao.propostasVencedoras[index].itens[i].produto.item.categoria = aquisicao.propostasVencedoras[index].itens[i].produto.item.categoria;
            })
        })

        aquisicao.dataContrato = moment(aquisicao.dataContrato, 'YYYY-MM-DD').format('DD/MM/YYYY')
        aquisicao.vigencia = moment(aquisicao.vigencia, 'YYYY-MM-DD').format('DD/MM/YYYY')

        aquisicao.propostasVencedoras = aquisicao.propostasVencedoras.map(proposta => {
            let valorProposta = proposta.itens
                .map(item => item.quantidade * item.precoUnidade)
                .reduce((soma, atual) => soma + atual)

            return { ...proposta, valor: valorProposta }
        })

        aquisicao.notificacao = null;
        
        this.props.editarAquisicao(aquisicao, this.props.token);
    }

    render() {
        let propostasForm =
            (
                <Row style={{ marginTop: '16px' }}>
                    <Col>
                        <Fieldset legend='Propostas vencedoras' align='left'>
                            {this.state.aquisicao.propostasVencedoras.map((proposta, propostaIndex) => (
                                <div className={styles.propostas} key={propostaIndex}>
                                    <Row style={{ marginBottom: '16px' }}>
                                        <Col align='center'>
                                            <SimpleButton type='button' name='Fornecedor' color='blue' />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label={`Fornecedor: ${propostaIndex + 1}`}
                                                type='text'
                                                disabled
                                                value={proposta.fornecedor.nomeFantasia || proposta.fornecedor.nome} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='Número Contrato:'
                                                type='text'
                                                disabled
                                                value={proposta.numeroContrato}
                                            />
                                        </Col>
                                    </Row>

                                    <SimpleButton type='button' name='Itens' color='blue' />

                                    {proposta.itens.map((item, itemIndex) => (
                                        <div className={styles.itens} key={itemIndex}>
                                            <Row>
                                                <Col md={12}>
                                                    <Input
                                                        label={`${itemIndex + 1}`}
                                                        type='text'
                                                        disabled
                                                        value={item.produto.nome} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Input
                                                        label='Descrição:'
                                                        type='text'
                                                        disabled
                                                        value={item.descricao} />
                                                </Col>
                                                <Col md={6}>
                                                    <Input
                                                        label='Quantidade:'
                                                        type='number'
                                                        disabled
                                                        value={item.quantidade} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Input
                                                        label='Unidade Medida:'
                                                        type='text'
                                                        disabled
                                                        value={item.unidadeProduto} />
                                                </Col>
                                                <Col md={6}>
                                                    <MoneyInputMask
                                                        label='Preço Unidade:'
                                                        disabled
                                                        value={item.precoUnidade} />

                                                    <span className={styles.valorTotalItem}>
                                                        Valor total do item: <CurrencyFormat value={item.valorItem} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                    </span>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Fieldset>
                    </Col>
                </Row>
            );

        let propostasEditForm =
            (
                <Row style={{ marginTop: '16px' }}>
                    <Col>
                        <Fieldset legend='Propostas vencedoras' align='left'>
                            {this.state.aquisicao.propostasVencedoras.map((proposta, propostaIndex) => (
                                <div className={styles.propostas} key={propostaIndex}>
                                    <Row style={{ marginBottom: '16px' }}>
                                        <Col align='center'>
                                            <SimpleButton type='button' name='Fornecedor' color='blue' />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={10}>
                                            <SimpleSelect
                                                label={`* Fornecedor: ${propostaIndex + 1}`}
                                                type='text'
                                                value={proposta.fornecedor.id}
                                                onChange={e => this.handlePropostasInput(parseInt(e.target.value), propostaIndex, 'fornecedor')}
                                                required
                                            >
                                                <option></option>
                                                {this.props.fornecedores.map(fornecedor =>
                                                    <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.nomeFantasia || fornecedor.nome}</option>
                                                )}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={2}>
                                            <Row>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        title='Adicionar novo fornecedor'
                                                        onClick={() => this.handleAddProposta(propostaIndex)}
                                                        color='green'
                                                        icon='fas fa-plus'
                                                    />
                                                </Col>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        title='Remover fornecedor'
                                                        onClick={() => this.handleRemoveProposta(propostaIndex)}
                                                        color='red'
                                                        icon='fas fa-minus'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Número Contrato:'
                                                type='text'
                                                onChange={value => this.handlePropostasInput(value, propostaIndex, 'numeroContrato')}
                                                value={proposta.numeroContrato}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <SimpleButton type='button' name='Itens' color='blue' />

                                    {proposta.itens.map((item, itemIndex) => (
                                        <div className={styles.itens} key={itemIndex}>
                                            <Row>
                                                <Col md={10}>
                                                    <SimpleSelect
                                                        label={`* ${itemIndex + 1}`}
                                                        type='text'
                                                        value={item.produto.id}
                                                        onChange={e => this.handleItensInput(parseInt(e.target.value), propostaIndex, itemIndex, 'produto')}
                                                        required
                                                    >
                                                        <option></option>
                                                        {this.props.produtos.map(produto =>
                                                            <option key={produto.id} value={produto.id}>{produto.nome}</option>
                                                        )}
                                                    </SimpleSelect>
                                                </Col>
                                                <Col md={2}>
                                                    <Row>
                                                        <Col align='center'>
                                                            <TinyAction
                                                                type='button'
                                                                title='Adicionar novo produto'
                                                                onClick={() => this.handleAddItem(propostaIndex, itemIndex)}
                                                                color='green'
                                                                icon='fas fa-plus'
                                                            />
                                                        </Col>
                                                        <Col align='center'>
                                                            <TinyAction
                                                                type='button'
                                                                title='Remover produto'
                                                                onClick={() => this.handleRemoveItem(propostaIndex, itemIndex)}
                                                                color='red'
                                                                icon='fas fa-minus'
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Input
                                                        label='Descrição:'
                                                        type='text'
                                                        case='upper'
                                                        value={item.descricao}
                                                        onChange={(value, upper) => this.handleItensInput(value, propostaIndex, itemIndex, 'descricao', upper)}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Input
                                                        label='* Quantidade:'
                                                        type='number'
                                                        value={item.quantidade}
                                                        onChange={value => this.handleItensInput(value, propostaIndex, itemIndex, 'quantidade')}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <SimpleSelect
                                                        label='* Unidade Medida:'
                                                        value={item.unidadeProduto}
                                                        onChange={e => this.handleItensInput(e.target.value, propostaIndex, itemIndex, 'unidadeProduto')}
                                                        required
                                                    >
                                                        <option></option>
                                                        {this.props.unidadesProduto.map((unidade, index) => (
                                                            <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                                        ))}
                                                    </SimpleSelect>
                                                </Col>
                                                <Col md={6}>
                                                    <MoneyInputMask
                                                        label='* Preço Unidade:'
                                                        value={item.precoUnidade}
                                                        onChangeEvent={(e, maskedValue, floatValue) => this.handleItensInput(floatValue, propostaIndex, itemIndex, 'precoUnidade')}
                                                    />
                                                    <span className={styles.valorTotalItem}>
                                                        Valor total do item: <CurrencyFormat value={item.valorItem} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                    </span>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Fieldset>
                    </Col>
                </Row>
            );


        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Licitação/Contrato' hasDivider={true} />
                </Row>

                <Form>
                    <Form.Group>
                        <Form.Label>Pesquisar por Número da Aquisição:</Form.Label>
                        <Row>
                            <Col lg={12}>
                                <Form.Control type='text'
                                    value={this.state.pesquisaNumero}
                                    onChange={e => this.setState({ ...this.state, pesquisaNumero: e.target.value }, () => this.props.filtrarAquisicoes(this.state.pesquisaNumero))} />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Número</th>
                            {/* <th>Processo Administrativo</th> */}
                            {/* <th>Tipo</th> */}
                            {/* <th>Ano Licitação</th> */}
                            <th>Modalidade</th>
                            <th>Data Contrato</th>
                            {/* <th>Número Contrato</th> */}
                            {/* <th>Tipo</th> */}
                            <th>Vigência</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(aquisicao => (
                            <tr className={aquisicao.status === 'CANCELADA' ? styles.cancel : ''} key={aquisicao.id}>
                                <td>{aquisicao.numero}</td>
                                {/* <td>{aquisicao.numeroProcessoAdminis}</td> */}
                                {/* <td>{aquisicao.aquisicaoTipo}</td> */}
                                {/* <td>{licitacao.ano}</td> */}
                                <td>{aquisicao.modalidadeLicitacao}</td>
                                <td>{aquisicao.dataContrato}</td>
                                {/* <td>{licitacao.numeroContrato}</td> */}
                                {/* <td>{licitacao.tipoDeLicitacao}</td> */}
                                <td>{aquisicao.vigencia}</td>
                                <td>{MoneyFormat(aquisicao.valor)}</td>
                                <td className={aquisicao.status ==='VIGENTE' ? styles.vigente : styles.cancelada}>
                                    {aquisicao.status !== 'CANCELADA' &&
                                    <>
                                        {aquisicao.status}
                                    </>
                                    }
                                    {
                                        aquisicao.status === 'CANCELADA'
                                        &&
                                        <>
                                            <div
                                                className={styles.motivo}
                                                onClick={e => this.handleShowModalMotivo(aquisicao.id)}>
                                                <span>?</span>
                                            </div>
                                        </>
                                    }
                                </td>

                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Licitação/Contrato'
                                        onClick={() => this.handleShowModal(aquisicao)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Imprimir Relatório de Licitação/Contrato'
                                        onClick={() => this.mostrarPaginaRelatorio(aquisicao.id)}
                                        icon='fas fa-print'
                                    />

                                    <ActionButton
                                        title='Editar Licitação/Contrato'
                                        disabled={aquisicao.status === 'CANCELADA' || aquisicao.status === 'VIGENTE'}
                                        onClick={() => this.handleShowModalEdit(aquisicao)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Gerenciar Licitação/Contrato'
                                        //disabled={aquisicao.status !== 'VIGENTE'}
                                        onClick={() => this.mostrarPaginaAditivos(aquisicao.id)}
                                        icon='far fa-file-alt'
                                    />

                                    <ActionButton
                                        title='Cancelar Licitação/Contrato'
                                        disabled={aquisicao.status === 'CANCELADA'}
                                        onClick={() => this.handleShowModalCancelar(aquisicao.id)}
                                        icon='fas fa-ban'
                                    />

                                    <ActionButton
                                        title='Excluir Licitação/Contrato'
                                        disabled={aquisicao.status === 'VIGENTE'}
                                        onClick={() => this.props.excluirAquisicao(aquisicao.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />

                                    <ActionButton
                                        title='Configurar notificação de Licitação/Contrato'
                                        onClick={() => this.handleShowModalNotificar(aquisicao)}
                                        icon='fas fa-bell'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Detalhes'
                    icon='fas fa-eye'>

                    {/* <Row align='right'>
                        <Col>
                            <SimpleButton color='green' name='Imprirmir' icon='fas fa-copy' onClick={() => gerarRelatorio(document.getElementById('relatorioAquisicao'))} />
                        </Col>
                    </Row> */}

                    <Form className='printable' id='relatorioAquisicao'>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados da aquisição' align='left'>
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label='Dono da Aquisição:'
                                                type='text'
                                                value={this.state.aquisicao.unidadeEducacional.nome}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Textarea
                                                label='Objeto:'
                                                rows={3}
                                                value={this.state.aquisicao.objeto}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='Número:'
                                                type='text'
                                                value={this.state.aquisicao.numero}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Nº Processo Administrativo:'
                                                type='text'
                                                value={this.state.aquisicao.numeroProcessoAdminis}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='Data Contrato:'
                                                type='text'
                                                disabled
                                                value={this.state.aquisicao.dataContrato}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Vigência:'
                                                type='text' disabled
                                                value={this.state.aquisicao.vigencia}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label='Ano:'
                                                type='text'
                                                value={this.state.aquisicao.ano}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6}>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='Tipo de Aquisição:'
                                                value={this.state.aquisicao.aquisicaoTipo}
                                                disabled>
                                                <option></option>
                                                <option value='LICITACAO'>LICITAÇÃO</option>
                                                <option value='CHAMADA'>CHAMADA PÚBLICA</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    {
                                        this.state.aquisicao.aquisicaoTipo === 'LICITACAO' ?
                                            (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <Input
                                                                label='Tipo de Licitação:'
                                                                type='text'
                                                                disabled
                                                                value={this.state.aquisicao.tipoDeLicitacao}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Input
                                                                label='Modalidade de Licitacao:'
                                                                type='text'
                                                                disabled
                                                                value={this.state.aquisicao.modalidadeLicitacao}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                            :
                                            (
                                                <Row>
                                                    <Col md={12}>
                                                        <Input
                                                            label='Fonte de recurso:'
                                                            type='text'
                                                            value={this.state.aquisicao.fonteRecurso}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                    }

                                    <Row>
                                        <Col>
                                            <MoneyInputMask
                                                label='Valor da aquisição:'
                                                disabled
                                                value={this.state.aquisicao.valor}
                                            />
                                            <span style={{ color: this.state.valorParcialAtual !== this.state.aquisicao.valor ? 'red' : 'green' }}>
                                                <b>Valor parcial:</b> <CurrencyFormat value={this.state.valorParcialAtual} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> {this.state.valorParcialAtual !== this.state.aquisicao.valor && '(O valor parcial é diferente do valor total da Aquisição)'}
                                            </span>
                                        </Col>
                                        {
                                            this.state.aquisicao.status !== 'PENDENTE'
                                            &&
                                            <Col>
                                                <MoneyInputMask
                                                    label='VALOR DISPONÍVEL:'
                                                    disabled
                                                    value={this.state.aquisicao.valorDisponivel}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        {propostasForm}
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalEdit}
                    onHide={() => this.setState({ showModalEdit: false })}
                    title='Editar Licitação/Contrato'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados da aquisição' align='left'>
                                    <Row>
                                        <Col md={12}>
                                            <SimpleSelect
                                                label='* Dono da Aquisição:'
                                                value={this.state.aquisicao.unidadeEducacional.id}
                                                onChange={e => this.handleAquisicaoInput(e.target.value, 'unidadeEducacional')}
                                                required>
                                                <option value=''></option>
                                                {this.props.unidadesUsuario.map((unidade, index) => (
                                                    <option key={index} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Textarea
                                                label='Objeto:'
                                                rows={3}
                                                value={this.state.aquisicao.objeto}
                                                onChange={(value, upper) => this.handleAquisicaoInput(value, 'objeto', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='Número:'
                                                type='text'
                                                value={this.state.aquisicao.numero}
                                                onChange={value => this.handleAquisicaoInput(value, 'numero')}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Nº Processo Administrativo:'
                                                type='text'
                                                value={this.state.aquisicao.numeroProcessoAdminis}
                                                onChange={value => this.handleAquisicaoInput(value, 'numeroProcessoAdminis')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='Data Contrato:'
                                                type='date'
                                                value={this.state.aquisicao.dataContrato}
                                                onChange={value => this.handleAquisicaoInput(value, 'dataContrato')}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Vigência:'
                                                type='date'
                                                value={this.state.aquisicao.vigencia}
                                                onChange={value => this.handleAquisicaoInput(value, 'vigencia')}
                                                min= {new Date().toISOString().split("T")[0]}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label='Ano:'
                                                type='text'
                                                value={this.state.aquisicao.ano}
                                                onChange={value => this.handleAquisicaoInput(value, 'ano')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='* Tipo de Aquisição:'
                                                value={this.state.aquisicao.aquisicaoTipo}
                                                onChange={e => this.handleAquisicaoInput(e.target.value, 'aquisicaoTipo')}
                                                disabled
                                                required>
                                                <option></option>
                                                <option value='LICITACAO'>LICITAÇÃO</option>
                                                <option value='CHAMADA'>CHAMADA PÚBLICA</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    {
                                        this.state.aquisicao.aquisicaoTipo === 'LICITACAO' ?
                                            (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <SimpleSelect
                                                                label='Tipo de Licitação:'
                                                                value={this.state.aquisicao.tipoDeLicitacao}
                                                                onChange={e => this.handleAquisicaoInput(e.target.value, 'tipoDeLicitacao')}
                                                                required
                                                            >

                                                                {this.props.tipos.map((tipo, index) => (
                                                                    <option key={index} value={tipo.nome}>{tipo.descricao}</option>
                                                                ))}
                                                            </SimpleSelect>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <SimpleSelect
                                                                label='Modalidade de Licitacao:'
                                                                value={this.state.aquisicao.modalidadeLicitacao}
                                                                onChange={e => this.handleAquisicaoInput(e.target.value, 'modalidadeLicitacao')}
                                                                required
                                                            >
                                                                {this.props.modalidades.map((modalidade, index) => (
                                                                    <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                                                ))}
                                                            </SimpleSelect>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                            :
                                            (
                                                <Row>
                                                    <Col md={12}>
                                                        <Input
                                                            label='* Fonte de recurso:'
                                                            type='text'
                                                            case='upper'
                                                            value={this.state.aquisicao.fonteRecurso}
                                                            onChange={(value, upper) => this.handleAquisicaoInput(value, 'fonteRecurso', upper)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                    }

                                    <Row>
                                        <Col>
                                            <MoneyInputMask
                                                label='Valor da licitação:'
                                                value={this.state.aquisicao.valor}
                                                onChangeEvent={(e, maskValue, floatValue) => this.handleAquisicaoInput(floatValue, 'valor')}
                                            />

                                            <span style={{ color: this.state.valorParcialAtual !== this.state.aquisicao.valor ? 'red' : 'green' }}>
                                                <b>Valor parcial:</b> <CurrencyFormat value={this.state.valorParcialAtual} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> {this.state.valorParcialAtual !== this.state.aquisicao.valor && '(O valor parcial é diferente do valor total da Aquisição)'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        {propostasEditForm}

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ showModalEdit: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalCancelamento}
                    onHide={this.handleCloseModalCancelar}
                    title='Cancelar Aquisição'
                    icon='fas fa-ban'>

                    {/* <Form> */}
                    <Row>
                        <Col>
                            <Textarea
                                label='* Motivo:'
                                rows='3'
                                value={this.state.motivo}
                                onChange={(value, upper) => this.setState({ ...this.state, motivo: value }, () => upper())}
                                required
                            />
                        </Col>
                    </Row>
                    {/* </Form> */}

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='left'>
                            <SimpleButton
                                type='button'
                                color='red'
                                name='Fechar'
                                icon='fas fa-times'
                                onClick={this.handleCloseModalCancelar}
                            />
                        </Col>
                        <Col align='right'>
                            <SimpleButton
                                type='button'
                                color='green'
                                name='Cancelar'
                                icon='fas fa-ban'
                                onClick={e => this.props.cancelarAquisicao(this.state.idAquisicao, this.state.motivo, this.props.token)}
                            />
                        </Col>
                    </Row>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalMotivo}
                    onHide={this.handleCloseModalMotivo}
                    title='Motivo'
                    icon='fas fa-comment'>

                    <Row>
                        <Col align='center'>
                            <p>{this.props.motivoCancelamento.motivo}</p>
                        </Col>
                    </Row>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalRelatorioIndividual}
                    onHide={() => this.setState({ ...this.state, showModalRelatorioIndividual: false })}
                    title='Relatório de Aquisições'
                    icon='fas fa-copy'>



                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalNotifica}
                    onHide={() => this.setState({ ...this.state, showModalNotifica: false })}
                    title='Notificação'
                    icon='fas fa-bell'>

                    <Row>
                        <Col>
                            <Fieldset legend='Configurar Notificação' align='left'>
                                 <Form onSubmit={this.handleValueSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='Notificar quando valor estiver abaixo de (%):'
                                            type='number'
                                            max='100'
                                            min='0'
                                            step="1"
                                            value={this.state.valorAbaixo}
                                            onChange={value => this.setState({...this.state, valorAbaixo: value})}
                                            required
                                        >
                                        </Input>
                                    </Col>
                                    <Col>
                                    <SimpleButton type='submit' name='Salvar' color='green' style={{height: "36px"}}/>
                                    </Col>
                                </Row>
                                </Form>
                            </Fieldset>
                        </Col>
                    </Row>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    unidadesUsuario: state.auth.user.estoques,
    closeModalNumbers: state.aquisicao.closeModalNumbers,
    list: state.aquisicao.list,
    filtrados: state.aquisicao.filtrados,
    motivoCancelamento: state.aquisicao.motivoCancelamento,
    fornecedores: state.fornecedor.list,
    produtos: state.produto.list,
    modalidades: state.convencao.modalidadesLicitacao,
    tipos: state.convencao.tiposLicitacao,
    status: state.convencao.statusAquisicao,
    unidadesProduto: state.convencao.unidadesProduto,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarAquisicoes,
    editarAquisicao,
    cancelarAquisicao,
    buscarMotivoCancelamentoAquisicao,
    filtrarAquisicoes,
    excluirAquisicao,
    listarFornecedores,
    listarProdutos,
    listarModalidadesLicitacao,
    listarTiposLicitacao,
    listarStatusAquisicao,
    listarUnidadesProduto,
    setAquisicaoAditivo,
    atualizarNotificarAquisicao
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarAquisicoesPage)), 'AQUISICOES');