import React, { Component } from 'react'
import { Form, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleButton from '../../components/SimpleButton/index';
import Fieldset from '../../components/Fieldset';
import TinyAction from '../../components/TinyAction/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import Input from '../../components/Input/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarPrato, listarCategorias, listarPreparos, listarModalidades } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import { roundToTwo } from '../../utils/mathCalc';

// import styles from './CadastrarPrato.module.css';

const INITIAL_FORM = {
    nome: '',
    modalidade: '',
    categoria: -1,
    preparos: [{ preparo: -1 }],

    fichaTecnica: [
        { nome: '', unidadeDeMedida: '', valor: '' }
    ]
}

const INITIAL_PREPAROS = [{ preparo: -1 }]

const INITIAL_FICHA = [
    { nome: '', unidadeDeMedida: '', valor: '' }
]

class CadastrarPratoPage extends Component {
    state = {
        nome: '',
        modalidade: '',
        categoria: -1,
        preparos: [{ preparo: -1 }],

        fichaTecnica: [
            { nome: '', unidadeDeMedida: '', valor: '' }
        ]
    }

    componentDidMount() {
        this.props.listarCategorias(this.props.token)
        this.props.listarPreparos(this.props.token)
        this.props.listarModalidades(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.resetCount < nextProps.resetCount) this.setState({ ...INITIAL_FORM, preparos: JSON.parse(JSON.stringify(INITIAL_PREPAROS)), fichaTecnica: JSON.parse(JSON.stringify(INITIAL_FICHA)) })
    }

    calcularFichaTecnica = () => {
        let preparos = JSON.parse(JSON.stringify(this.state.preparos));
        let preparosFetch = this.props.preparosPrato;

        let componentesFichaTecnica = [];

        if (preparosFetch.length > 0) {
            let componentesPadroes = preparosFetch[0].nutrientes.map(nutriente => nutriente.nome);

            for (let i = 0; i < preparos.length; i++) {
                let preparo = preparos[i];

                let preparoCompleto = preparosFetch.find(p => p.id === preparo.preparo);
                if (!!preparoCompleto) {
                    componentesFichaTecnica.push(...preparoCompleto.nutrientes)
                }
            }

            let componentesFinais = [];

            for (let k = 0; k < componentesPadroes.length; k++) {
                let componentePadrao = componentesPadroes[k];
                if (componentesFichaTecnica.length > 0) {
                    let valorSoma = componentesFichaTecnica
                        .filter(componente => componente.nome === componentePadrao)
                        .map(componente => componente.valor)
                        .reduce((accumulator, currentValue) => accumulator + currentValue)

                    componentesFinais.push({ ...componentesFichaTecnica[k], valor: roundToTwo(valorSoma) })
                }
            }

            this.setState({ ...this.state, fichaTecnica: componentesFinais });
        }
    }

    handlePreparoInput = (value, index, property) => {
        let preparos = this.state.preparos;
        preparos[index][property] = value;

        this.setState({ ...this.state, preparos });
    }

    handleAddPreparo = index => {
        let preparo = { preparo: -1 }

        let preparos = this.state.preparos;
        preparos.splice(index + 1, 0, preparo);

        this.setState({ ...this.state, preparos });
    }

    handleRemovePreparo = index => {
        let preparos = this.state.preparos;
        if (preparos.length !== 1) {
            preparos.splice(index, 1);
        }

        this.setState({ ...this.state, preparos });
    }

    handleSubmit = e => {
        e.preventDefault();
        // console.log(this.state)
        this.props.cadastrarPrato(this.state, this.props.token);
    }

    render() {
        const popover = (
            <Popover id='fichaTecnicaPopover' title='Ficha Técnica do Prato'>
                {this.state.fichaTecnica.map((componente, index) => (
                    <p key={index}>
                        <b>{componente.nome}:</b> {componente.valor} ({componente.unidadeDeMedida})
                    </p>
                ))}
            </Popover>
        )

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Prato' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Row>
                    <OverlayTrigger trigger='click' placement='right' overlay={popover}>
                        <SimpleButton
                            type='button'
                            name='Ver ficha técnica atual'
                            color='blue'
                            icon='fas fa-clipboard'
                            onClick={this.calcularFichaTecnica}
                        />
                    </OverlayTrigger>
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Dados do prato' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            label='* Nome:'
                                            type='text'
                                            case='upper'
                                            value={this.state.nome}
                                            onChange={(value, upper) => this.setState({ nome: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Categoria:'
                                            value={this.state.categoria}
                                            onChange={e => this.setState({ categoria: parseInt(e.target.value) })}
                                            required
                                        >
                                            <option></option>
                                            {this.props.categorias.map(categoria => (
                                                <option key={categoria.id} value={categoria.id}>{categoria.modalidade} / {categoria.faixaEtaria.nome}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Modalidade:'
                                            value={this.state.modalidade}
                                            onChange={e => this.setState({ modalidade: e.target.value })}
                                            required
                                        >
                                            <option></option>
                                            {this.props.modalidades.map((modalidade, index) => (
                                                <option key={index} value={modalidade.nome}>{modalidade.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Preparos' align='left'>
                                {this.state.preparos.map((preparo, index) => (
                                    <Row key={index}>
                                        <Col md={10}>
                                            <SimpleSelect
                                                label='* Preparo'
                                                value={preparo.preparo}
                                                onChange={e => this.handlePreparoInput(parseInt(e.target.value), index, 'preparo')}
                                                required
                                            >
                                                <option></option>
                                                {this.props.preparosPrato.map(p => (
                                                    <option key={p.id} value={p.id}>{p.nome}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={2}>
                                            <Row>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        icon='fas fa-plus'
                                                        onClick={() => this.handleAddPreparo(index)}
                                                        title='Adicionar novo preparo'
                                                        color='green'
                                                    />
                                                </Col>
                                                <Col align='center'>
                                                    <TinyAction
                                                        type='button'
                                                        icon='fas fa-minus'
                                                        onClick={() => this.handleRemovePreparo(index)}
                                                        title='Remover preparo'
                                                        color='red'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetCount: state.prato.resetCount,
    categorias: state.convencao.categorias,
    preparosPrato: state.preparo.list,
    modalidades: state.convencao.modalidades
})

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarPrato,
    listarCategorias,
    listarPreparos,
    listarModalidades
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarPratoPage), 'PRATOS');