import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from '../../actions'

const LogoutLink = ({ logout }) => (
    <span style={{ color: '#0071BC', cursor: 'pointer' }} onClick={logout}>Sair</span>
)

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default connect(null, mapDispatchToProps)(LogoutLink)