import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listarPreparosId, listarAlimentos } from '../../actions';

import styles from './styles.module.css';
import { Row, Col } from 'react-bootstrap';

class Preparos extends Component {

    state = {
        preparos: {
            ingredientes: [],
            criador: {},
            nutrientes: []
        },
    }

    componentWillMount() {
        const data = this.props.data;
        this.props.listarPreparosId(data, this.props.token)
        this.props.listarAlimentos(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.preparos !== this.props.preparos) this.setState({ ...this.state, preparos: nextProps.preparos });
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name={`Relatório de Preparo - ${this.state.preparos.nome}`} />

                    <Detail>

                        <Row>
                            <Col>
                                <b>Criador: </b> {this.state.preparos.criador.nome}
                            </Col>
                            <Col>
                                <b>Porções: </b> {this.state.preparos.porcoes}
                            </Col>
                        </Row>

                        <table border='1' className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.titulo}>Ingrediente</th>
                                    <th className={styles.titulo}>Quantidade</th>
                                    <th className={styles.titulo}>Unidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.preparos.ingredientes.map((item, index) => (
                                    <tr key={index} >
                                        <td className={styles.titulo}>{this.props.alimentos.map(alimento => (alimento.id === item.alimento ? alimento.nome : ''))}</td>
                                        <td className={styles.titulo}>{item.quantidade}</td>
                                        <td className={styles.titulo}>{item.unidadeMedida}</td>
                                    </tr>
                                ))

                                }
                            </tbody>
                        </table>
                        <table border='1' className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan="12">Componentes Nutricionais</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={styles.nutriente}>
                                    {
                                        this.state.preparos.nutrientes.map((nutriente, index) => (
                                            <td key={index}><b>{nutriente.nome}</b></td>
                                        ))
                                    }
                                </tr>
                                <tr className={styles.nutriente}>
                                    {
                                        this.state.preparos.nutrientes.map((nutriente, index) => (
                                            <td key={index}>{nutriente.valor} {nutriente.unidadeDeMedida}</td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
                        <Row>
                            <Col className={styles.borda}>
                                <b> Modo de Preparo:</b> {this.state.preparos.modoPreparo}
                            </Col>
                        </Row>
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    alimentos: state.alimento.filtrados,
    token: state.auth.token,
    preparos: state.preparo.preparo,
}
)

const mapDispatchToProps = dispatch => bindActionCreators({
    listarPreparosId,
    listarAlimentos,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Preparos);