import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Image } from 'react-bootstrap';
import Select from 'react-select';
import SimpleTable from '../../components/SimpleTable';
import SimpleModal from '../../components/SimpleModal/index';
import PageTitle from '../../components/PageTitle/index';
import TinyAction from '../../components/TinyAction';
import Fieldset from '../../components/Fieldset/index';
import ActionButton from '../../components/ActionButton/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SelectMultiple from '../../components/SelectMultiple/index';
import Input from '../../components/Input/index';
import SimpleButton from '../../components/SimpleButton/index';
import Textarea from '../../components/Textarea/index';
import SimpleInput from '../../components/SimpleInput/index';
import MoneyInputMask from '../../components/MoneyInputMask';
import FileInput from '../../components/FileInput/index';
import { MoneyFormat } from '../../utils/moneyFormat';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';

import moment from 'moment';

import {
    listarSolicitacoes,
    filtrarSolicitacoes,
    editarSolicitacao,
    cancelarSolicitacao,
    buscarMotivoCancelamentoSolicitacao,
    listarAquisicoes,
    listarFornecedores,
    listarItensFornecedor,
    listarUnidadesEducacionais,
    receberSolicitacao,
    listarStatusCompra,
    produtosRecebidos,
    imagemNotaFiscal,
    editarNotaFiscal,
    editarImagemNotaFiscal,
    listarCategorias
} from '../../actions';

import styles from './GerenciarSolicitacoes.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import BackButton from '../../components/BackButton';

class GerenciarSolicitacoesPage extends Component {
    state = {
        showModal: false,
        showModalDetalhes: false,
        solicitacao: {
            aquisicao: -1,
            fornecedor: {},
            propostaVencedora: -1,
            //propostaVencedora: {},
            itens: [{ id: -1, produto: { id: -1, unidadeProduto: '' }, quantidade: 0, quantidadeMaxima: 0, invalidQuant: false }],
            unidadeEducacional: {}
        },
        categorias: [
            { faixaEtaria: -1}
        ],
        invalidForm: false,
        showModalCancelamento: false,
        motivo: '',
        idSolicitacao: 0,
        showModalMotivo: false,

        showModalRecebimento: false,
        idSolicitacaoReceber: -1,
        observacao: '',
        itens: [{ id: -1, produto: { item: {}, unidadeProduto: '' }, quantidade: 0, lote: '', dataFabricacao: '', dataValidade: '', checked: false, quantidadeValidacao: 0 }],
        // nota fiscal
        numero: 0,
        dataEmissao: '',
        dataRecebimento: '',
        valor: 0,
        imagem: '',

        selecionarTodas: false,

        pesquisaData: '',
        pesquisaFornecedor: '',
        pesquisaUnidade: '',
        pesquisaStatus: '',

        isQuantInvalid: false,

        fornecedores: [],

        showModalProdutos: false,
        produto: {
            notaFiscal: {},
            produtoEntrada: []
        },
    }

    componentDidMount() {
        this.props.listarSolicitacoes(this.props.token);
        this.props.listarAquisicoes(this.props.token);
        this.props.listarFornecedores(this.props.token);
        this.props.listarUnidadesEducacionais(this.props.token);
        this.props.listarStatusCompra(this.props.token);
        this.props.listarCategorias(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalNumbers < nextProps.closeModalNumbers) {
            this.setState({ showModal: false, showModalRecebimento: false, showModalCancelamento: false, showModalProdutos: false })
        }

        if (nextProps.produto !== this.props.produto) {
            this.setState({ ...this.state, produto: nextProps.produto, showModalProdutos: true, imagem: this.props.imagem });
        }
        if (nextProps.imagem !== this.props.imagem) {
            this.setState({ ...this.state, imagem: nextProps.imagem }, () => console.log(this.state.imagem));
        }
    }

    handleCloseModal = () => {
        this.setState({ ...this.state, showModal: false })
    }

    handleShowModal = solicitacao => {
        // console.log(solicitacao)
        let aquisicaoId = solicitacao.aquisicao;
        let aquisicoes = JSON.parse(JSON.stringify(this.props.aquisicoes));
        let aquisicao = aquisicoes.find(aquisicao => aquisicao.id === aquisicaoId);

        let fornecedores = [];

        if (!!aquisicao) {
            fornecedores = aquisicao.propostasVencedoras.map(proposta => ({ idProposta: proposta.id, ...proposta.fornecedor }));
        }

        this.setState({ ...this.state, solicitacao, fornecedores, showModal: true }, () => {
            this.props.listarItensFornecedor(solicitacao.fornecedor.id, solicitacao.aquisicao, this.props.token)
        })
    }

    handleShowModalDetalhes = solicitacao => {
        let aquisicaoId = solicitacao.aquisicao;
        let aquisicoes = JSON.parse(JSON.stringify(this.props.aquisicoes));
        let aquisicao = aquisicoes.find(aquisicao => aquisicao.id === aquisicaoId);

        let fornecedores = [];

        if (!!aquisicao) {
            fornecedores = aquisicao.propostasVencedoras.map(proposta => ({ idProposta: proposta.id, ...proposta.fornecedor }));
        }

        this.setState({ ...this.state, solicitacao, fornecedores, showModalDetalhes: true }, () => {
            this.props.listarItensFornecedor(solicitacao.fornecedor.id, solicitacao.aquisicao, this.props.token)
        })
    }

    handleShowModalDetalhesProdutos = solicitacao => {
        this.props.produtosRecebidos(solicitacao.id, this.props.token);
    }

    getImagemNotaFiscal = idNota => {
        this.props.imagemNotaFiscal(idNota, this.props.token)
    }

    handleCloseModalCancelar = () => {
        this.setState({ ...this.state, motivo: '', showModalCancelamento: false })
    }

    handleShowModalCancelar = idSolicitacao => {
        this.setState({ ...this.state, idSolicitacao, showModalCancelamento: true })
    }

    handleCloseModalMotivo = () => {
        this.setState({ ...this.state, showModalMotivo: false })
    }

    handleShowModalMotivo = idSolicitacao => {
        this.setState({ ...this.state, showModalMotivo: true })
        this.props.buscarMotivoCancelamentoSolicitacao(idSolicitacao, this.props.token)
    }

    handleChange = (value, property) => {
        let solicitacao = this.state.solicitacao;
        solicitacao[property] = value;

        this.setState({ ...this.state, solicitacao })
    }

    handleChangeNota = (value, property) => {
        let produto = JSON.parse(JSON.stringify(this.state.produto));
        produto.notaFiscal[property] = value;

        this.setState({ ...this.state, produto });
    }

    handleChangeAquisicao = value => {
        let solicitacao = this.state.solicitacao;
        solicitacao.aquisicao = value;
        solicitacao.fornecedor = {};

        let itensVazios = [{ id: -1, produto: { id: -1, unidadeProduto: '' }, quantidade: 0, quantidadeMaxima: 0, invalidQuant: false }];
        solicitacao.itens = itensVazios;

        this.setState({ ...this.state, solicitacao, fornecedores: [] }, () => {
            let aquisicoes = JSON.parse(JSON.stringify(this.props.aquisicoes));
            let aquisicao = aquisicoes.find(aquisicao => aquisicao.id === value);

            if (!!aquisicao) {
                let fornecedores = aquisicao.propostasVencedoras.map(proposta => ({ idProposta: proposta.id, ...proposta.fornecedor }));
                this.setState({ ...this.state, fornecedores })
            }
        })
    }

    handleChangeCategories = values => {
        let arrayAux = [];

        values.map(itens => {
            arrayAux.push(itens.content);
        })
        
        this.setState({ ...this.state, categorias: arrayAux})
    }

    handleChangeFornecedor = value => {
        let solicitacao = this.state.solicitacao;
        solicitacao.fornecedor = { id: value };

        let itensVazios = [{ id: -1, produto: { id: -1, unidadeProduto: '' }, quantidade: 0, quantidadeMaxima: 0, invalidQuant: false }]
        solicitacao.itens = itensVazios;

        this.setState({ ...this.state, solicitacao }, () => {
            let fornecedor = this.state.fornecedores.find(fornecedor => fornecedor.id === value);

            if (!!fornecedor) {
                solicitacao.propostaVencedora = fornecedor.idProposta;
                this.setState({ ...this.state, solicitacao })
            } else {
                solicitacao.propostaVencedora = -1;
                this.setState({ ...this.state, solicitacao })
            }

            this.props.listarItensFornecedor(value, solicitacao.aquisicao, this.props.token)
        })
    }

    mostrarPaginaRelatorioSoliticao = idSolicitacao => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_SOLICITACAO_COMPRA}/${idSolicitacao}`);
    }

    mostrarPaginaRelatorioRecebimento = idSolicitacao => {
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_RECEBIMENTO_COMPRA}/${idSolicitacao}`);
    }

    // Itens
    handleItemInput = (value, index, property) => {
        if (isNaN(value)) value = -1;

        let solicitacao = JSON.parse(JSON.stringify(this.state.solicitacao));

        let i;
        switch (property) {
            case 'produto':
                solicitacao.itens[index].produto.id = value;
                i = this.props.itensFornecedor.find(itemFornecedor => itemFornecedor.id === value)

                if (!!i) {
                    if (solicitacao.itens[index]['quantidade'] < 0 || (i && solicitacao.itens[index]['quantidade'] > i.quantidade)) {
                        solicitacao.itens[index]['invalidQuant'] = true;
                        this.setState({ ...this.state, solicitacao })
                    } else {
                        solicitacao.itens[index]['invalidQuant'] = false;
                        this.setState({ ...this.state, solicitacao })
                    }
                    solicitacao.itens[index]['produto']['unidadeProduto'] = i.unidade;
                    solicitacao.itens[index]['quantidadeMaxima'] = i.quantidade;
                    solicitacao.itens[index]['precoUnidade'] = i.precoUnidade;
                }
                break;

            case 'quantidade':
                solicitacao.itens[index].quantidade = value;
                i = this.props.itensFornecedor.find(itemFornecedor => itemFornecedor.id === solicitacao.itens[index]['produto']['id'])

                if (!!i) {
                    if (value < 0 || (i && value > i.quantidade)) {
                        solicitacao.itens[index]['invalidQuant'] = true;
                        this.setState({ ...this.state, solicitacao })
                    } else {
                        solicitacao.itens[index]['invalidQuant'] = false;
                        this.setState({ ...this.state, solicitacao })
                    }
                    solicitacao.itens[index]['quantidadeMaxima'] = i.quantidade;
                }
                break;

            default:
                break;
        }

        if (solicitacao.itens.find(item => item.invalidQuant)) {
            this.setState({ ...this.state, invalidForm: true, solicitacao })
        }
        else {
            this.setState({ ...this.state, invalidForm: false, solicitacao })
        }
    }

    handleQuantidadeInput = (value, index) => {
        let solicitacao = this.state.solicitacao;
        solicitacao.itens[index].quantidade = value;

        this.setState({ ...this.state, solicitacao })
    }

    handleAddItem = index => {
        let item = { id: -1, produto: { id: -1, unidadeProduto: '' }, quantidade: 0, quantidadeMaxima: 0, invalidQuant: false }

        let solicitacao = this.state.solicitacao;
        solicitacao.itens.splice(index + 1, 0, item);

        this.setState({ ...this.state, solicitacao })
    }

    handleRemoveItem = index => {
        let solicitacao = this.state.solicitacao;
        solicitacao.itens.splice(index, 1);

        this.setState({ ...this.state, solicitacao })
    }
    // Itens

    handleEditar = e => {
        e.preventDefault();

        let solicitacao = JSON.parse(JSON.stringify(this.state.solicitacao));

        solicitacao.fornecedor = this.props.fornecedores.find(fornecedor => fornecedor.id === solicitacao.fornecedor.id);

        let { id, fornecedorTipo } = solicitacao.fornecedor;
        solicitacao.fornecedor = { id, fornecedorTipo }

        // let newItens = [];
        // this.state.solicitacao.itens.forEach(item => {
        //     let { itemProdutoTipo, id } = this.props.itensFornecedor.find(i => item.item === i.id || item.item.id === i.id)
        //     let newItem = {
        //         item: {
        //             id,
        //             itemProdutoTipo
        //         },
        //         quantidade: item.quantidade,
        //     }

        //     if (item.hasOwnProperty('id')) newItem.id = item.id

        //     newItens.push(newItem)
        // })

        let newItens = solicitacao.itens.map(item => ({ id: item.id, produto: item.produto.id, quantidade: item.quantidade, solicitacao: solicitacao.id }));
        let newCategories = this.state.categorias.map(item => ({ id: item.id, modalidade: item.modalidade, faixaEtaria: item.faixaEtaria }));

        if (newCategories[0].id === undefined) newCategories = this.state.solicitacao.categorias;

        solicitacao.propostaVencedora = this.state.solicitacao.propostaVencedora.id
        solicitacao.itens = newItens;
        solicitacao.categorias = newCategories;

        if (solicitacao.unidadeEducacional.hasOwnProperty('id')) solicitacao.unidadeEducacional = solicitacao.unidadeEducacional.id

        console.log(solicitacao)

        this.props.editarSolicitacao(solicitacao, this.props.token)
    }

    handleEditarNota = e => {
        e.preventDefault();
        let produto = JSON.parse(JSON.stringify(this.state.produto));
        let nota = produto.notaFiscal;
        //
        this.props.editarNotaFiscal(nota, this.props.token);
    }

    editarImagemNota = e => {
        e.preventDefault();
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = e => {
            this.setState({ ...this.state, imagem: e.target.result }, () => this.props.editarImagemNotaFiscal(this.state.produto.notaFiscal.id, e.target.result, this.props.token))
        }
    }

    handleShowModalRecebimento = (idSolicitacaoReceber, itens) => {
        //console.log(itens)
        //let newItens = itens.map(item => ({ ...item, lote: '', dataFabricacao: '', dataValidade: '', checked: false, quantidadeValidacao: item.quantidade }))
        let newItens = itens.map(item => ({ ...item, lote: '', dataFabricacao: '', dataValidade: '', checked: true, quantidadeValidacao: item.quantidade }))

        this.setState({
            ...this.state,
            idSolicitacaoReceber,
            itens: newItens,
            numero: 0,
            dataEmissao: '',
            dataRecebimento: '',
            valor: 0,
            showModalRecebimento: true
        })
    }

    handleCloseModalRecebimento = () => {
        this.setState({ ...this.state, showModalRecebimento: false })
    }

    handleItemReceberInput = (value, index, property) => {
        let itens = this.state.itens;
        itens[index][property] = value;

        this.setState({ ...this.state, itens })
    }

    selecionarTodas = () => {
        let itens = this.state.itens;
        let modificandoItens = [];
        if (this.state.selecionarTodas) {
            modificandoItens = itens.map(i => {
                i.checked = false;
                return i;
            })
        } else {
            modificandoItens = itens.map(i => {
                i.checked = true;
                return i;
            })
        }

        this.setState({ ...this.state, selecionarTodas: !this.state.selecionarTodas, itens: modificandoItens })
    }

    selecionarItemReceber = index => {
        let itens = this.state.itens;
        itens[index].checked = !itens[index].checked;

        this.setState({ ...this.state, itens })
    }

    handleImagem = e => {
        e.preventDefault();

        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0])

        reader.onload = e => {
            this.setState({ ...this.state, imagem: e.target.result })
        }
    }

    handleReceberCompra = e => {
        e.preventDefault();

        let { idSolicitacaoReceber, observacao, itens, numero, dataEmissao, dataRecebimento, valor } = JSON.parse(JSON.stringify(this.state));

        let recebimento = {
            observacao,
            produtos: itens
                .filter(i => i.checked)
                .map(i =>
                    ({
                        ...i,
                        produto: i.produto.id,
                        quantidade: parseInt(i.quantidade),
                        dataFabricacao: moment(i.dataFabricacao, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        dataValidade: moment(i.dataValidade, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                    })
                ),
            notaFiscal: {
                numero,
                dataEmissao: moment(dataEmissao, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                dataRecebimento: moment(dataRecebimento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                valor
            }
        }


        this.props.receberSolicitacao(idSolicitacaoReceber, recebimento, this.state.imagem, this.props.token);
    }

    checkQuantidadeRecebimento = (quantInput, quantDefault) => {
        if (quantInput > quantDefault || quantInput < 0) {
            this.setState({ isQuantInvalid: true })
        } else {
            this.setState({ isQuantInvalid: false })
        }
    }

    render() {
        let itensForm =
            (
                <>
                    {this.state.solicitacao.itens.map((item, index) => (
                        <Row key={index}>
                            <Col md={5}>
                                <SimpleSelect
                                    label='* Item:'
                                    value={item.produto.id}
                                    onChange={e => this.handleItemInput(parseInt(e.target.value), index, 'produto')}
                                    required
                                >

                                    <option></option>
                                    {this.props.itensFornecedor.map((i, index) =>
                                        <option key={i.id} value={i.id}>{i.nome}</option>
                                    )}
                                </SimpleSelect>
                            </Col>

                            {/* APLICAR LÓGICA DE RENDERIZAÇÃO. DEVE EXIBIR SOMENTE QUANDO SELECIONAR O PRODUTO */}
                            <label>Preço: {MoneyFormat(item.precoUnidade)}</label>

                            <Col md={5}>
                                <Input
                                    label={`Quant: (${item.produto.unidadeProduto})`}
                                    type='number'
                                    isInvalid={item.invalidQuant}
                                    value={item.quantidade}
                                    onChange={value => this.handleItemInput(parseInt(value), index, 'quantidade')}
                                    aside={item.quantidadeMaxima}
                                    required
                                />
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <Col align='center'>
                                        <TinyAction
                                            type='button'
                                            title='Adicionar novo item'
                                            onClick={() => this.handleAddItem(index)}
                                            color='green'
                                            icon='fas fa-plus'
                                        />
                                    </Col>
                                    <Col align='center'>
                                        <TinyAction
                                            type='button'
                                            title='Remover item'
                                            onClick={() => this.handleRemoveItem(index)}
                                            color='red'
                                            icon='fas fa-minus'
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </>
            );

        let itensFormDetalhes =
            (
                <>
                    {this.state.solicitacao.itens.map((item, index) => (
                        <Row key={index}>
                            <Col md={5}>
                                <SimpleSelect
                                    label='* Item:'
                                    value={item.produto.id}
                                    disabled
                                >

                                    <option value={0}></option>
                                    {this.props.itensFornecedor.map((i, index) =>
                                        <option key={i.id} value={i.id}>{i.nome}</option>
                                    )}
                                </SimpleSelect>
                            </Col>

                             {/* APLICAR LÓGICA DE RENDERIZAÇÃO. DEVE EXIBIR SOMENTE QUANDO SELECIONAR O PRODUTO */}
                             <label>PREÇO DO(A) {item.produto.unidadeProduto}: {MoneyFormat(item.precoUnidade)}</label>

                            <Col md={3}>
                                <Input
                                    label={`Quant: (${item.produto.unidadeProduto})`}
                                    type='number'
                                    isInvalid={item.invalidQuant}
                                    value={item.quantidade}
                                    aside={item.quantidadeMaxima}
                                    disabled
                                />
                            </Col>
                        </Row>
                    ))}
                </>
            );

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Solicitações de Compra' hasDivider={true} />
                </Row>

                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Data: </Form.Label>
                            <Form.Control
                                type='date'
                                value={this.state.pesquisaData}
                                onChange={e => this.setState({ ...this.state, pesquisaData: e.target.value },
                                    () => this.props.filtrarSolicitacoes(moment(this.state.pesquisaData, 'YYYY-MM-DD').format('DD/MM/YYYY'), this.state.pesquisaFornecedor.value, this.state.pesquisaUnidade.value))}
                            />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Modalidade: </Form.Label>
                            <Select
                                required
                                placeholder=''
                                value={this.state.categorias}
                                onChange={selectedOption => this.setState({ ...this.state, pesquisaModalidade: selectedOption === null ? {} : selectedOption },
                                    () => this.props.filtrarSolicitacoes(moment(
                                        this.state.pesquisaData, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                                        this.state.pesquisaFornecedor.value,
                                        this.state.pesquisaUnidade.value,
                                        this.state.pesquisaStatus.value,
                                        this.state.pesquisaModalidade.value))}
                                
                                        options={this.props.categorias.map(categoria => ({
                                            value: categoria.modalidade,
                                            label: categoria.modalidade +'/'+ categoria.faixaEtaria.nome
                                        }))}
                            />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Fornecedor: </Form.Label>
                            <Select
                                required
                                placeholder=''
                                isClearable
                                value={this.state.pesquisaFornecedor}
                                onChange={selectedOption => this.setState({ ...this.state, pesquisaFornecedor: selectedOption === null ? {} : selectedOption },
                                    () => this.props.filtrarSolicitacoes(moment(this.state.pesquisaData, 'YYYY-MM-DD').format('DD/MM/YYYY'), this.state.pesquisaFornecedor.value, this.state.pesquisaUnidade.value))}

                                options={this.props.fornecedores.map(fornecedor => ({
                                    value: fornecedor.nomeFantasia || fornecedor.nome,
                                    label: fornecedor.nomeFantasia || fornecedor.nome
                                }))}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Unidade: </Form.Label>
                            <Select
                                required
                                placeholder=''
                                value={this.state.pesquisaUnidade}
                                onChange={selectedOption => this.setState({ ...this.state, pesquisaUnidade: selectedOption === null ? {} : selectedOption },
                                    () => this.props.filtrarSolicitacoes(moment(this.state.pesquisaData, 'YYYY-MM-DD').format('DD/MM/YYYY'), this.state.pesquisaFornecedor.value, this.state.pesquisaUnidade.value))}

                                options={this.props.unidadesUsuario.map(unidade => ({
                                    value: unidade.unidadeEducacional.nome,
                                    label: unidade.unidadeEducacional.nome
                                }))}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Status: </Form.Label>
                            <Select
                                required
                                placeholder=''
                                value={this.state.pesquisaStatus}
                                onChange={selectedOption => this.setState({ ...this.state, pesquisaStatus: selectedOption === null ? {} : selectedOption },
                                    () => this.props.filtrarSolicitacoes(moment(this.state.pesquisaData, 'YYYY-MM-DD').format('DD/MM/YYYY'), this.state.pesquisaFornecedor.value, this.state.pesquisaUnidade.value, this.state.pesquisaStatus.value))}
                                
                                    options={options}
                            />
                        </Form.Group>
                    </Row>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Data Solicitação</th>
                            <th>Modalidade</th>
                            <th>Fornecedor</th>
                            <th>Unidade Educacional</th>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(solicitacao => (
                            <tr className={`${solicitacao.status === 'CANCELADA' ? styles.cancel : ''} ${solicitacao.status === 'RECEBIDA' ? styles.receive : ''}`}
                                key={solicitacao.id}>
                                <td>{solicitacao.dataSolicitacao}</td>
                                <td>{formatterCategories(solicitacao.categorias)}</td>
                                <td>{solicitacao.fornecedor.nome}</td>
                                <td>{solicitacao.unidadeEducacional.nome}</td>
                                <td>
                                    {solicitacao.status}
                                    {
                                        solicitacao.status === 'CANCELADA'
                                        &&
                                        <>
                                            <div
                                                className={styles.motivo}
                                                onClick={e => this.handleShowModalMotivo(solicitacao.id)}>
                                                <span>?</span>
                                            </div>
                                        </>
                                    }
                                </td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Solicitação'
                                        onClick={() => this.handleShowModalDetalhes(solicitacao)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Mostrar Produtos'
                                        onClick={() => this.handleShowModalDetalhesProdutos(solicitacao)}
                                        icon='fas fa-dolly'
                                        disabled={solicitacao.status === 'CANCELADA' || solicitacao.status === 'AGUARDANDO'}
                                    />

                                    <ActionButton
                                        title='Receber Solicitação'
                                        disabled={solicitacao.status === 'CANCELADA' || solicitacao.status === 'RECEBIDA'}
                                        onClick={() => this.handleShowModalRecebimento(solicitacao.id, solicitacao.itens)}
                                        //onClick={() => this.handleShowModalRecebimento(solicitacao.id, solicitacao.propostaVencedora.itens)}
                                        icon='fas fa-check'
                                    />

                                    <ActionButton
                                        title='Editar Solicitação'
                                        disabled={solicitacao.status === 'CANCELADA' || solicitacao.status === 'RECEBIDA'}
                                        onClick={() => this.handleShowModal(solicitacao)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Cancelar Solicitação'
                                        disabled={solicitacao.status === 'CANCELADA' || solicitacao.status === 'RECEBIDA'}
                                        onClick={() => this.handleShowModalCancelar(solicitacao.id)}
                                        icon='fas fa-ban'
                                    />
                                    <ActionButton
                                        title='Imprimir Relatório de Solicitação'
                                        onClick={() => this.mostrarPaginaRelatorioSoliticao(solicitacao.id)}
                                        icon='fas fa-print'
                                    />
                                     <ActionButton
                                        title='Imprimir Relatório de Recebimento de Compra'
                                        onClick={() => this.mostrarPaginaRelatorioRecebimento(solicitacao.id)}
                                        icon='fas fa-print'
                                        disabled={solicitacao.status === 'CANCELADA' || solicitacao.status === 'AGUARDANDO'}
                                    />
                                    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Solicitação'
                    icon='fas fa-edit'
                >

                    <Form onSubmit={this.handleEditar}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados da solicitação' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Data Solicitação:'
                                                type='text'
                                                value={this.state.solicitacao.dataSolicitacao}
                                                disabled
                                                required
                                            />
                                        </Col>
                                        <Col>
                                            <SimpleSelect
                                                label='* Status:'
                                                disabled
                                                value={this.state.solicitacao.status}
                                                onChange={e => this.handleChange(e.target.value, 'status')}
                                                required
                                            >
                                                {this.props.status.map((status, index) => (
                                                    <option key={index} value={status.nome}>{status.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='* Aquisição:'
                                                value={this.state.solicitacao.aquisicao}
                                                onChange={e => this.handleChangeAquisicao(parseInt(e.target.value))}
                                                required
                                            >

                                                <option></option>
                                                {this.props.aquisicoes
                                                    .filter(aquisicao => aquisicao.status !== 'PENDENTE')
                                                    .map(aquisicao => (
                                                        <option key={aquisicao.id} value={aquisicao.id}>{aquisicao.aquisicaoTipo} - {aquisicao.numero}</option>
                                                    )
                                                    )}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={6}>
                                            <SelectMultiple
                                                name="colors"
                                                label='Modalidade:'
                                                defaultValue={formatValueMultipleSelect(this.state.solicitacao.categorias)}
                                                value={this.state.solicitacao.categorias}
                                                options={this.props.modalidadesAux}
                                                onChange={e => this.handleChangeCategories(e)}>
                                            </SelectMultiple>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='* Fornecedor:'
                                                value={this.state.solicitacao.fornecedor.id}
                                                onChange={e => this.handleChangeFornecedor(parseInt(e.target.value))}
                                                required
                                            >

                                                <option></option>
                                                {this.state.fornecedores.map(fornecedor => (
                                                    <option key={fornecedor.id} value={fornecedor.id}>
                                                        {fornecedor.hasOwnProperty('nome') ? fornecedor.nome : fornecedor.nomeFantasia}
                                                    </option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='* Unidade Educacional:'
                                                value={this.state.solicitacao.unidadeEducacional.id}
                                                onChange={e => this.handleChange(parseInt(e.target.value), 'unidadeEducacional')}
                                                required
                                            >

                                                <option value=''></option>
                                                {this.props.unidadesUsuario.map(unidade => (
                                                    <option key={unidade.unidadeEducacional.id} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    {itensForm}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                    disabled={this.state.invalidForm || this.state.solicitacao.status === 'CANCELADA' || this.state.solicitacao.status === 'RECEBIDA'}
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalDetalhes}
                    onHide={() => this.setState({ ...this.state, showModalDetalhes: false })}
                    title='Detalhes'
                    icon='fas fa-eye'
                >

                    {/* <Form> */}
                    <Row>
                        <Col>
                            <Fieldset legend='Dados da solicitação' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            label='* Data Solicitação:'
                                            type='text'
                                            value={this.state.solicitacao.dataSolicitacao}
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <SimpleSelect
                                            label='* Status:'
                                            disabled
                                            value={this.state.solicitacao.status}
                                        >
                                            {this.props.status.map((status, index) => (
                                                <option key={index} value={status.nome}>{status.descricao}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Aquisição:'
                                            value={this.state.solicitacao.aquisicao}
                                            disabled
                                        >

                                            {/* <option value=''></option> */}
                                            {this.props.aquisicoes.map(aquisicao => (
                                                <option key={aquisicao.id} value={aquisicao.id}>{aquisicao.aquisicaoTipo} - {aquisicao.numero}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>

                                    <Col md={6}>
                                            <SelectMultiple
                                                name="colors"
                                                label='Modalidade:'
                                                isDisabled={true}
                                                defaultValue={formatValueMultipleSelect(this.state.solicitacao.categorias)}
                                                value={this.state.solicitacao.categorias}
                                                options={this.props.modalidadesAux}>
                                            </SelectMultiple>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Fornecedor:'
                                            value={this.state.solicitacao.fornecedor.id}
                                            disabled
                                        >

                                            {/* <option value=''></option> */}
                                            {this.state.fornecedores.map(fornecedor => (
                                                <option key={fornecedor.id} value={fornecedor.id}>
                                                    {fornecedor.hasOwnProperty('nome') ? fornecedor.nome : fornecedor.nomeFantasia}
                                                </option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Unidade Educacional:'
                                            value={this.state.solicitacao.unidadeEducacional.id}
                                            disabled
                                        >

                                            <option value=''></option>
                                            {this.props.unidadesUsuario.map(unidade => (
                                                <option key={unidade.unidadeEducacional.id} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                            ))}
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                {itensFormDetalhes}
                            </Fieldset>
                        </Col>
                    </Row>
                    {/* </Form> */}
                </SimpleModal>

                <SimpleModal

                    show={this.state.showModalProdutos}
                    onHide={() => this.setState({ ...this.state, showModalProdutos: false })}
                    title='Produtos'
                    icon='fas fa-dolly'
                    onShow={() => this.getImagemNotaFiscal(this.state.produto.notaFiscal.id)}
                >

                    {/* <Form> */}
                    <Form onSubmit={this.handleEditarNota}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados da nota fiscal' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Número:'
                                                text='text'
                                                value={this.state.produto.notaFiscal.numero}
                                                onChange={value => this.handleChangeNota(value, 'numero')}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Data emissão:'
                                                type='text'
                                                value={this.state.produto.notaFiscal.dataEmissao}
                                                onChange={value => this.handleChangeNota(value, 'dataEmissao')}
                                                required
                                            />
                                        </Col>
                                        <Col>
                                            <Input
                                                label='* Data recebimento:'
                                                type='text'
                                                value={this.state.produto.notaFiscal.dataRecebimento}
                                                onChange={value => this.handleChangeNota(value, 'dataRecebimento')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <MoneyInputMask
                                                label='* Valor:'
                                                value={this.state.produto.notaFiscal.valor}
                                                onChangeEvent={(e, maskValue, floatValue) => this.handleChangeNota(floatValue, 'valor')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FileInput
                                                extensions='.jpg,.png,.jpeg'
                                                label='Selecionar IMAGEM da nota fiscal'
                                                onChange={this.editarImagemNota}
                                            />
                                        </Col>
                                        {
                                            this.state.imagem !== null ? (
                                                <Col>
                                                    <Image src={`${this.state.imagem}`} fluid />
                                                </Col>
                                            ) : (
                                                    <Col>
                                                        Imagem ainda não selecionada
                                                    </Col>
                                                )
                                        }

                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Dados do recebimento' align='left'>

                                    <Row>
                                        <Col>
                                            <Textarea
                                                label='* Observação:'
                                                rows='3'
                                                value={this.state.produto.observacao}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <SimpleTable>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Form.Check type='checkbox' checked disabled />
                                                </th>
                                                <th>Lote</th>
                                                <th>Produto</th>
                                                <th>Quantidade</th>
                                                <th>Data Fabricação</th>
                                                <th>Data Validade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.produto.produtoEntrada.map((item, index) => (
                                                <tr key={index}>
                                                    <td><Form.Check type='checkbox' checked onChange={() => this.selecionarItemReceber(index)} disabled /></td>
                                                    <td>
                                                        <SimpleInput
                                                            type='text'
                                                            style={{width: 100}}
                                                            value={item.produtoLote.numero}
                                                            className={styles.colunaTabelaRecebimento}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        {`${item.produtoLote.produto.nome} - ${item.produtoLote.produto.item}`}
                                                    </td>
                                                    <td>
                                                        <div className={styles.colunaRecebimentoQuantidade}>
                                                            <Input
                                                                label={`${item.produtoLote.produto.unidadeProduto}`}
                                                                type='number' 
                                                                style={{width: 100}}                                                    
                                                                value={item.quantidade}
                                                                tooltip='Quantidade disponível'
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <SimpleInput
                                                            type='text'
                                                            style={{width: 150}}
                                                            value={item.produtoLote.dataFabricacao}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td md={2}>
                                                        <SimpleInput
                                                            type='text'
                                                            style={{width: 150}}
                                                            value={item.produtoLote.dataValidade}
                                                            disabled
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </SimpleTable>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ ...this.state, showModalProdutos: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                    disabled={this.state.isQuantInvalid}
                                />
                            </Col>
                        </Row>
                    </Form>
                    {/* </Form> */}
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalCancelamento}
                    onHide={this.handleCloseModalCancelar}
                    title='Cancelar Solicitação'
                    icon='fas fa-ban'>

                    <Row>
                        <Col>
                            <Textarea
                                label='* Motivo:'
                                rows='3'
                                value={this.state.motivo}
                                onChange={(value, upper) => this.setState({ ...this.state, motivo: value }, () => upper())}
                                required
                            />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='left'>
                            <SimpleButton
                                type='button'
                                color='red'
                                name='Fechar'
                                icon='fas fa-times'
                                onClick={this.handleCloseModalCancelar}
                            />
                        </Col>
                        <Col align='right'>
                            <SimpleButton
                                type='button'
                                color='green'
                                name='Cancelar'
                                icon='fas fa-ban'
                                onClick={e => this.props.cancelarSolicitacao(this.state.idSolicitacao, this.state.motivo, this.props.token)}
                            />
                        </Col>
                    </Row>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalMotivo}
                    onHide={this.handleCloseModalMotivo}
                    title='Motivo'
                    icon='fas fa-comment'>

                    <Row>
                        <Col align='center'>
                            <p>{this.props.motivoCancelamento.motivo}</p>
                        </Col>
                    </Row>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalRecebimento}
                    onHide={this.handleCloseModalRecebimento}
                    title='Verificar Recebimento'
                    icon='fas fa-check'>

                    <Form onSubmit={this.handleReceberCompra}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados da nota fiscal' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Número:'
                                                text='text'
                                                value={this.state.numero}
                                                onChange={value => this.setState({ ...this.state, numero: value })}
                                                required
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Input
                                                label='* Data emissão:'
                                                type='date'
                                                value={this.state.dataEmissao}
                                                onChange={value => this.setState({ ...this.state, dataEmissao: value })}
                                                required
                                            />
                                        </Col>
                                        <Col>
                                            <Input
                                                label='* Data recebimento:'
                                                type='date'
                                                value={this.state.dataRecebimento}
                                                onChange={value => this.setState({ ...this.state, dataRecebimento: value })}
                                                min={this.state.dataEmissao}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <MoneyInputMask
                                                label='* Valor:'
                                                value={this.state.valor}
                                                onChangeEvent={(e, maskedValue, floatValue) => this.setState({ ...this.state, valor: floatValue })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FileInput
                                                extensions='.jpg,.png,.jpeg'
                                                label='Selecionar IMAGEM da nota fiscal'
                                                onChange={this.handleImagem}
                                            /> {!!this.state.imagem ? 'Imagem selecionada' : 'Imagem ainda não selecionada'}
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Dados do recebimento' align='left'>
                                    <Row>
                                        <Col>
                                            <Textarea
                                                label='* Observação:'
                                                rows='3'
                                                value={this.state.observacao}
                                                onChange={(value, upper) => this.setState({ ...this.state, observacao: value }, () => upper())}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <p>
                                        <b>Observação: </b>Selecione o produtos que realmente chegaram e que serão recebidos!
                                    </p>
                                    <SimpleTable>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Form.Check type='checkbox' checked={this.state.selecionarTodas} onChange={this.selecionarTodas} />
                                                </th>
                                                <th>Lote</th>
                                                <th>Produto</th>
                                                <th>Valor</th>
                                                <th>Quantidade</th>
                                                <th>Total</th>
                                                <th>Data Fabricação</th>
                                                <th>Data Validade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.itens.map((item, index) => (
                                                <tr key={index}>
                                                    <td><Form.Check type='checkbox' checked={item.checked} onChange={() => this.selecionarItemReceber(index)} /></td>
                                                    <td>
                                                        <SimpleInput
                                                            type='text'
                                                            value={item.lote}
                                                            style={{width: 100}}
                                                            className={styles.colunaTabelaRecebimento}
                                                            onChange={value => this.handleItemReceberInput(value, index, 'lote')}
                                                            required
                                                        />
                                                    </td>
                                                    <td>
                                                        {`${item.produto.nome} - ${item.produto.item.nome}`}
                                                    </td>
                                                    <td>
                                                        {MoneyFormat(`${item.precoUnidade}`)}
                                                    </td>
                                                    <td>
                                                        <div style={{width: 270}} className={styles.colunaRecebimentoQuantidade}>
                                                            <Input
                                                                label={`${item.produto.unidadeProduto}`}
                                                                type='number'
                                                                value={item.quantidade}
                                                                style={{width:50}}
                                                                isInvalid={this.state.isQuantInvalid}
                                                                onKeyUp={() => this.checkQuantidadeRecebimento(item.quantidade, item.quantidadeValidacao)}
                                                                onChange={value => this.handleItemReceberInput(value, index, 'quantidade')}
                                                                min="0"
                                                                max={`${item.quantidadeValidacao}`}
                                                                aside={`${item.quantidadeValidacao}`}
                                                                tooltip='Quantidade disponível'
                                                                required
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {MoneyFormat(`${item.precoUnidade * item.quantidade}`)}
                                                    </td>
                                                    <td>
                                                        <SimpleInput
                                                            type='date'
                                                            value={item.dataFabricacao}
                                                            onChange={value => this.handleItemReceberInput(value, index, 'dataFabricacao')}
                                                            required
                                                        />
                                                    </td>
                                                    <td md={2}>
                                                        <SimpleInput
                                                            type='date'
                                                            value={item.dataValidade}
                                                            min= {new Date().toISOString().split("T")[0]}
                                                            onChange={value => this.handleItemReceberInput(value, index, 'dataValidade')}
                                                            required
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </SimpleTable>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.setState({ ...this.state, showModalRecebimento: false })}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Receber'
                                    icon='fas fa-check'
                                    disabled={this.state.isQuantInvalid}
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    list: state.solicitacao.list,
    closeModalNumbers: state.solicitacao.closeModalNumbers,
    filtrados: state.solicitacao.filtrados,
    motivoCancelamento: state.solicitacao.motivoCancelamento,
    token: state.auth.token,
    unidadesUsuario: state.auth.user.estoques,
    aquisicoes: state.aquisicao.list,
    fornecedores: state.fornecedor.list,
    itensFornecedor: state.fornecedor.itens,
    unidades: state.unidadeEducacional.list,
    status: state.convencao.statusCompra,
    produto: state.solicitacao.produto,
    imagem: state.solicitacao.imagem,
    categorias: state.convencao.categorias,
    modalidadesAux: formatValueMultipleSelect(state.convencao.categorias),
})

const options = [
    { value: 'ENVIADA', label: 'ENVIADA' },
    { value: 'AGUARDANDO', label: 'AGUARDANDO'},
    { value: 'RECEBIDA', label: 'RECEBIDA' },
    { value: 'DEVOLVIDA', label: 'DEVOLVIDA' },
    { value: 'CANCELADA', label: 'CANCELADA' },
  ]

const formatterCategories = (array) => {
    let label = '';

    if (array) {
        array.map((a, index) => {
            label += a.modalidade +'/'+ a.faixaEtaria.nome
    
            if (index < array.length -1) {
                label += ", "
            }
        })
    }
   
    return label;
}  

const formatValueMultipleSelect = (itens) => {
    const values = [];

    if (itens) {
        itens.map(v => {
            let value ={label : v.modalidade +'/'+ v.faixaEtaria.nome, value: v.id, content: v}
            values.push(value)
        })
    }

    return values;
}

const mapDispatchToProps = dispatch => bindActionCreators({
    listarSolicitacoes,
    filtrarSolicitacoes,
    editarSolicitacao,
    cancelarSolicitacao,
    buscarMotivoCancelamentoSolicitacao,
    listarAquisicoes,
    listarFornecedores,
    listarItensFornecedor,
    listarUnidadesEducacionais,
    receberSolicitacao,
    listarStatusCompra,
    produtosRecebidos,
    imagemNotaFiscal,
    editarNotaFiscal,
    editarImagemNotaFiscal,
    listarCategorias
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarSolicitacoesPage)), 'SOLICITACOES');