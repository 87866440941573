import React from 'react';
import { Row, Col } from 'react-bootstrap';

import styles from './Subheader.module.css';
import SubheaderLogo from './SubheaderLogo';
import SubheaderUser from './SubheaderUser';

const Subheader = props => (
    <Row className={styles.subheader}>
        <Col className={styles.leftItens}>
            <SubheaderLogo />
        </Col>
        <Col className={styles.rightItens}>
            <SubheaderUser />
        </Col>
    </Row>
)

export default Subheader;