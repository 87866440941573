import React from 'react';
import PrintButton from '../components/PrintButton/index';
import BackButton from '../components/BackButton';
import Printable from '../components/Printable/index';
import Header from '../components/Header/index';
import Title from '../components/Title/index';
import Detail from '../components/Detail/index';
import Info from '../components/Info';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { movimentacaoRelatorio, listarEstoques } from '../../actions'

import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import Etiqueta from '../components/Etiqueta';

class MovimentacaoProdutos extends React.Component {

    state = {
        etiquetas: true
    }

    componentDidMount() {
        this.props.listarEstoques(this.props.token);
        const data = this.props.data.split(',');
        const idEstoque = data[0];
        const idMovimentacao = data[1];
        this.props.movimentacaoRelatorio(idEstoque, idMovimentacao, this.props.token);
    }
    /*
        hideEtiqueta() {
           let etiquetas = document.getElementById('etiqueta');
           etiquetas.classList.add('etiqueta');
        }*/

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <div className='mb-3' align="center">
                    <Form.Check
                        type='checkbox'
                        id='custom-checkbox'
                        inline
                        checked={this.state.etiquetas}
                        onChange={() => this.setState({ etiquetas: !this.state.etiquetas })}
                    />
                    <Form.Label>* Imprimir Etiqueta</Form.Label>
                </div>

                <br />

                <Printable>
                    <Info />

                   <Header />

                    <Title name='Relatório de Movimentação de Produtos' />

                    <Detail>
                        <div className='row'>
                            <div className='col'>
                                <b>Origem:</b> {this.props.estoques.map(estoque => (estoque.id === this.props.movimentacao.origem ? estoque.unidadeEducacional.nome : ''))}
                            </div>
                            <div className='col'>
                                <b>Destino:</b>  {this.props.estoques.map(estoque => (estoque.id === this.props.movimentacao.destino ? estoque.unidadeEducacional.nome : ''))}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <b>Data Início:</b> {this.props.movimentacao.dataInicioMovimentacao}
                            </div>
                            <div className='col'>
                                <b>Data Fim:</b> {!this.props.movimentacao.dataFimMovimentacao ? '---' : this.props.movimentacao.dataFimMovimentacao}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <b>Status:</b> {this.props.movimentacao.status}
                            </div>
                        </div>

                        <br />

                        <div className='row'>
                            <div className='col'>
                                <b>Produtos Movimentados</b>
                                <hr />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <table className={styles.tableProdMovimen} border='1'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Lote</th>
                                            <th>Data Fabricação</th>
                                            <th>Data Validade</th>
                                            <th>Produto</th>
                                            <th>Unidade</th>
                                            <th>Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.movimentacao.produtos.map((produto, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{produto.produtoEstoque.produtoLote.numero}</td>
                                                <td>{produto.produtoEstoque.produtoLote.dataFabricacao}</td>
                                                <td>{produto.produtoEstoque.produtoLote.dataValidade}</td>
                                                <td className={styles.produto}>{produto.produtoEstoque.produtoLote.produto.item} - {produto.produtoEstoque.produtoLote.produto.nome}</td>
                                                <td>{produto.produtoEstoque.produtoLote.produto.unidadeProduto}</td>
                                                <td>{produto.quantidade}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={styles.assinaturas}>
                            <div className={styles.signature}>
                                <hr align="left" noshade />
                                <p>Nome do emissor</p>
                            </div>

                            <div >
                                Recebido por: <br />
                                <span className={styles.data}>
                                    Data: ___/___/___
                                </span>

                            </div>

                            <div className={styles.signature}>
                                <hr align="left" noshade />
                                <p>
                                    Nome do responsável pela unidade que está recebendo
                                </p>
                            </div>
                        </div>

                        <div className={this.state.etiquetas ? '' : 'hide'}>
                            <div className={styles.etiqueta}>
                                <Etiqueta produtos={this.props.movimentacao.produtos}></Etiqueta>
                            </div>
                        </div>

                    </Detail>
                </Printable>
            </>
        )
    }
}


const mapStateToProps = state => ({
    token: state.auth.token,
    movimentacao: state.movimentacao.relatorio,
    estoques: state.estoque.list,
    estoquesUsuario: state.auth.user.estoques,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    movimentacaoRelatorio,
    listarEstoques
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MovimentacaoProdutos);