import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import HomeItem from '../../components/HomeItem';
import HomeItemOverlay from '../../components/HomeItemOverlay';
import HomeItemModal from '../../components/HomeItemModal';

import { connect } from 'react-redux';

import styles from './Home.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import * as routes from '../../Routes/constants';

import inicialUsuarios from '../../assets/icons/inicialUsuarios.svg';
import inicialDadosCadastrais from '../../assets/icons/inicialDadosCadastrais.svg';
import inicialMenuItens from '../../assets/icons/inicialMenuItens.svg';
import inicialProdutos from '../../assets/icons/inicialProdutos.svg';
import inicialFornecedores from '../../assets/icons/inicialFornecedores.svg';
import inicialLicitacoes from '../../assets/icons/inicialLicitacoes.svg';
import inicialSolicitacoes from '../../assets/icons/inicialSolicitacoes.svg';
import inicialEstoque from '../../assets/icons/inicialEstoque.svg';
import inicialGerenciarMaior from '../../assets/icons/inicialGerenciarEstoques.svg';
import inicialMovimentacao from '../../assets/icons/inicialMovimentacao.svg';
import inicialMenuAlimentos from '../../assets/icons/inicialMenuAlimentos.svg';
import inicialAlimentos from '../../assets/icons/inicialAlimentos.svg';
import inicialPreparos from '../../assets/icons/inicialPreparos.svg';
import inicialPratos from '../../assets/icons/inicialPratos.svg';
import inicialCardapios from '../../assets/icons/inicialCardapios.svg';
import inicialCadastrarCardapio from '../../assets/icons/inicialCadastrarCardapio.svg';
import inicialSacolaProdutos from '../../assets/icons/inicialsacolaprodutos.svg';
import inicialCardapioSemanal from '../../assets/icons/inicialCardapioSemanal.svg';
import inicialCalendario from '../../assets/icons/inicialCalendario.svg';
import inicialEscola from '../../assets/icons/inicialEscola.svg';
import inicialSino from '../../assets/icons/inicialSino.svg';
import cadastrarIcon from '../../assets/icons/cadastrar.svg';
import gerenciarIcon from '../../assets/icons/gerenciar.svg';

const HomePage = props => (
    <>
        <Row>
            <PageTitle name='Página Inicial' />
        </Row>
        <Row className={styles.eachItem}>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('USUARIOS')}>
                <HomeItemOverlay subItems={[
                    { path: routes.CADASTRAR_USUARIO, icon: cadastrarIcon, name: 'Cadastrar' },
                    { path: routes.GERENCIAR_USUARIOS, icon: gerenciarIcon, name: 'Gerenciar' },
                ]}>
                    <HomeItem name='Usuários' icon={inicialUsuarios} />
                </HomeItemOverlay>
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('UNIDADES')}>
                <HomeItemOverlay subItems={[
                    { path: routes.CADASTRAR_UNIDADE, icon: cadastrarIcon, name: 'Cadastrar' },
                    { path: routes.GERENCIAR_UNIDADES, icon: gerenciarIcon, name: 'Gerenciar' },
                ]}>
                    <HomeItem name='Unidades Educacionais' icon={inicialEscola} />
                </HomeItemOverlay>
            </Col>
            <Col sm={12} md={4}>
                <HomeItem type='link' path={routes.DADOS_CADASTRAIS} name='Dados cadastrais' icon={inicialDadosCadastrais} />
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('ITENS') && !props.usuario.permissoes.includes('PRODUTOS') && !props.usuario.permissoes.includes('FORNECEDORES')}>
                <HomeItemModal name='Menu de itens'>
                    <HomeItem name='Menu de itens' icon={inicialMenuItens} />

                    <div hidden={!props.usuario.permissoes.includes('ITENS')}>
                        <HomeItemOverlay isInModal={true} subItems={[
                            { path: routes.CADASTRAR_ITEM, icon: cadastrarIcon, name: 'Cadastrar' },
                            { path: routes.GERENCIAR_ITENS, icon: gerenciarIcon, name: 'Gerenciar' },
                        ]}>
                            <HomeItem name='Itens' icon={inicialMenuItens} />
                        </HomeItemOverlay>
                    </div>
                    <div hidden={!props.usuario.permissoes.includes('PRODUTOS')}>
                        <HomeItemOverlay isInModal={true} subItems={[
                            { path: routes.CADASTRAR_PRODUTO, icon: cadastrarIcon, name: 'Cadastrar' },
                            { path: routes.GERENCIAR_PRODUTOS, icon: gerenciarIcon, name: 'Gerenciar' },
                        ]}>
                            <HomeItem name='Produtos' icon={inicialProdutos} />
                        </HomeItemOverlay>
                    </div>
                    <div hidden={!props.usuario.permissoes.includes('FORNECEDORES')}>
                        <HomeItemOverlay isInModal={true} subItems={[
                            { path: routes.CADASTRAR_FORNECEDOR, icon: cadastrarIcon, name: 'Cadastrar' },
                            { path: routes.GERENCIAR_FORNECEDORES, icon: gerenciarIcon, name: 'Gerenciar' },
                        ]}>
                            <HomeItem name='Fornecedores' icon={inicialFornecedores} />
                        </HomeItemOverlay>
                    </div>
                </HomeItemModal>
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('AQUISICOES')}>
                <HomeItemOverlay subItems={[
                    { path: routes.CADASTRAR_AQUISICAO, icon: cadastrarIcon, name: 'Cadastrar' },
                    { path: routes.GERENCIAR_AQUISICOES, icon: gerenciarIcon, name: 'Gerenciar' },
                ]}>
                    <HomeItem name='Licitação/Contrato' icon={inicialLicitacoes} />
                </HomeItemOverlay>
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('SOLICITACOES')}>
                <HomeItemOverlay subItems={[
                    { path: routes.CADASTRAR_SOLICITACAO, icon: cadastrarIcon, name: 'Cadastrar' },
                    { path: routes.GERENCIAR_SOLICITACOES, icon: gerenciarIcon, name: 'Gerenciar' },
                ]}>
                    <HomeItem name='Solicitações de compra' icon={inicialSolicitacoes} />
                </HomeItemOverlay>
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('ESTOQUES')}>
                <HomeItemModal name='Estoque'>
                    <HomeItem name='Estoque' icon={inicialEstoque} />

                    <HomeItem type='link' path={routes.ESTOQUE} name='Gerenciar estoques' icon={inicialGerenciarMaior} />

                    <HomeItem type='link' path={routes.BALANCOS} name='Balanços de estoques' icon={inicialProdutos} />

                    <HomeItemOverlay isInModal={true} subItems={[
                        { path: routes.CADASTRAR_MOVIMENTACAO, icon: cadastrarIcon, name: 'Cadastrar' },
                        { path: routes.GERENCIAR_MOVIMENTACOES, icon: gerenciarIcon, name: 'Gerenciar' },
                    ]}>
                        <HomeItem name='Movimentações' icon={inicialMovimentacao} />
                    </HomeItemOverlay>

                    <HomeItem type='link' path={routes.KITS} name='Kit merenda' icon={inicialSacolaProdutos} />

                </HomeItemModal>
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('ALIMENTOS') && !props.usuario.permissoes.includes('PREPAROS') && !props.usuario.permissoes.includes('PRATOS')}>
                <HomeItemModal name='Menus de alimentos'>
                    <HomeItem name='Menu de alimentos' icon={inicialMenuAlimentos} />

                    <div hidden={!props.usuario.permissoes.includes('ALIMENTOS')}>
                        <HomeItemOverlay isInModal={true} subItems={[
                            { path: routes.CADASTRAR_ALIMENTO, icon: cadastrarIcon, name: 'Cadastrar' },
                            { path: routes.GERENCIAR_ALIMENTOS, icon: gerenciarIcon, name: 'Gerenciar' },
                        ]}>
                            <HomeItem name='Alimentos' icon={inicialAlimentos} />
                        </HomeItemOverlay>
                    </div>
                    <div hidden={!props.usuario.permissoes.includes('PREPAROS')}>
                        <HomeItemOverlay isInModal={true} subItems={[
                            { path: routes.CADASTRAR_PREPARO, icon: cadastrarIcon, name: 'Cadastrar' },
                            { path: routes.GERENCIAR_PREPAROS, icon: gerenciarIcon, name: 'Gerenciar' },
                        ]}>
                            <HomeItem name='Preparos' icon={inicialPreparos} />
                        </HomeItemOverlay>
                    </div>
                    <div hidden={!props.usuario.permissoes.includes('PRATOS')}>
                        <HomeItemOverlay isInModal={true} subItems={[
                            { path: routes.CADASTRAR_PRATO, icon: cadastrarIcon, name: 'Cadastrar' },
                            { path: routes.GERENCIAR_PRATOS, icon: gerenciarIcon, name: 'Gerenciar' },
                        ]}>
                            <HomeItem name='Pratos' icon={inicialPratos} />
                        </HomeItemOverlay>
                    </div>
                </HomeItemModal>
            </Col>
            <Col sm={12} md={4} hidden={!props.usuario.permissoes.includes('CARDAPIOS')}>
                <HomeItemModal name='Cardápios'>
                    <HomeItem name='Cardápios' icon={inicialCardapios} />

                    <HomeItem type='link' path={routes.CADASTRAR_CARDAPIO} name='Cadastrar cardápio' icon={inicialCadastrarCardapio} />
                    <HomeItem type='link' path={routes.CARDAPIO_SEMANAL} name='Cardápio semanal' icon={inicialCardapioSemanal} />
                    <HomeItem type='link' path={routes.GERENCIAR_CARDAPIOS} name='Gerenciar cardápios' icon={inicialGerenciarMaior} />
                </HomeItemModal>
            </Col>
            <Col sm={12} md={4}>
                <HomeItem type='link' path={routes.CARDAPIO_CALENDARIO} name='Calendário de cardápios' icon={inicialCalendario} />
            </Col>
            <Col sm={12} md={4}>
                <HomeItemOverlay isInModal={true} subItems={[
                    { path: routes.EXECUCAO_CARDAPIOS, icon: cadastrarIcon, name: 'Realizar' },
                    { path: routes.HISTORICO_EXECUCAO_CARDAPIOS, icon: gerenciarIcon, name: 'Histórico' },
                ]}>
                    <HomeItem name='Execução de cardápios' icon={inicialPratos} />
                </HomeItemOverlay>
            </Col>

            <Col sm={12} md={4}>
                <HomeItem type='link' path={routes.NOTIFICACAO} name='Notificações' icon={inicialSino} />
            </Col>

              {/* <Col sm={12} md={4}>
                <HomeItem type='link' path={routes.ESCOLA} name='Escolas' icon={inicialEscola} />
            </Col> */}
        </Row>
    </>
)

const mapStateToProps = state => ({
    usuario: state.auth.user
})

export default withAuthorization(connect(mapStateToProps, null)(HomePage), 'HOME');