import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { 
    LISTAR_MOVIMENTACOES, 
    LISTAR_UMA_MOVIMENTACAO,
    MOVIMENTACAO_RELATORIO, 
    EDITAR_MOVIMENTACAO, 
    BUSCAR_MOTIVO_CANCELAMENTO_MOVIMENTACAO, 
    CADASTRAR_MOVIMENTACAO,
    FILTRAR_UNIDADE,
    LIMPAR_LISTA,
 } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function listarMovimentacoes(idEstoque, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/estoques/${idEstoque}/movimentacoes`, configAuthorizationToken(token))
            .then(res => dispatchEvent({ type: LISTAR_MOVIMENTACOES, payload: res.data }))
    }
}

export function listarUmaMovimentacao(idEstoque, idMovimentacao, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/estoques/${idEstoque}/movimentacoes/${idMovimentacao}`, configAuthorizationToken(token))
            .then(res => dispatchEvent({ type: LISTAR_UMA_MOVIMENTACAO, payload: res.data }))
    }
}

export function movimentacaoRelatorio(idEstoque, idMovimentacao, token) {
    return dispatchEvent => {
         axios.get(`${BASE_URL}/estoques/${idEstoque}/movimentacoes/${idMovimentacao}`, configAuthorizationToken(token))
             .then(res =>
                 dispatchEvent({ type: MOVIMENTACAO_RELATORIO, payload: res.data })
                //console.log(res.data)
                )
    }
}

export function cadastrarMovimentacao(movimentacao, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/estoques/${movimentacao.estoqueDe}/movimentar/${movimentacao.estoquePara}`, movimentacao.produtosEstoque, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Movimentação realizada com sucesso!')
                        dispatchEvent({ type: CADASTRAR_MOVIMENTACAO, payload: {} })
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        // dispatchEvent(listarMovimentacoes(token))                
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente realizar a Movimentação?' />
            )
        })
    }
}

export function cancelarMovimentacao(idEstoque, idMovimentacao, motivo, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/estoques/${idEstoque}/movimentacoes/${idMovimentacao}/cancelar`, { motivo }, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Movimentação cancelada com sucesso!')
                        dispatchEvent([
                            { type: EDITAR_MOVIMENTACAO, payload: {} },
                            listarMovimentacoes(idEstoque, token)
                        ])
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarMovimentacoes(idEstoque, token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cancelar a Movimentação?' />
            )
        })
    }
}

export function buscarMotivoCancelamentoMovimentacao(idEstoque, idMovimentacao, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/${idEstoque}/movimentacoes/${idMovimentacao}/cancelamento`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: BUSCAR_MOTIVO_CANCELAMENTO_MOVIMENTACAO, payload: res.data })
            })
    }
}

// export function receberMovimentacao(idEstoque, idMovimentacao, token) {
//     return dispatchEvent => {
//         toastr.confirm(null, {
//             onOk: () => {
//                 axios.put(`${BASE_URL}/estoques/${idEstoque}/movimentacoes/${idMovimentacao}/receber`, {}, configAuthorizationToken(token))
//                     .then(res => {
//                         toastr.success('Sucesso', 'Movimentação recebida com sucesso!')
//                         dispatchEvent(listarMovimentacoes(idEstoque, token))
//                     })
//                     .catch(err => {
//                         toastr.error('Erro', extractErrorMessage(err))
//                         dispatchEvent(listarMovimentacoes(idEstoque, token))
//                     })
//             },
//             onCancel: () => { },
//             component: () => (
//                 <ConfirmMessage message='Deseja realmente receber a Movimentação?' />
//             )
//         })
//     }
// }

export function receberMovimentacao(idEstoque, movimentacao, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                console.log(movimentacao);
                axios.put(`${BASE_URL}/estoques/movimentacoes/receber`, 
                {
                    idEstoque: idEstoque,
                    idMovimentacao: movimentacao.id, 
                    tipoObservacao: movimentacao.tipoObservacao, 
                    observacao: movimentacao.observacao}, configAuthorizationToken(token))

                    .then(res => {
                        toastr.success('Sucesso', 'Movimentação recebida com sucesso!')
                        dispatchEvent(listarMovimentacoes(idEstoque, token))
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarMovimentacoes(idEstoque, token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente receber a Movimentação?' />
            )
        })
    }
}

export function filtrarUnidade(value, tipoPesquisa, estoques) {
    return (dispatch) => {
        if (value != null || value != undefined) {
            let unidades = estoques.filter(stock => stock[tipoPesquisa] == value)
            //console.log('LISTA AQUI: ', unidades)
            dispatch({ type: FILTRAR_UNIDADE, payload:  unidades})
        }
        
    }
}

export function limparLista() {
    return (dispatch) => {
            dispatch({ type: LIMPAR_LISTA, payload:  []})
    }
}