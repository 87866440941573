import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import Fieldset from '../../components/Fieldset';
import SimpleSelect from '../../components/SimpleSelect/index';
import Input from '../../components/Input/index';
import TextInputMask from '../../components/TextInputMask/index';
import SimpleButton from '../../components/SimpleButton';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cadastrarFornecedor, listarPortesEmpresa } from '../../actions';

import * as viacep from '../../services/viacep';

// import styles from './CadastrarFornecedor.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';

const INITIAL_FORM = {
    email: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro_distrito: '',
    municipio: '',
    uf: '',
    cep: '',
    telefone: '',
    celular: '',
    fornecedorTipo: 'FISICO',
    fornecedorfisico: {
        cpf: '',
        nome: ''
    },
    fornecedorjuridico: {
        nomeFantasia: '',
        cnpj: '',
        inscricaoEstadual: '',
        porteDaEmpresa: '',
        razaoSocial: ''
    },

    typingCepTimeout: 0,
    fetching: false,
}

const INITIAL_FISICO = {
    cpf: '',
    nome: ''
}

const INITIAL_JURIDICO = {
    nomeFantasia: '',
    cnpj: '',
    inscricaoEstadual: '',
    porteDaEmpresa: '',
    razaoSocial: ''
}

class CadastrarFornecedorPage extends Component {
    state = {
        ...INITIAL_FORM
    }

    componentDidMount() {
        this.props.listarPortesEmpresa(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.resetNumbers < nextProps.resetNumbers) this.setState({ ...INITIAL_FORM, fornecedorfisico: JSON.parse(JSON.stringify(INITIAL_FISICO)), fornecedorjuridico: JSON.parse(JSON.stringify(INITIAL_JURIDICO)) })
    }

    handleChangeFornecedorFisico = (value, property, upper = () => { }) => {
        let fisico = this.state.fornecedorfisico;
        fisico[property] = value;

        this.setState({ ...this.state, fornecedorfisico: fisico }, () => upper());
    }

    handleChangeFornecedorJuridico = (value, property, upper = () => { }) => {
        let juridico = this.state.fornecedorjuridico;
        juridico[property] = value;

        this.setState({ ...this.state, fornecedorjuridico: juridico }, () => upper());
    }

    handleChangeCep = value => {
        if (this.state.typingCepTimeout) clearTimeout(this.state.typingCepTimeout)

        this.setState({
            ...this.state,
            cep: value,
            fetching: true,
            typingCepTimeout: setTimeout(() => {
                let cepFormatado = value.replace(new RegExp(/\D/g), '');
                viacep.query(cepFormatado)
                    .then(res => {
                        let result = res.data;
                        this.setState({
                            logradouro: result.logradouro.toUpperCase(),
                            complemento: result.complemento.toUpperCase(),
                            bairro_distrito: result.bairro.toUpperCase(),
                            municipio: result.localidade.toUpperCase(),
                            uf: result.uf,
                            fetching: false
                        })
                    });
            }, 3000)
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        // console.log(this.state)
        this.props.cadastrarFornecedor(this.state, this.props.token);
    }

    render() {
        let formFornecedorFisico = (
            <>
                <Row>
                    <Col>
                        <Input
                            label='* Nome:'
                            type='text'
                            case='upper'
                            value={this.state.fornecedorfisico.nome}
                            onChange={(value, upper) => this.handleChangeFornecedorFisico(value, 'nome', upper)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInputMask
                            label='* CPF:'
                            type='text'
                            mask='999.999.999-99'
                            value={this.state.fornecedorfisico.cpf}
                            onChange={e => this.handleChangeFornecedorFisico(e.target.value, 'cpf')}
                            required
                        />
                    </Col>
                </Row>
            </>
        )

        let formFornecedorJuridico = (
            <>
                <Row>
                    <Col>
                        <Input
                            type='text'
                            label='* Nome fantasia:'
                            case='upper'
                            value={this.state.fornecedorjuridico.nomeFantasia}
                            onChange={(value, upper) => this.handleChangeFornecedorJuridico(value, 'nomeFantasia', upper)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            type='text'
                            label='* Razão social:'
                            case='upper'
                            value={this.state.fornecedorjuridico.razaoSocial}
                            onChange={(value, upper) => this.handleChangeFornecedorJuridico(value, 'razaoSocial', upper)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <TextInputMask
                            label='* CNPJ:'
                            type='text'
                            mask='99.999.999/9999-99'
                            value={this.state.fornecedorjuridico.cnpj}
                            onChange={e => this.handleChangeFornecedorJuridico(e.target.value, 'cnpj')}
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <TextInputMask
                            label='* Inscrição Estadual:'
                            type='text'
                            mask='99.999.999-9'
                            value={this.state.fornecedorjuridico.inscricaoEstadual}
                            onChange={e => this.handleChangeFornecedorJuridico(e.target.value, 'inscricaoEstadual')}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SimpleSelect
                            label='* Porte da Empresa:'
                            value={this.state.fornecedorjuridico.porteDaEmpresa}
                            onChange={e => this.handleChangeFornecedorJuridico(e.target.value, 'porteDaEmpresa')}
                            required
                        >

                            <option value=''></option>
                            {this.props.portesEmpresa.map((porte, index) => (
                                <option key={index} value={porte.nome}>{porte.descricao}</option>
                            ))}
                        </SimpleSelect>
                    </Col>
                </Row>
            </>
        )

        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Cadastro de Fornecedor' hasDivider={true} requiredMessage='Campos com * são obrigatórios' />
                </Row>

                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Fieldset legend='Dados do fornecedor' align='left'>
                                <Row>
                                    <Col>
                                        <SimpleSelect
                                            label='* Tipo de fornecedor:'
                                            value={this.state.fornecedorTipo}
                                            onChange={e => this.setState({ ...this.state, fornecedorTipo: e.target.value })}
                                            required
                                        >

                                            <option value='FISICO'>FÍSICO</option>
                                            <option value='JURIDICO'>JURÍDICO</option>
                                        </SimpleSelect>
                                    </Col>
                                </Row>

                                {this.state.fornecedorTipo === 'FISICO' ? formFornecedorFisico : formFornecedorJuridico}
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Endereço' align='left'>
                                <Row>
                                    <Col md={4}>
                                        <TextInputMask
                                            label='* CEP:'
                                            type='text'
                                            mask='99999-999'
                                            value={this.state.cep}
                                            onChange={e => this.handleChangeCep(e.target.value)}
                                            loading={this.state.fetching}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='* Municipio:'
                                            type='text'
                                            readOnly={!this.state.cep}
                                            case='upper'
                                            value={this.state.municipio}
                                            onChange={(value, upper) => this.setState({ ...this.state, municipio: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Input
                                            label='* UF:'
                                            type='text'
                                            readOnly={!this.state.cep}
                                            case='upper'
                                            value={this.state.uf}
                                            onChange={(value, upper) => this.setState({ ...this.state, uf: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={9}>
                                        <Input
                                            label='* Logradouro:'
                                            type='text'
                                            readOnly={!this.state.cep}
                                            case='upper'
                                            value={this.state.logradouro}
                                            onChange={(value, upper) => this.setState({ ...this.state, logradouro: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            label='* Número:'
                                            type='text'
                                            case='upper'
                                            value={this.state.numero}
                                            onChange={(value, upper) => this.setState({ ...this.state, numero: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            label='* Bairro:'
                                            type='text'
                                            readOnly={!this.state.cep}
                                            case='upper'
                                            value={this.state.bairro_distrito}
                                            onChange={(value, upper) => this.setState({ ...this.state, bairro_distrito: value }, () => upper())}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            label='Complemento:'
                                            type='text'
                                            readOnly={!this.state.cep}
                                            case='upper'
                                            value={this.state.complemento}
                                            onChange={(value, upper) => this.setState({ ...this.state, complemento: value }, () => upper())} />
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col>
                            <Fieldset legend='Contato' align='left'>
                                <Row>
                                    <Col>
                                        <Input
                                            label='* E-mail:'
                                            type='text'
                                            value={this.state.email}
                                            onChange={value => this.setState({ ...this.state, email: value })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Telefone fixo:'
                                            type='text'
                                            mask='(99) 9999-9999'
                                            value={this.state.telefone}
                                            onChange={e => this.setState({ ...this.state, telefone: e.target.value })}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInputMask
                                            label='Celular:'
                                            mask='(99) 99999-9999'
                                            type='text'
                                            value={this.state.celular}
                                            onChange={e => this.setState({ ...this.state, celular: e.target.value })}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '16px' }}>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Cadastrar'
                                icon='fas fa-check'
                            />
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    resetNumbers: state.fornecedor.resetNumbers,
    portesEmpresa: state.convencao.portesEmpresa
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarFornecedor,
    listarPortesEmpresa
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(CadastrarFornecedorPage), 'FORNECEDORES');