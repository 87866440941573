import React from 'react';
import { Modal } from 'react-bootstrap';
import ModalTitle from '../ModalTitle/index';

import styles from './SimpleModal.module.css';

const SimpleModal = props => (
    <Modal
        {...props}
        className={styles.simpleModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>
                <ModalTitle name={props.title} icon={props.icon} />
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {props.children}
        </Modal.Body>
    </Modal>
)

export default SimpleModal;