import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage'

import {
    LISTAR_CARDAPIOS,
    LISTAR_CARDAPIOS_SEMANAIS,
    LISTAR_CARDAPIOS_MENSAIS,
    FILTRAR_CARDAPIOS,
    FILTRAR_CARDAPIOS_SEMANAIS,
    CADASTRAR_CARDAPIOS,
    EDITAR_CARDAPIOS,
} from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests'
import { toastr } from 'react-redux-toastr';

export function listarCardapios(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent([
                    { type: LISTAR_CARDAPIOS, payload: res.data },
                    { type: FILTRAR_CARDAPIOS, payload: res.data }
                ])
            })
    }
}

export function filtrarCardapios(categoria, modalidade) {
    return (dispatchEvent, getState) => {
        let cardapios = getState().cardapio.list;
        let cardapiosFiltrados = cardapios.filter(c =>
            (!categoria || c.categoria.id === categoria) &&
            (!modalidade || c.modalidade.nome === modalidade)
        )

        dispatchEvent({ type: FILTRAR_CARDAPIOS, payload: cardapiosFiltrados })
    }
}

export function listarCardapiosPorCategoria(idCategoria, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios`, configAuthorizationToken(token))
            .then(res => {
                let cardapios = res.data;
                let cardapiosFiltrados = cardapios.filter(cardapio => cardapio.categoria.id === idCategoria)

                dispatchEvent({ type: LISTAR_CARDAPIOS, payload: cardapiosFiltrados })
            })
    }
}

export function listarCardapiosPorCategoriaEModalidade(idCategoria, modalidade, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios`, configAuthorizationToken(token))
            .then(res => {
                let cardapios = res.data;
                let cardapiosFiltrados = cardapios.filter(cardapio => cardapio.categoria.id === idCategoria && cardapio.modalidade.nome === modalidade)

                dispatchEvent({ type: LISTAR_CARDAPIOS, payload: cardapiosFiltrados })
            })
    }
}

export function listarCardapiosSemanais(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios-semanais`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent([
                    { type: LISTAR_CARDAPIOS_SEMANAIS, payload: res.data },
                    { type: FILTRAR_CARDAPIOS_SEMANAIS, payload: res.data }
                ])
            })
    }
}

export function filtrarCardapiosSemanais(categoria, modalidade) {
    return (dispatchEvent, getState) => {
        let cardapiosSemanais = getState().cardapio.semanais;
        let cardapiosSemanaisFiltrados = cardapiosSemanais.filter(c =>
            (!categoria || c.categoria.id === categoria) &&
            (!modalidade || c.modalidade.nome === modalidade)
        )

        dispatchEvent({ type: FILTRAR_CARDAPIOS_SEMANAIS, payload: cardapiosSemanaisFiltrados })
    }
}

export function listarCardapiosSemanaisPorCategoria(idCategoria, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios-semanais`, configAuthorizationToken(token))
            .then(res => {
                let cardapios = res.data;
                let cardapiosFiltrados = cardapios.filter(cardapio => cardapio.categoria.id === idCategoria)

                dispatchEvent({ type: LISTAR_CARDAPIOS_SEMANAIS, payload: cardapiosFiltrados })
            })
    }
}

export function listarCardapiosMensais(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/cardapios-mensais`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent({ type: LISTAR_CARDAPIOS_MENSAIS, payload: res.data })
            })
    }
}

export function cadastrarCardapio(cardapio, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/cardapios`, cardapio, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Cardápio cadastrado com sucesso!')
                        dispatchEvent({ type: CADASTRAR_CARDAPIOS, payload: {} })
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar o Cardápios?' />
            )
        })
    }
}

export function cadastrarCardapioSemanal(cardapio, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/cardapios-semanais`, cardapio, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Cardápio cadastrado com sucesso!')
                        dispatchEvent({ type: CADASTRAR_CARDAPIOS, payload: {} })
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar o Cardápios Semanal?' />
            )
        })
    }
}

export function cadastrarCardapioMensal(cardapio, token) {
    return dispatchEvent => {
        axios.post(`${BASE_URL}/cardapios-mensais`, cardapio, configAuthorizationToken(token))
            .then(res => {
                toastr.success('Sucesso', 'Cardápio cadastrado com sucesso!')
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
    }
}

export function editarCardapio(cardapio, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/cardapios/${cardapio.id}`, cardapio, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: EDITAR_CARDAPIOS, payload: {} },
                            listarCardapios(token)
                        ])
                        toastr.success('Sucesso', 'Cardápio editado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarCardapios(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Cardápio?' />
            )
        })
    }
}

export function editarCardapioSemanal(cardapioSemanal, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/cardapios-semanais/${cardapioSemanal.id}`, cardapioSemanal, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([
                            { type: EDITAR_CARDAPIOS, payload: {} },
                            listarCardapiosSemanais(token)
                        ])
                        toastr.success('Sucesso', 'Cardápio Semanal editado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarCardapiosSemanais(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Cardápio Semanal?' />
            )
        })
    }
}

export function excluirCardapio(id, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/cardapios/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent(listarCardapios(token))
                        toastr.success('Sucesso', 'Cardápio excluido com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarCardapios(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Cardápio?' />
            )
        })
    }
}

export function excluirCardapioSemanal(id, token) {
    return dispatchEvent => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/cardapios-semanais/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent(listarCardapiosSemanais(token))
                        toastr.success('Sucesso', 'Cardápio Semanal excluido com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarCardapiosSemanais(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Cardápio Semanal?' />
            )
        })
    }
}