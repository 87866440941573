import { 
    LISTAR_CATEGORIAS_ALIMENTOS, 
    LISTAR_TIPOS_COMPONENTES_NUTRICIONAIS, 
    LISTAR_UNIDADES_MEDIDA_NUTRICIONAIS,
    LISTAR_FORMAS_ALIMENTOS,
    LISTAR_GRUPOS_NUTRIENTES,
    LISTAR_TIPOS_PERMISSOES,
    LISTAR_CATEGORIAS,
    LISTAR_TIPOS_REFEICAO,
    LISTAR_GRUPOS_ALIMENTARES,
    LISTAR_MODALIDADES,
    LISTAR_REFERENCIAS_NUTRICIONAIS,
    LISTAR_PORTES_EMPRESA,
    LISTAR_MODALIDADES_LICITACAO,
    LISTAR_TIPOS_LICITACAO,
    LISTAR_STATUS_AQUISICAO,
    LISTAR_STATUS_COMPRA,
    LISTAR_UNIDADES_MEDIDA,
    LISTAR_TIPOS_PESSOA,
    LISTAR_CARGOS,
    LISTAR_UNIDADES_PRODUTO,
    LISTAR_PATOLOGIAS
} from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, configAuthorizationTokenAndParams } from '../utils/requests';

export function listarCategoriasAlimentos(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/categorias-alimento`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_CATEGORIAS_ALIMENTOS, payload: res.data }))
    }
}

export function listarTiposComponentesNutricionais(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/tipos-componente-nutricional`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_TIPOS_COMPONENTES_NUTRICIONAIS, payload: res.data }))
    }
}

export function listarUnidadesMedidaNutricionais(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/unidades-medida-nutricional`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_UNIDADES_MEDIDA_NUTRICIONAIS, payload: res.data }))
    }
}

export function listarFormasAlimentos(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/formas-alimento`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_FORMAS_ALIMENTOS, payload: res.data }))
    }
}

export function listarGruposNutrientes(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/grupos-nutriente`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_GRUPOS_NUTRIENTES, payload: res.data }))
    }
}

export function listarTiposPermissoes(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/permissoes`, configAuthorizationToken(token))
            .then(res => {
                // let permissoes = res.data.map(permissao => ({ nome: permissao, checked: false }))
                dispatch({ type: LISTAR_TIPOS_PERMISSOES, payload: res.data })
            })
    }
}

export function listarCategorias(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/categorias`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_CATEGORIAS, payload: res.data }))
    }
}

export function listarTiposRefeicao(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/tipos-refeicao`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_TIPOS_REFEICAO, payload: res.data }))
    }
}

export function listarGruposAlimentares(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/grupos-alimentares`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_GRUPOS_ALIMENTARES, payload: res.data }))
    }
}

export function listarModalidades(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/modalidades-escolar`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_MODALIDADES, payload: res.data }))
    }
}

export function listarReferenciasNutricionais(categoria, modalidade, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/referencias-nutricionais`, configAuthorizationTokenAndParams(token, { categoria, modalidade }))
            .then(res => dispatch({ type: LISTAR_REFERENCIAS_NUTRICIONAIS, payload: res.data }))
    }
}

export function listarPortesEmpresa(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/portes-empresa`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_PORTES_EMPRESA, payload: res.data }))
    }
}

export function listarModalidadesLicitacao(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/modalidades-licitacao`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_MODALIDADES_LICITACAO, payload: res.data }))
    }
}

export function listarTiposLicitacao(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/tipos-licitacao`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_TIPOS_LICITACAO, payload: res.data }))
    }
}

export function listarStatusAquisicao(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/status-aquisicao`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_STATUS_AQUISICAO, payload: res.data }))
    }
}

export function listarStatusCompra(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/status-compra`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_STATUS_COMPRA, payload: res.data }))
    }
}

export function listarUnidadesMedida(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/unidades-medida`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_UNIDADES_MEDIDA, payload: res.data }))
    }
}

export function listarTiposPessoa(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/tipos-pessoa`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_TIPOS_PESSOA, payload: res.data }))
    }
}

export function listarCargos(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/convencoes/cargos`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_CARGOS, payload: res.data }))
    }
}

export function listarUnidadesProduto(token) {
    return dispatch => {    
        axios.get(`${BASE_URL}/convencoes/unidades-produto`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_UNIDADES_PRODUTO, payload: res.data }))
    }
}

export function listarPatologias(token) {
    return dispatch => {    
        axios.get(`${BASE_URL}/patologias`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_PATOLOGIAS, payload: res.data }))
    }
}