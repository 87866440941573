import React from 'react';
import { Link } from 'react-router-dom';

import styles from './SubItem.module.css';

const SubItem = props => (
    <div className={styles.subItem}>
        <Link to={props.path}>
            <img src={props.icon} className={styles.iconSubmenu} alt='' width='30' />
            <div className={styles.nameSubmenu}>
                {props.name}
            </div>
        </Link>
    </div>
)

export default SubItem;