import React from 'react';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Sidebar from './components/Sidebar/index';
import Subheader from './components/Subheader';
import MainContent from './components/MainContent/index';
// import styles from './App.module.css';

class App extends React.Component {
    state = {
        respMenuOpened: false,
    }

    handleOpen = () => this.setState({ respMenuOpened: true })

    handleClose = () => {
        if (this.state.respMenuOpened) this.setState({ respMenuOpened: false });
        return;
    }

    render() {
        return (
            <div>
                <Header respMenuOpened={this.state.respMenuOpened} handleOpen={this.handleOpen} />
                <Sidebar respMenuOpened={this.state.respMenuOpened} handleOpen={this.handleOpen} handleClose={this.handleClose}>
                    <Subheader />
                    <MainContent>
                        <Container>
                            {this.props.children}
                        </Container>
                    </MainContent>
                </Sidebar>
            </div>
        );
    }
}

export default App;