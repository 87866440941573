import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import Input from '../../components/Input/index';
import TextInputMask from '../../components/TextInputMask/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import SimpleTable from '../../components/SimpleTable/index';
import ActionButton from '../../components/ActionButton/index';
import PageTitle from '../../components/PageTitle/index';
import SimpleModal from '../../components/SimpleModal';
import Fieldset from '../../components/Fieldset/index';
import SimpleButton from '../../components/SimpleButton';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarFornecedores, editarFornecedor, excluirFornecedor, listarPortesEmpresa, filtrarFornecedores } from '../../actions';

import * as viacep from '../../services/viacep';

import styles from './GerenciarFornecedores.module.css';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';

import withAuthorization from '../../components/HighOrder/withAuthorization';
import SimpleModalSmall from '../../components/SimpleModalSmall';

// import hash from 'object-hash';

class GerenciarFornecedoresPage extends Component {
    state = {
        showModal: false,
        showModalDetalhes: false,
        showModalRelatorio: false,
        fornecedor: {},

        fornecedorDetalhes: {},

        pesquisaNome: '',

        typingCepTimeout: 0,
        fetching: false,
    }

    componentDidMount() {
        this.props.listarFornecedores(this.props.token);
        this.props.listarPortesEmpresa(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalNumbers < nextProps.closeModalNumbers) this.handleCloseModal();
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleShowModal = fornecedor => {
        this.setState({ ...this.state, fornecedor, showModal: true })
    }

    handleCloseModalDetalhes = () => {
        this.setState({ showModalDetalhes: false })
    }

    handleShowModalDetalhes = fornecedor => {
        let copyFornecedor = JSON.parse(JSON.stringify(fornecedor))
        this.setState({ ...this.state, fornecedorDetalhes: copyFornecedor, showModalDetalhes: true })
    }

    handleChange = (value, property, upper = () => { }) => {
        let fornecedor = JSON.parse(JSON.stringify(this.state.fornecedor));
        fornecedor[property] = value;

        this.setState({ ...this.state, fornecedor }, () => upper());
    }

    mostrarPaginaRelatorio = fornecedorTipo => {
        sessionStorage.setItem('fornecedorTipo', fornecedorTipo);
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_FORNECEDORES}`);
    }

    mostrarModalRelatorio = () => {
        this.setState({ ...this.state, showModalRelatorio: true })
    }

    fecharModalRelatorio = () => {
        this.setState({ ...this.state, showModalRelatorio: false })
    }

    handleChangeCep = value => {
        let fornecedor = JSON.parse(JSON.stringify(this.state.fornecedor));

        if (this.state.typingCepTimeout) clearTimeout(this.state.typingCepTimeout)

        fornecedor.cep = value

        this.setState({
            ...this.state,
            fornecedor,
            fetching: true,
            typingCepTimeout: setTimeout(() => {
                let cepFormatado = value.replace(new RegExp(/\D/g), '');
                viacep.query(cepFormatado)
                    .then(res => {
                        let result = res.data;

                        fornecedor.logradouro = result.logradouro.toUpperCase();
                        fornecedor.complemento = result.complemento.toUpperCase();
                        fornecedor.bairro_distrito = result.bairro.toUpperCase();
                        fornecedor.municipio = result.localidade.toUpperCase();
                        fornecedor.uf = result.uf;

                        this.setState({
                            fornecedor,
                            fetching: false
                        })
                    });
            }, 3000)
        });
    }

    // handlePorteInput = value => {
    //     let fornecedor = this.state.fornecedor;
    //     fornecedor.porteDaEmpresa.nome = value;

    //     this.setState({ ...this.state, fornecedor })
    // }

    handleEditSubmit = e => {
        e.preventDefault();

        let fornecedor = JSON.parse(JSON.stringify(this.state.fornecedor));

        // console.log(fornecedor)
        this.props.editarFornecedor(fornecedor, this.props.token);
    }

    render() {
        let formFornecedorFisico = (
            <>
                <Row>
                    <Col>
                        <Input
                            label='* Nome:'
                            type='text'
                            case='upper'
                            value={this.state.fornecedor.nome}
                            onChange={(value, upper) => this.handleChange(value, 'nome', upper)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInputMask
                            label='* CPF:'
                            type='text'
                            mask='999.999.999-99'
                            value={this.state.fornecedor.cpf}
                            onChange={e => this.handleChange(e.target.value, 'cpf')}
                            required
                        />
                    </Col>
                </Row>
            </>
        )

        let formFornecedorJuridico = (
            <>
                <Row>
                    <Col>
                        <Input
                            type='text'
                            label='* Nome fantasia:'
                            case='upper'
                            value={this.state.fornecedor.nomeFantasia}
                            onChange={(value, upper) => this.handleChange(value, 'nomeFantasia', upper)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            type='text'
                            label='* Razão social:'
                            case='upper'
                            value={this.state.fornecedor.razaoSocial}
                            onChange={(value, upper) => this.handleChange(value, 'razaoSocial', upper)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <TextInputMask
                            label='* CNPJ:'
                            type='text'
                            mask='99.999.999/9999-99'
                            value={this.state.fornecedor.cnpj}
                            onChange={e => this.handleChange(e.target.value, 'cnpj')}
                            required
                        />
                    </Col>
                    <Col md={6}>
                        <TextInputMask
                            label='* Inscrição Estadual:'
                            type='text'
                            mask='99.999.999-9'
                            value={this.state.fornecedor.inscricaoEstadual}
                            onChange={e => this.handleChange(e.target.value, 'inscricaoEstadual')}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SimpleSelect
                            label='* Porte da Empresa:'
                            value={this.state.fornecedor.porteDaEmpresa}
                            onChange={e => this.handleChange(e.target.value, 'porteDaEmpresa')}
                            required
                        >

                            <option value=''></option>
                            {this.props.portesEmpresa.map((porte, index) => (
                                <option key={index} value={porte.nome}>{porte.descricao}</option>
                            ))}
                        </SimpleSelect>
                    </Col>
                </Row>
            </>
        )

        let detalhesFornecedorFisico = (
            <>
                <Row>
                    <Col>
                        <Input
                            label='* Nome:'
                            type='text'
                            value={this.state.fornecedorDetalhes.nome}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInputMask
                            label='* CPF:'
                            type='text'
                            mask='999.999.999-99'
                            value={this.state.fornecedorDetalhes.cpf}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </>
        )

        let detalhesFornecedorJuridico = (
            <>
                <Row>
                    <Col>
                        <Input
                            type='text'
                            label='* Nome fantasia:'
                            value={this.state.fornecedorDetalhes.nomeFantasia}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            type='text'
                            label='* Razão social:'
                            value={this.state.fornecedorDetalhes.razaoSocial}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <TextInputMask
                            label='* CNPJ:'
                            type='text'
                            mask='99.999.999/9999-99'
                            value={this.state.fornecedorDetalhes.cnpj}
                            disabled={true}
                        />
                    </Col>
                    <Col md={6}>
                        <TextInputMask
                            label='* Inscrição Estadual:'
                            type='text'
                            mask='99.999.999-9'
                            value={this.state.fornecedorDetalhes.inscricaoEstadual}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SimpleSelect
                            label='* Porte da Empresa:'
                            value={this.state.fornecedorDetalhes.porteDaEmpresa}
                            disabled
                        >

                            <option value=''></option>
                            {this.props.portesEmpresa.map((porte, index) => (
                                <option key={index} value={porte.nome}>{porte.descricao}</option>
                            ))}
                        </SimpleSelect>
                    </Col>
                </Row>
            </>
        )

        return (
            <>
                <BackButton />
                <Row>
                    <PageTitle name='Gerenciar Fornecedores' hasDivider={true} />
                </Row>


                <div align='right'>
                    <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        onClick={() => this.mostrarModalRelatorio()}
                    />
                </div>

                <Form>
                    <Form.Group>
                        <Form.Label>Pesquisar por nome:</Form.Label>
                        <Row>
                            <Col md={12}>
                                <Form.Control type='text'
                                    value={this.state.pesquisaNome}
                                    onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value }, () => this.props.filtrarFornecedores(this.state.pesquisaNome))} />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Tipo</th>
                            {/* <th>E-mail</th> */}
                            <th>Logradouro</th>
                            {/* <th>Número</th> */}
                            {/* <th>Complemento</th> */}
                            <th>Bairro/Distrito</th>
                            <th>Município</th>
                            <th>UF</th>
                            {/* <th>CEP</th> */}
                            <th>Telefone</th>
                            <th>Celular</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(fornecedor => (
                            <tr key={fornecedor.id}>
                                <td>{fornecedor.hasOwnProperty('nome') ? fornecedor.nome : fornecedor.nomeFantasia}</td>
                                <td>{fornecedor.fornecedorTipo}</td>
                                {/* <td>{fornecedor.email}</td> */}
                                <td>{fornecedor.logradouro}</td>
                                {/* <td>{fornecedor.numero}</td> */}
                                {/* <td>{fornecedor.complemento}</td> */}
                                <td>{fornecedor.bairro_distrito}</td>
                                <td>{fornecedor.municipio}</td>
                                <td>{fornecedor.uf}</td>
                                {/* <td>{fornecedor.cep}</td> */}
                                <td>{fornecedor.telefone}</td>
                                <td>{fornecedor.celular}</td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Mostrar Fornecedor'
                                        onClick={() => this.handleShowModalDetalhes(fornecedor)}
                                        icon='fas fa-eye'
                                    />

                                    <ActionButton
                                        title='Editar Fornecedor'
                                        onClick={() => this.handleShowModal(fornecedor)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Fornecedor'
                                        onClick={() => this.props.excluirFornecedor(fornecedor.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Fornecedor'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do fornecedor' align='left'>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='* Tipo de fornecedor:'
                                                value={this.state.fornecedor.fornecedorTipo}
                                                onChange={e => this.handleChange(e.target.value, 'fornecedorTipo')}
                                                required
                                            >

                                                <option value='FISICO'>FÍSICO</option>
                                                <option value='JURIDICO'>JURÍDICO</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    {this.state.fornecedor.fornecedorTipo === 'FISICO' ? formFornecedorFisico : formFornecedorJuridico}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Endereço' align='left'>
                                    <Row>
                                        <Col md={4}>
                                            <TextInputMask
                                                label='* CEP:'
                                                type='text'
                                                mask='99999-999'
                                                value={this.state.fornecedor.cep}
                                                onChange={e => this.handleChangeCep(e.target.value)}
                                                loading={this.state.fetching}
                                                required
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <Input
                                                label='* Municipio:'
                                                type='text'
                                                case='upper'
                                                value={this.state.fornecedor.municipio}
                                                onChange={(value, upper) => this.handleChange(value, 'municipio', upper)}
                                                required
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Input
                                                label='* UF:'
                                                type='text'
                                                case='upper'
                                                value={this.state.fornecedor.uf}
                                                onChange={(value, upper) => this.handleChange(value, 'uf', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9}>
                                            <Input
                                                label='* Logradouro:'
                                                type='text'
                                                case='upper'
                                                value={this.state.fornecedor.logradouro}
                                                onChange={(value, upper) => this.handleChange(value, 'logradouro', upper)}
                                                required
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Input
                                                label='* Número:'
                                                type='text'
                                                case='upper'
                                                value={this.state.fornecedor.numero}
                                                onChange={(value, upper) => this.handleChange(value, 'numero', upper)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='* Bairro:'
                                                type='text'
                                                case='upper'
                                                value={this.state.fornecedor.bairro_distrito}
                                                onChange={(value, upper) => this.handleChange(value, 'bairro_distrito', upper)}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Complemento:'
                                                type='text'
                                                case='upper'
                                                value={this.state.fornecedor.complemento}
                                                onChange={(value, upper) => this.handleChange(value, 'complemento', upper)} />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Contato' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* E-mail:'
                                                type='text'
                                                value={this.state.fornecedor.email}
                                                onChange={value => this.handleChange(value, 'email')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='Telefone fixo:'
                                                type='text'
                                                mask='(99) 9999-9999'
                                                value={this.state.fornecedor.telefone}
                                                onChange={e => this.handleChange(e.target.value, 'telefone')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='Celular:'
                                                mask='(99) 99999-9999'
                                                type='text'
                                                value={this.state.fornecedor.celular}
                                                onChange={e => this.handleChange(e.target.value, 'celular')}
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalDetalhes}
                    onHide={this.handleCloseModalDetalhes}
                    title='Detalhes'
                    icon='fas fa-eye'>

                    <Form>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do fornecedor' align='left'>
                                    <Row>
                                        <Col>
                                            <SimpleSelect
                                                label='* Tipo de fornecedor:'
                                                value={this.state.fornecedorDetalhes.fornecedorTipo}
                                                disabled
                                            >

                                                <option value='FISICO'>FÍSICO</option>
                                                <option value='JURIDICO'>JURÍDICO</option>
                                            </SimpleSelect>
                                        </Col>
                                    </Row>

                                    {this.state.fornecedorDetalhes.fornecedorTipo === 'FISICO' ? detalhesFornecedorFisico : detalhesFornecedorJuridico}
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Endereço' align='left'>
                                    <Row>
                                        <Col md={4}>
                                            <TextInputMask
                                                label='* CEP:'
                                                type='text'
                                                mask='99999-999'
                                                value={this.state.fornecedorDetalhes.cep}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <Input
                                                label='* Municipio:'
                                                type='text'
                                                readOnly={!this.state.cep}
                                                value={this.state.fornecedorDetalhes.municipio}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Input
                                                label='* UF:'
                                                type='text'
                                                readOnly={!this.state.cep}
                                                value={this.state.fornecedorDetalhes.uf}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9}>
                                            <Input
                                                label='* Logradouro:'
                                                type='text'
                                                readOnly={!this.state.cep}
                                                value={this.state.fornecedorDetalhes.logradouro}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Input
                                                label='* Número:'
                                                type='text'
                                                value={this.state.fornecedorDetalhes.numero}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                label='* Bairro:'
                                                type='text'
                                                readOnly={!this.state.cep}
                                                value={this.state.fornecedorDetalhes.bairro_distrito}
                                                disabled
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                label='Complemento:'
                                                type='text'
                                                readOnly={!this.state.cep}
                                                value={this.state.fornecedorDetalhes.complemento}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Contato' align='left'>
                                    <Row>
                                        <Col>
                                            <Input
                                                label='* E-mail:'
                                                type='text'
                                                value={this.state.fornecedorDetalhes.email}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='* Telefone fixo:'
                                                type='text'
                                                mask='(99) 9999-9999'
                                                value={this.state.fornecedorDetalhes.telefone}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <TextInputMask
                                                label='* Celular:'
                                                mask='(99) 99999-9999'
                                                type='text'
                                                value={this.state.fornecedorDetalhes.celular}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>

                <SimpleModalSmall
                    show={this.state.showModalRelatorio}
                    onHide={this.fecharModalRelatorio}
                    title='Tipo do Relatório'
                >
                    <Row style={{ marginTop: '16px', marginBottom: '16px', paddingLeft: '25px' }}>
                        <Col md={6}>
                            <SimpleButton
                                color='green'
                                name='Jurídico'
                                onClick={() => this.mostrarPaginaRelatorio('JURIDICO')}
                            />
                        </Col>
                        <Col md={6}>
                            <SimpleButton
                                color='green'
                                name='Físico'
                                onClick={() => this.mostrarPaginaRelatorio('FISICO')}
                            />
                        </Col>
                    </Row>

                </SimpleModalSmall>
            </>
        )
    }
}

const mapStateToProps = state => ({
    list: state.fornecedor.list,
    filtrados: state.fornecedor.filtrados,
    token: state.auth.token,
    closeModalNumbers: state.fornecedor.closeModalNumbers,
    portesEmpresa: state.convencao.portesEmpresa
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarFornecedores,
    editarFornecedor,
    excluirFornecedor,
    listarPortesEmpresa,
    filtrarFornecedores
}, dispatch);

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarFornecedoresPage)), 'FORNECEDORES');