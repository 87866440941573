import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { checkValidToken } from '../actions/auth.action';

import * as routes from './constants';
import App from '../App';

class PrivateRoute extends Component {
    render() {
        const { component: Component, auth, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    auth.isValidToken ? (
                        <div>
                            <App>
                                <Component {...props} />
                            </App>
                        </div>
                    ) : (
                        <Redirect to={routes.LOGIN} />
                    )
                }
            />
        )
    }
}

const mapStateToProps = state => ({ auth: state.auth })

const mapDispatchToProps = dispatch => bindActionCreators({ checkValidToken }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));