import React, { Component } from 'react';

import Title from '../components/Title';
import Detail from '../components/Detail';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filtrarItens, listarItens } from '../../actions';

import styles from './styles.module.css';

class Itens extends Component {

    state = {
        itens: [],
    }

    componentDidMount() {
        const data = this.props.data.split('=');
        var pesquisaNome, pesquisaTipo = '';
        if (data[1] === 'true') {
            pesquisaNome = sessionStorage.getItem('pesquisaNome');
            pesquisaTipo = sessionStorage.getItem('pesquisaTipo');
            //
            this.props.filtrarItens(pesquisaNome, pesquisaTipo);
        } else if (data[1] === 'false') {
            this.props.listarItens(this.props.token);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.itens !== this.props.itens) this.setState({ ...this.state, itens: nextProps.itens });
    }

    componentWillUnmount() {
        sessionStorage.removeItem('pesquisaNome');
        sessionStorage.removeItem('pesquisaTipo');
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Itens' />

                    <Detail>
                        <table border='1' className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.titulo}>Nome</th>
                                    <th className={styles.titulo}>Tipo</th>
                                    <th className={styles.titulo}>Categoria</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.itens.map((item, index) => (
                                        <tr key={index} >
                                            <td className={styles.tipo}>{item.nome}</td>
                                            <td className={styles.tipo}>{item.itemProdutoTipo}</td>
                                            <td className={styles.tipo}>{item.categoria}</td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                        </table>
                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    itens: state.item.filtrados
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarItens,
    filtrarItens
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Itens);