import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { ADICIONAR_PRODUTO_ESTOQUE, LISTAR_ITENS_PRODUTOS, REALIZAR_SAIDA_PRODUTO_ESTOQUE, REALIZAR_BALANCO_PRODUTO_ESTOQUE, LISTAR_ESTOQUES, LISTAR_PRODUTOS_ESTOQUE, FILTRAR_PRODUTOS_ESTOQUE, LISTAR_ESTOQUE, LISTAR_ESTOQUE_ITENS, EXCLUIR_PRODUTO_ESTOQUE, LISTAR_BALANCOS, LISTAR_ALUNOS_KITS } from './types';

import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

export function listarEstoques(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_ESTOQUES, payload: res.data }))
    }
}

export function listarEstoquesUsuario(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/me`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_ESTOQUES, payload: res.data }))
    }
}

export function listarEstoqueItens(estoqueId, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/${estoqueId}/produtos-estoque`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: LISTAR_ESTOQUE_ITENS, payload: res.data },
                    { type: FILTRAR_PRODUTOS_ESTOQUE, payload: res.data },
                ])
            })
        axios.get(`${BASE_URL}/itens-produto`, configAuthorizationToken(token))
        .then(res => {
            dispatch([
                { type: LISTAR_ITENS_PRODUTOS, payload: res.data },
            ])
        })
    }
}

export function listarPesquisaEstoques(estoqueId, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/me/${estoqueId}`, configAuthorizationToken(token))
            .then(res => dispatch([
                { type: LISTAR_ESTOQUE, payload: res.data },
                { type: FILTRAR_PRODUTOS_ESTOQUE, payload: res.data },
            ]))
    }
}

export function filtrarProdutosEstoque(produto) {
    return (dispatch, getState) => {
        let estoque = JSON.parse(JSON.stringify(getState().estoque.onlyOne));

        let newEstoque = estoque.filter(item => item.produtoLote.produto.nome.toLowerCase().includes(!!produto ? produto.toLowerCase() : ''))
        dispatch({ type: FILTRAR_PRODUTOS_ESTOQUE, payload: newEstoque})

    }
}

export function filtrarItemEstoque(item) {
    return (dispatch, getState) => {
        let estoque = JSON.parse(JSON.stringify(getState().estoque.onlyOne));
        //
        let newEstoque = estoque.filter(i => i.produtoLote.produto.item.toLowerCase().includes(!!item ? item.toLowerCase() : ''))
        dispatch({ type: FILTRAR_PRODUTOS_ESTOQUE, payload: newEstoque})
    }
}

export function filtrarLoteEstoque(lote) {
    return (dispatch, getState) => {
        let estoque = JSON.parse(JSON.stringify(getState().estoque.onlyOne));
        let newEstoque = estoque.filter( item => item.produtoLote.numero.includes(!!lote ? lote : ''))
        dispatch({ type: FILTRAR_PRODUTOS_ESTOQUE, payload: newEstoque})
        // let newEstoque = estoque.map(e => {
        //     let estoqueFiltrado = e.produtos.map(p => {
        //         let produtosFiltrados = p.produtosLote.filter(l => {
        //             return l.numero.includes(!!lote ? lote : '')
        //         })

        //         if (produtosFiltrados.length > 0) return { ...p, produtos: produtosFiltrados.filter(p => !!p) }
        //         else return null
        //     })
        //     if (estoqueFiltrado.length > 0) return { ...e, produtos: estoqueFiltrado.filter(e => !!e) }
        //     else return null
        // })
        // dispatch({ type: FILTRAR_PRODUTOS_ESTOQUE, payload: newEstoque.filter(e => e.produtos.length > 0) })
    }
}

export function filtrarValidadeEstoque(validade) {
    return (dispatch, getState) => {
        let estoque = JSON.parse(JSON.stringify(getState().estoque.onlyOne));
        let newEstoque = estoque.filter( item => {
            return item.produtoLote.dataValidade.includes(validade)})
        dispatch({ type: FILTRAR_PRODUTOS_ESTOQUE, payload: newEstoque})
       
        // let newEstoque = estoque.map(e => {
        //     let estoqueFiltrado = e.produtos.map(p => {
        //         let produtosFiltrados = p.produtosLote.filter(l => {
        //             return l.dataDeValidade.includes(validade === 'Invalid date' ? '' : validade)
        //         })

        //         if (produtosFiltrados.length > 0) return { ...p, produtos: produtosFiltrados.filter(p => !!p) }
        //         else return null
        //     })
        //     if (estoqueFiltrado.length > 0) return { ...e, produtos: estoqueFiltrado.filter(e => !!e) }
        //     else return null
        // })
        // dispatch({ type: FILTRAR_PRODUTOS_ESTOQUE, payload: newEstoque.filter(e => e.produtos.length > 0) })
    }
}

export function listarProdutosEstoque(idEstoque, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/${idEstoque}/produtos-estoque`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_PRODUTOS_ESTOQUE, payload: res.data }))
    }
}

export function adicionarProdutoEstoque(idEstoque, produto, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/estoques/${idEstoque}/produtos-estoque`, produto, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Produto adicionado ao estoque com sucesso!')
                        dispatch([
                            { type: ADICIONAR_PRODUTO_ESTOQUE, payload: {} },
                            listarEstoqueItens(idEstoque, token)
                        ])
                    })
                    .catch(err => {
                        dispatch(listarEstoqueItens(idEstoque, token))
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente adicionar o Produto ao Estoque?' />
            )
        })

    }
}

export function excluirProdutoEstoque(idEstoque, idProduto, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/estoques/${idEstoque}/produtos-estoque/${idProduto}`, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Produto excluído do estoque com sucesso!')
                        dispatch([
                           { type: EXCLUIR_PRODUTO_ESTOQUE , payload: {} },
                           listarEstoqueItens(idEstoque, token)
                        ])
                    })
                    .catch(err => {
                        dispatch(listarPesquisaEstoques(idEstoque, token))
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Produto do Estoque?' />
            )
        })
    }
}

/**
 * Essa edição de produto estoque serve para realizar a Saida-Direta
 */
export function realizarSaidaProdutoEstoque(idEstoque, idProdutoEstoque, saida, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/estoques/${idEstoque}/produtos-estoque/${idProdutoEstoque}`, saida, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Saida realizada com sucesso!')
                        dispatch([
                            { type: REALIZAR_SAIDA_PRODUTO_ESTOQUE, payload: {} },
                            listarEstoqueItens(idEstoque, token)
                        ])
                    })
                    .catch(err => {
                        dispatch(listarEstoqueItens(idEstoque, token))
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente realizar a saída desse Produto do Estoque?' />
            )
        })
    }
}

export function realizarBalancoProdutoEstoque(idEstoque, idProdutoEstoque, balanco, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/estoques/${idEstoque}/balancos/produtos-estoque/${idProdutoEstoque}`, balanco, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Balanço realizado com sucesso!')
                        dispatch([
                            { type: REALIZAR_BALANCO_PRODUTO_ESTOQUE, payload: {} },
                            listarEstoqueItens(idEstoque, token)
                        ])
                    })
                    .catch(err => {
                        dispatch(listarEstoqueItens(idEstoque, token))
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente realizar o balanço desse Produto do Estoque?' />
            )
        })
    }
}

export function listarBalancos(idEstoque, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/${idEstoque}/balancos`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_BALANCOS, payload: res.data }))
    }
}

export function estoqueCriarKitMerenda(idEstoque, kit, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/estoques/${idEstoque}/produtos-estoque/kit`, kit, configAuthorizationToken(token))
                    .then(res => {
                        toastr.success('Sucesso', 'Kit criado com sucesso!')
                        dispatch([
                           // { type: REALIZAR_SAIDA_PRODUTO_ESTOQUE, payload: {} },
                            listarEstoqueItens(idEstoque, token)
                        ])
                    })
                    .catch(err => {
                        dispatch(listarEstoqueItens(idEstoque, token))
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente realizar a saída desse Produto do Estoque?' />
            )
        })
    }
}

export function listarAlunosHistorico(idEstoque, idTurma, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/estoques/${idEstoque}/unidade/${idTurma}/kit`, configAuthorizationToken(token))
            .then(res => {dispatch({ type: LISTAR_ALUNOS_KITS, payload: res.data })
            })
    }
}