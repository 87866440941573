import React from 'react';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Header from '../components/Header';
import Title from '../components/Title';
import Printable from '../components/Printable';
import Info from '../components/Info/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { recebimentoCompra } from '../../actions';

import styles from './styles.module.css';
import { Form, Row, Col } from 'react-bootstrap';

class RecebimentoCompra extends React.Component {

    state = {
        recebimento: {
            produtoLote: [],
            categorias: [
                { faixaEtaria: -1}
            ],
            solicitacaoCompra: {
                fornecedor: {},
                unidadeEducacional: {}
            }
        }
    }

    componentWillMount() {
        this.props.recebimentoCompra(this.props.data, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.recebimento !== this.props.recebimento) this.setState({ ...this.state, recebimento: nextProps.recebimento }, () => console.log(this.state.recebimento));
    }

    render() {
        return (
            <>
                <div className={styles.topbuttons}>
                    <BackButton />
                    <PrintButton />
                </div>
                <div className='mb-3' align="center">
                    <Form.Check
                        type='checkbox'
                        id='custom-checkbox'
                        inline
                        checked={this.state.etiquetas}
                        onChange={() => this.setState({ etiquetas: !this.state.etiquetas })}
                    />
                    <Form.Label>* Imprimir Etiqueta</Form.Label>
                </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Recebimento de Compra' />

                    <Row>
                        <Col>
                            <b>Fornecedor: </b> {this.state.recebimento.solicitacaoCompra.fornecedor.nome}
                        </Col>
                        <Col>
                            <b>Data de Solicitação: </b>{this.state.recebimento.solicitacaoCompra.dataSolicitacao}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <b>Unidade Educacional: </b> {this.state.recebimento.solicitacaoCompra.unidadeEducacional.nome}
                        </Col>
                        <Col>
                            <b>Status: </b>{this.state.recebimento.solicitacaoCompra.status}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <b>NF: </b> {this.state.recebimento.notaFiscal}
                        </Col>

                        <Col>
                            <b>Modalidade: </b> {formatterCategories(this.state.recebimento.solicitacaoCompra.categorias)}
                        </Col>
                    </Row>

                    <Row>
                        <Col className={styles.borda}>
                            <b>Observação: </b> {this.state.recebimento.observacao}
                        </Col>
                    </Row>

                    <table border='1' className={styles.tableItens}>
                        <thead>
                            <tr className={styles.dados}>
                                <th>Nº</th>
                                <th>Produto</th>
                                <th>Unidade Medida</th>
                                <th>Unidade do Produto</th>
                                <th>Medida</th>
                                <th>Lote</th>
                                <th>Data Fab.</th>
                                <th>Data Val.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.recebimento.produtoLote.map((produto, index) => (
                                <tr key={index}>
                                    {/* <td className={styles.dados}>{this.state.recebimento.notaFiscal}</td> */}
                                    <td className={styles.dados}>{index +1 }</td>
                                    <td className={styles.produto}>{produto.produto.nome}</td>
                                    <td className={styles.dados}>{produto.produto.unidadeMedida}</td>
                                    <td className={styles.dados}>{produto.produto.unidadeProduto}</td>
                                    <td className={styles.dados}>{produto.produto.medidaReal}</td>
                                    <td className={styles.dados}>{produto.numero}</td>
                                    <td className={styles.dados}>{produto.dataFabricacao}</td>
                                    <td className={styles.dados}>{produto.dataValidade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className={this.state.etiquetas ? '' : 'hide'}>
                        <hr className={styles.corte} />

                        <div className='row'>
                            {
                                this.state.recebimento.produtoLote.map((lote, i) => (
                                    <div className={`col-md-6 ${styles.lote}`} key={i}>
                                        <h2 className={styles.txtLote}>Lote: {lote.numero}</h2>
                                        <h6><b>Produto: </b>{lote.produto.nome}</h6>
                                        <h6><b>Val: </b>{lote.dataValidade}</h6>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    recebimento: state.solicitacao.recebimento
})

const mapDispatchToProps = dispatch => bindActionCreators({
    recebimentoCompra
}, dispatch)

const formatterCategories = (array) => {
    let label = '';

    if (array) {
        array.map((a, index) => {
            label += a.modalidade +'/'+ a.faixaEtaria.nome
    
            if (index < array.length -1) {
                label += ", "
            }
        })
    }

    return label;
}  

export default connect(mapStateToProps, mapDispatchToProps)(RecebimentoCompra);