export const LOGIN = 'LOGIN';
export const TOKEN_VALIDATED = 'TOKEN_VALIDATED'; 
export const LOGOUT = 'LOGOUT';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const IS_LOADING_AUTH =  'IS_LOADING_AUTH';

export const CADASTRAR_ITEM = 'CADASTRAR_ITEM';
export const LISTAR_ITENS = 'LISTAR_ITENS';
export const FILTRAR_ITENS = 'FILTRAR_ITENS';
export const EDITAR_ITEM = 'EDITAR_ITEM';
export const EXCLUIR_ITEM = 'EXCLUIR_ITEM';

export const CADASTRAR_PRODUTO = 'CADASTRAR_PRODUTO';
export const LISTAR_PRODUTOS = 'LISTAR_PRODUTOS';
export const FILTRAR_PRODUTOS = 'FILTRAR_PRODUTOS';
export const EDITAR_PRODUTO = 'EDITAR_PRODUTO';
export const EXCLUIR_PRODUTO = 'EXCLUIR_PRODUTO';

export const CADASTRAR_USUARIO = 'CADASTRAR_USUARIO';
export const LISTAR_USUARIOS = 'LISTAR_USUARIOS';
export const FILTRAR_USUARIOS = 'FILTRAR_USUARIOS';
export const EDITAR_USUARIO = 'EDITAR_USUARIO';
export const EXCLUIR_USUARIO = 'EXCLUIR_USUARIO';
// export const EDITAR_DADOS_CADASTRAIS_USUARIO = 'EDITAR_DADOS_CADASTRAIS_USUARIO';
export const ATUALIZAR_FOTO_USUARIO = 'ATUALIZAR_FOTO_USUARIO';
export const RECUPERAR_SENHA_USUARIO = 'RECUPERAR_SENHA_USUARIO';
export const BUSCAR_NOTIFICACOES = 'BUSCAR_NOTIFICACOES';
export const BUSCAR_SECRETARIA = 'BUSCAR_SECRETARIA';

export const CADASTRAR_FORNECEDOR = 'CADASTRAR_FORNECEDOR';
export const LISTAR_FORNECEDORES = 'LISTAR_FORNECEDORES';
export const FILTRAR_FORNECEDORES = 'FILTRAR_FORNECEDORES';
export const EDITAR_FORNECEDOR = 'EDITAR_FORNECEDOR';
export const EXCLUIR_FORNECEDOR = 'EXCLUIR_FORNECEDOR';
export const LISTAR_ITENS_FORNECEDOR = 'LISTAR_ITENS_FORNECEDOR';
export const LISTAR_FORNECEDOR_POR_ID = 'LISTAR_FORNECEDOR_POR_ID';

export const CADASTRAR_AQUISICAO = 'CADASTRAR_AQUISICAO';
export const LISTAR_AQUISICOES = 'LISTAR_AQUISICOES';
export const LISTAR_UMA_AQUISICAO = 'LISTAR_UMA_AQUISICAO';
export const FILTRAR_AQUISICOES = 'FILTRAR_AQUISICOES';
export const EDITAR_AQUISICAO = 'EDITAR_AQUISICAO';
export const EXCLUIR_AQUISICAO = 'EXCLUIR_AQUISICAO';
export const BUSCAR_MOTIVO_CANCELAMENTO_AQUISICAO = 'BUSCAR_MOTIVO_CANCELAMENTO_AQUISICAO';
export const LISTAR_ITENS_AQUISICAO = 'LISTAR_ITENS_AQUISICAO';

export const CADASTRAR_ADITIVO = 'CADASTRAR_ADITIVO';
export const LISTAR_ADITIVOS = 'LISTAR_ADITIVOS';
export const EDITAR_ADITIVO = 'EDITAR_ADITIVO';
export const ATUALIZAR_ADITIVOS = 'ATUALIZAR_ADITIVOS';
export const SET_AQUISICAO_ADITIVO = 'SET_AQUISICAO_ADITIVO';

export const CADASTRAR_SOLICITACAO = 'CADASTRAR_SOLICITACAO';
export const LISTAR_SOLICITACOES = 'LISTAR_SOLICITACOES';
export const LISTAR_UMA_SOLICITACAO = 'LISTAR_UMA_SOLICITACAO';
export const FILTRAR_SOLICITACOES = 'FILTRAR_SOLICITACOES';
export const EDITAR_SOLICITACAO = 'EDITAR_SOLICITACAO';
export const EXCLUIR_SOLICITACAO = 'EXCLUIR_SOLICITACAO';
export const BUSCAR_MOTIVO_CANCELAMENTO_SOLICITACAO = 'BUSCAR_MOTIVO_CANCELAMENTO_SOLICITACAO';
export const PRODUTOS_RECEBIDOS = 'PRODUTOS_RECEBIDOS';
export const IMAGEM_NOTA_FISCAL = 'IMAGEM_NOTA_FISCAL';
export const EDITAR_NOTA_FISCAL = 'EDITAR_NOTA_FISCAL';
export const EDITAR_IMAGEM_NOTA_FISCAL = 'EDITAR_IMAGEM_NOTA_FISCAL';
export const RECEBIMENTO_COMPRA = 'RECEBIMENTO_COMPRA';

export const LISTAR_ESTOQUES = 'LISTAR_ESTOQUES';
export const ENDERECO_UNIDADE = 'ENDERECO_UNIDADE';
export const ADICIONAR_PRODUTO_ESTOQUE = 'ADICIONAR_PRODUTO_ESTOQUE';
export const REALIZAR_SAIDA_PRODUTO_ESTOQUE = 'REALIZAR_SAIDA_PRODUTO_ESTOQUE';
export const REALIZAR_BALANCO_PRODUTO_ESTOQUE = 'REALIZAR_BALANCO_PRODUTO_ESTOQUE';
export const LISTAR_ESTOQUE = 'LISTAR_ESTOQUE';
export const LISTAR_ESTOQUE_ITENS = 'LISTAR_ESTOQUE_ITENS';
export const FILTRAR_PRODUTOS_ESTOQUE = 'FILTRAR_PRODUTOS_ESTOQUE';  
export const EXCLUIR_PRODUTO_ESTOQUE = 'EXCLUIR_PRODUTO_ESTOQUE';
export const LISTAR_PRODUTOS_ESTOQUE = 'LISTAR_PRODUTOS_ESTOQUE';
export const LISTAR_ITENS_PRODUTOS = 'LISTAR_ITENS_PRODUTOS';
export const LISTAR_BALANCOS = 'LISTAR_BALANCOS';
export const LISTAR_ALUNOS_KITS = 'LISTAR_ALUNOS_KITS';

export const CADASTRAR_MOVIMENTACAO = 'CADASTRAR_MOVIMENTACAO';
export const LISTAR_MOVIMENTACOES = 'LISTAR_MOVIMENTACOES';
export const LISTAR_UMA_MOVIMENTACAO = 'LISTAR_UMA_MOVIMENTACAO';
export const MOVIMENTACAO_RELATORIO = 'MOVIMENTACAO_RELATORIO';
export const FILTRAR_UNIDADE = 'FILTRAR_UNIDADE';
export const LIMPAR_LISTA = 'LIMPAR_LISTA';
export const EDITAR_MOVIMENTACAO = 'EDITAR_MOVIMENTACAO';
export const CANCELAR_MOVIMENTACAO = 'CANCELAR_MOVIMENTACAO';
export const BUSCAR_MOTIVO_CANCELAMENTO_MOVIMENTACAO = 'BUSCAR_MOTIVO_CANCELAMENTO_MOVIMENTACAO';

// export const CADASTRAR_PROPOSTA = 'CADASTRAR_PROPOSTA';
// export const LISTAR_PROPOSTAS = 'LISTAR_PROPOSTAS';
// export const EDITAR_PROPOSTA = 'EDITAR_PROPOSTA';
// export const EXCLUIR_PROPOSTA = 'EXCLUIR_PROPOSTA';

export const LISTAR_TIPOS_COMPONENTES_NUTRICIONAIS = 'LISTAR_TIPOS_COMPONENTES_NUTRICIONAIS';
export const LISTAR_UNIDADES_MEDIDA_NUTRICIONAIS = 'LISTAR_UNIDADES_MEDIDA_NUTRICIONAIS';
export const LISTAR_CATEGORIAS_ALIMENTOS = 'LISTAR_CATEGORIAS_ALIMENTOS';
export const LISTAR_FORMAS_ALIMENTOS = 'LISTAR_FORMAS_ALIMENTOS';
export const LISTAR_GRUPOS_NUTRIENTES = 'LISTAR_GRUPOS_NUTRIENTES'; 
export const LISTAR_TIPOS_PERMISSOES = 'LISTAR_PERMISSOES';
export const LISTAR_CATEGORIAS = 'LISTAR_CATEGORIAS';
export const LISTAR_TIPOS_REFEICAO = 'LISTAR_TIPOS_REFEICAO'; 
export const LISTAR_GRUPOS_ALIMENTARES = 'LISTAR_GRUPOS_ALIMENTARES';
export const LISTAR_MODALIDADES = 'LISTAR_MODALIDADES';
export const LISTAR_REFERENCIAS_NUTRICIONAIS = 'LISTAR_REFERENCIAS_NUTRICIONAIS';
export const LISTAR_PORTES_EMPRESA = 'LISTAR_PORTES_EMPRESA';
export const LISTAR_MODALIDADES_LICITACAO = 'LISTAR_MODALIDADES_LICITACAO';
export const LISTAR_TIPOS_LICITACAO = 'LISTAR_TIPOS_LICITACAO';
export const LISTAR_STATUS_AQUISICAO = 'LISTAR_STATUS_AQUISICAO'; 
export const LISTAR_STATUS_COMPRA = 'LISTAR_STATUS_COMPRA'; 
export const LISTAR_UNIDADES_MEDIDA = 'LISTAR_UNIDADES_MEDIDA';
export const LISTAR_TIPOS_PESSOA = 'LISTAR_TIPOS_PESSOA'; 
export const LISTAR_CARGOS = 'LISTAR_CARGOS';
export const LISTAR_UNIDADES_PRODUTO = 'LISTAR_UNIDADES_PRODUTO';
export const LISTAR_PATOLOGIAS = 'LISTAR_PATOLOGIAS';

export const CADASTRAR_UNIDADE_EDUCACIONAL = 'CADASTRAR_UNIDADES_EDUCACIONAL';
export const EDITAR_UNIDADE_EDUCACIONAL = 'EDITAR_UNIDADE_EDUCACIONAL';
export const EXCLUIR_UNIDADE_EDUCACIONAL = 'EXCLUIR_UNIDADE_EDUCACIONAL';
export const LISTAR_UNIDADES_EDUCACIONAIS = 'LISTAR_UNIDADES_EDUCACIONAIS';
export const FILTRAR_UNIDADES = 'FILTRAR_UNIDADES';
export const FILTRAR_NOTIFICACOES = 'FILTRAR_NOTIFICACOES';
// ALIMENTAÇÃO
export const CADASTRAR_ALIMENTO = 'CADASTRAR_ALIMENTO';
export const LISTAR_ALIMENTOS = 'LISTAR_ALIMENTOS';
export const LISTAR_COMPONENTES_NUTRICIONAIS = 'LISTAR_COMPONENTES_NUTRICIONAIS';
export const FILTRAR_ALIMENTOS = 'FILTRAR_ALIMENTOS'; 
export const EDITAR_ALIMENTO = 'EDITAR_ALIMENTO';
export const EXCLUIR_ALIMENTO = 'EXCLUIR_ALIMENTO';
  
export const CADASTRAR_PREPARO = 'CADASTRAR_PREPARO';
export const LISTAR_PREPAROS = 'LISTAR_PREPAROS';
export const FILTRAR_PREPAROS = 'FILTRAR_PREPAROS';
export const EDITAR_PREPARO = 'EDITAR_PREPARO';
export const EXCLUIR_PREPARO = 'EXCLUIR_PREPARO';
export const LISTAR_PREPAROS_ID = 'LISTAR_PREPAROS_ID';

export const CADASTRAR_PRATO = 'CADASTRAR_PRATO';
export const LISTAR_PRATOS = 'LISTAR_PRATOS';
export const FILTRAR_PRATOS = 'FILTRAR_PRATOS';
export const EDITAR_PRATO = 'EDITAR_PRATO';
export const EXCLUIR_PRATO = 'EXCLUIR_PRATO';
export const LISTAR_PRATO_POR_ID = 'LISTAR_PRATO_POR_ID';

export const CADASTRAR_CARDAPIOS = 'CADASTRAR_CARDAPIOS';
export const LISTAR_CARDAPIOS = 'LISTAR_CARDAPIOS';
export const FILTRAR_CARDAPIOS = 'FILTRAR_CARDAPIOS';
export const LISTAR_CARDAPIOS_SEMANAIS = 'LISTAR_CARDAPIOS_SEMANAIS';
export const FILTRAR_CARDAPIOS_SEMANAIS = 'FILTRAR_CARDAPIOS_SEMANAIS';
export const LISTAR_CARDAPIOS_MENSAIS = 'LISTAR_CARDAPIOS_MENSAIS';
export const EDITAR_CARDAPIOS = 'EDITAR_CARDAPIOS';
export const EXCLUIR_CARDAPIO = 'EXCLUIR_CARDAPIO';

export const LISTAR_CARDAPIOS_CALENDARIO = 'LISTAR_CARDAPIOS_CALENDARIO';
export const ACAO_CARDAPIO_CALENDARIO = 'ACAO_CARDAPIO_CALENDARIO';
export const CALENDARIO_RELATORIO = 'CALENDARIO_RELATORIO';

// EXECUÇÃO
export const LISTAR_CARDAPIOS_LANCAMENTOS_DIA = 'LISTAR_CARDAPIOS_LANCAMENTOS_DIA';
export const SUBSTITUIR_PRATO_CARDAPIO_LANCAMENTO = 'SUBSTITUIR_PRATO_CARDAPIO_LANCAMENTO';
export const REALIZAR_EXECUCAO_ALIMENTOS_CARDAPIO = 'REALIZAR_EXECUCAO_ALIMENTOS_CARDAPIO';
export const BUSCAR_PRODUTOS_ESTOQUE_REFEICAO = 'BUSCAR_PRODUTOS_ESTOQUE_REFEICAO';
export const LISTAR_EXECUCOES_CARDAPIOS = 'LISTAR_EXECUCOES_CARDAPIOS';
export const FILTRAR_EXECUCOES_CARDAPIOS = 'FILTRAR_EXECUCOES_CARDAPIOS';
export const LISTAR_UMA_EXECUCAO_CARDAPIOS = 'LISTAR_UMA_EXECUCAO_CARDAPIOS'; 
export const LISTAR_ALIMENTOS_REGISTRO = 'LISTAR_ALIMENTOS_REGISTRO';
export const LISTAR_REFEICAO = 'LISTAR_REFEICAO';
export const REGISTRAR_SOBRA = 'REGISTRAR_SOBRA';
export const LISTAR_DESPERDICIOS = 'LISTAR_DESPERDICIOS'; 
export const BUSCAR_QUANTIDADE_ALUNOS_ALIMENTACAO = 'BUSCAR_QUANTIDADE_ALUNOS_ALIMENTACAO';

// ESCOLA
export const LISTAR_TURMAS = 'LISTAR_ESCOLAS';
export const FILTRAR_TURMAS = 'FILTRAR_ESCOLAS';
export const LISTAR_ALUNOS = 'LISTAR_ALUNOS';