import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { CADASTRAR_FORNECEDOR, LISTAR_FORNECEDORES, LISTAR_ITENS_FORNECEDOR, FILTRAR_FORNECEDORES, EDITAR_FORNECEDOR, LISTAR_FORNECEDOR_POR_ID } from './types';

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';

function transformFornecedor(fornecedor) {
    let transformedFornecedor;
    switch (fornecedor.fornecedorTipo) {
        case 'FISICO':
            delete fornecedor['fornecedorjuridico']

            transformedFornecedor = {
                ...fornecedor,
                ...fornecedor.fornecedorfisico
            }

            delete transformedFornecedor['fornecedorfisico']
            transformedFornecedor.cpf = transformedFornecedor.cpf.replace(new RegExp(/\D/g), '');
            break;

        case 'JURIDICO':
            delete fornecedor['fornecedorfisico']

            transformedFornecedor = {
                ...fornecedor,
                ...fornecedor.fornecedorjuridico
            }

            delete transformedFornecedor['fornecedorjuridico']
            transformedFornecedor.cnpj = transformedFornecedor.cnpj.replace(new RegExp(/\D/g), '');
            transformedFornecedor.inscricaoEstadual = transformedFornecedor.inscricaoEstadual.replace(new RegExp(/\D/g), '');
            break;

        default:
            break;
    }

    transformedFornecedor.cep = transformedFornecedor.cep.replace(new RegExp(/\D/g), '');
    transformedFornecedor.telefone = transformedFornecedor.telefone.replace(new RegExp(/\D/g), '');
    transformedFornecedor.celular = transformedFornecedor.celular.replace(new RegExp(/\D/g), '');

    return transformedFornecedor;
}

export function cadastrarFornecedor(fornecedor, token) {
    return dispatch => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/fornecedores`, transformFornecedor(fornecedor), configAuthorizationToken(token))
                .then(res => {
                        dispatch({ type: CADASTRAR_FORNECEDOR, payload: {} })
                        toastr.success('Sucesso', 'Fornecedor cadastrado com sucesso!')
                    })
                    .catch(err => toastr.error('Erro', extractErrorMessage(err)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar o Fornecedor?' />
            )
        })
    }
}

export function listarFornecedores(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/fornecedores`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: LISTAR_FORNECEDORES, payload: res.data },
                    { type: FILTRAR_FORNECEDORES, payload: res.data }
                ])
            })
            .catch(err => console.log(err))
    }
}

export function listarFornecedoresPorTipo(fornecedorTipo, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/fornecedores?fornecedorTipo=${fornecedorTipo}`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: LISTAR_FORNECEDORES, payload: res.data }
                ])
            })
            .catch(err => console.log(err))
    }
}

export function listarFornecedorPorId(idFornecedor, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/fornecedores/${idFornecedor}`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: LISTAR_FORNECEDOR_POR_ID, payload: res.data },
                ])
            })
            .catch(err => console.log(err))
    }
}

export function filtrarFornecedores(nome) {
    return (dispatch, getState) => {
        let fornecedores = getState().fornecedor.list;
        let fornecedoresFiltrados = fornecedores
            .filter(fornecedor => {
                if (!!fornecedor.nome) return fornecedor.nome.toLowerCase().includes(nome.toLowerCase())
                else return fornecedor.nomeFantasia.toLowerCase().includes(nome.toLowerCase())
            })

        dispatch({ type: FILTRAR_FORNECEDORES, payload: fornecedoresFiltrados })
    }
}

export function listarItensFornecedor(idFornecedor, idAquisicao, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/fornecedores/${idFornecedor}/itens?aquisicao=${idAquisicao}`, configAuthorizationToken(token))
            .then(res => dispatch({ type: LISTAR_ITENS_FORNECEDOR, payload: res.data }))
            .catch(err => console.log(err))
    }
}

export function editarFornecedor(fornecedor, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/fornecedores/${fornecedor.id}`, transformFornecedor(fornecedor), configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_FORNECEDOR, payload: {} }, 
                            listarFornecedores(token)
                        ])
                        toastr.success('Sucesso', 'Fornecedor editado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarFornecedores(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Fornecedor?' />
            )
        })
    }
}

export function excluirFornecedor(id, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/fornecedores/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatch(listarFornecedores(token))
                        toastr.success('Sucesso', 'Fornecedor excluído com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarFornecedores(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Fornecedor?' />
            )
        })
    }
}