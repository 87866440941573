import React from 'react';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Header from '../components/Header';
import Title from '../components/Title';
import Printable from '../components/Printable';
import Info from '../components/Info/index';
import { MoneyFormat } from '../../utils/moneyFormat';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarUmaSolicitacao } from '../../actions';

import styles from './styles.module.css';
import { Form } from 'react-bootstrap';

class SolicitacaoCompra extends React.Component {

    state = {
        solicitacao: {
            fornecedor: {},
            unidadeEducacional: {},
            itens: [],
            categorias: [
                { faixaEtaria: -1}
            ],
            propostaVencedora: {
                itens: []
            },
            etiquetas: true
        }
    }

    count = 0;

    componentWillMount() {
        this.props.listarUmaSolicitacao(this.props.data, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.solicitacao !== this.props.solicitacao) this.setState({ ...this.state, solicitacao: nextProps.solicitacao }, () => console.log(this.state));
    }

    render() {
        return (
            <>
                <div className={styles.topbuttons}>
                    <BackButton />
                    <PrintButton />
                </div>
                <div className='mb-3' align="center">
                    <Form.Check
                        type='checkbox'
                        id='custom-checkbox'
                        inline
                        checked={this.state.etiquetas}
                        onChange={() => this.setState({ etiquetas: !this.state.etiquetas })}
                    />
                    <Form.Label>* Imprimir Etiqueta</Form.Label>
                </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Solicitação de Compra' />

                    <br />

                    <div className={styles.detail}>

                        <div className='row'>
                            <div className='col'>
                                <b>Fornecedor:</b> {this.state.solicitacao.fornecedor.nome}
                            </div>
                            <div className='col'>
                                <b>Data de Solicitação:</b> {this.state.solicitacao.dataSolicitacao}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Destinatário:</b> {this.state.solicitacao.unidadeEducacional.nome}
                            </div>
                            <div className='col'>
                                <b>Aquisição:</b> {this.state.solicitacao.licitacao}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Situação:</b> {this.state.solicitacao.status}
                            </div>
                            <div className='col'>
                                <b>Modalidade:</b> {formatterCategories(this.state.solicitacao.categorias)}
                            </div>
                        </div>


                        <br />

                        <div className='row'>
                            <div className='col'>

                                <table border='1' className={styles.tableItens}>
                                    <thead>
                                        <tr>
                                            <th>Produto</th>
                                            <th>Unidade Medida</th>
                                            <th>Unidade do Produto</th>
                                            <th>Quantidade</th>
                                            <th>Valor Unitário</th>
                                            <th>Valor Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.solicitacao.itens.map((item, itemIndex) => (
                                        // {this.state.solicitacao.propostaVencedora.itens.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                <td className={styles.produto}>{item.produto.nome}</td>
                                                <td>{item.produto.unidadeMedida}</td>
                                                <td>{item.produto.unidadeProduto}</td>
                                                <td>{item.quantidade}</td>
                                                <td>{MoneyFormat(item.precoUnidade)}</td>
                                                <td>{MoneyFormat(item.quantidade * item.precoUnidade)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <p style={{textAlign: 'right'}}>TOTAL: {getValorTotal(this.state.solicitacao.itens)}</p>

                    <div className={styles.assinaturas}>
                        <div className={styles.signature}>
                            <hr align="left" noshade />
                            <p>
                                {this.props.usuario.pessoa.nome}
                            </p>
                        </div>

                        <div className={styles.signature}>
                            <hr align="left" noshade />
                            <p>
                                {this.state.solicitacao.fornecedor.nome}
                            </p>
                        </div>

                        <div >
                            <span className={styles.data}>
                                Data Fornecimento: ___/___/___
                            </span>

                        </div>

                    </div>

                    <div className={styles.box}>
                        <span>Observações:</span>
                    </div>
                </Printable>
            </>
        );
    }
}

function getValorTotal(itensCompra) {
    let total = 0;
    itensCompra.forEach(item => {
        total += (item.quantidade * item.precoUnidade);
    });

    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 2
    }).format(total);
}

const mapStateToProps = state => ({
    usuario: state.auth.user,
    token: state.auth.token,
    solicitacao: state.solicitacao.solicitacao
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarUmaSolicitacao
}, dispatch)

const formatterCategories = (array) => {
    let label = '';

    if (array) {
        array.map((a, index) => {
            label += a.modalidade +'/'+ a.faixaEtaria.nome
    
            if (index < array.length -1) {
                label += ", "
            }
        })
    }

    return label;
}  

export default connect(mapStateToProps, mapDispatchToProps)(SolicitacaoCompra);