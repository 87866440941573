import * as reports from './reportTypes';

import AquisicaoIndividual from './AquisicaoIndividual';
import SolicitacaoCompra from './SolicitacaoCompra'
import ProdutosRefeicao from './ProdutosRefeicao';
import MovimentacaoProdutos from './MovimentacaoProdutos';
import EstoqueRelatorio from './Estoque';
import RelatorioCardapios from './RelatorioCardapios';
import Fornecedores from './Fornecedores';
import Itens from './Itens';
import Produtos from './Produtos';
import Usuarios from './Usuarios';
import Preparos from './Preparos';
import Alimentos from './Alimentos';
import Refeicao from './Refeicao';
import Pratos from './Pratos';
import RecebimentoCompra from './RecebimentoCompra';
import Balancos from './Balancos';
import KitEscolar from './KitEscolar';

export default {
    [reports.RELATORIO_AQUISICAO]: AquisicaoIndividual,
    [reports.RELATORIO_SOLICITACAO_COMPRA]: SolicitacaoCompra,
    [reports.RELATORIO_PRODUTOS_REFEICAO]: ProdutosRefeicao,
    [reports.RELATORIO_MOVIMENTACAO_PRODUTOS]: MovimentacaoProdutos,
    [reports.RELATORIO_ESTOQUE]: EstoqueRelatorio,
    [reports.RELATORIO_CARDAPIOS]: RelatorioCardapios,
    [reports.RELATORIO_FORNECEDORES]: Fornecedores,
    [reports.RELATORIO_ITENS]:Itens,
    [reports.RELATORIO_PRODUTOS]: Produtos,
    [reports.RELATORIO_USUARIOS]: Usuarios,
    [reports.RELATORIO_PREPAROS]: Preparos,
    [reports.RELATORIO_ALIMENTOS]: Alimentos,
    [reports.RELATORIO_REFEICAO]: Refeicao,
    [reports.RELATORIO_PRATOS]: Pratos,
    [reports.RELATORIO_RECEBIMENTO_COMPRA]: RecebimentoCompra,
    [reports.RELATORIO_BALANCO_ESTOQUE]: Balancos,
    [reports.RELATORIO_KIT_ESCOLAR]: KitEscolar,
}