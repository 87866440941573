import React from 'react';

import styles from './PhotoUpload.module.css';
import FileInput from '../FileInput';

const PhotoUpload = props => (
    <div className={styles.photoUpload}>
        <img src={props.image} alt='' className={styles.userImage} style={{ width: '180px', height: '180px', borderRadius: '700%' }} />
        <FileInput extensions='.jpg,.png,.jpeg' {...props} />
    </div>
)

export default PhotoUpload;