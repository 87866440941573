import React from 'react';
import InputMask from 'react-input-mask';
import { Form, InputGroup } from 'react-bootstrap';
import Spinner from 'react-loader-spinner';

import styles from './TextInputMask.module.css';

const TextInputMask = ({ loading, disabled, ...props }) => (
    <InputGroup className='mb-3'>
        <InputGroup.Prepend>
            <InputGroup.Text className={styles.label}>{props.label}</InputGroup.Text>
        </InputGroup.Prepend>
        <InputMask
            {...props}
            className={styles.inputMask}
            mask={props.mask}
            value={props.value}
            disabled={disabled}>
            {
                (inputProps) =>
                    <Form.Control
                        {...inputProps}
                        type={props.type}
                        disabled={disabled}
                    />
            }
        </InputMask>
        <InputGroup.Append hidden={!loading} className={styles.loading}>
            <InputGroup.Text>
                <Spinner
                    type='TailSpin'
                    color='#000000'
                    height='21'
                    width='20'
                />
            </InputGroup.Text>
        </InputGroup.Append>
    </InputGroup>

)

export default TextInputMask;