import React, { Component } from 'react'
import { Row, Form, Col } from 'react-bootstrap';
import moment from 'moment';
import PageTitle from '../../components/PageTitle/index';
import Card from '../../components/Card';
import SimpleModal from '../../components/SimpleModal';
import SimpleTable from '../../components/SimpleTable';
import SimpleButton from '../../components/SimpleButton';
import Input from '../../components/Input/index';
import ActionButton from '../../components/ActionButton/index';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarCardapiosLancamentosDia,
    substituirPratoCardapioLancamento,
    buscarQuantidadeDeAlunosComAlimentacao,
    registrarRefeicao,
    filtrarPratosPorCategoriaEModalidade
} from '../../actions'

import withAuthorization from '../../components/HighOrder/withAuthorization';

// import styles from './ExecucaoCardapios.module.css';
import SimpleSelect from '../../components/SimpleSelect/index';

class ExecucaoCardapiosPage extends Component {
    state = {
        idUnidadePesquisa: -1,
        showVisualizarPratos: false,
        showModalSubstituir: false,

        showCardapiosView: false,
        showPratosView: false,
        showItensView: false,

        lancamento: {},
        pratos: [],
        prato: { tipo: {} },
        itens: [],

        novoPrato: -1,

        quantAlunos: 0,
        acrescimo: 0,
    }

    componentDidMount() {
        // console.log(moment().format('DD/MM/YYYY'))
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalCount < nextProps.closeModalCount) {
            this.setState({
                ...this.state,
                showModalSubstituir: false,
                showPratosView: false,
                showItensView: false,
                pratos: [],
                itens: []
            })
        }

        if (this.props.quantidadeAlunosAlimentacao !== nextProps.quantidadeAlunosAlimentacao) {
            this.handleChangeQuantidadeAlunos(nextProps.quantidadeAlunosAlimentacao)
        } else {
            this.handleChangeQuantidadeAlunos(this.props.quantidadeAlunosAlimentacao)
        }
    }

    handleChangePesquisaUnidade = idUnidadePesquisa => {
        this.setState({ idUnidadePesquisa, showCardapiosView: true }, () => {
            this.props.listarCardapiosLancamentosDia(moment().format('DD/MM/YYYY').split('/').join(''), this.state.idUnidadePesquisa, this.props.token)
            // this.props.listarCardapiosLancamentosDia('09092019', this.state.idUnidadePesquisa, this.props.token)
        });
    }

    handleShowModalSubstituir = prato => {
        this.setState({ ...this.state, showModalSubstituir: true, prato }, () => {
            this.props.filtrarPratosPorCategoriaEModalidade(this.state.lancamento.cardapio.categoria, this.state.lancamento.cardapio.modalidade, this.props.token);
        })
    }

    handleSubstituir = () => {
        // console.log(this.state.prato)
        // console.log(this.state.novoPrato)

        const substituicao = {
            lancamentoCardapio: this.state.lancamento.lancamento,
            substituido: this.state.prato.id,
            substituto: this.state.novoPrato
        }

        // console.log(substituicao)

        this.props.substituirPratoCardapioLancamento(substituicao, moment().format('DD/MM/YYYY').split('/').join(''), this.state.idUnidadePesquisa, this.props.token);
    }

    handleSetItens = prato => {
        // console.log(prato)
        let itensCopy = JSON.parse(JSON.stringify(prato.itens));
        itensCopy = itensCopy.map(item => ({ ...item, valorComQuantAlunos: 0, valorComAcrescimo: 0, valorArredondado: 0, corArredondamento: 'black' }))

        this.setState({ ...this.state, showItensView: true, prato, itens: itensCopy })
    }

    handleBuscarQuantidadeDeAlunos = () => {
        // console.log(this.state.lancamento)
        this.props.buscarQuantidadeDeAlunosComAlimentacao(moment().format('YYYY-MM-DD'), this.state.lancamento.cardapio.idFaixaEtaria, this.props.token)
        // this.props.buscarQuantidadeDeAlunosComAlimentacao('2019-09-06', this.state.lancamento.cardapio.categoria.faixaEtaria.id, this.props.token)
    }

    handleChangeQuantidadeAlunos = value => {
        this.setState({ ...this.state, quantAlunos: value }, () => {
            let itens = this.state.itens;

            const self = this;

            itens = itens.map(item => ({
                ...item,
                valorComQuantAlunos: (item.quantidadePre * self.state.quantAlunos),
                valorComAcrescimo: (item.quantidadePre * self.state.quantAlunos)+((item.quantidadePre * self.state.quantAlunos)  * (self.state.acrescimo / 100)),
                valorArredondado: (item.quantidadePre * self.state.quantAlunos)+((item.quantidadePre * self.state.quantAlunos)  * (self.state.acrescimo / 100))
            }))

            // console.log(itens);

            this.setState({ ...this.state, itens })
        })
    }

    handleChangeAcrescimo = value => {
        this.setState({ ...this.state, acrescimo: value }, () => {
            let itens = this.state.itens;

            const self = this;

            itens = itens.map(item => ({
                ...item,
                valorComQuantAlunos: item.quantidadePre * self.state.quantAlunos,
                valorComAcrescimo: (item.quantidadePre * self.state.quantAlunos)+((item.quantidadePre * self.state.quantAlunos)  * (self.state.acrescimo / 100)),
                valorArredondado: (item.quantidadePre * self.state.quantAlunos)+((item.quantidadePre * self.state.quantAlunos)  * (self.state.acrescimo / 100))
            }))

            console.log(itens);

            this.setState({ ...this.state, itens })
        })
    }

    handleArredondamentoCima = itemIndex => {
        let itens = JSON.parse(JSON.stringify(this.state.itens))

        itens[itemIndex].valorArredondado = Math.ceil(itens[itemIndex].valorArredondado);
        itens[itemIndex].corArredondamento = 'green';

        this.setState({ ...this.state, itens })
    }

    handleArredondamentoBaixo = itemIndex => {
        let itens = JSON.parse(JSON.stringify(this.state.itens))

        itens[itemIndex].valorArredondado = Math.floor(itens[itemIndex].valorArredondado);
        itens[itemIndex].corArredondamento = 'red';

        this.setState({ ...this.state, itens })
    }

    handleTirarArredondamento = itemIndex => {
        let itens = JSON.parse(JSON.stringify(this.state.itens))

        itens[itemIndex].valorArredondado = (itens[itemIndex].quantidadePre * this.state.quantAlunos)+ (itens[itemIndex].quantidadePre * this.state.quantAlunos) * (this.state.acrescimo / 100)
        itens[itemIndex].corArredondamento = 'black';

        this.setState({ ...this.state, itens })
    }

    handleExecutar = () => {
        const refeicao = {
            dia: moment().format('DD/MM/YYYY'),
            prato: this.state.prato.prato.id,
            tipo: this.state.prato.tipo.nome,
            hora: this.state.prato.hora,
            quantidadeDePessoas: this.state.quantAlunos,
            acrescimo: this.state.acrescimo,
            lancamentoCardapio: this.state.lancamento.lancamento,
            unidadeEducacional: this.state.idUnidadePesquisa,
        }

        const alimentos = this.state.itens.map(item => ({ id: item.alimento.id, valor: item.valorArredondado, unidadeDeMedida: item.unidadeDeMedidaPos }))

        const registroRefeicao = {
            alimentos,
            refeicao
        }

        // console.log(registroRefeicao)

        this.props.registrarRefeicao(registroRefeicao, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Execução de Cardápios' hasDivider={true} />
                </Row>

                <Form>
                    <Form.Group>
                        <Form.Label>Selecione a Unidade Educacional:</Form.Label>
                        <Row>
                            <Col lg={12}>
                                <Form.Control
                                    as='select'
                                    value={this.state.idUnidadePesquisa}
                                    onChange={e => this.handleChangePesquisaUnidade(e.target.value)}
                                    required>

                                    <option></option>
                                    {this.props.unidadesUsuario.map((unidade, index) => (
                                        <option key={index} value={unidade.unidadeEducacional.id}>{unidade.unidadeEducacional.nome}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>

                <div hidden={!this.state.showCardapiosView}>
                    <Row style={{ marginTop: '26px', marginBottom: '8px' }}>
                        <Col>
                            Selecione um cardápio:
                        </Col>
                    </Row>

                    <Row>
                        {this.props.cardapiosLancamentosDia.map((lancamento, index) => (
                            <Col md={4} key={index} style={{ marginBottom: '10px' }}>
                                <Card text={lancamento.cardapio.nome} onClick={() => this.setState({ ...this.state, showVisualizarPratos: true, lancamento: lancamento, pratos: lancamento.pratos, showPratosView: true })} />
                            </Col>
                        ))}
                    </Row>
                </div>

                <div hidden={!this.state.showPratosView}>
                    {/* PRATOS PARA SEREM EXECUTADOS */}
                    <Row style={{ marginTop: '16px', marginBottom: '8px' }}>
                        <Col>
                            <b>Cardápio: </b> Cardapio de teste
                        </Col>
                    </Row>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Hora</th>
                                <th>Tipo</th>
                                <th>Prato</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pratos.map((prato, index) => (
                                <tr key={index}>
                                    <td>{prato.hora}</td>
                                    <td>{prato.tipo.descricao}</td>
                                    <td>{prato.nome}</td>
                                    <td>
                                        <SimpleButton
                                            name='Exibir'
                                            color='green'
                                            onClick={() => this.handleSetItens(prato)}
                                        />

                                        <SimpleButton
                                            name='Substituir'
                                            color='red'
                                            onClick={() => this.handleShowModalSubstituir(prato)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable>
                </div>

                <SimpleModal
                    show={this.state.showModalSubstituir}
                    onHide={() => this.setState({ ...this.state, showModalSubstituir: false })}
                    title='Substituir Prato'
                    icon='fas fa-exchange-alt'
                >

                    <b>Substituir prato: </b>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Hora</th>
                                <th>Tipo</th>
                                <th>Prato</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.state.prato.hora}</td>
                                <td>{this.state.prato.tipo.descricao}</td>
                                <td>{this.state.prato.nome}</td>
                            </tr>
                        </tbody>
                    </SimpleTable>

                    <b>Por: </b>

                    <SimpleSelect
                        label='* Prato:'
                        value={this.state.novoPrato}
                        onChange={e => this.setState({ ...this.state, novoPrato: parseInt(e.target.value) })}
                        required
                    >

                        <option></option>
                        {this.props.pratos.map(p => (
                            <option key={p.id} value={p.id}>{p.nome}</option>
                        ))}
                    </SimpleSelect>


                    <Row style={{ marginTop: '16px' }}>
                        <Col align='left'>
                            <SimpleButton
                                type='button'
                                color='red'
                                name='Cancelar'
                                icon='fas fa-times'
                                onClick={() => this.setState({ showModalSubstituir: false })}
                            />
                        </Col>
                        <Col align='right'>
                            <SimpleButton
                                type='submit'
                                color='green'
                                name='Substituir'
                                icon='fas fa-exchange-alt'
                                onClick={() => this.handleSubstituir()}
                            />
                        </Col>
                    </Row>
                </SimpleModal>

                <div hidden={!this.state.showItensView}>
                    {/* EXECUÇAÕ DE PRATO */}

                    <SimpleButton
                        name='Buscar quantidade de alunos'
                        color='blue'
                        onClick={() => this.handleBuscarQuantidadeDeAlunos()}
                    />

                    <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col>
                            <Input
                                type='number'
                                label='Quantidade de alunos: '
                                value={this.state.quantAlunos}
                                onChange={value => this.handleChangeQuantidadeAlunos(value)}
                            />
                        </Col>
                        <Col>
                            <Input
                                type='number'
                                label='Porcentagem de acréscimo: '
                                value={this.state.acrescimo}
                                onChange={value => this.handleChangeAcrescimo(value)}
                            />
                        </Col>
                    </Row>

                    <SimpleTable>
                        <thead>
                            <tr>
                                <th>Alimento</th>
                                <th>Quant. por aluno</th>
                                <th>Valor com a quant. de alunos</th>
                                <th>Valor com acréscimo</th>
                                <th>Valor arredondado</th>
                                <th>Arredondamento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.itens.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.alimento.nome}</td>
                                    <td>{item.quantidadePre} {item.unidadeDeMedidaPre}</td>
                                    <td>{item.valorComQuantAlunos} {item.unidadeDeMedidaPre}</td>
                                    <td>{item.valorComAcrescimo} {item.unidadeDeMedidaPre}</td>
                                    <td style={{ color: item.corArredondamento }}>
                                        {item.valorArredondado} {item.unidadeDeMedidaPre}
                                    </td>
                                    <td>
                                        <ActionButton
                                            title='Arredondar para cima'
                                            onClick={() => this.handleArredondamentoCima(index)}
                                            icon='fas fa-arrow-up'
                                        />

                                        <ActionButton
                                            title='Manter valor original'
                                            onClick={() => this.handleTirarArredondamento(index)}
                                            icon='fas fa-minus'
                                        />

                                        <ActionButton
                                            title='Arredondar para baixo'
                                            onClick={() => this.handleArredondamentoBaixo(index)}
                                            icon='fas fa-arrow-down'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </SimpleTable>

                    <Row style={{ marginTop: '10px' }}>
                        <Col align='right'>
                            <SimpleButton
                                name='Registrar'
                                color='blue'
                                onClick={() => this.handleExecutar()}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    unidadesUsuario: state.auth.user.estoques,
    cardapiosLancamentosDia: state.execucao.cardapiosLancamentosDia,
    quantidadeAlunosAlimentacao: state.execucao.quantidadeAlunosAlimentacao,
    closeModalCount: state.execucao.closeModalCount,
    pratos: state.prato.list,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarCardapiosLancamentosDia,
    buscarQuantidadeDeAlunosComAlimentacao,
    registrarRefeicao,
    substituirPratoCardapioLancamento,
    filtrarPratosPorCategoriaEModalidade,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(ExecucaoCardapiosPage), 'CARDAPIOS')