import React from 'react';
import SimpleButton from '../../../components/SimpleButton/index';

const PrintButton = () => (
    <div className='row text-right'>
        <div className='col'>
            <SimpleButton
                onClick={() => {
                    window.scrollTo(0, 0);
                    window.print();
                }}
                type='button'
                color='blue'
                name='Imprimir'
                icon='fas fa-print'
            />
        </div>
    </div>
)

export default PrintButton;