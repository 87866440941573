import { LOGIN, TOKEN_VALIDATED, LOGOUT, IS_LOADING_AUTH } from './types';

import axios from 'axios';
import querystring from 'querystring';
import { toastr } from 'react-redux-toastr';

import { BASE_URL, configFormUrlEncoded, configAuthorizationToken } from '../utils/requests';

import { buscarFotoUsuario, buscarNotificacoes, buscarSecretaria } from './usuario.aciton'

export function login(data) {
    return dispatch => {
        dispatch(toggleLoading(true));
        axios.post(`${BASE_URL}/tokens/autenticar`, querystring.stringify(data), configFormUrlEncoded)
            .then(res => {
                dispatch([ 
                    { type: LOGIN, payload: res.data },
                    buscarFotoUsuario(res.data.user.id, res.data.token),
                    buscarNotificacoes(res.data.token),
                    buscarSecretaria(res.data.token),
                    toggleLoading(false)
                ])
            })
            .catch(err => {
                toastr.warning('Desculpa...', 'Usuário ou senha estão incorretos!');
                dispatch(toggleLoading(false))
                // console.log('Login failed');
            })
    }
}

export function checkValidToken(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/tokens/checar`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: TOKEN_VALIDATED, payload: res.data.check })
            })
            .catch(err => {
                dispatch({ type: TOKEN_VALIDATED, payload: false })
            })
    }
}

// export function buscarUsuario(token) {
//     return dispatch => {
//         axios.get(`${BASE_URL}/usuarios/me`, configAuthorizationToken(token))
//             .then(res => {
//                 dispatch({ type:  })
//             })
//     }
// }

export function logout() {
    return { type: LOGOUT, payload: false }
}

export function toggleLoading(value) {
    return { type: IS_LOADING_AUTH, payload: value }
}