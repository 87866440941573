import React from 'react';
import { Row, Col } from 'react-bootstrap';

import styles from './Header.module.css';

const Header = props => (
    <header>
        <Row>
            <Col xs={3}>
                <button onClick={props.handleOpen} className={`${styles.menuButton} ${styles.iconVisibility}`}>
                    <i className='fas fa-bars fa-2x'></i>
                </button>
            </Col>
            <Col xs={6} className={styles.headerTitle}>
                <h2>Alimentação Escolar</h2>
            </Col>
            <Col xs={3}>
            </Col>
        </Row>
    </header>
)

export default Header;