import React from 'react';
import { Table } from 'react-bootstrap';

import styles from './SimpleTable.module.css';

const SimpleTable = props => (
    <Table className={styles.table} responsive bordered>
        {props.children}
    </Table>
)

export default SimpleTable;