import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { LISTAR_PRATOS, LISTAR_PRATO_POR_ID, CADASTRAR_PRATO, EDITAR_PRATO, FILTRAR_PRATOS } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests'
import { toastr } from 'react-redux-toastr';

export function listarPratos(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/pratos`, configAuthorizationToken(token))
            .then(res => {
                dispatchEvent([ 
                    { type: LISTAR_PRATOS, payload: res.data }, 
                    { type: FILTRAR_PRATOS, payload: res.data }, 
                ])                
            })
    }
}

export function filtrarPratos(nome) {
    return (dispatchEvent, getState) => {
        let pratos = getState().prato.list;
        let pratosFiltrados = pratos.filter(prato => prato.nome.toLowerCase().includes(nome.toLowerCase()))

        dispatchEvent({ type: FILTRAR_PRATOS, payload: pratosFiltrados })
    }
}

export function filtrarPratosPorCategoria(categoriaId, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/pratos`, configAuthorizationToken(token))
            .then(res => {
                let pratos = res.data
                let pratosFiltrados = pratos.filter(prato => prato.categoria.id === categoriaId)
                dispatchEvent({ type: LISTAR_PRATOS, payload: pratosFiltrados })                
            })
    }
}

export function filtrarPratosPorCategoriaEModalidade(categoriaId, modalidade, token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/pratos`, configAuthorizationToken(token))
            .then(res => {
                let pratos = res.data
                let pratosFiltrados = pratos.filter(prato => prato.categoria.id === categoriaId && prato.modalidade.nome === modalidade)
                dispatchEvent({ type: LISTAR_PRATOS, payload: pratosFiltrados })                
            })
    }
}

export function cadastrarPrato(prato, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.post(`${BASE_URL}/pratos`, prato, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent({ type: CADASTRAR_PRATO, payload: {} })
                        toastr.success('Sucesso', 'Prato cadastrado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Preparo?' />
            )
        })        
    }
}

export function editarPrato(prato, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.put(`${BASE_URL}/pratos/${prato.id}`, prato, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent([ 
                            { type: EDITAR_PRATO, payload: {} },
                            listarPratos(token)
                        ])
                        toastr.success('Sucesso', 'Prato editado com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarPratos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Prato?' />
            )
        })

    }
}

export function excluirPrato(id, token) {
    return dispatchEvent => {
        toastr.confirm(null, {        
            onOk: () => {
                axios.delete(`${BASE_URL}/pratos/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatchEvent(listarPratos(token))
                        toastr.success('Sucesso', 'Prato excluido com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatchEvent(listarPratos(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Prato?' />
            )
        })
    }
}

export function listarPratoPorId(idPrato, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/pratos/${idPrato}`, configAuthorizationToken(token))
            .then(res => {
                dispatch([
                    { type: LISTAR_PRATO_POR_ID, payload: res.data },
                ])
            })
            .catch(err => console.log(err))
    }
}