import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { CADASTRAR_SOLICITACAO, PRODUTOS_RECEBIDOS, IMAGEM_NOTA_FISCAL, RECEBIMENTO_COMPRA, EDITAR_SOLICITACAO, EDITAR_IMAGEM_NOTA_FISCAL, LISTAR_SOLICITACOES, EDITAR_NOTA_FISCAL, BUSCAR_MOTIVO_CANCELAMENTO_SOLICITACAO, FILTRAR_SOLICITACOES, LISTAR_UMA_SOLICITACAO } from './types';

import axios from 'axios';
import { BASE_URL, configAuthorizationToken, extractErrorMessage, configAuthorizationTokenAndContentTypeMultipart } from '../utils/requests';
import { toastr } from 'react-redux-toastr';

export function cadastrarSolicitacao(solicitacao, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/solicitacoes`, solicitacao, configAuthorizationToken(token))
                    .then(res => {
                        dispatch({ type: CADASTRAR_SOLICITACAO, payload: {} })
                        toastr.success('Sucesso', 'Solicitação de Compra cadastrada com sucesso!')
                    })
                    .catch(err => toastr.error('Erro', extractErrorMessage(err)));
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar a Solicitação de Compra?' />
            )
        })
    }
}

export function listarSolicitacoes(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/solicitacoes`, configAuthorizationToken(token))
            .then(res => {
                //console.log("QUEST RESPONSE: ", res.data)
                dispatch([
                    { type: LISTAR_SOLICITACOES, payload: res.data },
                    { type: FILTRAR_SOLICITACOES, payload: res.data }
                ])
            })
            .catch(err => console.log(err))
    }
}

export function filtrarSolicitacoes(data, fornecedor, unidade, status, modalidade) {
    //console.log(data, fornecedor, unidade, status, modalidade);
    return (dispatch, getState) => {
        let solicitacoes = getState().solicitacao.list;
        let solicitacoesFiltradas = solicitacoes
            .filter(s =>
                s.dataSolicitacao.includes(data === 'Invalid date' ? '' : data) &&
                s.fornecedor.nome.toLowerCase().includes(!!fornecedor ? fornecedor.toLowerCase() : '') &&
                s.unidadeEducacional.nome.toLowerCase().includes(!!unidade ? unidade.toLowerCase() : '') &&
                s.status.toLowerCase().includes(!!status ? status.toLowerCase() : '') &&
                formatterCategories(s.categorias).toLocaleLowerCase().includes(!!modalidade ? modalidade.toLowerCase() : '') 
            )

        dispatch({ type: FILTRAR_SOLICITACOES, payload: solicitacoesFiltradas })
    }
}

const formatterCategories = (array) => {
    let label = '';
    array.map((a, index) => {
        label += a.modalidade;

        if (index < array.length -1) {
            label += ", "
        }
    })

    console.log(label)
    return label;
}  

export function listarUmaSolicitacao(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/solicitacoes/${id}`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: LISTAR_UMA_SOLICITACAO, payload: res.data })
            })
            .catch(err => console.log(err))
    }
}

export function editarSolicitacao(solicitacao, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/solicitacoes/${solicitacao.id}`, solicitacao, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_SOLICITACAO, payload: {} },
                            listarSolicitacoes(token)
                        ])
                        toastr.success('Sucesso', 'Solicitação de Compra editada com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarSolicitacoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar a Socicitação?' />
            )
        })
    }
}

export function cancelarSolicitacao(id, motivo, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/solicitacoes/${id}/cancelar`, { motivo }, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_SOLICITACAO, payload: {} },
                            listarSolicitacoes(token)
                        ])
                        toastr.success('Sucesso', 'Solicitação de Compra cancelada com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarSolicitacoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cancelar a Solicitação?' />
            )
        })
    }
}

export function buscarMotivoCancelamentoSolicitacao(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/solicitacoes/${id}/cancelamento`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: BUSCAR_MOTIVO_CANCELAMENTO_SOLICITACAO, payload: res.data })
            })
    }
}

export function receberSolicitacao(idSolicitacao, recebimento, imagem, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/solicitacoes/${idSolicitacao}/receber`, recebimento, configAuthorizationToken(token))
                    .then(res => {
                        if (!imagem) {
                            dispatch([
                                { type: EDITAR_SOLICITACAO, payload: {} },
                                listarSolicitacoes(token)
                            ])
                            toastr.success('Sucesso', 'Solicitação de Compra recebida com sucesso!')
                        } else {
                            dispatch(enviarNotaFiscal(res.data.notaFiscal, imagem, token));
                        }
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarSolicitacoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente receber a Solicitação?' />
            )
        })
    }
}


export function enviarNotaFiscal(idNotaFiscal, imagem, token) {
    return dispatch => {
        axios.put(`${BASE_URL}/notas-fiscais/${idNotaFiscal}/imagem`, imagem, configAuthorizationTokenAndContentTypeMultipart(token))
            .then(res => {
                dispatch(listarSolicitacoes(token))
                toastr.success('Sucesso', 'Solicitação de Compra recebida com sucesso!')
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
    }
}

export function produtosRecebidos(idSolicitacao, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/solicitacoes/${idSolicitacao}/produtos-recebidos`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: PRODUTOS_RECEBIDOS, payload: res.data })
            })
            .catch(err => console.log(err))
    }
}

export function editarNotaFiscal(notaFiscal, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/notas-fiscais/${notaFiscal.id}`, notaFiscal, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_NOTA_FISCAL, payload: {} },
                            listarSolicitacoes(token)
                        ])
                        toastr.success('Sucesso', 'Nota Fiscal editada com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarSolicitacoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar a Nota Fiscal?' />
            )
        })
    }
}

export function editarImagemNotaFiscal(idNotaFiscal, imagem, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/notas-fiscais/${idNotaFiscal}/imagem`, imagem, configAuthorizationTokenAndContentTypeMultipart(token))
                    .then(res => {
                        dispatch([dispatch({ type: EDITAR_IMAGEM_NOTA_FISCAL, payload:  res.data })
                        ])
                        toastr.success('Sucesso', 'Imagem da nota fiscal editada com sucesso!')
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                        dispatch(listarSolicitacoes(token))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar a imagem da nota fiscal ?' />
            )
        })
    }
}

export function imagemNotaFiscal(idSolicitacao, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/notas-fiscais/${idSolicitacao}/imagem`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: IMAGEM_NOTA_FISCAL, payload: res.data })
            })
            .catch(err => {
                dispatch({ type: IMAGEM_NOTA_FISCAL, payload: null })
            })
    }
}

export function recebimentoCompra(idSolicitacao, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/recebimentos-compra/solicitacoes/${idSolicitacao}`, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: RECEBIMENTO_COMPRA, payload: res.data })
            })
            .catch(err => console.log(err))
    }
}