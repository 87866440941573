import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/aller/style.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

// import 'fullcalendar/dist/fullcalendar.min.css';
// import 'fullcalendar/dist/fullcalendar.min';
// import 'fullcalendar/dist/locales-all';
// import 'fullcalendar/dist/locales/pt-br';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import Routes from './Routes';
import Messages from './components/Messages';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Routes />
            <Messages /> 
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
