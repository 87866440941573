import React from 'react';
import { withRouter } from 'react-router-dom';

import maps from '../../relatorios/reportMaps';

const RelatorioPage = props => {
    // console.log(props.match.params.type, props.match.params.someId)

    const Component = maps[props.match.params.type];

    return <Component data={props.match.params.someId} />
}

export default withRouter(RelatorioPage);