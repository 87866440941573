import React from 'react';
import CurrencyFormat from 'react-currency-format';
import Header from '../components/Header';
import Title from '../components/Title';
import PrintButton from '../components/PrintButton/index';
import BackButton from '../components/BackButton';
import Printable from '../components/Printable/index';
import Detail from '../components/Detail/index';
import Info from '../components/Info';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarUmaAquisicao } from '../../actions'

import formatCnpjCpf from '../../utils/formatCnpjCpf';
import formatTelefoneCelular from '../../utils/formatTelefoneCelular';

import styles from './styles.module.css';

class AquisicaoIndidual extends React.Component {
    state = {
        aquisicao: {
            propostasVencedoras: []
        }
    }

    componentWillMount() {
        this.props.listarUmaAquisicao(this.props.data, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.aquisicao !== this.props.aquisicao) this.setState({ ...this.state, aquisicao: nextProps.aquisicao });
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header />

                    <Title name='Relatório de Licitação/Contrato' />

                    <Detail>
                        <div className='row'>
                            <div className='col'>
                                <b>Número:</b> {this.state.aquisicao.numero}
                            </div>
                            <div className='col'>
                                <b>Nº Processo Administrativo:</b> {this.state.aquisicao.numeroProcessoAdminis}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Data Contrato:</b> {this.state.aquisicao.dataContrato}
                            </div>
                            <div className='col'>
                                <b>Vigência:</b> {this.state.aquisicao.vigencia}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Ano:</b> {this.state.aquisicao.ano}
                            </div>
                            <div className='col'>
                                <b>Tipo de Aquisição:</b> {this.state.aquisicao.aquisicaoTipo}
                            </div>
                        </div>

                        <div className='row'>
                            {
                                this.state.aquisicao.aquisicaoTipo === 'LICITACAO' ?
                                    (
                                        <>
                                            <div className='col'>
                                                <b>Tipo de Aquisição:</b> {this.state.aquisicao.tipoDeLicitacao}
                                            </div>
                                            <div className='col'>
                                                <b>Modalidade de Licitação:</b> {this.state.aquisicao.modalidadeLicitacao}
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <div className='col'>
                                            <b>Fonte de Recurso:</b> {this.state.aquisicao.fonteRecurso}
                                        </div>
                                    )
                            }
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Valor da Aquisição:</b> <CurrencyFormat value={this.state.aquisicao.valor} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                            </div>
                            <div className='col'>
                                <b>Valor Disponível:</b> <CurrencyFormat value={this.state.aquisicao.valorDisponivel} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className={styles.borda}>
                                <div className='col-md-12'>
                                    <b>Objeto:</b>
                                </div>
                                <div className='col-md-12'>
                                    {this.state.aquisicao.objeto}
                                </div>
                            </div>
                        </div>


                        <br />

                        <div className='row'>
                            <div className='col'>
                                <b>Propostas Vencedoras</b>
                                <hr />
                            </div>
                        </div>

                        {this.state.aquisicao.propostasVencedoras.map((proposta, index) => (
                            <div key={index}>
                                <div className='row'>
                                    {
                                        proposta.fornecedor.fornecedorTipo === 'JURIDICO' ?
                                            (
                                                <>
                                                    <div className='col'>
                                                        <b>Fornecedor {index + 1}:</b> {proposta.fornecedor.nomeFantasia}
                                                    </div>
                                                    <div className='col'>
                                                        <b>CNPJ:</b> {formatCnpjCpf(proposta.fornecedor.cnpj)}
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <div className='col'>
                                                        <b>Fornecedor {index + 1}:</b> {proposta.fornecedor.nome}
                                                    </div>
                                                    <div className='col'>
                                                        <b>CPF:</b> {formatCnpjCpf(proposta.fornecedor.cpf)}
                                                    </div>
                                                </>
                                            )
                                    }
                                    <div className='col'>
                                        <b>Tipo:</b> {proposta.fornecedor.fornecedorTipo}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>Nº Contrato:</b> {proposta.numeroContrato}
                                    </div>
                                    <div className='col'>
                                        <b>Contato:</b> {formatTelefoneCelular(proposta.fornecedor.telefone)}
                                    </div>
                                    <div className='col'>
                                        <b>Logradouro:</b> {proposta.fornecedor.logradouro}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>Bairro/Distríto:</b> {proposta.fornecedor.bairro_distrito}
                                    </div>
                                    <div className='col'>
                                        <b>Município:</b> {proposta.fornecedor.municipio}
                                    </div>
                                    <div className='col'>
                                        <b>UF:</b> {proposta.fornecedor.uf}
                                    </div>
                                </div>

                                <br />

                                <div className='row'>
                                    <div className='col'>
                                        <table border='1' className={styles.tableItensAquisicao}>
                                            <thead>
                                                <tr>
                                                    <th className={styles.produto}>Produto</th>
                                                    <th>Descrição</th>
                                                    <th>Unidade Medida</th>
                                                    <th>Quant.</th>
                                                    <th>Preço Unidade</th>
                                                    <th>Valor total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proposta.itens.map((item, itemIndex) => (
                                                    <tr key={itemIndex}>
                                                        <td className={styles.produto}>{item.produto.nome}</td>
                                                        <td>{item.descricao}</td>
                                                        <td>{item.unidadeProduto}</td>
                                                        <td>{item.quantidade}</td>
                                                        <td>
                                                            <CurrencyFormat value={item.precoUnidade} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                        </td>
                                                        <td>
                                                            <CurrencyFormat value={item.quantidade * item.precoUnidade} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br />

                                <div className='row text-right'>
                                    <div className='col'>
                                        <b>Total:</b> <CurrencyFormat value={proposta.valor} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                    </div>
                                </div>

                                <br />
                            </div>
                        ))}
                    </Detail>
                </Printable>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    aquisicao: state.aquisicao.aquisicao
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarUmaAquisicao
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AquisicaoIndidual);