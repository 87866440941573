import { LISTAR_ADITIVOS, CADASTRAR_ADITIVO, EDITAR_ADITIVO, SET_AQUISICAO_ADITIVO } from "../actions/types";

const INITIAL_STATE = {
    aquisicao: {},
    list: [],

    closeModalNumbers: 0
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_ADITIVOS:
            return { ...state, list: action.payload }

        case CADASTRAR_ADITIVO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case EDITAR_ADITIVO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case SET_AQUISICAO_ADITIVO:
            return { ...state, aquisicao: action.payload }

        default:
            return state
    }
}