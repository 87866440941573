import { CADASTRAR_FORNECEDOR, EDITAR_FORNECEDOR, LISTAR_FORNECEDORES, LISTAR_ITENS_FORNECEDOR, FILTRAR_FORNECEDORES, LISTAR_FORNECEDOR_POR_ID } from '../actions/types';

const INITIAL_STATE = {
    list: [],
    itens: [],
    filtrados: [],

    resetNumbers: 0,
    closeModalNumbers: 0,
    fornecedor: {}
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CADASTRAR_FORNECEDOR:
            return { ...state, resetNumbers: ++state.resetNumbers }

        case EDITAR_FORNECEDOR:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case LISTAR_FORNECEDORES:
            return { ...state, list: action.payload }

        case LISTAR_ITENS_FORNECEDOR:
            return { ...state, itens: action.payload }

        case FILTRAR_FORNECEDORES:
            return { ...state, filtrados: action.payload }

        case LISTAR_FORNECEDOR_POR_ID:
            return { ...state, fornecedor: action.payload }

        default:
            return state
    }
}