import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle/index';
import SimpleTable from '../../components/SimpleTable/index';
import SimpleModal from '../../components/SimpleModal';
import Fieldset from '../../components/Fieldset';
import ActionButton from '../../components/ActionButton/index';
import SearchSelect from '../../components/SearchSelect/index';
import SimpleSelect from '../../components/SimpleSelect/index';
import Input from '../../components/Input/index';
import SimpleButton from '../../components/SimpleButton';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';

import { 
    listarProdutos, 
    editarProduto, 
    excluirProduto, 
    listarItens, 
    filtrarProdutos, 
    listarUnidadesMedida, 
    listarUnidadesProduto 
} from '../../actions'

import styles from './GerenciarProdutos.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';

class GerenciarProdutosPage extends Component {
    state = {
        showModal: false,

        pesquisaItem: '',
        pesquisaProduto: '',
        isPesquisa: false,

        produto: {},
        item: {},

        itemSelecionado: '',
    }

    componentDidMount() {
        this.props.listarProdutos(this.props.token);
        this.props.listarItens(this.props.token);
        this.props.listarUnidadesMedida(this.props.token);
        this.props.listarUnidadesProduto(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.closeModalNumbers < nextProps.closeModalNumbers) this.handleCloseModal();
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleShowModal = produto => {
        this.setState({
            produto,
            item: produto.item,
            itemSelecionado: { value: produto.item.id, label: produto.item.nome },
            showModal: true
        })
    }

    handleChange = (value, property, upper = () => {}) => {
        let produto = JSON.parse(JSON.stringify(this.state.produto));
        produto[property] = value;

        this.setState({ ...this.state, produto }, () => upper())
    }

    handleChangePerecivel = e => {
        let produto = JSON.parse(JSON.stringify(this.state.produto));
        produto['isPerecivel'] = e.target.checked;

        this.setState({ ...this.state, produto })
    }

    handleChangeItem = (itemSelecionado) => {
        let item = this.props.itens.find(item => item.id === itemSelecionado.value)

        this.setState({ ...this.state, item, itemSelecionado })
    }

    handleEditar = e => {
        e.preventDefault();

        let produto = this.state.produto;
        produto['item'] = this.state.item;

        // console.log(produto)

        this.props.editarProduto(produto, this.props.token)
        // this.handleCloseModal();
    }

    mostrarPaginaRelatorio = () => {
        if(this.state.isPesquisa) {
            sessionStorage.setItem('pesquisaItem', this.state.pesquisaItem);
            sessionStorage.setItem('pesquisaProduto', this.state.pesquisaProduto);
        }
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_PRODUTOS}/filter=${this.state.isPesquisa}`);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Produtos' hasDivider={true} />
                </Row>
                <div align='right'>
                    <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        onClick={() => this.mostrarPaginaRelatorio()}
                    />
                </div>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Item:</Form.Label>
                            <Form.Control type='text' value={this.state.pesquisaItem}
                                onChange={e => this.setState({ ...this.state, pesquisaItem: e.target.value, isPesquisa: true }, () => this.props.filtrarProdutos(this.state.pesquisaItem, this.state.pesquisaProduto))} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Produto:</Form.Label>
                            <Form.Control type='text' value={this.state.pesquisaProduto}
                                onChange={e => this.setState({ ...this.state, pesquisaProduto: e.target.value, isPesquisa: true }, () => this.props.filtrarProdutos(this.state.pesquisaItem, this.state.pesquisaProduto))} />
                        </Form.Group>
                    </Row>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Nome</th>
                            <th>Unidade</th>
                            <th>Medida</th>
                            <th>Perecível</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(produto => (
                            <tr key={produto.id}>
                                <td>{produto.item ? produto.item.nome : '---'}</td>
                                <td>{produto.nome}</td>
                                <td>{produto.unidadeProduto}</td>
                                <td>{produto.medidaReal} ({produto.unidadeMedida})</td>
                                <td>{produto.isPerecivel ? 'SIM' : 'NÃO'}</td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Editar Produto'
                                        onClick={() => this.handleShowModal(produto)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Produto'
                                        onClick={() => this.props.excluirProduto(produto.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    title='Editar Produto'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditar}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do produto' align='left'>
                                    <Row>
                                        <Col md={9}>
                                            <Input
                                                type='text'
                                                label='* Nome:'
                                                placeholder='Nome do produto'
                                                case='upper'
                                                value={this.state.produto.nome}
                                                onChange={(value, upper) => this.handleChange(value, 'nome', upper)}
                                                required />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Check inline type='checkbox' checked={this.state.produto.isPerecivel} onChange={this.handleChangePerecivel} />
                                            <Form.Label>* Perecível </Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <SimpleSelect
                                                label='* Unidade do Produto:'
                                                value={this.state.produto.unidadeProduto}
                                                onChange={e => this.handleChange(e.target.value, 'unidadeProduto')}
                                                required
                                            >

                                                <option></option>
                                                {this.props.unidadesProduto.map((unidade, index) => (
                                                    <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                        <Col md={6}>
                                            <SimpleSelect
                                                label='* Unidade de Medida:'
                                                value={this.state.produto.unidadeMedida}
                                                onChange={e => this.handleChange(e.target.value, 'unidadeMedida')}
                                                required
                                            >

                                                <option></option>
                                                {this.props.unidadesMedida.map((unidade, index) => (
                                                    <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                                ))}
                                            </SimpleSelect>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input
                                                type='number'
                                                label='* Valor da Medida:'
                                                placeholder='Medida do produto'
                                                value={this.state.produto.medidaReal}
                                                onChange={value => this.handleChange(value, 'medidaReal')}
                                                required />
                                        </Col>
                                        <Col md={6}>
                                            <SearchSelect
                                                label='* Item:'
                                                value={this.state.itemSelecionado}
                                                onChange={selectedOption => this.handleChangeItem(selectedOption)}
                                                options={this.props.itens.map(item => (
                                                    { value: item.id, label: item.nome })
                                                )}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={this.handleCloseModal}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>


                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    list: state.produto.list,
    filtrados: state.produto.filtrados,
    closeModalNumbers: state.produto.closeModalNumbers,
    itens: state.item.list,
    unidadesMedida: state.convencao.unidadesMedida,
    unidadesProduto: state.convencao.unidadesProduto
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarProdutos,
    editarProduto,
    excluirProduto,
    listarItens,
    filtrarProdutos,
    listarUnidadesMedida,
    listarUnidadesProduto
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarProdutosPage)), 'PRODUTOS');