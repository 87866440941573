import React from 'react';
import { Link } from 'react-router-dom';

import styles from './HomeItem.module.css';

const HomeItem = props => 
    props.type === 'link' ?
    <Link to={props.path} className={styles.homeItem}>
        <img src={props.icon} alt='' width='60' />
        <br />
        <div>
            {props.name}
        </div>
    </Link>
    :
    <div className={styles.homeItem}>
        <img src={props.icon} alt='' width='60' />
        <br />
        <div>
            {props.name}
        </div>
    </div>

export default HomeItem;