import React from 'react';
import { Form } from 'react-bootstrap';

import styles from './LoginForm.module.css';


const LoginForm = props => {

    return (
        <div className={styles.loginForm}>
            <div className={styles.nomeEscola}>
                <img width="100" src={`https://storage.googleapis.com/iescolar-static-01/${props.municipio.cidade}/logo/logo.png`} />
                <p>SECRETARIA DE EDUCAÇÃO</p>
                <p>{props.municipio.cidadeCompleto}</p>
                
            </div>
            <Form onSubmit={props.handleSubmit}>
                {props.children}
            </Form>
            <div className={styles.blocoLinks}>
                <a class="" target="_blank" href="https://web.whatsapp.com/send?+55 (66) 98101-1961">
                    <img width="30" src={require('../../../src/assets/images/iconeWhats.png')} />
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCWD5c1kxHbJ5ualjNJyeXtA">
                    <img width="30" src={require('../../../src/assets/images/iconeYou.png')} />
                </a>
                <a class="" target="_blank" href="https://www.instagram.com/iescolaronline/?igshid=YWJhMjlhZTc%3D">
                    <img width="30" src={require('../../../src/assets/images/iconeInsta.png')} />
                </a>
                <a class="" target="_blank" href="http://redenetsolucoes.com.br/">
                    <img width="30" src={require('../../../src/assets/images/iconeEmail.png')} />
                </a>
            </div>

        </div>

    )
}

export default LoginForm;