import {
    LISTAR_CATEGORIAS_ALIMENTOS,
    LISTAR_TIPOS_COMPONENTES_NUTRICIONAIS,
    LISTAR_UNIDADES_MEDIDA_NUTRICIONAIS,
    LISTAR_FORMAS_ALIMENTOS,
    LISTAR_GRUPOS_NUTRIENTES,
    LISTAR_TIPOS_PERMISSOES,
    LISTAR_CATEGORIAS,
    LISTAR_TIPOS_REFEICAO,
    LISTAR_GRUPOS_ALIMENTARES,
    LISTAR_MODALIDADES,
    LISTAR_REFERENCIAS_NUTRICIONAIS,
    LISTAR_PORTES_EMPRESA,
    LISTAR_MODALIDADES_LICITACAO,
    LISTAR_TIPOS_LICITACAO,
    LISTAR_STATUS_AQUISICAO,
    LISTAR_STATUS_COMPRA,
    LISTAR_UNIDADES_MEDIDA,
    LISTAR_TIPOS_PESSOA,
    LISTAR_CARGOS,
    LISTAR_UNIDADES_PRODUTO,
    LISTAR_PATOLOGIAS
} from '../actions/types'

const INITIAL_STATE = {
    categoriasAlimentos: [],
    tiposComponentesNutricionais: [],
    unidadesMedidaNutricionais: [],
    formasAlimentos: [],
    gruposNutrientes: [],
    tiposPermissoes: [],
    categorias: [],
    tiposRefeicao: [],
    gruposAlimentares: [],
    modalidades: [],
    referenciasNutricionais: [],
    portesEmpresa: [],
    modalidadesLicitacao: [],
    tiposLicitacao: [],
    statusAquisicao: [],
    statusCompra: [],
    unidadesMedida: [],
    tiposPessoa: [],
    cargos: [],
    unidadesProduto: [],
    patologias: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_CATEGORIAS_ALIMENTOS:
            return { ...state, categoriasAlimentos: action.payload }

        case LISTAR_TIPOS_COMPONENTES_NUTRICIONAIS:
            return { ...state, tiposComponentesNutricionais: action.payload }

        case LISTAR_UNIDADES_MEDIDA_NUTRICIONAIS:
            return { ...state, unidadesMedidaNutricionais: action.payload }

        case LISTAR_FORMAS_ALIMENTOS:
            return { ...state, formasAlimentos: action.payload }

        case LISTAR_GRUPOS_NUTRIENTES:
            return { ...state, gruposNutrientes: action.payload }

        case LISTAR_TIPOS_PERMISSOES: {
            return { ...state, tiposPermissoes: action.payload }
        }

        case LISTAR_CATEGORIAS: {
            return { ...state, categorias: action.payload }
        }

        case LISTAR_TIPOS_REFEICAO: {
            return { ...state, tiposRefeicao: action.payload }
        }
        
        case LISTAR_GRUPOS_ALIMENTARES: {
            return { ...state, gruposAlimentares: action.payload }
        }

        case LISTAR_MODALIDADES: {
            return { ...state, modalidades: action.payload }
        }

        case LISTAR_REFERENCIAS_NUTRICIONAIS: {
            return { ...state, referenciasNutricionais: action.payload }
        }

        case LISTAR_PORTES_EMPRESA: {
            return { ...state, portesEmpresa: action.payload }
        }

        case LISTAR_MODALIDADES_LICITACAO: {
            return { ...state, modalidadesLicitacao: action.payload }
        }

        case LISTAR_TIPOS_LICITACAO: {
            return { ...state, tiposLicitacao: action.payload }
        }

        case LISTAR_STATUS_AQUISICAO: {
            return { ...state, statusAquisicao: action.payload }
        }

        case LISTAR_STATUS_COMPRA: {
            return { ...state, statusCompra: action.payload }
        }
        
        case LISTAR_UNIDADES_MEDIDA: {
            return { ...state, unidadesMedida: action.payload }
        }
        
        case LISTAR_TIPOS_PESSOA: {
            return { ...state, tiposPessoa: action.payload }
        }

        case LISTAR_CARGOS: {
            return { ...state, cargos: action.payload }
        }

        case LISTAR_UNIDADES_PRODUTO: {
            return { ...state, unidadesProduto: action.payload }
        }

        case LISTAR_PATOLOGIAS: {
            return { ...state, patologias: action.payload }
        }

        default:
            return state
    }
}