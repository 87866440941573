import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import history from './history';

import * as routes from './constants';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/Login';
import Home from '../pages/Home';
import DadosCadastraisPage from '../pages/DadosCadastrais';
import CadastrarUsuarioPage from '../pages/CadastrarUsuario';
import GerenciarUsuariosPage from '../pages/GerenciarUsuarios';
import CadastrarItemPage from '../pages/CadastrarItem';
import GerenciarItensPage from '../pages/GerenciarItens';
import CadastrarProdutoPage from '../pages/CadastrarProduto';
import GerenciarProdutosPage from '../pages/GerenciarProdutos';
import CadastrarFornecedorPage from '../pages/CadastrarFornecedor';
import GerenciarFornecedoresPage from '../pages/GerenciarFornecedores';
import CadastrarAquisicaoPage from '../pages/CadastrarAquisicao';
import GerenciarAquisicoesPage from '../pages/GerenciarAquisicoes';
import AditivosPage from '../pages/Aditivos';
import CadastrarSolicitacaoPage from '../pages/CadastrarSolicitacao';
import GerenciarSolicitacoesPage from '../pages/GerenciarSolicitacoes';
import Estoque from '../pages/Estoque';
import CadastrarMovimentacaoPage from '../pages/CadastrarMovimentacao';
import GerenciarMovimentacoesPage from '../pages/GerenciarMovimentacoes';
import BalancosPage from '../pages/Balancos';
import CadastrarAlimentoPage from '../pages/CadastrarAlimento';
import GerenciarAlimentosPage from '../pages/GerenciarAlimentos';
import CadastrarPreparoPage from '../pages/CadastrarPreparo';
import GerenciarPreparosPage from '../pages/GerenciarPreparos';
import CadastrarPratoPage from '../pages/CadastrarPrato';
import GerenciarPratosPage from '../pages/GerenciarPratos';
import CadastrarCardapioPage from '../pages/CadastrarCardapio';
import GerenciarUnidades from '../pages/GerenciarUnidades';
import CadastrarUnidade from '../pages/CadastrarUnidade';
import CardapioSemanalPage from '../pages/CadastrarCardapio/CardapioSemanal';
import KitEscolarPage from '../pages/KitEscolar';
// import CardapioMensalPage from '../pages/CadastrarCardapio/CardapioMensal';
import GerenciarCardapiosPage from '../pages/GerenciarCardapios';
import CardapioCalendarioPage from '../pages/CardapioCalendario';
import ExecucaoCardapiosPage from '../pages/ExecucaoCardapios';
import HistoricoExecucaoCardapiosPage from '../pages/HistoricoExecucaoCardapios';
import DesperdicioPage from '../pages/Desperdicio';
import RelatorioPage from '../pages/Relatorio';
import NotificacaoPage from '../pages/Notificacao';
// import EscolaPage from '../pages/Escola';

const Routes = () => (
    <Router>
        <div>
            <Route exact path={routes.LOGIN} component={Login} />
            <PrivateRoute path={routes.HOME} component={Home} />
            <PrivateRoute path={routes.DADOS_CADASTRAIS} component={DadosCadastraisPage} />

            <PrivateRoute path={routes.CADASTRAR_UNIDADE} component={CadastrarUnidade} />
            <PrivateRoute path={routes.GERENCIAR_UNIDADES} component={GerenciarUnidades} />

            <PrivateRoute path={routes.CADASTRAR_USUARIO} component={CadastrarUsuarioPage} />
            <PrivateRoute path={routes.GERENCIAR_USUARIOS} component={GerenciarUsuariosPage} />

            <PrivateRoute path={routes.CADASTRAR_ITEM} component={CadastrarItemPage} />
            <PrivateRoute path={routes.GERENCIAR_ITENS} component={GerenciarItensPage} />

            <PrivateRoute path={routes.CADASTRAR_PRODUTO} component={CadastrarProdutoPage} />
            <PrivateRoute path={routes.GERENCIAR_PRODUTOS} component={GerenciarProdutosPage} />

            <PrivateRoute path={routes.CADASTRAR_FORNECEDOR} component={CadastrarFornecedorPage} />
            <PrivateRoute path={routes.GERENCIAR_FORNECEDORES} component={GerenciarFornecedoresPage} />

            <PrivateRoute path={routes.CADASTRAR_AQUISICAO} component={CadastrarAquisicaoPage} />
            <PrivateRoute exact path={routes.GERENCIAR_AQUISICOES} component={GerenciarAquisicoesPage} />
            <PrivateRoute path={`${routes.GERENCIAR_AQUISICOES}/:idAquisicao${routes.ADITIVOS}`} component={AditivosPage} />

            <PrivateRoute path={routes.CADASTRAR_SOLICITACAO} component={CadastrarSolicitacaoPage} />
            <PrivateRoute path={routes.GERENCIAR_SOLICITACOES} component={GerenciarSolicitacoesPage} />

            <PrivateRoute path={routes.ESTOQUE} component={Estoque} />

            <PrivateRoute path={routes.CADASTRAR_MOVIMENTACAO} component={CadastrarMovimentacaoPage} />
            <PrivateRoute path={routes.GERENCIAR_MOVIMENTACOES} component={GerenciarMovimentacoesPage} />

            <PrivateRoute path={routes.KITS} component={KitEscolarPage} />

            <PrivateRoute path={routes.BALANCOS} component={BalancosPage} />

            <PrivateRoute path={routes.CADASTRAR_ALIMENTO} component={CadastrarAlimentoPage} />
            <PrivateRoute path={routes.GERENCIAR_ALIMENTOS} component={GerenciarAlimentosPage} />

            <PrivateRoute path={routes.CADASTRAR_PREPARO} component={CadastrarPreparoPage} />
            <PrivateRoute path={routes.GERENCIAR_PREPAROS} component={GerenciarPreparosPage} />

            <PrivateRoute path={routes.CADASTRAR_PRATO} component={CadastrarPratoPage} />
            <PrivateRoute path={routes.GERENCIAR_PRATOS} component={GerenciarPratosPage} />

            <PrivateRoute path={routes.CADASTRAR_CARDAPIO} component={CadastrarCardapioPage} />
            <PrivateRoute path={routes.CARDAPIO_SEMANAL} component={CardapioSemanalPage} />
            {/* <PrivateRoute path={routes.CARDAPIO_MENSAL} component={CardapioMensalPage} /> */}
            <PrivateRoute path={routes.GERENCIAR_CARDAPIOS} component={GerenciarCardapiosPage} />

            <PrivateRoute path={routes.CARDAPIO_CALENDARIO} component={CardapioCalendarioPage} />

            <PrivateRoute path={routes.EXECUCAO_CARDAPIOS} component={ExecucaoCardapiosPage} />
            <PrivateRoute exact path={routes.HISTORICO_EXECUCAO_CARDAPIOS} component={HistoricoExecucaoCardapiosPage} />
            <PrivateRoute path={`${routes.HISTORICO_EXECUCAO_CARDAPIOS}/:idRefeicao${routes.DESPERDICIO}`} component={DesperdicioPage} />

            <PrivateRoute path={`${routes.RELATORIO}/:type/:someId?`} component={RelatorioPage} />

            <PrivateRoute path={routes.NOTIFICACAO} component={NotificacaoPage} />

            {/* <PrivateRoute path={routes.ESCOLA} component={EscolaPage} /> */}
        </div>
    </Router>
)

export default Routes;