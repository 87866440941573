import React, { Component } from 'react';

import Title from '../components/Title';
import Header from '../components/Header';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Info from '../components/Info';
import Printable from '../components/Printable';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filtrarAlimentos, listarAlimentos, buscarEnderecoUnidade } from '../../actions';

import styles from './styles.module.css';

class KitEscolar extends Component {

    state = {
        alunoKit: {}
    }

    componentWillMount() {
        var kitEscolarAlunoId = sessionStorage.getItem('alunoKit');
        this.setState({ ...this.state, alunoKit: this.props.alunosKit.filter(k => k.id === Number(kitEscolarAlunoId))[0]});
        this.props.buscarEnderecoUnidade(this.props.data, this.props.token);
    }

    componentWillUnmount() {
        sessionStorage.removeItem('alunoKit');
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header  escola={this.props.enderecoUnidade}/>

                    <Title name='Recibo de entrega de generos alimenticios para composicao dos kits escolares' />
            
                    <br />
                    <div className={styles.detail}>

                        <div className='row'>
                            <div className='col'>
                                <b>Código:</b> {this.state.alunoKit.alunoId}
                            </div>
                            <div className='col'>
                                <b>Data de criação:</b> {new Date(this.state.alunoKit.data).toLocaleDateString('pt-br')}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <b>Aluno:</b> {this.state.alunoKit.nome}
                            </div>
                            {/* <div className='col'>
                                <b>Aquisição:</b> {}
                            </div> */}
                        </div>

                        <div className='row'>
                            {/* <div className='col'>
                                <b>Situação:</b> {}
                            </div> */}
                        </div>

                        <br />

                        <div className='row'>
                            <div className='col'>

                                <table border='1' className={styles.tableItens}>
                                    <thead>
                                        <tr>
                                            <th>Produto</th>
                                            <th>Unidade Medida</th>
                                            <th>Quantidade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.alunoKit.produtosKits.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                <td className={styles.produto}>{item.nome}</td>
                                                <td>{item.unidadeMedida}</td>
                                                <td>{item.quantidade}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                                  

                <div className={styles.assinaturas}>
                            <div className={styles.signature}>
                                <hr align="left" noshade />
                                <p>
                                 {`Assinatura do responsável pela entrega.`}
                                </p>
                            </div>

                            <div className={styles.signature}>
                                <hr align="left" noshade />
                                <p>
                                 {`Assinatura do pai ou responsável legal.`}
                                </p>
                            </div>

                            <div className={styles.signature}>
                                <hr align="left" noshade />
                                <p>
                                 {`Número do documento de identificação (RG) do responsável.`}
                                </p>
                            </div>

                            <div >
                                <span className={styles.data}>
                                    Data da entrega: ___/___/___
                                </span>

                            </div>

                        </div>

                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    alimentos: state.alimento.filtrados,
    alunosKit: state.estoque.alunosKits,
    enderecoUnidade: state.unidadeEducacional.enderecoUnidade
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarAlimentos,
    filtrarAlimentos,
    buscarEnderecoUnidade
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(KitEscolar);