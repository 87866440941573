import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import ActionButton from '../../components/ActionButton/index';
import SimpleModal from '../../components/SimpleModal/index';
import SimpleButton from '../../components/SimpleButton/index';
import Fieldset from '../../components/Fieldset/index';
import Input from '../../components/Input/index';
import SearchSelect from '../../components/SearchSelect/index';
import SimpleTable from '../../components/SimpleTable/index';
import SimpleInput from '../../components/SimpleInput/index';
import PageTitle from '../../components/PageTitle/index';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { listarAlimentos, editarAlimento, excluirAlimento, listarItens, listarFormasAlimentos, listarGruposAlimentares, filtrarAlimentos, listarCategoriasAlimentos, listarUnidadesMedida } from '../../actions';

import withAuthorization from '../../components/HighOrder/withAuthorization';

import styles from './GerenciarAlimentos.module.css';
import * as routes from '../../Routes/constants';
import * as reports from '../../relatorios/reportTypes';
import { withRouter } from 'react-router-dom';
import BackButton from '../../components/BackButton';


class GerenciarAlimentosPage extends Component {
    state = {
        showModal: false,
        alimento: {
            forma: {},
            formaSelecionado: {},
            itemAlimento: {},
            itemSelecionado: {},
            unidadeSelecionado: {},
            componentes: []
        },

        pesquisaNome: '',
        pesquisaItem: {},
        pesquisaCategoria: {},
        isPesquisa: false
    }

    componentDidMount() {
        this.props.listarAlimentos(this.props.token);
        this.props.listarItens(this.props.token);
        this.props.listarFormasAlimentos(this.props.token);
        this.props.listarCategoriasAlimentos(this.props.token);
        this.props.listarUnidadesMedida(this.props.token);
        // this.props.listarGruposAlimentares(this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalNumbers < nextProps.closeModalNumbers) this.handleCloseModal();
    }

    handleShowModal = alimento => {
        this.setState({
            showModal: true,
            alimento: {
                ...alimento,
                formaSelecionado: { value: alimento.forma.nome, label: alimento.forma.descricao },
                itemSelecionado: { value: alimento.itemAlimento.id, label: alimento.itemAlimento.nome },
                unidadeSelecionado: { value: alimento.unidadeMedida, label: alimento.unidadeMedida }
            }
        })
    }

    handleCloseModal = () => {
        this.setState({ showModal: false })
    }

    handleChange = (value, property, upper = () => { }) => {
        let alimento = JSON.parse(JSON.stringify(this.state.alimento));
        alimento[property] = value;

        this.setState({ ...this.state, alimento }, () => upper());
    }

    handleUnidadeInput = (selectedOption, property, option) => {
        let alimento = JSON.parse(JSON.stringify(this.state.alimento));
        alimento[property] = selectedOption.value;
        alimento[option] = selectedOption;

        this.setState({ ...this.state, alimento });
    }

    handleInputWithName = (selectedOption, property, option) => {
        let alimento = this.state.alimento;
        alimento[property].nome = selectedOption.value;
        alimento[option] = selectedOption;

        this.setState({ ...this.state, alimento });
    }

    handleItemInput = selectedOption => {
        let alimento = this.state.alimento;
        alimento.itemAlimento['id'] = selectedOption.value;
        alimento.itemSelecionado = selectedOption;

        this.setState({ ...this.state, alimento });
    }

    handleComponentesInput = (value, index, property) => {
        let alimento = JSON.parse(JSON.stringify(this.state.alimento));
        alimento.componentes[index][property] = value;

        this.setState({ ...this.state, alimento });
    }

    handleEditAlimento = e => {
        e.preventDefault();
        // console.log(this.state.alimento)
        let alimento = JSON.parse(JSON.stringify(this.state.alimento));

        let { itemProdutoTipo } = this.props.itens.find(i => i.id === alimento.itemAlimento.id)
        let { id } = alimento.itemAlimento;

        alimento.itemAlimento = {
            id, itemProdutoTipo
        }

        alimento.componentes = alimento.componentes.map(componente => ({ id: componente.id, componente: componente.componente.id, valor: componente.valor }));

        alimento.forma = alimento.forma.nome;
        // alimento.grupoAlimentar = alimento.grupoAlimentar.nome;

        console.log(alimento)

        this.props.editarAlimento(alimento, this.props.token);
    }


    mostrarPaginaRelatorio = () => {
        if (this.state.isPesquisa) {
            if (this.state.pesquisaNome !== '') {
                sessionStorage.setItem('pesquisaNome', this.state.pesquisaNome);
            }
            if (this.state.pesquisaItem.label) {
                sessionStorage.setItem('pesquisaItem', this.state.pesquisaItem.label);
            }
            if (this.state.pesquisaCategoria.value) {
                sessionStorage.setItem('pesquisaCategoria', this.state.pesquisaCategoria.value);
            }
        }
        this.props.history.push(`${routes.RELATORIO}/${reports.RELATORIO_ALIMENTOS}/filter=${this.state.isPesquisa}`);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Gerenciar Alimentos' hasDivider={true} />
                </Row>
                <div align='right'>
                    <SimpleButton
                        type='button'
                        color='blue'
                        name='Relatório'
                        icon='fas fa-print'
                        onClick={() => this.mostrarPaginaRelatorio()}
                    />
                </div>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Nome: </Form.Label>
                            <Form.Control
                                type='text'
                                value={this.state.pesquisaNome}
                                onChange={e => this.setState({ ...this.state, pesquisaNome: e.target.value, isPesquisa: true },
                                    () => this.props.filtrarAlimentos(this.state.pesquisaNome, this.state.pesquisaItem.label, this.state.pesquisaCategoria.value))}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Item: </Form.Label>
                            <Select
                                required
                                placeholder=''
                                isClearable
                                value={this.state.pesquisaItem}
                                onChange={selectedOption => this.setState({ ...this.state, pesquisaItem: selectedOption === null ? {} : selectedOption, isPesquisa: true },
                                    () => this.props.filtrarAlimentos(this.state.pesquisaNome, this.state.pesquisaItem.label, this.state.pesquisaCategoria.value))}

                                options={this.props.itens.map(item => ({
                                    value: item.id,
                                    label: item.nome
                                }))}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Pesquisar por Categoria: </Form.Label>
                            <Select
                                required
                                placeholder=''
                                isClearable
                                value={this.state.pesquisaCategoria}
                                onChange={selectedOption => this.setState({ ...this.state, pesquisaCategoria: selectedOption === null ? {} : selectedOption, isPesquisa: true },
                                    () => this.props.filtrarAlimentos(this.state.pesquisaNome, this.state.pesquisaItem.label, this.state.pesquisaCategoria.value))}

                                options={this.props.categoriasAlimentos.map(categoria => ({
                                    value: categoria.nome,
                                    label: categoria.descricao
                                }))}
                            />
                        </Form.Group>
                    </Row>
                </Form>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Forma</th>
                            <th>Item</th>
                            <th>Unidade de Medida</th>
                            <th>Base para Cálculo</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.filtrados.map(alimento => (
                            <tr key={alimento.id}>
                                <td>{alimento.nome}</td>
                                <td>{alimento.forma.descricao}</td>
                                <td>{alimento.itemAlimento.nome} - {alimento.itemAlimento.categoria}</td>
                                <td>{alimento.unidadeMedida}</td>
                                <td>{alimento.quantidadeBase}</td>
                                <td className={styles.actions}>
                                    <ActionButton
                                        title='Editar alimento'
                                        onClick={() => this.handleShowModal(alimento)}
                                        icon='fas fa-edit'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModal}
                    onHide={() => this.handleCloseModal()}
                    title='Editar Alimento'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleEditAlimento}>
                        <Row>
                            <Col>
                                <Fieldset legend='Dados do alimento' align='left'>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Input
                                                label='* Nome do alimento:'
                                                type='text'
                                                case='upper'
                                                value={this.state.alimento.nome}
                                                onChange={(value, upper) => this.handleChange(value, 'nome', upper)}
                                                required
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Input
                                                label='* Quant. base para cálculo:'
                                                type='number'
                                                value={this.state.alimento.quantidadeBase}
                                                onChange={value => this.handleChange(value, 'quantidadeBase')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <SearchSelect
                                                label='* Item:'
                                                value={this.state.alimento.itemSelecionado}
                                                onChange={selectedOption => this.handleItemInput(selectedOption)}
                                                options={
                                                    this.props.itens.map(item => (
                                                        { value: item.id, label: item.nome }
                                                    ))
                                                }
                                                required
                                            />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <SearchSelect
                                                label='* Unidade medida:'
                                                value={this.state.alimento.unidadeSelecionado}
                                                onChange={selectedOption => this.handleUnidadeInput(selectedOption, 'unidadeMedida', 'unidadeSelecionado')}
                                                options={
                                                    this.props.unidadesMedida.map(unidade => (
                                                        { value: unidade.nome, label: unidade.descricao }
                                                    ))
                                                }
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12}>
                                            <SearchSelect
                                                label='* Forma do alimento:'
                                                value={this.state.alimento.formaSelecionado}
                                                onChange={selectedOption => this.handleInputWithName(selectedOption, 'forma', 'formaSelecionado')}
                                                // onChange={selectedOption => console.log(selectedOption)}
                                                options={
                                                    this.props.formasAlimentos.map(forma => (
                                                        { value: forma.nome, label: forma.descricao }
                                                    ))
                                                }
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col>
                                <Fieldset legend='Selecione o componente' align='left'>
                                    <SimpleTable>
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Grupo nutricional</th>
                                                <th>Unidade de medida</th>
                                                <th>Valor</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.alimento.componentes.map((componente, index) => (
                                                <tr key={index}>
                                                    <td>{componente.componente.tipo}</td>
                                                    <td>{componente.componente.grupo}</td>
                                                    <td>{componente.componente.unidadeMedida}</td>
                                                    <td>
                                                        <SimpleInput type='number' step='.01' value={componente.valor} onChange={value => this.handleComponentesInput(value, index, 'valor')} required />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </SimpleTable>
                                </Fieldset>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.handleCloseModal()}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalNumbers: state.alimento.closeModalNumbers,
    list: state.alimento.list,
    filtrados: state.alimento.filtrados,
    itens: state.item.list,
    formasAlimentos: state.convencao.formasAlimentos,
    gruposAlimentares: state.convencao.gruposAlimentares,
    categoriasAlimentos: state.convencao.categoriasAlimentos,
    unidadesMedida: state.convencao.unidadesMedida,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarAlimentos,
    editarAlimento,
    excluirAlimento,
    listarItens,
    listarFormasAlimentos,
    listarGruposAlimentares,
    filtrarAlimentos,
    listarCategoriasAlimentos,
    listarUnidadesMedida,
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(GerenciarAlimentosPage)), 'ALIMENTOS')