import React from 'react';
import Detail from '../components/Detail';
import Printable from '../components/Printable';
import PrintButton from '../components/PrintButton';
import BackButton from '../components/BackButton';
import Header from '../components/Header';
import Title from '../components/Title';
import Info from '../components/Info';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { listarEstoqueItens, buscarEnderecoUnidade } from '../../actions';

import styles from './styles.module.css';

class EstoqueRelatorio extends React.Component {

    state = {
        estoque: {
            produtos: []
        }
    }

    componentWillMount() {
        this.props.listarEstoqueItens(this.props.data, this.props.token);
        this.props.buscarEnderecoUnidade(this.props.data, this.props.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.estoque !== this.props.estoque) this.setState({ ...this.state, estoque: nextProps.estoque });
    }

    render() {
        return (
            <>
             <div className={styles.topbuttons}>
                <BackButton />
                <PrintButton />
              </div>

                <br />

                <Printable>
                    <Info />

                    <Header  escola={this.props.enderecoUnidade}/>

                    <Title name='Relatório de Estoque' />

                    <Detail>

                        {this.state.estoque.produtos.map((estoque, index) => (
                            <div key={index} className='row-md-12'>
                                <div className={styles.dados}>
                                <div className='row'>
                                    <div className='col'>
                                        <b>Lote:</b> {estoque.produtoLote.numero}
                                    </div>
                                    <div className='col'>
                                        <b>Data de fabricação:</b> {estoque.produtoLote.dataFabricacao}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>Nome:</b> {estoque.produtoLote.produto.nome}
                                    </div>
                                    <div className='col'>
                                        <b>Data de validade:</b> {estoque.produtoLote.dataValidade}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>Item:</b> {estoque.produtoLote.produto.item}
                                    </div>
                                    <div className='col'>
                                        <b>Unidade de medida:</b> {estoque.produtoLote.produto.unidadeMedida}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>Quantidade:</b> {estoque.quantidade}
                                    </div>
                                    <div className='col'>
                                        <b>Unidade do produto:</b> {estoque.produtoLote.produto.unidadeProduto}
                                    </div>
                                </div>
     
                                </div>
                            </div>
                        ))}

                    </Detail>
                </Printable>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    estoque: state.estoque.filtrado,
    enderecoUnidade: state.unidadeEducacional.enderecoUnidade
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarEstoqueItens,
    buscarEnderecoUnidade
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EstoqueRelatorio);