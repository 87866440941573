import React from 'react';
import { Link } from 'react-router-dom';

const ItemWithSubSubmenu = props => (
    <>
        <Link to={{}} data-toggle='collapse' data-target={`#${props.target}`} aria-expanded="false" aria-controls={props.target}>
            <img src={props.icon} alt='' className='fa' />
            <span className='nav-text'>
                {props.name}
            </span>
        </Link>
        <div className='collapse' id={props.target}>
            {React.Children.map(props.children, child => (
                <div hidden={!props.opened} className={props.opened ? 'subMenuSpace' : ''}>
                    {React.cloneElement(child)}        
                </div>
            ))}
        </div>
    </>
)

export default ItemWithSubSubmenu;