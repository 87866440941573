import React from 'react';
import SimpleButton from '../../components/SimpleButton/index';

import styles from './BackButton.module.css';

const BackButton = props => {
    return (
        <div className = {styles.backbutton}>
        <SimpleButton
                type='button'
                color='blue'
                name='Voltar'
                icon='fas fa-chevron-left'
                onClick={() => window.history.go(-1)}
            />
        </div>
    );
};

export default BackButton;