import React from 'react';
import { Link } from 'react-router-dom';

import '../Sidebar.css';

const Item = props => (
    <Link to={props.path}>
        <img src={props.icon} alt='' className='fa' />
        <span className='nav-text'>
            {props.name}
        </span>
    </Link>
)

export default Item;