import { LISTAR_TURMAS,  FILTRAR_TURMAS, LISTAR_ALUNOS } from '../actions/types'

const INITIAL_STATE = {
    turmasFiltradas: [],
    turmas: [],
    alunos: [],
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case LISTAR_TURMAS:
            return { ...state, turmas: action.payload }

        case FILTRAR_TURMAS:
            return { ...state, turmasFiltradas: action.payload }

        case LISTAR_ALUNOS :
            return { ...state, alunos: action.payload }

        default:
            return state
    }
}