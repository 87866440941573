import React from 'react';

import styles from './styles.module.css';

const Title = props => (
    <div className={styles.title}>
        <div className='row text-center'>
            <div className='col'>
                <b>{props.name}</b>
            </div>
        </div>
    </div>
)

export default Title;