import React from 'react';

import styles from './Fieldset.module.css';

const Fieldset = props => (
    <fieldset {...props} className={styles.fieldset}>
        <legend style={{ textAlign: props.align }}>{props.legend}</legend>
        {props.children}
    </fieldset>
)

export default Fieldset;