import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import { CADASTRAR_ITEM, EDITAR_ITEM, LISTAR_ITENS, FILTRAR_ITENS } from './types'

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { BASE_URL, configAuthorizationToken, extractErrorMessage } from '../utils/requests';

export function cadastrarItem(item, token) {
    switch (item.itemProdutoTipo) {
        case 'LIMPEZA':
            delete item['componentes'];
            break;

        case 'ESCRITORIO':
            delete item['componentes'];
            break;

        default:
            // path = 'itens';
            break;
    }

    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.post(`${BASE_URL}/itens-produto`, item, configAuthorizationToken(token))
                    .then(res => {
                        dispatch({ type: CADASTRAR_ITEM, payload: {} })
                        toastr.success('Sucesso', 'Item cadastrado com sucesso!');
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err));
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente cadastrar o Item?' />
            )
        })
    }
}

export function listarItens(token) {
    return dispatch => {
        axios.get(`${BASE_URL}/itens-produto`, configAuthorizationToken(token))
            .then(res => dispatch([
                { type: LISTAR_ITENS, payload: res.data },
                { type: FILTRAR_ITENS, payload: res.data }
            ]))
            .catch(err => console.log(err))
    }
}

export function filtrarItens(nome, tipo) {
    return (dispatch, getState) => {
        let itens = getState().item.list;
        let itensFiltrados = itens.filter(item =>
            item.nome.toLowerCase().includes(nome.toLowerCase()) && item.itemProdutoTipo.toLowerCase().includes(tipo.toLowerCase()))
        dispatch({ type: FILTRAR_ITENS, payload: itensFiltrados })
    }
}

export function editarItem(item, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/itens-produto/${item.id}`, item, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_ITEM, payload: {} },
                            listarItens(token)
                        ])

                        toastr.success('Sucesso!', 'Item editado com sucesso')
                    })
                    .catch(err => {
                        dispatch(listarItens(token))

                        toastr.error('Erro', extractErrorMessage(err));
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Item?' />
            )
        })
    }
}

export function excluirItem(id, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/itens-produto/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatch(listarItens(token))
                        toastr.success('Sucesso!', 'Item exluído com sucesso')
                    })
                    .catch(err => toastr.error('Erro', extractErrorMessage(err)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Item?' />
            )
        })
    }
}