import React from 'react';

import styles from './TinyAction.module.css';

const chooseAClass = color => {
    switch(color) {
        case 'blue' : return styles.blueButton
        case 'red' : return styles.redButton
        case 'green' : return styles.greenButton
        default : return ''
    }
}

const TinyAction = props => (
    <button
        {...props}
        title={props.title}
        className={`${styles.tiny} ${chooseAClass(props.color)}`} 
        type={props.type}
    >
        <i className={props.icon}></i>
    </button>
)

export default TinyAction;