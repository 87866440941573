import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import SimpleTable from '../../components/SimpleTable/index';
import PageTitle from '../../components/PageTitle/index';
import SimpleModal from '../../components/SimpleModal/index';
import ActionButton from '../../components/ActionButton/index';
import Textarea from '../../components/Textarea/index';
import Input from '../../components/Input';
import MoneyInputMask from '../../components/MoneyInputMask/index';
import SimpleButton from '../../components/SimpleButton/index';
import SimpleSelect from '../../components/SimpleSelect';
import TinyAction from '../../components/TinyAction';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    cadastrarAditivo,
    listarAditivos,
    listarUmaAquisicao,
    editarAdivito,
    excluirAditivo,
    listarItensAquisicao,
    listarAquisicoes,
    listarUnidadesProduto,
} from '../../actions'

import styles from './Aditivos.module.css';

import moment from 'moment';

import withAuthorization from '../../components/HighOrder/withAuthorization';

const defaultState = {
    id: -1,

    aquisicao: -1,
    observacao: '',

    aditivoTipo: 'PRAZO',

    novaVigencia: '',

    valor: 0,
    itensAditivados: [{
        produto: 0,
        descricao: '',
        quantidade: 0,
        unidadeProduto: '',
        precoUnidade: 0,
        propostaVencedora: 0,

        valorItem: 0
    }],

    valorParcial: 0,
}

const INITIAL_ITENS_ADITIVADOS = [{
    produto: 0,
    descricao: '',
    quantidade: 0,
    unidadeProduto: '',
    precoUnidade: 0,
    propostaVencedora: 0,

    valorItem: 0
}]

class AditivosPage extends Component {
    state = {
        showModalAdicionar: false,
        showModalEditar: false,

        ...defaultState
    }

    componentDidMount() {
        this.props.listarAditivos(this.props.match.params.idAquisicao, this.props.token)
        this.props.listarAquisicoes(this.props.token)
        this.props.listarUmaAquisicao(this.props.match.params.idAquisicao, this.props.token)
        this.props.listarItensAquisicao(this.props.match.params.idAquisicao, this.props.token)
        this.props.listarUnidadesProduto(this.props.token)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.closeModalNumbers < nextProps.closeModalNumbers) this.setState({ ...defaultState, itensAditivados: JSON.parse(JSON.stringify(INITIAL_ITENS_ADITIVADOS)), showModalAdicionar: false, showModalEditar: false })
    }

    handleShowModalAdicionar = () => {
        this.setState({ showModalAdicionar: true })
    }

    handleCloseModalAdicionar = () => {
        this.setState({ ...this.state, ...defaultState, itensAditivados: JSON.parse(JSON.stringify(INITIAL_ITENS_ADITIVADOS)), showModalAdicionar: false })
    }

    handleShowModalEditar = aditivo => {
        let aditivoCopy = JSON.parse(JSON.stringify(aditivo));

        if (aditivoCopy.aditivoTipo === 'VALOR') {
            aditivoCopy.itensAditivados = aditivoCopy.itensAditivados
                .map(item => ({ ...item, produto: item.produto.id }))

            aditivoCopy.itensAditivados = this.calcValorItem(aditivoCopy.itensAditivados);

            this.setState({ ...this.state, showModalEditar: true, ...aditivoCopy }, () => this.calcValorParcial(aditivoCopy.itensAditivados))
        } else {
            aditivoCopy.novaVigencia = moment(aditivoCopy.novaVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD')
            this.setState({ ...this.state, showModalEditar: true, ...aditivoCopy })
        }
    }

    handleCloseModalEditar = () => this.setState({ ...this.state, ...defaultState, itensAditivados: JSON.parse(JSON.stringify(INITIAL_ITENS_ADITIVADOS)), showModalEditar: false })

    handleAddItem = index => {
        let item = { produto: 0, descricao: '', quantidade: 0, unidadeProduto: '', precoUnidade: 0, valorItem: 0 }

        let itensAditivados = this.state.itensAditivados;
        itensAditivados.splice(index + 1, 0, item);

        this.setState({ ...this.state, itensAditivados })
    }

    handleRemoveItem = index => {
        let itensAditivados = this.state.itensAditivados;
        if (itensAditivados.length > 1) {
            itensAditivados.splice(index, 1);

            this.setState({ ...this.state, itensAditivados })
        }
    }

    handleChangeProduto = (value, index) => {
        let item = this.props.itensAquisicao.filter(item => item.produto.id = value)[0];

        let itensAditivados = this.state.itensAditivados;
        let itemAditivado = this.state.itensAditivados[index];

        itemAditivado.produto = value;
        itemAditivado.descricao = item.descricao;
        itemAditivado.unidadeProduto = item.unidadeProduto;
        itemAditivado.precoUnidade = item.precoUnidade;
        itemAditivado.propostaVencedora = item.propostaVencedora;

        itensAditivados[index] = itemAditivado;

        this.setState({ ...this.state, itensAditivados }, () => this.calcValorParcial(itensAditivados))
    }

    handleChangeQuantidade = (value, index) => {
        let itensAditivados = this.state.itensAditivados;
        itensAditivados[index].quantidade = value;

        let quantidade = itensAditivados[index].quantidade;
        let precoUnidade = itensAditivados[index].precoUnidade;

        itensAditivados[index].valorItem = quantidade * precoUnidade;

        this.setState({ ...this.state, itensAditivados }, () => this.calcValorParcial(itensAditivados))
    }

    handleChangeInput = (value, index, property, upper = () => { }) => {
        let itensAditivados = this.state.itensAditivados;
        itensAditivados[index][property] = value;

        this.setState({ ...this.state, itensAditivados }, () => upper())
    }

    calcValorItem = itens => {
        return itens.map(item => ({ ...item, valorItem: item.quantidade * item.precoUnidade }))
    }

    calcValorParcial = itens => {
        // let valorParcial = 0;

        let valorSoma = itens
            .map(item => item.valorItem)
            .reduce((soma, valorAtual) => soma + valorAtual);

        this.setState({ valorParcial: valorSoma })
    }

    handleSubmitAdd = e => {
        e.preventDefault();

        let {
            observacao,
            aditivoTipo,
            novaVigencia,
            valor,
            itensAditivados,
        } = this.state;

        novaVigencia = moment(novaVigencia, 'YYYY-MM-DD').format('DD/MM/YYYY')

        let aditivo = {
            aquisicao: {
                id: this.props.aquisicao.id,
                aquisicaoTipo: this.props.aquisicao.aquisicaoTipo
            },
            aditivoTipo,
            observacao,
            novaVigencia,
            valor,
            itensAditivados
        }

        // console.log(aditivo)

        this.props.cadastrarAditivo(aditivo, this.props.token);
    }

    handleSubmitEdit = e => {
        e.preventDefault();

        let {
            id,
            observacao,
            aditivoTipo,
            novaVigencia,
            valor,
            itensAditivados,
        } = this.state;

        novaVigencia = moment(novaVigencia, 'YYYY-MM-DD').format('DD/MM/YYYY')

        let aditivo = {
            id,
            aquisicao: {
                id: this.props.aquisicao.id,
                aquisicaoTipo: this.props.aquisicao.aquisicaoTipo
            },
            aditivoTipo,
            observacao,
            novaVigencia,
            valor,
            itensAditivados
        }

        // console.log(aditivo)

        this.props.editarAdivito(aditivo, this.props.token);
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Aditivos das Licitações/Contratos' hasDivider={true} />
                </Row>

                <Row style={{ marginBottom: '16px' }}>
                    <Col align='right'>
                        <SimpleButton
                            type='button'
                            color='green'
                            onClick={() => this.handleShowModalAdicionar()}
                            name='Adicionar Aditivo'
                            icon='fas fa-plus'
                        />
                    </Col>
                </Row>

                <SimpleTable>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Tipo</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.list.map((aditivo, index) => (
                            <tr key={index}>
                                <td>{aditivo.id}</td>
                                <td>{aditivo.aditivoTipo}</td>

                                <td className={styles.actions}>
                                    {/* <ActionButton
                                        title='Mostrar Aditivo'
                                        // onClick={() => this.handleShowModal(aquisicao)}
                                        icon='fas fa-eye'
                                    /> */}

                                    <ActionButton
                                        title='Editar Aditivo'
                                        // disabled={aquisicao.status === 'CANCELADA'}
                                        onClick={() => this.handleShowModalEditar(aditivo)}
                                        icon='fas fa-edit'
                                    />

                                    <ActionButton
                                        title='Excluir Aditivo'
                                        // disabled={aquisicao.status === 'VIGENTE'}
                                        onClick={() => this.props.excluirAditivo(this.props.match.params.idAquisicao, aditivo.id, this.props.token)}
                                        icon='fas fa-trash-alt'
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </SimpleTable>

                <SimpleModal
                    show={this.state.showModalAdicionar}
                    onHide={this.handleCloseModalAdicionar}
                    title='Adicionar Aditivo'
                    icon='fas fa-plus'>

                    <Form onSubmit={this.handleSubmitAdd}>
                        <Row>
                            <Col>
                                <SimpleSelect
                                    label='* Tipo de Aditivo:'
                                    value={this.state.aditivoTipo}
                                    onChange={e => this.setState({ ...this.state, aditivoTipo: e.target.value })}
                                    required>
                                    <option></option>
                                    <option value='PRAZO'>PRAZO</option>
                                    <option value='VALOR'>VALOR</option>
                                </SimpleSelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Textarea
                                    label='* Observação:'
                                    rows={3}
                                    value={this.state.observacao}
                                    onChange={(value, upper) => this.setState({ ...this.state, observacao: value }, () => upper())}
                                    required
                                />
                            </Col>
                        </Row>
                        {
                            this.state.aditivoTipo === 'PRAZO' ?
                                (
                                    <>
                                        <Row>
                                            <Col>
                                                <Input
                                                    label='Nova Vigência:'
                                                    type='date'
                                                    value={this.state.novaVigencia}
                                                    onChange={value => this.setState({ ...this.state, novaVigencia: value })}
                                                    min= {new Date().toISOString().split("T")[0]}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Row>
                                            <Col md={12}>
                                                <span>
                                                    O valor do aditivo não pode passar de <CurrencyFormat value={(this.props.aquisicao.valor * (25 / 100))} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> (25% do valor da Aquisição)
                                                </span>
                                                <MoneyInputMask
                                                    label='* Valor total:'
                                                    value={this.state.valor}
                                                    onChangeEvent={(e, maskValue, floatValue) => this.setState({ ...this.state, valor: floatValue })}
                                                />
                                                <span style={{ color: this.state.valorParcial !== this.state.valor ? 'red' : 'green' }}>
                                                    <b>Valor parcial:</b> <CurrencyFormat value={this.state.valorParcial} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> {this.state.valorParcial !== this.state.valor && '(O valor parcial é diferente do valor total do Aditivo)'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '16px' }}>
                                            <Col align='center'>
                                                <SimpleButton type='button' name='Itens' color='blue' />
                                            </Col>
                                        </Row>
                                        {this.state.itensAditivados.map((item, itemIndex) => (
                                            <div className={styles.itens} key={itemIndex}>
                                                <Row>
                                                    <Col md={10}>
                                                        <SimpleSelect
                                                            label={`* ${itemIndex + 1}:`}
                                                            value={item.produto}
                                                            onChange={e => this.handleChangeProduto(parseInt(e.target.value), itemIndex)}
                                                            required
                                                        >

                                                            <option></option>
                                                            {this.props.itensAquisicao
                                                                .map(item => item.produto)
                                                                .map(produto =>
                                                                    <option key={produto.id} value={produto.id}>{produto.nome}</option>
                                                                )}
                                                        </SimpleSelect>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Row>
                                                            <Col align='center'>
                                                                <TinyAction
                                                                    type='button'
                                                                    title='Adicionar novo produto'
                                                                    onClick={() => this.handleAddItem(itemIndex)}
                                                                    color='green'
                                                                    icon='fas fa-plus'
                                                                />
                                                            </Col>
                                                            <Col align='center'>
                                                                <TinyAction
                                                                    type='button'
                                                                    title='Remover produto'
                                                                    onClick={() => this.handleRemoveItem(itemIndex)}
                                                                    color='red'
                                                                    icon='fas fa-minus'
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Input
                                                            label='Descrição:'
                                                            type='text'
                                                            case='upper'
                                                            value={item.descricao}
                                                            onChange={(value, upper) => this.handleChangeInput(value, itemIndex, 'descricao', upper)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Input
                                                            label='* Quantidade:'
                                                            type='number'
                                                            value={item.quantidade}
                                                            onChange={value => this.handleChangeQuantidade(value, itemIndex)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <SimpleSelect
                                                            label='* Unidade de medida:'
                                                            value={item.unidadeProduto}
                                                            onChange={e => this.handleChangeInput(e.target.value, itemIndex, 'unidadeProduto')}
                                                            disabled
                                                        >

                                                            <option></option>
                                                            {this.props.unidadesProduto.map((unidade, index) => (
                                                                <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                                            ))}
                                                        </SimpleSelect>
                                                    </Col>
                                                    <Col md={6}>
                                                        <MoneyInputMask
                                                            label='* Preço unidade:'
                                                            value={item.precoUnidade}
                                                            onChange={(e, maskedValue, floatValue) => this.handleChangeInput(floatValue, itemIndex, 'precoUnidade')}
                                                            disabled
                                                        />
                                                        <span className={styles.valorTotalItem}>
                                                            Valor total do item: <CurrencyFormat value={item.valorItem} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>
                                        ))}
                                    </>
                                )
                        }

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.handleCloseModalAdicionar()}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Adicionar'
                                    disabled={this.state.valor > (this.props.aquisicao.valor * (25 / 100))}
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>

                </SimpleModal>

                <SimpleModal
                    show={this.state.showModalEditar}
                    onHide={this.handleCloseModalEditar}
                    title='Editar Aditivo'
                    icon='fas fa-edit'>

                    <Form onSubmit={this.handleSubmitEdit}>
                        <Row>
                            <Col>
                                <SimpleSelect
                                    label='* Tipo de Aditivo:'
                                    value={this.state.aditivoTipo}
                                    onChange={e => this.setState({ ...this.state, aditivoTipo: e.target.value })}
                                    disabled
                                    required>
                                    <option></option>
                                    <option value='PRAZO'>PRAZO</option>
                                    <option value='VALOR'>VALOR</option>
                                </SimpleSelect>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Textarea
                                    label='* Observação:'
                                    rows={3}
                                    case='upper'
                                    value={this.state.observacao}
                                    onChange={(value, upper) => this.setState({ ...this.state, observacao: value }, () => upper())}
                                    required
                                />
                            </Col>
                        </Row>
                        {
                            this.state.aditivoTipo === 'PRAZO' ?
                                (
                                    <>
                                        <Row>
                                            <Col>
                                                <Input
                                                    label='Nova Vigência:'
                                                    type='date'
                                                    value={this.state.novaVigencia}
                                                    onChange={value => this.setState({ ...this.state, novaVigencia: value })}
                                                    min= {new Date().toISOString().split("T")[0]}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Row>
                                            <Col md={12}>
                                                <span>
                                                    O valor do aditivo não pode passar de <CurrencyFormat value={(this.props.aquisicao.valor * (25 / 100))} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> (25% do valor da Aquisição)
                                                </span>
                                                <MoneyInputMask
                                                    label='* Valor total:'
                                                    value={this.state.valor}
                                                    onChangeEvent={(e, maskValue, floatValue) => this.setState({ ...this.state, valor: floatValue })}
                                                />
                                                <span style={{ color: this.state.valorParcial !== this.state.valor ? 'red' : 'green' }}>
                                                    <b>Valor parcial:</b> <CurrencyFormat value={this.state.valorParcial} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} /> {this.state.valorParcial !== this.state.valor && '(O valor parcial é diferente do valor total do Aditivo)'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '16px' }}>
                                            <Col align='center'>
                                                <SimpleButton type='button' name='Itens' color='blue' />
                                            </Col>
                                        </Row>
                                        {this.state.itensAditivados.map((item, itemIndex) => (
                                            <div className={styles.itens} key={itemIndex}>
                                                <Row>
                                                    <Col md={10}>
                                                        <SimpleSelect
                                                            label={`* ${itemIndex + 1}:`}
                                                            value={item.produto}
                                                            onChange={e => this.handleChangeProduto(parseInt(e.target.value), itemIndex)}
                                                            required
                                                        >

                                                            <option></option>
                                                            {this.props.itensAquisicao
                                                                .map(item => item.produto)
                                                                .map(produto =>
                                                                    <option key={produto.id} value={produto.id}>{produto.nome}</option>
                                                                )}
                                                        </SimpleSelect>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Row>
                                                            <Col align='center'>
                                                                <TinyAction
                                                                    type='button'
                                                                    title='Adicionar novo produto'
                                                                    onClick={() => this.handleAddItem(itemIndex)}
                                                                    color='green'
                                                                    icon='fas fa-plus'
                                                                />
                                                            </Col>
                                                            <Col align='center'>
                                                                <TinyAction
                                                                    type='button'
                                                                    title='Remover produto'
                                                                    onClick={() => this.handleRemoveItem(itemIndex)}
                                                                    color='red'
                                                                    icon='fas fa-minus'
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Input
                                                            label='Descrição:'
                                                            type='text'
                                                            case='upper'
                                                            value={item.descricao}
                                                            onChange={(value, upper) => this.handleChangeInput(value, itemIndex, 'descricao', upper)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Input
                                                            label='* Quantidade:'
                                                            type='number'
                                                            value={item.quantidade}
                                                            onChange={value => this.handleChangeQuantidade(value, itemIndex)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <SimpleSelect
                                                            label='* Unidade de medida:'
                                                            value={item.unidadeProduto}
                                                            onChange={e => this.handleChangeInput(e.target.value, itemIndex, 'unidadeProduto')}
                                                            disabled
                                                        >

                                                            <option></option>
                                                            {this.props.unidadesProduto.map((unidade, index) => (
                                                                <option key={index} value={unidade.nome}>{unidade.descricao}</option>
                                                            ))}
                                                        </SimpleSelect>
                                                    </Col>
                                                    <Col md={6}>
                                                        <MoneyInputMask
                                                            label='* Preço unidade:'
                                                            value={item.precoUnidade}
                                                            onChange={(e, maskedValue, floatValue) => this.handleChangeInput(floatValue, itemIndex, 'precoUnidade')}
                                                            disabled
                                                        />
                                                        <span className={styles.valorTotalItem}>
                                                            Valor total do item: <CurrencyFormat value={item.valorItem} thousandSeparator='.' decimalSeparator=',' fixedDecimalScale={true} decimalScale={2} displayType={'text'} prefix={'R$ '} />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </div>
                                        ))}
                                    </>
                                )
                        }

                        <Row style={{ marginTop: '16px' }}>
                            <Col align='left'>
                                <SimpleButton
                                    type='button'
                                    color='red'
                                    name='Cancelar'
                                    icon='fas fa-times'
                                    onClick={() => this.handleCloseModalEditar()}
                                />
                            </Col>
                            <Col align='right'>
                                <SimpleButton
                                    type='submit'
                                    color='green'
                                    name='Atualizar'
                                    disabled={this.state.valor > (this.props.aquisicao.valor * (25 / 100))}
                                    icon='fas fa-check'
                                />
                            </Col>
                        </Row>
                    </Form>

                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    closeModalNumbers: state.aditivo.closeModalNumbers,
    list: state.aditivo.list,
    itensAquisicao: state.aquisicao.itens,
    aquisicoes: state.aquisicao.list,
    aquisicao: state.aquisicao.aquisicao,
    unidadesProduto: state.convencao.unidadesProduto,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    cadastrarAditivo,
    listarAditivos,
    listarUmaAquisicao,
    editarAdivito,
    excluirAditivo,
    listarItensAquisicao,
    listarAquisicoes,
    listarUnidadesProduto
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(AditivosPage)), 'AQUISICOES');
