import { LISTAR_PRATOS, CADASTRAR_PRATO, EDITAR_PRATO, FILTRAR_PRATOS, LISTAR_PRATO_POR_ID } from '../actions/types'

const INITIAL_STATE = {
    list: [],
    filtrados: [],

    resetCount: 0,
    closeModalCount: 0,
    prato: {}
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case LISTAR_PRATOS:
            return { ...state, list: action.payload }

        case CADASTRAR_PRATO:
            return { ...state, resetCount: ++state.resetCount }

        case EDITAR_PRATO:
            return { ...state, closeModalCount: ++state.closeModalCount }

        case FILTRAR_PRATOS:
            return { ...state, filtrados: action.payload }
        
        case LISTAR_PRATO_POR_ID:
            return {...state, prato: action.payload }

        default:
            return state
    }
}