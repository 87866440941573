import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PageTitle from '../../components/PageTitle/index';
import Fieldset from '../../components/Fieldset/index';
import SimpleButton from '../../components/SimpleButton/index';
import SimpleTable from '../../components/SimpleTable';
import BackButton from '../../components/BackButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    listarDesperdicios
} from './../../actions'

// import styles from './Desperdicio.module.css';

import withAuthorization from '../../components/HighOrder/withAuthorization';

const types = {
    ALUNOS_PARA_REFEICAO: {
        text: 'Alunos para refeicao',
        name: 'alunosParaRefeicao'
    },
    ENTRADA_REFEITORIO: {
        text: 'Alunos alimentados',
        name: 'entradaRefeitorio'
    },
    DESPERDICIO: {
        text: 'Alunos não alimentados',
        name: 'desperdicio'
    }
}

class DesperdicioPage extends React.Component {

    state = {
        nomeAlunoPesquisa: '',
        listProps: types.ALUNOS_PARA_REFEICAO,
        chartOptions: {}
    }

    componentDidMount() {
        this.props.listarDesperdicios(this.props.match.params.idRefeicao, this.props.token)
    }

    // componentWillReceiveProps(nextProps) {
    //     if(this.props.desperdicios !== nextProps.desperdicios) {

    //     }
    // }

    handleVisualizarAlunos = type => {
        this.setState({ ...this.state, listProps: type })
    }

    render() {
        return (
            <>
                <BackButton/>
                <Row>
                    <PageTitle name='Desperdício' hasDivider={true} />
                </Row>

                <Row>
                    <Col>
                        <Fieldset legend='Dados de alunos da refeição' align='left'>
                            <Row>
                                <Col>
                                    Total de alunos para refeição:
                                </Col>
                                <Col>
                                    <b>{this.props.desperdicios.alunosParaRefeicao.length}</b>
                                </Col>
                                <Col>
                                    <SimpleButton
                                        type='button'
                                        color='blue'
                                        onClick={() => this.handleVisualizarAlunos(types.ALUNOS_PARA_REFEICAO)}
                                        name='Visualizar alunos'
                                        icon='fas fa-eye'
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    Total de alunos alimentados:
                                </Col>
                                <Col>
                                    <b>{this.props.desperdicios.entradaRefeitorio.length}</b>
                                </Col>
                                <Col>
                                    <SimpleButton
                                        type='button'
                                        color='blue'
                                        onClick={() => this.handleVisualizarAlunos(types.ENTRADA_REFEITORIO)}
                                        name='Visualizar alunos'
                                        icon='fas fa-eye'
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    Total de alunos não alimentados:
                                </Col>
                                <Col>
                                    <b>{this.props.desperdicios.desperdicio.length}</b>
                                </Col>
                                <Col>
                                    <SimpleButton
                                        type='button'
                                        color='blue'
                                        onClick={() => this.handleVisualizarAlunos(types.DESPERDICIO)}
                                        name='Visualizar alunos'
                                        icon='fas fa-eye'
                                    />
                                </Col>
                            </Row>
                            <hr />

                            <div style={{ marginTop: '36px' }}>
                                {/* <Form>
                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Pesquisar por Nome do Aluno: </Form.Label>
                                            <Form.Control
                                                type='text'
                                                value={this.state.nomeAlunoPesquisa}
                                                onChange={e => this.setState({ ...this.state, nomeAlunoPesquisa: e.target.value },
                                                    () => console.log(this.state.nomeAlunoPesquisa))}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Form> */}

                                <Row>
                                    <Col align='center'>
                                        <b>{this.state.listProps.text}</b>
                                    </Col>
                                </Row>

                                <SimpleTable>
                                    <thead>
                                        <tr>
                                            <th>Nome do aluno</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.desperdicios[this.state.listProps.name].map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.nome}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </SimpleTable>

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        {
                                            chart: {
                                                type: 'column'
                                            },
                                            title: {
                                                text: ''
                                            },
                                            xAxis: {
                                                categories: ['Alunos para refeição', 'Alunos alimentados', 'Não alimentados']
                                            },
                                            yAxis: {
                                                title: {
                                                    text: 'Alunos'
                                                }
                                            },
                                            series: [{
                                                name: 'Alunos',
                                                data: [
                                                    this.props.desperdicios.alunosParaRefeicao.length,
                                                    this.props.desperdicios.entradaRefeitorio.length,
                                                    this.props.desperdicios.desperdicio.length
                                                ]
                                            }]
                                        }
                                    }
                                />

                            </div>
                        </Fieldset>
                    </Col>
                </Row>

            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    desperdicios: state.execucao.desperdicios
})

const mapDispatchToProps = dispatch => bindActionCreators({
    listarDesperdicios
}, dispatch)

export default withAuthorization(connect(mapStateToProps, mapDispatchToProps)(withRouter(DesperdicioPage)), 'CARDAPIOS')
