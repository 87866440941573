import React from 'react';

import DataToggle from './DataToggle';
import DataTarget from './DataTarget';

class Accordion extends React.Component {
    static DataToggle = DataToggle;
    static DataTarget = DataTarget;

    render() {
        return (
            <div id='acoordion'>
                {this.props.children}
            </div>
        )
    }
}

export default Accordion;