import React from 'react';
import ConfirmMessage from '../components/ConfirmMessage';

import {
    CADASTRAR_USUARIO,
    EDITAR_USUARIO,
    LISTAR_USUARIOS,
    FILTRAR_USUARIOS,
    UPDATE_AUTH_USER,
    ATUALIZAR_FOTO_USUARIO,
    RECUPERAR_SENHA_USUARIO,
    BUSCAR_NOTIFICACOES,
    BUSCAR_SECRETARIA,
    FILTRAR_NOTIFICACOES
} from './types';

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { BASE_URL, configAuthorizationToken, configAuthorizationTokenAndContentTypeMultipart, extractErrorMessage, configAuthorizationTokenAndContentTypeJson } from '../utils/requests';

import userImgIfError from './../utils/userImgIfError';

export function cadastrarUsuario(usuario, token) {
    return dispatch => {
        axios.post(`${BASE_URL}/usuarios`, usuario, configAuthorizationToken(token))
            .then(res => {
                dispatch({ type: CADASTRAR_USUARIO, payload: res.data })
                toastr.success('Sucesso', 'Usuário cadastrado com sucesso!')
            })
            .catch(err => toastr.error('Erro', extractErrorMessage(err)));
    }
}

export function listarUsuarios(token) {
    console.log('1')
    return dispatch => {
        axios.get(`${BASE_URL}/usuarios`, configAuthorizationToken(token))
            .then(res => dispatch([
                { type: LISTAR_USUARIOS, payload: res.data },
                { type: FILTRAR_USUARIOS, payload: res.data }
            ]))
            .catch(err => toastr.error('Erro', extractErrorMessage(err)))
    }
}

export function filtrarUsuarios(property, value) {
    console.log('2')
    return (dispatch, getState) => {
        let usuarios = getState().usuario.list
        let usuariosFiltrados = usuarios.filter(usuario => usuario.pessoa[property].toLowerCase().includes(value.toLowerCase()))
        dispatch({ type: FILTRAR_USUARIOS, payload: usuariosFiltrados })
    }
}

export function editarUsuario(usuario, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/usuarios/${usuario.id}`, usuario, configAuthorizationToken(token))
                    .then(res => {
                        dispatch([
                            { type: EDITAR_USUARIO, payload: {} },
                            listarUsuarios(token),
                            { type: UPDATE_AUTH_USER, payload: res.data }
                        ])
                        toastr.success('Sucesso', 'Usuário editado com sucesso!')
                    })
                    .catch(err => {
                        dispatch(listarUsuarios(token))
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar o Usuário?' />
            )
        })
    }
}

export function excluirUsuario(id, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.delete(`${BASE_URL}/usuarios/${id}`, configAuthorizationToken(token))
                    .then(res => {
                        dispatch(listarUsuarios(token))
                        toastr.success('Sucesso', 'Usuário excluído com sucesso!')
                    })
                    .catch(err => toastr.error('Erro', extractErrorMessage(err)))
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente excluir o Usuário?' />
            )
        })
    }
}

export function editarDadosCadastrais(usuario, token) {
    return dispatch => {
        toastr.confirm(null, {
            onOk: () => {
                axios.put(`${BASE_URL}/pessoas/${usuario.pessoa.id}`, usuario, configAuthorizationToken(token))
                    .then(res => {
                        // console.log(res.data);
                        dispatch({ type: UPDATE_AUTH_USER, payload: res.data });
                        toastr.success('Sucesso', 'Dados cadastrais atualizados com sucesso!');
                    })
                    .catch(err => {
                        toastr.error('Erro', extractErrorMessage(err))
                    })
            },
            onCancel: () => { },
            component: () => (
                <ConfirmMessage message='Deseja realmente atualizar os dados do Usuário?' />
            )
        })
    }
}

// function Uint8ToString(u8a) {
//     var CHUNK_SZ = 0x8000;
//     var c = [];
//     for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
//         c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
//     }
//     return c.join("");
// }

export function buscarFotoUsuario(id, token) {
    return dispatch => {
        axios.get(`${BASE_URL}/usuarios/${id}/imagem`, configAuthorizationToken(token))
            .then(res => {
                // let enc = new TextEncoder();
                // let u8 = enc.encode(res.data)
                // let base64String = btoa(Uint8ToString(u8))
                dispatch({ type: ATUALIZAR_FOTO_USUARIO, payload: res.data });
                // toastr.success('Sucesso', '');
            })
            .catch(err => {
                dispatch({
                    type: ATUALIZAR_FOTO_USUARIO,
                    payload: userImgIfError
                })
            })
    }
}

export function atualizarFotoUsaurio(id, data, token) {
    return dispatch => {
        axios.put(`${BASE_URL}/usuarios/${id}/imagem`, data, configAuthorizationTokenAndContentTypeMultipart(token))
            .then(res => {
                dispatch({ type: ATUALIZAR_FOTO_USUARIO, payload: res.data });
                toastr.success('Sucesso', 'Imagem atualizada com sucesso!');
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
                dispatch({
                    type: ATUALIZAR_FOTO_USUARIO,
                    payload: userImgIfError
                })
            })
    }
}

export function recuperarSenha(data, token) {
    return dispatch => {
        axios.post(`${BASE_URL}/usuarios/recuperar-senha`, data,
            configAuthorizationTokenAndContentTypeJson(token))
            .then(res => {
                dispatch({ type: RECUPERAR_SENHA_USUARIO, payload: res.data });
                toastr.success('Sucesso', 'E-mail enviado com sucesso!');
            })
            .catch(err => {
                toastr.error('Erro', extractErrorMessage(err))
            })
    }
}

export function buscarNotificacoes(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/notificacoes`, configAuthorizationToken(token))
            .then(res => 
                dispatchEvent([
                    {
                        type: BUSCAR_NOTIFICACOES,
                        payload: res.data,
                        
                    }, 
                    {
                        type: FILTRAR_NOTIFICACOES,
                        payload: res.data,
                        
                    }
                ])
            )
    }

}
    
export function buscarSecretaria(token) {
    return dispatchEvent => {
        axios.get(`${BASE_URL}/secretarias-estaduais/1`, configAuthorizationToken(token))
        .then(res => 
            dispatchEvent({
                type: BUSCAR_SECRETARIA,
                payload: res.data
            })
    )
}

}
    