import { CADASTRAR_PRODUTO, EDITAR_PRODUTO, LISTAR_PRODUTOS, FILTRAR_PRODUTOS } from '../actions/types';

const INITIAL_STATE = {
    list: [],
    filtrados: [],

    resetNumbers: 0,
    closeModalNumbers: 0,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CADASTRAR_PRODUTO:
            return { ...state, resetNumbers: ++state.resetNumbers }

        case EDITAR_PRODUTO:
            return { ...state, closeModalNumbers: ++state.closeModalNumbers }

        case LISTAR_PRODUTOS:
            return { ...state, list: action.payload }

        case FILTRAR_PRODUTOS:
            return { ...state, filtrados: action.payload }

        default:
            return state
    }
}