import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';

const Info = props => (
    <div className='row'>
        <div className='col'>
            <b>Emitido por </b> {props.nome}
        </div>

        <div className='col text-right'>
            {moment(new Date()).format('DD/MM/YYYY HH:mm:ss')}
        </div>
    </div>
)

const mapStateToProps = state => ({
    nome: state.auth.user.pessoa.nome,
    // usuario: state.auth.user.usuario
})

export default connect(mapStateToProps, null)(Info);